.popinfo-wrap {
	position: relative;
	cursor: pointer;

	.popinfo-content {
		@include font-main;
		@include font-regular;
		position: absolute;
		top: calc(100%);
		left: -1.7rem;
		width: auto;
		color: $color-text-negative;
		font-size: 1.2rem;
		line-height: 1.6em;
		padding: 2.1rem 1.3rem 1.2rem;
		text-transform: none;
		text-align: left;
		cursor: default;
		transform: translate3d(0, -.3rem, 0);
		opacity: 0;
		transition: opacity $transition-hover ease, transform $transition-hover ease;

		&.rtl {
			left: auto;
			right: -1.7rem;

			.popinfo-bg {
				.bg-pop {
					left: auto;
					right: 2rem;
				}
			}
		}

		&.upside-down {
			top: auto;
			bottom: calc(100%);
			padding-top: 1.2rem;
			padding-bottom: 2.1rem;

			.popinfo-bg {
				top: 0;
				bottom: 1rem;

				.bg-pop {
					top: 100%;
					bottom: auto;
					margin-top: -.5rem;
					margin-bottom: 0;
				}
			}
		}

		&.show {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}

	.popinfo-bg {
		opacity: .9;
		position: absolute;
		top: 1rem;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		background-color: $color-text;
		border-radius: $radius-general;


		.bg-pop{
			@extend %pseudo;
			position: absolute;
			bottom: 100%;
			left: 2rem;
			border-radius: 50%;
			width: .9rem;
			height: .9rem;
			margin-bottom: -.5rem;
			background-color: $color-text;
		}
	}

	.popinfo-text {
		position: relative;
		z-index: 2;
	}

	&.nowrap {
		.popinfo-content {
			white-space: nowrap;
		}
	}

	&.wide {
		.popinfo-content {
			min-width: 26rem;
		}
	}
}