.section {
	&.branch-detail {
		display: flex;

		@include media {
			display: block;
		}

		.detail-info {
			width: 28rem;

			@include media {
				width: auto;
			}
		}

			.info-sum {
				background-color: $color-gray-light;
				padding: 2.8rem 2.3rem 1.5rem 2.6rem;
				margin-bottom: 4.9rem;

				.sum-title {
					@include font-semibold;
					font-size: 1.8rem;
					line-height: 2.2rem;
				}

					.title-badge {
						position: relative;
						width: 2.2rem;
						height: 2.2rem;
						font-size: 2.2rem;
						color: $color-primary;
						display: inline-block;
						vertical-align: middle;
						margin-right: 1rem;
						margin-top: -.2em;
					}

						.badge-icon {
							color: $color-white;
							font-size: 1.2rem;
							position: absolute;
							z-index: 2;
							top: 53%;
							left: 50%;
							transform: translate3d(-50%, -50%, 0);
						}

				.sum-address {
					font-size: 1.4rem;
					line-height: 2rem;
					margin-top: 2.5rem;
					max-width: 19rem;
				}

					.address-showonmap {
						color: $color-primary;
						text-decoration: underline;
					}

				.sum-workinghours {
					display: block;
					font-size: 1.2rem;
					text-transform: uppercase;
					margin-top: 1.4rem;
					color: $color-error;

					span {
						margin: 0 1rem;
					}

					&.open {
						color: $color-success;
					}
				}

				.sum-controls {
					margin-top: 2rem;
					font-size: 0;
					letter-spacing: 0;

					.btn {
						width: calc(50% - .6rem);
						font-size: 1.1rem;
						padding: .9rem 0;

						&:nth-child(2n+2){
							margin-left: 1.2rem;
						}

						.btn-icon {
							font-size: 1.3rem;

							&.pre {
								margin-right: .5rem;
							}
						}
					}
				}
			}

		.detail-right {
			width: calc(100% - 28rem);

			.breadcrumbs{
				padding: 1.8rem 0;
			}

			@include media {
				width: auto;
			}
		}

		.branch-cover {
			height: 37.2rem;
			width: 100%;
			background-color: $color-ice;

			.loaderwrap-icon{
				top: auto;
				left: auto;
				right: -1rem;
				bottom: -1rem;
				font-size: 30rem;
				transform: none;
			}
		}

		.branch-listing {
			border-left: .1rem solid $color-gray-light;

			@include media {
				border-left: none;
			}
		}
	}
}
