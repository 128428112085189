.modal-container.modal-youtube{
	.map-container {
		z-index: 1;
	}

	.modal-content {
		width: 120rem;
	}

	.modal-innercontent {
		@include media {
			padding: 1.5rem;
		}
	}

	.modal-closebtn {
		@include media {
			top: .4rem;
			right: .4rem;
		}
	}

	.youtube-wrap {
		width: 100%;
		position: relative;
		padding-bottom: 56%;
	}

	.youtube-video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}