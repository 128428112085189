.fav-button {
	min-width: 2.2rem;
	height: 2rem;
	color: rgba($color-text, .3);
	//overflow: hidden;

	&.faved {
		color: $color-primary;
	}

	.fav-button-btn {
		min-height: 2rem;
		line-height: 2rem;
	}

	.fav-button-icon{
		position: relative;
		width: 2.2rem;
		height: 2rem;
		font-size: 2.2rem;
		display: inline-block;
		vertical-align: middle;

		&:disabled {
			cursor: inherit;
		}

		i{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
		}
	}

	&.disabled {
		&:not(.faved){
			display: none;
		}
	}
}