@-webkit-keyframes spin {
    from { -webkit-transform: translate(-50%, -50%) rotate(0deg); }
    to { -webkit-transform: translate(-50%, -50%) rotate(360deg); }
}
@-moz-keyframes spin {
    from { -moz-transform: translate(-50%, -50%) rotate(0deg); }
    to { -moz-transform: translate(-50%, -50%) rotate(360deg); }
}
@keyframes spin {
    from {transform: translate(-50%, -50%) rotate(0deg);}
    to {transform: translate(-50%, -50%) rotate(360deg);}
}