.page.detail{
	.contentpage-form{
		.inputwrap{
			.input-error{
				overflow: unset;
			}
		}
	}
	&.gallery-fullscreen {
		.section.detail-content{
			z-index: 50;

			.content-left {
				z-index: 50;
				width: 100vw;
			}

			.content-gallery {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 21;

				@include media {
					width: 100%;
					margin: 0;
				}

				.gallery-mainslider{
					padding-bottom: 0;
					height: calc(100% - 15.3rem);

					@include media {
						height: 100%;
					}

					.mainslider-slider {
						left: 0;
						right: 0;
					}

					.imagewrap-image {
						background-size: contain;
					}

				}

				.gallery-thumbs {
					//height: 12rem;
					height: 15.3rem;
				}
			}
		}
	}

	.section{
		&.detail-top {
			padding: 1.8rem 0;
			color: $color-text-light;
			border-bottom: .1rem solid $color-gray-light;

			@include media {
				background-color: $color-gray-light;
				padding: 1.1rem 1.5rem;
			}

			.top-wrap {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.top-controls {
				font-size: 1.4rem;

				> * {
					vertical-align: middle;
					+ * {
						display: inline-block;
						margin-left: 3.8rem;
					}
				}

				.controls-viewers {
					background-color: $color-success;
					color: $color-text-negative;
					border-radius: $radius-general;
					font-size: 1.2rem;
					line-height: 1.4rem;
					padding: .4rem .8rem .5rem;
				}

				.fav-button {
					height: 2.4em;

					button {
						height: 100%;
					}
				}

				.share-button {
					height: 2.5em;
				}
				.controls-btn, .fav-button .fav-button-btn {
					text-transform: uppercase;
					font-size: 1.2rem;
					color: inherit;
					overflow: visible;
					&.faved {
						.fav-button-icon {
							color: $color-primary;
						}
					}

					i, .fav-button-icon {
						font-size: 2.4rem;
						width: 2.4rem;
						height: 2.4rem;
						margin-right: .8rem;
						display: inline-block;
						vertical-align: middle;
						margin-top: -.16em;
						position: static;
						transform: none;
					}
				}
			}
		}

		&.detail-topinfo {
			display: flex;
			justify-content: space-between;
			align-items: center;
			line-height: 2rem;
			padding: 1.4rem 0;

			@include media {
				padding: 0;
				line-height: 1.8rem;
			}

			.topinfo-highlights {

				li {
					display: inline-block;
					display: inline-block;
					vertical-align: middle;


					> * {
						display: block;
					}

					+ li {
						margin-left: .7rem;
					}
				}

				span {
					display: block;
					border: .1rem solid $color-ice;
					font-size: 2rem;
					text-transform: uppercase;
					border-radius: $radius-general;
					color: $color-text-light;
					line-height: 2rem;
					padding: .2rem .6rem;
					background-color: $color-white;

					@include media {
						color: $color-text;
					}
				}

				img {
					height: 1.8rem;
				}
			}

			.topinfo-id {
				text-transform: uppercase;
				color: $color-text;
				font-size: 1.4rem;

				@include media {
					font-size: 1.2rem;
				}

				strong {
					@include font-medium;
					font-size: 1.2rem;
					color: $color-text-light;
				}
			}

			.topinfo-controls {
				white-space: nowrap;

				@include media{
					.fav-button {
						height: 1.5em;

						button {
							height: 100%;
						}
					}

					.share-button {
						height: 1.1em;
					}
					button{
						span{
							margin-left: .4em;
						}
					}
				}

			}

				.controls-btn {
					display: inline-block;
					font-size: 2rem;
					vertical-align: middle;
					color: $color-text-light;

					&.faved {
						color: $color-primary;
					}

					+ .controls-btn {
						margin-left: 1.3rem;
					}

					.fav-button-icon {
						vertical-align: top;
					}
				}
		}

		&.detail-content {
			position: relative;
			z-index: 2;

			.content-wrap {
				display: flex;
				flex-flow: row wrap;

				@include media {
					display: block;
				}
			}

			.content-left {
				width: calc(100% - 46rem);
				position: relative;
				z-index: 1;

				@include media {
					width: auto;
				}
			}

			.content-right{
				width: 46rem;
				padding: 0 3rem;
				position: relative;
				z-index: 2;

				@include media {
					width: auto;
				}
			}

			.content-gallery {
				background-color: $color-white;

				@include media {
					width: calc(100% + 3rem);
					margin: 0 -1.5rem;
				}
			}

				.gallery-close {
					position: absolute;
					top: 2rem;
					right: 2rem;
					z-index: 10;
				}

				.gallery-mainslider{
					background: $color-gray-light;
					width: 100%;
					position: relative;
					padding-bottom: 52.6%;

					@include media {
						padding-bottom: 75%;
					}

					.mainslider-nav {
						position: absolute;
						top: 50%;
						transform: translate3d(0, -50%, 0);
						width: 4.8rem;
						height: 4.8rem;
						line-height: 4.8rem;
						font-size: 3rem;
						z-index: 2;

						&.prev {
							left: 0;

							@include media {
								padding-right: 2rem;
							}
						}

						&.next {
							right: 0;

							@include media {
								padding-left: 2rem;
							}
						}

						@include media {
							color: $color-text-negative;
							height: 11rem;
							width: 6.8rem;
						}
					}

					.mainslider-slider {
						position: absolute;
						top: 0;
						left: 10.5rem;
						right: 10.5rem;
						bottom: 0;
						z-index: 1;

						@include media {
							left: 0;
							right: 0;
						}
					}

						.slider-imagewrap {
							width: 100%;
							height: 100%;
						}

							.imagewrap-image {
								position: relative;
								width: 100%;
								height: 100%;
								background-size: contain;
								background-repeat: no-repeat;
								background-position: center;
								opacity: 1;
								transition: opacity $transition-hover ease;

								@include media {
									background-size: cover;
								}


								&.swiper-lazy-loaded {
									opacity: 1;
									+ .imagewrap-loader {
										opacity: 0;
									}
								}
							}

							.imagewrap-loader {
								position: absolute;
								top: 50%;
								left: 50%;
								width: 5rem;
								height: 5rem;
								border-radius: 50%;
								overflow: hidden;
								transform: translate3d(-50%, -50%, 0);
								transition: opacity $transition-hover ease;

								.imagewrap-image {
									background-size: cover;
								}
							}

					.mainslider-fullscreen {
						position: absolute;
						bottom: 1.8rem;
						left: 50%;
						transform: translate3d(-50%, 0, 0);
						z-index: 2;
						color: $color-text;
						padding-top: .4rem;
						padding-bottom: .4rem;
						font-size: 1rem;

						.btn-icon {
							font-size: 1.2rem;
						}
					}

				}

				.gallery-thumbs {
					position: relative;
					//height: 9.5rem;
					height: 11.4rem;
					padding: 1rem 1.9rem;
					background-color: $color-white;

					&:before, &:after {
						@extend %pseudo;
						position: absolute;
						top: 0;
						bottom: 0;
						background-color: $color-white;
						width: 2.4rem;
						left: 0;
						z-index: 2;
					}

					&:after {
						left: auto;
						right: 0;
					}
				}

					.thumbs-nav {
						position: absolute;
						top: 50%;
						color: $color-text-light;
						transform: translate3d(0, -50%, 0);
						width: 2.4rem;
						height: 2.4rem;
						line-height: 2.4rem;
						font-size: 1.8rem;
						z-index: 3;

						&.prev {
							left: 0;
						}

						&.next {
							right: 0;
						}
					}

					.thumbs-carousel {
						height: 100%;
						width: 100%;

						.swiper-slide {
							padding: 0 .5rem;
						}
					}

						.carousel-imagebtn {
							position: relative;
							display: block;
							width: 100%;
							height: 100%;

							&:before {
								@extend %pseudo;
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								border: .1rem solid $color-primary;
								border-bottom-width: .5rem;
								opacity: 0;
								transition: opacity $transition-hover ease;
							}

							&.active {
								&:before {
									opacity: 1;
								}
							}
						}

						.carousel-image {
							width: 100%;
							height: 100%;
						}

			.content-details {
				margin-top: 3rem;
			}

				.details-tabs {
					@include media {
						margin: 0 -1.5rem;
						width: calc(100% + 3rem);
					}
				}

					.tabs-tab {
						padding: 5.6rem 5.6rem;

						@include media {
							padding: 2.6rem 1.5rem;
						}
					}

					.tab-content {
						position: relative;

						&.expandable {
							max-height: 39.6rem;
							overflow: hidden;

							&:before {
								@extend %pseudo;
								position: absolute;
								bottom: 0;
								left: 0;
								right: 0;
								height: 4rem;
								pointer-events: none;
								background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
								background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
								background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
								filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
							}

							&.expanded {
								max-height: none;

								&:before {
									display: none;
								}
							}
						}
					}

					.tab-textarea {
						background-color: $color-gray-light;
						padding: 3.6rem 3.2rem;
						font-size: 1.5rem;
						line-height: 1.47em;

						@include media {
							font-size: 1.4rem;
							line-height: 1.57em;
							padding: 1.6rem 2rem 2rem;

							h1, h2, h3, h4, h5, h6 {
								line-height: 1.38em;
							}
						}
					}

					.tab-specslist {
						display: flex;
						justify-content: space-between;
						flex-flow: row wrap;

						@include media {
							display: block;
						}
					}

			.tab-equipmentlist {
				table {
					.icon-check-thin {
						color: $color-blue;
					}

					.group-title {
						background-color: white;
						padding-top: 35px;

						th {
							padding-top: 1em;
							padding-bottom: 1em;
							background-color: white;

							&:first-child {
								font-weight: bold;
							}
						}
					}

					th:nth-child(n+2) {
						max-width: 4em;
						width: 6em;
						text-align: center;
					}
				}
			}

						.specslist-group {
							width: calc(50% - 3rem);

							@include media {
								margin-top: 2em;
								width: auto;
							}

							+ .specslist-group + .specslist-group {
								@include media{
									margin-top: 2em;
								}
								margin-top: 3.6rem;
							}
						}

			.equ

							.group-title {
								@include font-semibold;
								font-size: 1.8rem;
								margin-bottom: 2rem;

								@include media {
									font-size: 1.5rem;
									margin-bottom: 1.1rem;
								}
							}
							.group-table {
								width: 100%;
								td{
									@include font-bold;
									color: $color-primary;
								}

								th {
									padding-right: 1rem;
								}

								td {
									padding-left: 1rem;
								}
							}

					.tab-gallery {
						width: 100%;

						.swiper-slide {
							width: auto;

							+ .swiper-slide {
								margin-left: 3.8rem;
							}
						}

						.swiper-wrapper {
							padding-bottom: 8.8rem;
						}

						.swiper-scrollbar {
							left: 0;
							width: 100%;
							background-color: $color-gray-light;
						}

						.swiper-scrollbar-drag {
							background-color: $color-primary;
						}
					}

						.gallery-item {
							display: inline-block;
							height: 34rem;

							@include media {
								height: auto;

								+ .gallery-item {
									margin-top: 1.5rem;
								}
							}
						}

							.item-btn {
								height: 100%;
								background: $color-text;

								&:hover {
									.item-image {
										opacity: .9;
									}
								}
							}

							.item-image {
								height: 100%;
								transition: opacity $transition-hover ease;

								@include media {
									height: auto;
								}
							}

					.tab-expand {
						margin-top: 5.6rem;
					}

					.tab-related {
						margin: -1.5rem -1.5rem;
						width: calc(100% + 3rem);
					}

			.detail-info {
				@include media {
					padding-top: 1rem;
				}
			}

				.info-title {
					@include font-semibold;
					font-size: 2.2rem;
					line-height: 2.8rem;
				}

				.info-mainfeatures {
					padding: 1.4rem 0;
					color: $color-text-light;

					li {
						display: inline-block;
						font-size: 1.4rem;

						+ li {
							margin-left: 1.8rem;
						}
					}

					i {
						font-size: 1.3rem;
						margin-right: .6rem;
					}

					+ .info-highlights {
						padding-top: 0;
					}
				}

				.info-highlights {
					padding: 1.4rem 0;

					li {
						display: inline-block;
						display: inline-block;
						vertical-align: middle;


						> * {
							display: block;
						}

						+ li {
							margin-left: .7rem;
						}
					}

					span {
						display: block;
						border: .1rem solid $color-ice;
						font-size: 1.2rem;
						text-transform: uppercase;
						border-radius: $radius-general;
						color: $color-text-light;
						line-height: 2rem;
						padding: 0 .5rem;
						background-color: $color-white;

						@include media {
							color: $color-text;
						}
					}

					img {
						height: 1.8rem;
					}
				}

				.info-ncap {
					@include font-semibold;
					position: relative;
					z-index: 4;
					border-top: .1rem solid $color-ice;
					border-bottom: .1rem solid $color-ice;
					font-size: 1.2rem;
					color: rgba($color-text, .7);
					padding: 1rem 0;

					> * {
						display: inline-block;
						vertical-align: middle;
					}

					@include media {
						padding: 1rem 1.5rem;
						width: calc(100% + 3rem);
						margin: 0 -1.5rem;
					}
				}

					.ncap-stars {
						color: $color-gray;
						font-size: 1.8rem;
						display: inline-block;
						margin-left: 1.2rem;

						i.active {
							color: $color-yellow;
						}

						i + i {
							margin-left: .5rem;
						}
					}

					.ncap-info {
						margin-left: .6rem;
					}

				.info-price {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 1.5rem 0;
				}

					.price-current {
						@include font-semibold;
						font-size: 3.8rem;
						color: $color-primary;

						@include media {
							font-size: 2.8rem;
							line-height: 3.4rem;
						}
					}

					.price-listing {
						font-size: 1.4rem;
						strong {
							@include font-medium;
							color: $color-text-light;
						}

						&.higher {
							span {
								text-decoration: line-through;
							}
						}
					}

				.info-costs {
					position: relative;
					padding: 1.4rem 0;
					font-size: 1.4rem;
					line-height: 1.6rem;
					z-index: 3;

					@include media {
						padding: 0;
					}

					.costs-sum {
						position: relative;
						background-color: $color-gray-light;
						display: block;
						width: 100%;
						border-radius: $radius-general;
						padding: 1.2rem 1.5rem;
						text-align: left;

						&:after{
							position: absolute;
							right: 1.5rem;
							opacity: .5;
							font-size: .7rem;
							color: $color-text;
							@include icon('caret-down');
							top: 50%;
							transform: translate3d(0, -50%, 0);
						}

						strong {
							color: $color-text-light;
						}
					}

					.costs-wrap {
						margin-top: .2rem;
					}

					.costs-list {
						border-radius: $radius-general;
						background-color: $color-ice;
					}

						.list-cost {
							padding: 1.2rem 1.5rem;
							display: flex;
							justify-content: space-between;
						}

							.cost-info {
								margin-left: 1rem;
								> i {
									opacity: .5;
									font-size: 1.2rem;
								}
							}

							.cost-num {
								width: 7rem;
							}
				}

				.info-controls {
					position: relative;
					z-index: 2;

					@include media {
						position: fixed;
						bottom: 0;
						left: 0;
						width: 100%;
						background: $color-white;
						padding: 1rem 2rem;
						z-index: 20;
						border-top: .1rem solid $color-ice;
					}



					.controls-button {
						width: calc(50% - 1rem);
						position: relative;
						vertical-align: middle;

						@include media {
							width: calc(50% - 2.5rem);
							position: static;
							padding-left: 2rem;
							padding-right: 2rem;

							.btn-info {
								position: relative;
								right: auto;
								margin-left: .6rem;
								z-index: 10;
								display: inline-block;
								vertical-align: bottom;
								margin-bottom: -.4rem;
							}
						}

						&:first-child {
							z-index: 2;
						}

						&:nth-child(2n+2) {
							margin-left: 2rem;
						}

						&:disabled {
							opacity: 1;

							&.reservate {
								border-color: $color-gray-light;
								color: $color-text-light;

								&:hover, &:active {
									background-color: transparent;
								}
							}

							&.bid{
								background-color: rgba($color-red-light, .5);
								border: none;
								//color: $color-white;
							}
						}
					}

					.controls-phone {
						display: inline-block;
						vertical-align: middle;
						position: relative;
						z-index: 3;
						width: 7rem;
						height: 7rem;
						line-height: 6.6rem;
						font-size: 2.6rem;
						text-align: center;
						margin-left: -1rem;
						margin-right: -1rem;
						background-color: $color-primary;
						color: $color-white;
						border-radius: 50%;
						border: .4rem solid $color-white;
						transition: background-color $transition-superfast;

						&:active {
							background-color: darken($color-primary, 20);
						}
					}

					.controls-note {
						color: $color-primary;
						font-size: 1.2rem;
					}
				}

				.info-dealers {
					position: relative;
					padding: 1.4rem 0;
					font-size: 1.4rem;
					line-height: 1.6rem;
					z-index: 1;

					@include media {
						padding: 0;
					}

					.dealers-sum {
						position: relative;
						background-color: $color-gray-light;
						display: block;
						width: 100%;
						border-radius: $radius-general;
						padding: 1.2rem 1.5rem;
						text-align: left;

						&:after{
							position: absolute;
							right: 1.5rem;
							opacity: .5;
							font-size: .7rem;
							color: $color-text;
							@include icon('caret-down');
							top: 50%;
							transform: translate3d(0, -50%, 0);
						}

						strong {
							color: $color-text-light;
						}
					}

					.dealers-wrap {
						margin-top: .2rem;
					}

					.dealers-list {
						border-radius: $radius-general;
						background-color: $color-ice;
					}

						.list-cost {
							padding: 1.2rem 1.5rem;
							display: flex;
							justify-content: space-between;
						}

							.cost-info {
								margin-left: 1rem;
								> i {
									opacity: .5;
									font-size: 1.2rem;
								}
							}

							.cost-num {
								width: 7rem;
							}
				}

				.info-dealer {
					background-color: $color-gray-light;
					border-radius: $radius-general;
					margin-top: 3.7rem;

					@include media {
						margin-top: 1.5rem;
					}

					.dealer-head{
						padding: 2.7rem 2.1rem 2.7rem 2.7rem;
						position: relative;
						display: flex;
						flex-flow: row wrap;
						align-items: flex-start;
						justify-content: space-between;
					}

						.head-content {
							flex: 1;
						}

					.dealer-badge {
						position: absolute;
						top: 0.3rem;
						left: 0;
						width: 1.8rem;
						height: 1.8rem;
						font-size: 1.8rem;
						color: $color-primary;
						display: inline-block;
					}

						.badge-icon {
							color: $color-white;
							font-size: 0.9rem;
							position: absolute;
							z-index: 2;
							top: 53%;
							left: 50%;
							transform: translate3d(-50%, -50%, 0);
						}

					.dealer-image {
						width: 5rem;
						height: 5rem;
						margin-right: 2rem;
						border-radius: 50%;
						background-color: $color-white;

						.loaderwrap-icon {
							font-size: 5rem;
						}
					}

					.dealer-title {
						@include font-semibold;
						font-size: 1.8rem;
						text-decoration: underline;
						text-transform: uppercase;
						display: block;
						padding-top: .2rem;
						position: relative;
						padding-left: 2.2rem;
					}

					.dealer-info {
						@include font-semibold;
						margin-top: 1rem;
						color: rgba($color-text, .5);
						text-transform: uppercase;
						font-size: 1.2rem;
					}

						.info-workinghours {
							margin-left: 1rem;

							span {
								margin: 0 1rem;
							}

							&.open {
								color: $color-success;
							}
						}

					.dealer-rep {
						padding: 1.6rem 2rem 1.7rem;
						border-top: .1rem solid rgba($color-text, .2);
					}

						.rep-image {
							border-radius: 50%;
							display: inline-block;
							vertical-align: middle;
							height: 3.6rem;
							margin-right: 1rem;
						}

						.rep-title {
							@include font-medium;
							display: inline-block;
							vertical-align: middle;
							font-size: 1.6rem;
						}

						.rep-role {
							text-transform: uppercase;
							color: rgba($color-text, .4);
							display: inline-block;
							vertical-align: middle;
							font-size: 1.2rem;
							margin-left: 1.5rem;
						}

					.dealer-controls {
						display: flex;

						> * {
							min-width: 50%;
							border-radius: 0;

							&:nth-child(2n+1){
								border-bottom-left-radius: $radius-general;
								margin-right: .1rem;
							}

							&:nth-child(2n+2){
								border-bottom-right-radius: $radius-general;
							}
						}
					}
				}

				.info-credit-calculation{
					background-color: $color-gray-light;
					border-radius: $radius-general;
					border: solid 1px #ebebf3;
					padding: 17px 19px;
					margin-top: 3.7rem;
					@include media{
						margin-top: 1.1rem;
					}
					h2{
						font-size: 20px;
						color: #161d4c;
						font-weight: 600;
					}
					p{
						margin-top: 8px;
						line-height: 1.43;
						color: #161d4c;
						font-weight: 400;
					}
					.no-padding{
						padding-right: 0;
						padding-left: 0;
						@include media{
							padding-right: 1.1rem;
							padding-left: 1.1rem;
						}
					}

					.credit-price.currency-after{
						&:after{
							content: 'TL';
							position: absolute;
							right: 2rem;
							top: 1.9rem;
							opacity: 0.6;
						}
					}
					.credit-price.month-after{
						&:after{
							content: 'AY';
							position: absolute;
							right: 2rem;
							top: 1.9rem;
							opacity: 0.6;
						}
					}
					.credit-price > input{
						padding: 1.5rem 1.3rem;
					}
				}
				.info-credit-results{
					background-color: $color-gray-light;
					border-radius: $radius-general;
					border: solid 1px #ebebf3;
					margin-top: 1.2rem;
					margin-bottom: 3.7rem;
					.tablePad{
						padding-right: 15px;
						padding-left: 15px;
						border-right: 1px solid #d5d5e3;
						border-left: 1px solid #d5d5e3;
					}
					th{
						background-color: #ebebf3;
						&:nth-child(1){
							width: 16rem;
							padding-right: 0.5rem;
						}
						&:nth-child(2){
							width: 8rem;
							padding-right: 0.5rem;
							padding-left: 0.5rem;
						}
						&:nth-child(3){
							padding-right: 0.5rem;
							padding-left: 1rem;
						}
						&:nth-child(4){
							width: 10rem;
							padding-right: 0.5rem;
							padding-left: 1rem;
						}
					}
					td{
						&:nth-child(1){
							min-width: 10rem;
							@include media{
								min-width: 11rem;
							}
							padding-top: 20px;
							padding-bottom: 20px;
							padding-right: 0.5rem;
						}
						&:nth-child(2){
							padding-top: 20px;
							padding-bottom: 20px;
							width: 10rem;
							padding-right: 0.5rem;
							padding-left: 0.5rem;
						}
						&:nth-child(3){
							padding-top: 20px;
							padding-bottom: 20px;
							padding-right: 0.5rem;
							padding-left: 1rem;
							width: 121px;
						}
						&:nth-child(4){
							padding-top: 20px;
							padding-bottom: 20px;
							padding-right: 0.5rem;
							padding-left: 1rem;
						}

						button{
							padding: 8px 0px;
							span.btn-content{
								font-size: 1.8rem;
							}
							@include media{
								span.btn-content{
									font-size: 1.8rem;
								}
							}
						}
					}

				}
		}

		&.detail-related {
			border-top: .1rem solid $color-gray-light;
			padding: 5.4rem 0 10.1rem;
			margin-top: 3.3rem;

			.related-innerwrap {
				width: calc(100% + 3rem);
				margin: 0 -1.5rem;
			}

			.section.listing{
				.listing-content {
					padding: 0;
				}

				.content-results {
					padding: 0;
				}
			}

			.related-title {
				@include font-regular;
				text-align: center;
				margin-bottom: 4.4rem;
				font-size: 2.2rem;
			}
		}

		&.detail-banners {
			padding: 7rem 0 10.2rem;
			text-align: center;

			.banners-item {
				height: 33rem;
				display: inline-block;

				@include media {
					height: auto;
				}

				+ .banners-item {
					margin-left: 4rem;

					@include media {
						margin: 1.5rem 0 0;
					}
				}

				img {
					height: 100%;

					@include media {
						height: auto;
					}
				}
			}
		}

		&.detail-blogposts {
			padding: 5.5rem 0 8.7rem;
			max-width: 160rem;
			margin: 0 auto;
			text-align: center;
			position: relative;
			z-index: 1;

			.blogposts-list {
				display: flex;
				flex-flow: row wrap;
				justify-content: center;
				margin: 0 -1.5rem;
				width: calc(100% + 3rem);
			}

			.blogposts-item {
				width: 25%;
				padding: 1.5rem;

				@include media {
					width: 50%;
				}
			}
		}
	}
}
