.breadcrumbs {
	@include font-medium;
	font-size: 0;
	letter-spacing: 0;
	color: $color-text-light;

	.breadcrumbs-item {
		font-size: 1.2rem;
		display: inline-block;
		text-transform: uppercase;

		+ .breadcrumbs-item {
			&:before{
				@include icon('caret-right');
				font-size: .5em;
				display: inline-block;
				vertical-align: middle;
				margin: -.3rem .5rem 0;
			}
		}
	}

	&.section {
		padding: 2.4rem 0 0;
	}
}