.wysiwyg {
	@include font-regular;
	color: rgba($color-text, 0.7);
	line-height: 1.2em;
	h1, h2, h3, h4, h5, h6 {
		color: $color-text;
		@include font-medium;
		margin-top: 3em;
		margin-bottom: .5em;

		+ * {
			margin-top: 0em;
		}
	}

	strong, b {
		color: $color-text;
	}

	ul {
		list-style: disc inside;

		li {
			+ li {
				margin-top: .5em;
			}
		}
	}

	blockquote:not(.instagram-media) {
		font-family: inherit;
		color: $color-text;
		font-size: 1.45em;
		line-height: 1.44em;
		position: relative;

		@include media {
			padding-left: 3.9rem;
			padding-right: 3.9rem;
		}

		&:before, &:after {
			position: absolute;
			color: $color-black;
			font-size: 6em;
			opacity: .05;

			@include media {
				font-size: 9rem;
			}
		}

		&:before {
			content: '“';
			top: .25em;
			right: calc(100% + .1em);

			@include media {
				left: 0;
				right: auto;
			}
		}

		&:after {
			content: '“';
			bottom: .25em;
			left: calc(100% + 0em);
			transform: rotate(180deg);

			@include media {
				right: 0;
				left: auto;
			}
		}
	}

	img {
		&:not([style*="float"]){
			width: calc(100% + 10rem) !important;
			margin: 0 -5rem;
			max-width: none;
			display: block;
			height: auto !important;
		}

		&[style*="float:left"], &[style*="float: left"] {
			width: auto;
			margin: 2rem 2rem 2rem 0;
		}

		&[style*="float:right"], &[style*="float: right"] {
			width: auto;
			margin: 2rem 0 2rem 2rem;
		}
	}

	> *:not(br) {
		margin: 2em 0 1em;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}