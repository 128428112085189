.section{
	&.footer {
		.footer-jump-to{
			display: block;
			position: fixed;
			bottom: 20px;
			right: 30px;
			z-index: 99;
			font-size: 18px;
			border: none;
			outline: none;
			background-color: #f7f7fa;
			color: #8688a2;
			cursor: pointer;
			padding: 15px;
			border-radius: 4px;
		}
		.footer-jump-to:hover{
			background-color: #555;
		}
		.footer-bottom {
			background-color: $color-text;
			color: $color-text-negative;
			padding: 5rem 0 5rem;
			font-size: 1.4rem;

			@include media {
				padding: 3rem 0 3.3rem;
			}
		}

		.footer-wrap {
			display: flex;
			justify-content: space-between;

			@include media {
				display: block;
				padding: 0 1.5rem;
			}
		}

		.footer-logo{
			display: inline-block;
			width: 9.4rem;

			@include media {
				width: 10.4rem;
			}

			img {
				width: 100%;
			}
		}

		.footer-col {
			@include media {
				+ .footer-col {
					margin-top: 2rem;
				}
			}
		}

		.footer-nav {
			width: 55.72%;
			display: flex;
			justify-content: space-between;

			@include media {
				width: auto;
				flex-flow: row wrap;
			}
		}

			.nav-col {
				line-height: 2.43em;

				@include media {
					font-size: 1.4rem;
					line-height: 2.14em;
				}
			}

			.nav-contact{
				i {
					margin-right: .9rem;
				}

				@include media {
					width: 100%;
					margin-top: 6rem;
					display: static;
					justify-content: space-between;
					align-items: center;
					.contact-text{
						margin-bottom: 2rem;
					}
				}
			}

				.contact-social {
					margin-top: 1.8rem;

					li {
						display: inline-block;
						width: 3rem;
						height: 3rem;

						+ li {
							margin-left: 1rem;
						}

						a, img {
							width: 100%;
						}
					}

					@include media {
						margin-top: 0;
					}
				}

			.footer-app {
				@include media {
					padding: 0 3rem;
					text-align: center;
				}
			}

				.app-title {
					line-height: 3.4rem;
					text-transform: uppercase;
					margin-bottom: .3rem;

					@include media {
						@include font-regular;
						font-size: 1.4rem;
						margin-bottom: 1rem;
					}
				}

				.app-link {
					width: calc(50% - .75rem);

					+ .app-link {
						margin-left: .6rem;

						@include media {
							margin-left: 1.5rem;
						}
					}
				}

			.footer-subscription {
				margin-top: 1.2rem;
				position: relative;
				width: 30rem;

				@include media {
					width: auto;
					margin-top: 4rem;
					margin-bottom: 4.4rem;
				}
			}

				.subscription-input {
					> input {
						background: transparent;
						border: .1rem solid rgba($color-white, .2);
						color: $color-text-negative;
						border-radius: $radius-general;
						display: block;
						font-size: 1.1rem;
						padding: 1.9rem 1.7rem;
						line-height: 1.3rem;
						width: 31rem;

						@include media {
							padding: 1.3rem 1rem;
							line-height: 1.2rem;
							width: 31rem;
						}

						@include placeholder() {
							color: $color-text-negative-light;
						}
					}
				}

				.subscription-submitwrap {
					position: absolute;
					min-width: 3rem;
					min-height: 3rem;
					right: 1rem;
					top: 2.6rem;
					transform: translate3d(0, -50%, 0);

					@include media {
						right: 0.6rem;
						top: 2rem;
					}
				}

					.submitwrap-submit {
						width: 3rem;
						height: 4rem;
						font-size: 1.1rem;
					}

					.submitwrap-loader {
						@include spin;
						position: absolute;
						top: 50%;
						left: 50%;
						display: inline-block;
						width: 1.8rem;
						height: 1.8rem;
						font-size: 1.8rem;
					}

					.submitwrap-complete {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate3d(-50%, -50%, 0);
						width: 1.8rem;
						height: 1.8rem;
						font-size: 1.8rem;
					}

		.footer-copyright {
			margin-top: 8rem;
			font-size: 1.2rem;
			color: $color-text-negative-light;

			@include media {
				margin-top: 4.8rem;
				margin-bottom: 5.7rem;
			}
		}

			.copyright-disclaimer {
				width: 50%;
				font-size: 1.2rem;
				@include media{
					width: 100%;
				}
			}

			.copyright-designby {
				@include media {
					margin-top: 2.6rem;
				}

				a {
					text-decoration: underline;
				}
			}

		.footer-brands {
			background-color: $color-gray-light;
			color: $color-text-light;
			padding: 6rem 0;
			font-size: 1.4rem;

			@include media {
				padding: 2.7rem 1.5rem;
			}
		}

			.brands-list {
				display: flex;
				justify-content: space-between;
			}

				/*.list-group {
					@include media {
						width: calc(50% - 1.5rem);
					}
				}*/

			.brands-item {
				//width: 16.66666666666667%;
				line-height: 2rem;
				padding: 1rem 0;

				@include media {
					font-size: 1.2rem;
				}
			}

				.item-count {
					margin-left: .4rem;
				}
		.payment-methods{
			width: 50%;
			text-align: right;
			margin-top: -30px;
			@include media{
				width: 100%;
				text-align:left;
			}
			img {
				width: 70px;
				display: inline-block;
				margin-left: 25px;
				@include media {
					margin-left: 0;
					margin-right: 25px;
				}
			}
		}
	}

}
