.modal-container.modal-bid{
	text-align: center;
	font-size: 1.8rem;

	.modal-closebtn {
		background-color: transparent;
		color: $color-text-light;
	}

	.modal-content {
		width: 65rem;
	}

	.modal-innercontent {
		padding: 8rem 10rem 10rem;

		@include media {
			padding: 3rem 2rem;
		}
	}

	.bid-title {
		@include font-bold;
		margin-bottom: 4rem;
		font-size: 3.2rem;
		line-height: 1.2em;

		strong {
			font-weight: inherit;
			color: $color-primary;
		}
	}



		.form-message {
			margin-top: 2rem;
		}

		.form-submitbtn {
			margin-top: 2rem;
		}

	.bid-complete {
		font-size: 1.8rem;
		line-height: 1.2em;
	}

		.complete-icon {
			font-size: 10rem;
			color: $color-success;
		}

		.complete-description {
			margin-top: 2rem;

			strong {
				@include font-semibold;
			}
		}

		.complete-controls {
			@include font-semibold;
			margin-top: 1.5rem;

			> * + * {
				margin-left: 2rem;
			}
		}

	.bid-login {
		> * + * {
			margin-top: 2rem;
		}
	}
}