@charset "UTF-8";
*:focus {
  outline: 0 none; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent; }

body {
  margin: 0;
  padding: 0;
  line-height: 1; }

iframe {
  border: 0; }

h1, h2, h3, h4, h5, h6, p, ul, ol, figure, button, table {
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 600; }

strong {
  font-weight: 600; }

sup {
  position: relative;
  top: -.5em;
  font-size: 65%;
  line-height: 0;
  vertical-align: baseline; }

a, button {
  color: inherit;
  cursor: pointer; }

a {
  text-decoration: none; }

button {
  overflow: visible;
  border: 0;
  font: inherit;
  letter-spacing: inherit;
  background: none;
  -webkit-font-smoothing: inherit; }

img {
  max-width: 100%;
  height: auto;
  border: 0;
  display: block;
  outline: none; }

ul {
  list-style-type: none; }

i {
  font-style: normal; }

select {
  box-sizing: border-box;
  max-width: 100%; }

address {
  font-style: normal; }

input {
  border-radius: 0;
  box-shadow: none;
  border: none;
  font-size: inherit; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

::-moz-focus-inner {
  padding: 0;
  border: 0; }

hr {
  border: none;
  border-bottom: 1px solid #000;
  height: 0;
  background: transparent; }

* {
  box-sizing: border-box; }

.contentbox:before, .popinfo-wrap .popinfo-bg .bg-pop, .tabs-container .tabs-labels:before, .tabs-container .labels-item:before, .section.listing .banner-youtube .text-heading:before, .section.listing-filters .filter-title:after, .section.dealer-detail .branches-list .branch-title:before, .page.detail .section.detail-content .gallery-thumbs:before, .page.detail .section.detail-content .gallery-thumbs:after, .page.detail .section.detail-content .carousel-imagebtn:before, .page.detail .section.detail-content .tab-content.expandable:before, .section.listprices .listprices-table tbody:before, .section.account-nav .nav-item:before {
  content: ' ';
  overflow: hidden;
  display: block; }

.clear:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  overflow: hidden; }

.only-mobile {
  display: none; }

@media (max-width: 960px) {
  .only-web {
    display: none; }
  .only-mobile {
    display: block; } }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?na5yty");
  src: url("../fonts/icomoon.eot?na5yty#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?na5yty") format("truetype"), url("../fonts/icomoon.woff?na5yty") format("woff"), url("../fonts/icomoon.svg?na5yty#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.inputwrap.type-checkbox .checkwrap input + label > span:before, .breadcrumbs .breadcrumbs-item + .breadcrumbs-item:before, .collapse-group .collapse-group-trigger:after, .section.listing-filters .filters-filter.type-tree .item-subgroup > .item-wrap.expandable:after, .section.listing-filters .filter-title:after, .section.dealer-detail .branches-list .branch-sum:before, .section.brand-detail .dealers-list .branch-sum:before, .page.detail .section.detail-content .info-costs .costs-sum:after, .page.detail .section.detail-content .info-dealers .dealers-sum:after, .section.reservation-payment .billing-btn:after, [class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-error:before {
  content: "\e931"; }

.icon-minoto:before {
  content: "\e930"; }

.icon-filter:before {
  content: "\e92e"; }

.icon-user:before {
  content: "\e92a"; }

.icon-card:before {
  content: "\e92b"; }

.icon-notification:before {
  content: "\e929"; }

.icon-lock:before {
  content: "\e92c"; }

.icon-security:before {
  content: "\e923"; }

.icon-wallet:before {
  content: "\e920"; }

.icon-handshake:before {
  content: "\e921"; }

.icon-calendar:before {
  content: "\e91f"; }

.icon-calendar-2:before {
  content: "\e92d"; }

.icon-trash:before {
  content: "\e91e"; }

.icon-marker:before {
  content: "\e91d"; }

.icon-ribbon:before {
  content: "\e91c"; }

.icon-question:before {
  content: "\e91b"; }

.icon-fuel:before {
  content: "\e918"; }

.icon-star:before {
  content: "\e917"; }

.icon-transmission:before {
  content: "\e919"; }

.icon-engine:before {
  content: "\e91a"; }

.icon-google:before {
  content: "\e924"; }

.icon-whatsapp:before {
  content: "\e914"; }

.icon-facebook:before {
  content: "\e916"; }

.icon-twitter:before {
  content: "\e915"; }

.icon-share:before {
  content: "\e912"; }

.icon-compare:before {
  content: "\e913"; }

.icon-arrow-left:before {
  content: "\e925"; }

.icon-arrow-right:before {
  content: "\e926"; }

.icon-arrow-up:before {
  content: "\e927"; }

.icon-arrow-down:before {
  content: "\e928"; }

.icon-caret-right:before, .breadcrumbs .breadcrumbs-item + .breadcrumbs-item:before {
  content: "\e90e"; }

.icon-caret-left:before {
  content: "\e90f"; }

.icon-caret-up:before {
  content: "\e910"; }

.icon-caret-down:before, .page.detail .section.detail-content .info-costs .costs-sum:after, .page.detail .section.detail-content .info-dealers .dealers-sum:after, .section.reservation-payment .billing-btn:after {
  content: "\e911"; }

.icon-close:before {
  content: "\e90d"; }

.icon-search:before {
  content: "\e90b"; }

.icon-spinner:before {
  content: "\e90a"; }

.icon-check-round:before {
  content: "\e92f"; }

.icon-check-thin:before {
  content: "\e922"; }

.icon-check:before, .inputwrap.type-checkbox .checkwrap input + label > span:before {
  content: "\e909"; }

.icon-angle-down:before, .collapse-group .collapse-group-trigger:after, .section.listing-filters .filter-title:after, .section.dealer-detail .branches-list .branch-sum:before, .section.brand-detail .dealers-list .branch-sum:before {
  content: "\e905"; }

.icon-angle-up:before {
  content: "\e906"; }

.icon-angle-left:before {
  content: "\e907"; }

.icon-angle-right:before, .section.listing-filters .filters-filter.type-tree .item-subgroup > .item-wrap.expandable:after {
  content: "\e908"; }

.icon-playstore:before {
  content: "\e900"; }

.icon-appstore:before {
  content: "\e901"; }

.icon-envelope:before {
  content: "\e902"; }

.icon-phone:before {
  content: "\e903"; }

.icon-phone-nude:before {
  content: "\e932"; }

.icon-heart:before {
  content: "\e904"; }

.icon-heart-empty:before {
  content: "\e90c"; }

.icon-new-tab:before {
  content: "\e933"; }

.icon.link:before {
  content: "\e934"; }

.icon-try:before {
  content: "\e935"; }

.icon-youtube-play:before {
  content: "\e936"; }

@font-face {
  font-family: "Barlow";
  src: url("../fonts/subset-Barlow-ExtraLight.eot");
  src: url("../fonts/subset-Barlow-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Barlow-ExtraLight.woff2") format("woff2"), url("../fonts/subset-Barlow-ExtraLight.woff") format("woff"), url("../fonts/subset-Barlow-ExtraLight.ttf") format("truetype"), url("../fonts/subset-Barlow-ExtraLight.svg#Barlow") format("svg");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  font-display: fallback;
  unicode-range: U+0020-017E; }

@font-face {
  font-family: "Barlow";
  src: url("../fonts/subset-Barlow-Light.eot");
  src: url("../fonts/subset-Barlow-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Barlow-Light.woff2") format("woff2"), url("../fonts/subset-Barlow-Light.woff") format("woff"), url("../fonts/subset-Barlow-Light.ttf") format("truetype"), url("../fonts/subset-Barlow-Light.svg#Barlow") format("svg");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: fallback;
  unicode-range: U+0020-017E; }

@font-face {
  font-family: "Barlow";
  src: url("../fonts/subset-Barlow-Regular.eot");
  src: url("../fonts/subset-Barlow-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Barlow-Regular.woff2") format("woff2"), url("../fonts/subset-Barlow-Regular.woff") format("woff"), url("../fonts/subset-Barlow-Regular.ttf") format("truetype"), url("../fonts/subset-Barlow-Regular.svg#Barlow") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: fallback;
  unicode-range: U+0020-017E; }

@font-face {
  font-family: "Barlow";
  src: url("../fonts/subset-Barlow-Medium.eot");
  src: url("../fonts/subset-Barlow-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Barlow-Medium.woff2") format("woff2"), url("../fonts/subset-Barlow-Medium.woff") format("woff"), url("../fonts/subset-Barlow-Medium.ttf") format("truetype"), url("../fonts/subset-Barlow-Medium.svg#Barlow") format("svg");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: fallback;
  unicode-range: U+0020-017E; }

@font-face {
  font-family: "Barlow";
  src: url("../fonts/subset-Barlow-MediumItalic.eot");
  src: url("../fonts/subset-Barlow-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Barlow-MediumItalic.woff2") format("woff2"), url("../fonts/subset-Barlow-MediumItalic.woff") format("woff"), url("../fonts/subset-Barlow-MediumItalic.ttf") format("truetype"), url("../fonts/subset-Barlow-MediumItalic.svg#Barlow") format("svg");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-display: fallback;
  unicode-range: U+0020-017E; }

@font-face {
  font-family: "Barlow";
  src: url("../fonts/subset-Barlow-SemiBold.eot");
  src: url("../fonts/subset-Barlow-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Barlow-SemiBold.woff2") format("woff2"), url("../fonts/subset-Barlow-SemiBold.woff") format("woff"), url("../fonts/subset-Barlow-SemiBold.ttf") format("truetype"), url("../fonts/subset-Barlow-SemiBold.svg#Barlow") format("svg");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: fallback;
  unicode-range: U+0020-017E; }

@font-face {
  font-family: "Barlow";
  src: url("../fonts/subset-Barlow-Bold.eot");
  src: url("../fonts/subset-Barlow-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Barlow-Bold.woff2") format("woff2"), url("../fonts/subset-Barlow-Bold.woff") format("woff"), url("../fonts/subset-Barlow-Bold.ttf") format("truetype"), url("../fonts/subset-Barlow-Bold.svg#Barlow") format("svg");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: fallback;
  unicode-range: U+0020-017E; }

html {
  position: relative;
  font-size: 10px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; }

body {
  font-family: 'Barlow', Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  opacity: 1;
  margin: 0;
  padding: 0;
  overflow-x: hidden; }

/*.site-content{
	&.loading{
		&:before{
			@extend %pseudo;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 999;
			background-color: $color-white;
		}
	}
}*/
@media (max-width: 1600px) {
  html {
    font-size: 0.625vw; } }

@media (max-width: 960px) {
  html {
    font-size: 10px; }
  body {
    font-size: 1.4rem; } }

@media (max-width: 750px) {
  html {
    font-size: 2.66667vw; } }

@media screen and (orientation: landscape) and (max-height: 750px) and (max-width: 750px) {
  html {
    font-size: 2.66667vh; } }

.router-wrap {
  min-height: calc(100vh - 7rem); }
  @media (max-width: 960px) {
    .router-wrap {
      min-height: calc(100vh - 5rem);
      padding-top: 4em; } }

.text-minoto {
  color: #145aff; }

.seoElement {
  opacity: 0;
  width: 0;
  height: 0; }

.cursor-pointer {
  cursor: pointer !important; }

.table tr:nth-child(even) td, .table tr:nth-child(even) th {
  background-color: transparent !important; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(0deg); }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg); } }

@-moz-keyframes spin {
  from {
    -moz-transform: translate(-50%, -50%) rotate(0deg); }
  to {
    -moz-transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg); }
  to {
    transform: translate(-50%, -50%) rotate(360deg); } }

.swiper-container.slider-component.slider-scrollbar {
  position: relative; }
  .swiper-container.slider-component.slider-scrollbar .swiper-slide {
    height: auto;
    box-sizing: border-box; }
  .swiper-container.slider-component.slider-scrollbar .swiper-scrollbar {
    background-color: transparent;
    border-radius: 0.3rem; }
  .swiper-container.slider-component.slider-scrollbar .swiper-scrollbar-drag {
    background-color: rgba(134, 151, 168, 0.5); }

.minoto-select {
  font-size: 1.4rem; }
  .minoto-select .minoto-select__control {
    font-weight: 500;
    border-radius: 0;
    background-color: #fff;
    border: 0.1rem solid #eaebf3;
    cursor: pointer;
    min-height: 5rem;
    transition: border-color 200ms ease; }
    .minoto-select .minoto-select__control:hover {
      border-color: #eaebf3; }
  .minoto-select .minoto-select__control--menu-is-open,
  .minoto-select .minoto-select__control--is-focused {
    border-color: #f7f7fa;
    box-shadow: none; }
  .minoto-select .minoto-select__value-container {
    padding-left: 1.5rem;
    overflow: visible;
    /*input{
			position: absolute;
		}*/ }
  .minoto-select .minoto-select__single-value {
    color: #161d4c; }
  .minoto-select .minoto-select__indicator-separator {
    display: none; }
  .minoto-select .minoto-select__menu {
    margin: 0;
    border-radius: 0;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    border: 0.1rem solid rgba(234, 235, 243, 0.5);
    box-shadow: 0 1.2rem 1.8rem 0 rgba(29, 42, 68, 0.13); }
  .minoto-select .minoto-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 0.1rem solid #eaebf3; }
  .minoto-select .minoto-select__option {
    transition: background-color 200ms ease;
    border-bottom: 0.1rem solid #e3e4e2;
    cursor: pointer;
    padding: .7rem 1.6rem;
    line-height: 1.7rem; }
    .minoto-select .minoto-select__option:focus, .minoto-select .minoto-select__option:active {
      background-color: rgba(234, 235, 243, 0.5); }
  .minoto-select .minoto-select__option--is-selected {
    background-color: transparent;
    color: #145aff; }
  .minoto-select .minoto-select__option--is-focused {
    background-color: rgba(234, 235, 243, 0.2); }

.site-content {
  max-width: 192rem;
  margin: 0 auto; }

.wrapper {
  max-width: calc(100% - 5rem);
  margin: 0 auto; }
  .wrapper.medium {
    width: 130rem; }
  .wrapper.narrow {
    width: 117rem; }
  .wrapper.narrower {
    width: 77rem; }

.link {
  color: #145aff;
  text-decoration: underline; }

@media (max-width: 960px) {
  .wrapper {
    max-width: calc(100% - 3rem); } }

.contentbox {
  position: relative;
  display: flex;
  flex: 1; }
  .contentbox:hover:before {
    opacity: 1; }
  .contentbox:hover .contentbox-innerwrap {
    border-color: #145aff; }
  .contentbox:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    box-shadow: 0 1.8rem 3.6rem 0 rgba(22, 29, 76, 0.3);
    opacity: 0;
    transition: opacity 200ms ease; }
  .contentbox.inactive .contentbox-title {
    opacity: .5; }
  .contentbox.inactive .info-price {
    color: #161d4c;
    opacity: .5; }
  .contentbox.inactive .contentbox-image {
    opacity: .6;
    filter: grayscale(100%); }
  .contentbox .contentbox-innerwrap {
    width: 100%;
    position: relative;
    display: block;
    z-index: 2;
    background: #fff;
    border: 0.1rem solid #eaebf3;
    transition: border-color 200ms ease; }
  .contentbox .contentbox-content {
    padding: 1.8rem 1rem 1.4rem;
    flex: 1; }
    @media (max-width: 960px) {
      .contentbox .contentbox-content {
        padding: .5rem 1rem .9rem; } }
  .contentbox .contentbox-imagewrap {
    position: relative;
    width: 100%;
    padding-bottom: 75%; }
  .contentbox .contentbox-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .contentbox .contentbox-badge {
    font-weight: 600;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 1rem;
    background-color: #145aff;
    color: #fff;
    line-height: 3rem;
    padding: 0 1.1rem;
    text-transform: uppercase;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem; }
    .contentbox .contentbox-badge.error {
      background-color: #ff5a60; }
    @media (max-width: 960px) {
      .contentbox .contentbox-badge {
        font-size: 1.4rem;
        line-height: 2.6rem; } }
  .contentbox .contentbox-favicon {
    position: absolute;
    z-index: 2;
    color: #145aff;
    font-size: 2rem;
    top: 1.5rem;
    right: 1.5rem;
    text-shadow: white 0.1rem 0rem 0rem, white 0.05403rem 0.08415rem 0rem, white -0.04161rem 0.09093rem 0rem, white -0.099rem 0.01411rem 0rem, white -0.06536rem -0.07568rem 0rem, white 0.02837rem -0.09589rem 0rem, white 0.09602rem -0.02794rem 0rem; }
  .contentbox .contentbox-pretitle {
    font-size: 1.2rem;
    margin-bottom: 1.6rem;
    color: black !important; }
  .contentbox .contentbox-title {
    font-weight: 600;
    font-size: 1.4rem;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: black !important; }
    @media (max-width: 960px) {
      .contentbox .contentbox-title {
        white-space: normal;
        line-height: 1.9rem;
        height: 3.8rem; } }
  .contentbox .contentbox-info {
    margin-top: .8rem; }
    @media (max-width: 960px) {
      .contentbox .contentbox-info {
        display: block; } }
  .contentbox .info-subtitle {
    font-weight: 600;
    font-size: 1.2rem;
    opacity: .5;
    padding-top: .3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: black !important; }
    @media (max-width: 960px) {
      .contentbox .info-subtitle {
        font-size: 1.1rem;
        line-height: 1.3rem; } }
  .contentbox .info-price {
    font-weight: 600;
    display: inline-block;
    font-size: 1.8rem;
    color: #145aff;
    text-align: right;
    white-space: nowrap;
    margin-top: 1rem;
    flex-grow: 2; }
    @media (max-width: 960px) {
      .contentbox .info-price {
        margin-top: .8rem;
        display: block;
        text-align: left; } }
  .contentbox .contentbox-additions {
    margin-top: 1.3rem;
    min-height: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 960px) {
      .contentbox .contentbox-additions {
        display: block;
        margin-top: 1rem; } }
  .contentbox .additions-labels span {
    display: inline-block;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #8688a2;
    border: 0.1rem solid #f7f7fa;
    margin-right: .6rem;
    border-radius: 0.3rem;
    padding: 0 .6rem .2rem;
    text-transform: uppercase; }
  .contentbox .additions-bottomnote {
    font-size: 1.2rem;
    color: #161d4c; }
    .contentbox .additions-bottomnote.success {
      color: #16d2d3; }
    @media (max-width: 960px) {
      .contentbox .additions-bottomnote {
        display: none; } }
  .contentbox.type-plain {
    text-align: center; }
    .contentbox.type-plain .contentbox-imagewrap {
      padding-bottom: 50%; }
      @media (max-width: 960px) {
        .contentbox.type-plain .contentbox-imagewrap {
          margin-top: .6rem;
          padding-bottom: 65%;
          margin-bottom: .6rem; } }
      .contentbox.type-plain .contentbox-imagewrap .contentbox-image {
        top: 50%;
        left: 50%;
        width: 50%;
        height: 50%;
        transform: translate3d(-50%, -50%, 0); }
        @media (max-width: 960px) {
          .contentbox.type-plain .contentbox-imagewrap .contentbox-image {
            width: 75%;
            height: 80%; } }
    .contentbox.type-plain .contentbox-content {
      padding: 0 2.2rem 1.5rem; }
      @media (max-width: 960px) {
        .contentbox.type-plain .contentbox-content {
          padding: 0 0 1rem; } }
    .contentbox.type-plain .contentbox-subtitle {
      font-weight: 600;
      font-size: 1.2rem;
      color: rgba(22, 29, 76, 0.5);
      margin-top: .8rem; }
    .contentbox.type-plain .contentbox-additions {
      border-top: 0.1rem solid #f7f7fa;
      padding: 1.5rem 0 0;
      display: block; }
      @media (max-width: 960px) {
        .contentbox.type-plain .contentbox-additions {
          margin-top: .5rem; } }
    .contentbox.type-plain .additions-title {
      color: #145aff;
      font-size: 1.3rem; }
      .contentbox.type-plain .additions-title + .additions-labels {
        margin-top: .4rem; }
    .contentbox.type-plain .additions-labels span {
      font-size: 1.3rem;
      border: none;
      padding: 0;
      margin-right: 0;
      color: #161d4c;
      text-transform: none;
      /* + span {
						&:before{
							content: ', ';
						}
					} */ }
  .contentbox.type-blogpost .contentbox-title {
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 3.2rem;
    white-space: normal;
    height: 6.4rem; }
    @media (max-width: 960px) {
      .contentbox.type-blogpost .contentbox-title {
        font-size: 1.4rem;
        line-height: 1.5rem;
        height: 3.2rem; }
        .contentbox.type-blogpost .contentbox-title.wrap:after {
          content: '...';
          text-align: right;
          position: absolute;
          right: 1rem;
          bottom: 1rem;
          width: 3rem;
          background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 70%, white 99%);
          background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(70%, white), color-stop(99%, white));
          background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 70%, white 99%);
          background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 70%, white 99%);
          background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 70%, white 99%);
          background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 58%, white 99%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#fff',GradientType=1 ); } }
  .contentbox.type-blogpost .contentbox-pretitle {
    margin-bottom: .6rem; }
  @media (max-width: 960px) {
    .contentbox.type-blogpost .contentbox-content {
      padding-top: .7rem; } }

.store-logo {
  position: absolute;
  bottom: 15px;
  right: 15px; }

.btn {
  font-weight: 600;
  position: relative;
  border-radius: 0.3rem;
  display: inline-block;
  background-color: #145aff;
  color: #fff;
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 1.3rem 1rem;
  text-align: center;
  border: 0.1rem solid #145aff;
  transition: background-color 200ms ease, color 200ms ease, border-color 200ms ease; }
  @media (min-width: 960px) {
    .btn:hover, .btn:active {
      background-color: #0043e0;
      border-color: #0043e0; } }
  @media (max-width: 960px) {
    .btn {
      transition: background-color 40ms ease, color 40ms ease, border-color 40ms ease; } }
  .btn:active {
    background-color: #0034ad;
    border-color: #0034ad; }
  .btn.hollow {
    background-color: transparent;
    color: #145aff; }
    @media (min-width: 960px) {
      .btn.hollow:hover, .btn.hollow:active {
        background-color: #0043e0;
        color: #fff; } }
    .btn.hollow:active {
      background-color: #0034ad;
      color: #fff; }
  .btn.white {
    background-color: #fff;
    color: #145aff;
    border-color: #fff; }
    @media (min-width: 960px) {
      .btn.white:hover, .btn.white:active {
        background-color: #e6e6e6;
        border-color: #e6e6e6; } }
    .btn.white:active {
      background-color: #cccccc;
      border-color: #cccccc; }
    .btn.white.hollow {
      background-color: transparent;
      color: #fff; }
      @media (min-width: 960px) {
        .btn.white.hollow:hover, .btn.white.hollow:active {
          background-color: #e6e6e6;
          color: #145aff; } }
      .btn.white.hollow:active {
        background-color: #cccccc;
        color: #145aff; }
  .btn.text {
    background-color: #161d4c;
    color: #fff;
    border-color: #161d4c; }
    @media (min-width: 960px) {
      .btn.text:hover, .btn.text:active {
        background-color: #0b0e24;
        border-color: #0b0e24; } }
    .btn.text:active {
      background-color: black;
      border-color: black; }
    .btn.text.hollow {
      background-color: transparent;
      color: #161d4c; }
      @media (min-width: 960px) {
        .btn.text.hollow:hover, .btn.text.hollow:active {
          background-color: #0b0e24;
          color: #fff; } }
      .btn.text.hollow:active {
        background-color: black;
        color: #fff; }
  .btn.dark {
    background-color: #e3e4e2;
    color: #161d4c;
    border-color: #e3e4e2; }
    @media (min-width: 960px) {
      .btn.dark:hover, .btn.dark:active {
        background-color: #cacbc8;
        border-color: #cacbc8; } }
    .btn.dark:active {
      background-color: #b0b3ad;
      border-color: #b0b3ad; }
    .btn.dark.hollow {
      background-color: transparent;
      color: #161d4c; }
      @media (min-width: 960px) {
        .btn.dark.hollow:hover, .btn.dark.hollow:active {
          background-color: #e3e4e2;
          border-color: #e3e4e2; } }
      .btn.dark.hollow:active {
        background-color: #b0b3ad;
        border-color: #b0b3ad; }
  .btn.facebook {
    background-color: #3b5998;
    border-color: #3b5998; }
    @media (min-width: 960px) {
      .btn.facebook:hover, .btn.facebook:active {
        background-color: #2d4373;
        border-color: #2d4373; } }
    .btn.facebook:active {
      background-color: #1e2e4f;
      border-color: #1e2e4f; }
    .btn.facebook.hollow {
      background-color: transparent;
      color: #3b5998; }
      @media (min-width: 960px) {
        .btn.facebook.hollow:hover, .btn.facebook.hollow:active {
          background-color: #2d4373;
          color: #fff; } }
      .btn.facebook.hollow:active {
        background-color: #1e2e4f;
        color: #fff; }
  .btn.google {
    background-color: #d44638;
    border-color: #d44638; }
    @media (min-width: 960px) {
      .btn.google:hover, .btn.google:active {
        background-color: #b23327;
        border-color: #b23327; } }
    .btn.google:active {
      background-color: #89271d;
      border-color: #89271d; }
    .btn.google.hollow {
      background-color: transparent;
      color: #d44638; }
      @media (min-width: 960px) {
        .btn.google.hollow:hover, .btn.google.hollow:active {
          background-color: #b23327;
          color: #fff; } }
      .btn.google.hollow:active {
        background-color: #89271d;
        color: #fff; }
  .btn.block {
    width: 100%;
    display: block; }
  .btn.uppercase {
    text-transform: uppercase; }
  .btn.big {
    font-size: 1.4rem; }
  .btn.wide {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .btn.low {
    padding-top: .8rem;
    padding-bottom: .8rem; }
  .btn.small {
    padding-top: .4rem;
    padding-bottom: .4rem;
    font-size: 1.2rem; }
  .btn.light {
    font-weight: 400; }
  .btn.small-icon i {
    font-size: .9rem; }
  .btn.has-info {
    padding-right: 2.6rem;
    padding-left: 2.6rem; }
  .btn:disabled {
    opacity: 0.5; }
  .btn.status-loading:disabled {
    opacity: 1; }
  .btn.show-status .btn-content {
    transform: scale(0.5);
    opacity: 0;
    transition: opacity 200ms ease, transform 200ms ease; }
  .btn.show-status .btn-statuswrap {
    opacity: .8;
    transform: translate3d(-50%, -50%, 0) scale(1);
    transition: opacity 200ms ease 180ms, transform 200ms ease 180ms; }
  .btn .btn-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.8rem;
    margin: -.3em 0 -.1em; }
    .btn .btn-icon.pre {
      margin-right: 1rem; }
    .btn .btn-icon.post {
      margin-left: 1rem; }
  .btn .btn-content {
    display: block;
    width: 100%;
    transition: opacity 200ms ease 180ms, transform 200ms ease 180ms; }
  .btn .btn-statuswrap {
    opacity: 0;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(0.5);
    transition: opacity 200ms ease, transform 200ms ease; }
  .btn .btn-loader {
    animation: spin 3s infinite linear;
    font-size: 2.4rem;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    top: 50%;
    left: 50%; }
  .btn .btn-status {
    font-size: 2rem; }
  .btn .btn-info {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    font-size: 1.4rem;
    height: 1.4rem;
    line-height: 1.4rem;
    opacity: .5;
    transition: opacity 200ms ease; }
    .btn .btn-info:hover {
      opacity: 1; }

.btn .btn-content:hover {
  color: white !important; }

.loginform .btn .btn-content:hover {
  color: white !important; }

.image:not(.imagewrap) {
  transition: opacity 300ms ease; }

.image.image-loading:not(.imagewrap) {
  opacity: 0 !important; }

.image.image-loading.imagewrap .imagewrap-image {
  opacity: 0; }

.imagewrap {
  overflow: hidden; }
  .imagewrap.contain .imagewrap-image {
    background-size: contain; }
  .imagewrap.abs {
    position: relative; }
    .imagewrap.abs .imagewrap-image {
      position: absolute;
      left: 0;
      top: 0; }
  .imagewrap .imagewrap-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: opacity 200ms ease; }
  .imagewrap .imagewrap-loaderwrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .imagewrap .imagewrap-loaderwrap .imagewrap-image {
      position: absolute;
      top: 0;
      left: 0; }
  .imagewrap .loaderwrap-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    color: #145aff;
    font-size: 7rem;
    opacity: .7; }

.inlinesvg {
  opacity: 0; }
  .inlinesvg.loaded {
    opacity: 1; }
  .inlinesvg svg {
    width: 100%; }

.popinfo-wrap {
  position: relative;
  cursor: pointer; }
  .popinfo-wrap .popinfo-content {
    font-family: 'Barlow', Arial, Helvetica, sans-serif;
    font-weight: 400;
    position: absolute;
    top: calc(100%);
    left: -1.7rem;
    width: auto;
    color: #fff;
    font-size: 1.2rem;
    line-height: 1.6em;
    padding: 2.1rem 1.3rem 1.2rem;
    text-transform: none;
    text-align: left;
    cursor: default;
    transform: translate3d(0, -0.3rem, 0);
    opacity: 0;
    transition: opacity 200ms ease, transform 200ms ease; }
    .popinfo-wrap .popinfo-content.rtl {
      left: auto;
      right: -1.7rem; }
      .popinfo-wrap .popinfo-content.rtl .popinfo-bg .bg-pop {
        left: auto;
        right: 2rem; }
    .popinfo-wrap .popinfo-content.upside-down {
      top: auto;
      bottom: calc(100%);
      padding-top: 1.2rem;
      padding-bottom: 2.1rem; }
      .popinfo-wrap .popinfo-content.upside-down .popinfo-bg {
        top: 0;
        bottom: 1rem; }
        .popinfo-wrap .popinfo-content.upside-down .popinfo-bg .bg-pop {
          top: 100%;
          bottom: auto;
          margin-top: -.5rem;
          margin-bottom: 0; }
    .popinfo-wrap .popinfo-content.show {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
  .popinfo-wrap .popinfo-bg {
    opacity: .9;
    position: absolute;
    top: 1rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: #161d4c;
    border-radius: 0.3rem; }
    .popinfo-wrap .popinfo-bg .bg-pop {
      position: absolute;
      bottom: 100%;
      left: 2rem;
      border-radius: 50%;
      width: .9rem;
      height: .9rem;
      margin-bottom: -.5rem;
      background-color: #161d4c; }
  .popinfo-wrap .popinfo-text {
    position: relative;
    z-index: 2; }
  .popinfo-wrap.nowrap .popinfo-content {
    white-space: nowrap; }
  .popinfo-wrap.wide .popinfo-content {
    min-width: 26rem; }

.inputwrap {
  display: block;
  position: relative;
  touch-action: manipulation; }
  .inputwrap input[type='file'] + label,
  .inputwrap select + label,
  .inputwrap > input,
  .inputwrap textarea,
  .inputwrap .input-uploadlabel {
    font-family: 'Barlow', Arial, Helvetica, sans-serif;
    font-weight: 500;
    border: 0.1rem solid #eaebf3;
    color: #161d4c;
    border-radius: 0.3rem;
    padding: 1.3rem 1.3rem;
    line-height: 1.8rem;
    font-size: 1.5rem;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    display: block;
    transition: color 200ms ease, border-color 200ms ease;
    background-color: #fff;
    touch-action: manipulation; }
    .inputwrap input[type='file'] + label:focus,
    .inputwrap select + label:focus,
    .inputwrap > input:focus,
    .inputwrap textarea:focus,
    .inputwrap .input-uploadlabel:focus {
      border-color: #145aff; }
    .inputwrap input[type='file'] + label::placeholder,
    .inputwrap select + label::placeholder,
    .inputwrap > input::placeholder,
    .inputwrap textarea::placeholder,
    .inputwrap .input-uploadlabel::placeholder {
      opacity: 1;
      color: rgba(22, 29, 76, 0.5);
      transition: color 200ms ease; }
    .inputwrap input[type='file'] + label:-ms-input-placeholder,
    .inputwrap select + label:-ms-input-placeholder,
    .inputwrap > input:-ms-input-placeholder,
    .inputwrap textarea:-ms-input-placeholder,
    .inputwrap .input-uploadlabel:-ms-input-placeholder {
      color: rgba(22, 29, 76, 0.5);
      transition: color 200ms ease; }
    .inputwrap input[type='file'] + label::-ms-input-placeholder,
    .inputwrap select + label::-ms-input-placeholder,
    .inputwrap > input::-ms-input-placeholder,
    .inputwrap textarea::-ms-input-placeholder,
    .inputwrap .input-uploadlabel::-ms-input-placeholder {
      color: rgba(22, 29, 76, 0.5);
      transition: color 200ms ease; }
  .inputwrap.error > input[type='text'],
  .inputwrap.error input[type='password'],
  .inputwrap.error input[type='email'],
  .inputwrap.error input[type='file'] + label,
  .inputwrap.error textarea {
    border-color: #ca320d; }
  .inputwrap.error .minoto-select .minoto-select__control {
    border-color: #ca320d; }
  .inputwrap.disabled {
    opacity: .6; }
  .inputwrap.input-full.pop-label .input-label {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    pointer-events: auto; }
  .inputwrap.no-select .checkwrap input + label {
    user-select: none; }
  .inputwrap.pop-label .input-label {
    position: absolute;
    z-index: 2;
    display: inline-block;
    left: 0.7rem;
    bottom: calc(100% - 1rem);
    font-size: 1.2rem;
    line-height: 1.4rem;
    background-color: #fff;
    padding: 0.2rem 0.8rem;
    margin-bottom: 0;
    border-radius: 0.3rem;
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 0.3rem, 0);
    transition: transform 200ms ease, opacity 200ms ease;
    touch-action: manipulation; }
  .inputwrap .input-addon {
    position: absolute;
    right: 0;
    color: white;
    background: #a5a5a5;
    height: 100%;
    padding: 1em;
    top: 50%;
    transform: translate(0, -50%);
    border-bottom-right-radius: .3rem;
    border-top-right-radius: .3rem; }
  .inputwrap .input-label {
    font-weight: 400;
    display: block;
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-bottom: 1.1rem;
    color: #8688a2; }
  .inputwrap .input-error {
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.6rem;
    margin-top: 0.5rem;
    color: #ca320d;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left; }
  .inputwrap .input-icon {
    position: absolute;
    font-size: 2rem;
    color: rgba(22, 29, 76, 0.4);
    top: 2.3rem;
    left: 1.7rem;
    transform: translateY(-50%);
    pointer-events: none; }
  .inputwrap .input-info {
    position: absolute;
    top: 2.3rem;
    right: 1.1rem;
    color: rgba(22, 29, 76, 0.5);
    transform: translate3d(0, -50%, 0);
    font-size: 1.4rem;
    line-height: 0; }
    .inputwrap .input-info .popinfo-content {
      min-width: 20rem; }
  .inputwrap.type-file input + label {
    cursor: pointer;
    color: #e3e4e2;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 5.7rem; }
  .inputwrap.type-file.input-full input + label {
    color: #145aff; }
  .inputwrap.type-file.input-full .input-icon {
    color: #145aff; }
  .inputwrap.type-file input[type='file'] {
    pointer-events: none;
    touch-action: manipulation;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem; }
  .inputwrap.type-file .input-icon {
    left: 1.5rem;
    font-size: 3rem;
    color: #e3e4e2; }
  .inputwrap.type-date input {
    padding-right: 5rem;
    touch-action: manipulation; }
  .inputwrap.type-date .input-icon {
    right: 2rem; }
  .inputwrap.type-checkbox .checkwrap {
    font-weight: 500;
    position: relative; }
    .inputwrap.type-checkbox .checkwrap input {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none; }
      .inputwrap.type-checkbox .checkwrap input:checked + label {
        color: #145aff; }
        .inputwrap.type-checkbox .checkwrap input:checked + label > span {
          background-color: #145aff;
          border-color: #145aff; }
          .inputwrap.type-checkbox .checkwrap input:checked + label > span:before {
            opacity: 1; }
      .inputwrap.type-checkbox .checkwrap input + label {
        display: inline-block;
        position: relative;
        font-size: 1.4rem;
        letter-spacing: -0.015em;
        padding: .2rem 0 .4rem 2.8rem;
        line-height: 1.7rem;
        cursor: pointer;
        transition: color 200ms ease; }
        .inputwrap.type-checkbox .checkwrap input + label > span {
          position: absolute;
          top: 50%;
          left: 0;
          width: 1.8rem;
          height: 1.8rem;
          display: inline-block;
          border: 0.1rem solid #161d4c;
          vertical-align: middle;
          cursor: pointer;
          transform: translate3d(0, -50%, 0);
          transition: border-color 200ms ease, background-color 200ms ease; }
          .inputwrap.type-checkbox .checkwrap input + label > span:before {
            display: block;
            font-size: .8rem;
            position: absolute;
            top: 55%;
            left: 53%;
            transform: translate(-50%, -50%);
            color: #fff;
            opacity: 0;
            transition: opacity 200ms ease; }
        .inputwrap.type-checkbox .checkwrap input + label .check-link {
          color: #145aff; }
  .inputwrap.type-image .input-uploadlabel {
    /*display: block;
			width: 100%;*/
    position: relative;
    color: #8688a2;
    cursor: pointer;
    border-style: dashed;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
    .inputwrap.type-image .input-uploadlabel > input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none; }
  .inputwrap.type-image .editorwrap-previewwrap {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    background-color: #e3e4e2; }
  .inputwrap.type-image .editorwrap-preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
    display: block; }
  .inputwrap.type-image .editorwrap-zoom {
    width: 100%;
    margin: 2rem 0;
    display: block; }
  .inputwrap.dark input[type='file'] + label, .inputwrap.dark select + label, .inputwrap.dark input, .inputwrap.dark textarea {
    background-color: #f7f7fa; }
  .inputwrap.dark .minoto-select .minoto-select__control {
    background-color: #f7f7fa;
    border-color: #f7f7fa; }
  .inputwrap.high input[type='file'] + label,
  .inputwrap.high select + label,
  .inputwrap.high > input,
  .inputwrap.high textarea {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .inputwrap.high .input-icon {
    top: 2.5rem; }
  .inputwrap.high .input-info {
    top: 2.5rem; }
  .inputwrap.has-icon input[type='text'],
  .inputwrap.has-icon input[type='password'],
  .inputwrap.has-icon input[type='email'],
  .inputwrap.has-icon input[type='file'] + label,
  .inputwrap.has-icon textarea {
    padding-left: 5.2rem; }

.fileContainer {
  overflow: hidden;
  position: relative;
  border: 1px dashed #cdcdcd;
  padding: 20px;
  color: #cdcdcd; }

.fileContainer [type=file] {
  cursor: pointer;
  display: block;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0; }

/*
@include media() {
	.inputwrap {
		input[type='file'] + label,
		select + label,
		input,
		textarea {
			font-size: 1.4rem;
			padding: 1.4rem 1.8rem;
			line-height: 1.8rem;
			border-radius: $radius-general-mobile;
		}

		.input-icon {
			top: 2.2rem;
			font-size: 2rem;
		}

		// Types

		&.type-file {
			input + label {
				padding-left: 5rem;
			}

			.input-icon {
				left: 1.8rem;
				font-size: 2rem;
			}
		}

		&.type-select{
			select{
				height: 4.7rem;

				+ label {
					&:before{
						font-size: 1.1rem;
					}
				}
			}
		}

		&.type-date {
			.input-icon {
				right: 1.8rem;
			}
		}

		&.textwrap{
			padding: 1.5rem 0;
			font-size: 1.4rem;
			line-height: 1.6rem;
		}
	}
}*/
.loader-container {
  position: relative;
  min-height: 30rem;
  z-index: 1; }
  .loader-container > .loader {
    position: absolute; }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999;
  opacity: 0;
  transition: opacity 400ms ease;
  /*&.in-page{
		z-index: 9;
		background-color: $color-ice;

		i{
			display: none;
			color: $color-gray;
		}
	}*/ }
  .loader.inner {
    position: absolute; }
  .loader.strict {
    background-color: #fff; }
  .loader.oversize .loader-spinnerwrap {
    height: 50rem; }
  .loader .loader-spinnerwrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .loader i {
    animation: spin 3s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #145aff;
    font-size: 3rem; }
  .loader.show {
    opacity: 1; }

.searchbar {
  position: relative;
  width: 56rem; }
  .searchbar.show .searchbar-input {
    border-color: #145aff; }
  .searchbar.show .searchbar-results {
    opacity: 1;
    height: auto;
    transform: translate3d(0, 0rem, 0); }
  .searchbar.fullscreen {
    display: none;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 200ms ease;
    background-color: #fff; }
    .searchbar.fullscreen.active {
      display: block; }
      .searchbar.fullscreen.active.show {
        opacity: 1; }
    .searchbar.fullscreen .searchbar-icon {
      position: absolute;
      left: 2rem;
      font-size: 1.9rem;
      top: 50%;
      transform: translate3d(0, -50%, 0); }
    .searchbar.fullscreen .searchbar-input {
      border-radius: 0;
      border: none;
      border-bottom: 0.1rem solid #eaebf3;
      font-size: 1.4rem;
      color: #145aff;
      line-height: 4rem;
      padding: 1rem 5.4rem .9rem 5.8rem; }
    .searchbar.fullscreen .searchbar-results {
      top: 6rem;
      height: calc(100% - 6rem);
      overflow: scroll;
      overflow-x: hidden;
      overflow-y: scroll; }
    .searchbar.fullscreen .group-wrap {
      max-height: none; }
    .searchbar.fullscreen .group-item a {
      padding: .9rem 2rem .9rem 5.8rem;
      height: auto;
      line-height: 2rem; }
    .searchbar.fullscreen .item-image {
      width: 3rem;
      height: 3rem;
      margin-right: .9rem; }
  .searchbar .searchbar-form {
    position: relative; }
  .searchbar .searchbar-input {
    width: 100%;
    display: block;
    line-height: 4.4rem;
    padding: 0 9rem 0 2rem;
    border-radius: 0.3rem;
    background-color: #fff;
    border: 0.1rem solid #e3e4e2;
    transition: border-color 200ms ease;
    font-size: 1.5rem; }
    @media (max-width: 960px) {
      .searchbar .searchbar-input {
        line-height: 3.5rem;
        padding: .4rem 8.7rem .5rem 1.4rem; } }
  .searchbar .searchbar-loader {
    animation: spin 3s infinite linear;
    position: absolute;
    top: 2.3rem;
    right: 8.5rem;
    color: #e3e4e2; }
  .searchbar .searchbar-submit {
    position: absolute;
    top: .5rem;
    right: .5rem;
    padding: .7rem 1.4rem;
    text-transform: uppercase;
    color: white !important; }
    .searchbar .searchbar-submit i {
      font-size: 1.4rem;
      margin-right: 1.1rem; }
    .searchbar .searchbar-submit:hover {
      color: white !important; }
    .searchbar .searchbar-submit a {
      color: white !important; }
  .searchbar .searchbar-close {
    position: absolute;
    font-size: 1.4rem;
    right: 0;
    height: 6rem;
    line-height: 6rem;
    width: 5.4rem;
    top: 50%;
    transform: translate3d(0, -50%, 0); }
  .searchbar .searchbar-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border-radius: 0.3rem;
    font-size: 1.4rem;
    box-shadow: 0 1.2rem 1.8rem 0 rgba(29, 42, 68, 0.13);
    overflow: hidden;
    opacity: 0;
    height: 0;
    transform: translate3d(0, -0.3rem, 0);
    transition: opacity 200ms ease, transform 200ms ease; }
  .searchbar .results-group {
    color: #145aff; }
  .searchbar .group-title {
    display: block;
    background-color: #e3e4e2;
    color: #161d4c;
    font-size: 1.1rem;
    text-transform: uppercase;
    padding: .8rem 2rem .8rem;
    line-height: 1.4rem; }
  .searchbar .group-wrap {
    max-height: 20rem; }
  .searchbar .group-item.focused a {
    background-color: #f7f7fa; }
  .searchbar .group-item a {
    display: flex;
    align-items: center;
    line-height: 1.7rem;
    padding: 0 2rem .2rem;
    height: 5.2rem;
    transition: background-color 200ms ease; }
    .searchbar .group-item a:hover, .searchbar .group-item a:active {
      background-color: #f7f7fa; }
  .searchbar .group-item + .group-item {
    border-top: 0.1rem solid #f7f7fa; }
  .searchbar .item-image {
    width: 3.7rem !important;
    height: 3.7rem !important;
    border-radius: 50%;
    border-right-color: 0.1rem solid #f7f7fa;
    margin-right: 1.8rem; }
  .searchbar .group-cta {
    border-top: 0.1rem solid #f7f7fa;
    color: #161d4c;
    padding: .6rem 1.1rem .4rem; }
  .searchbar .cta-link {
    font-weight: 600;
    display: block;
    text-align: center;
    text-transform: uppercase;
    border: 0.1rem solid #e3e4e2;
    font-size: 1.3rem;
    line-height: 3.8rem;
    transition: background-color 200ms ease; }
    .searchbar .cta-link:hover, .searchbar .cta-link:active {
      background-color: #f7f7fa; }
    .searchbar .cta-link i {
      font-size: 1rem;
      display: inline-block;
      vertical-align: middle;
      margin: -.3rem 0 0 1rem; }

.breadcrumbs {
  font-weight: 500;
  font-size: 0;
  letter-spacing: 0;
  color: #8688a2; }
  .breadcrumbs .breadcrumbs-item {
    font-size: 1.2rem;
    display: inline-block;
    text-transform: uppercase; }
    .breadcrumbs .breadcrumbs-item + .breadcrumbs-item:before {
      font-size: .5em;
      display: inline-block;
      vertical-align: middle;
      margin: -.3rem .5rem 0; }
  .breadcrumbs.section {
    padding: 2.4rem 0 0; }

.collapse {
  display: none;
  transition: max-height 300ms ease, opacity 300ms ease;
  overflow: hidden;
  opacity: 0; }
  .collapse.active {
    display: block; }
  .collapse.hide {
    max-height: 0 !important; }
  .collapse.show {
    opacity: 1; }
  .collapse.shown {
    overflow: visible; }

.collapse-group {
  border: 0.1rem solid #eaebf3; }
  .collapse-group.open .collapse-group-trigger:after {
    transform: rotate(180deg); }
  .collapse-group .collapse-group-trigger {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    background-color: #eaebf3;
    padding: 1.6rem 2rem 1.5rem;
    font-size: 1.6rem;
    line-height: 1.9rem; }
    .collapse-group .collapse-group-trigger:after {
      display: inline-block;
      color: rgba(22, 29, 76, 0.7); }
    @media (max-width: 960px) {
      .collapse-group .collapse-group-trigger {
        font-size: 1.4rem;
        line-height: 1.7rem;
        padding: 1.1rem 1.4rem 1.2rem; } }
  .collapse-group .collapse-group-collapse .collapse-innerwrap {
    padding: 1.7rem 2rem; }
    .collapse-group .collapse-group-collapse .collapse-innerwrap .wysiwyg {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.94em;
      color: rgba(22, 29, 76, 0.7); }

.wysiwyg {
  font-weight: 400;
  color: rgba(22, 29, 76, 0.7);
  line-height: 1.2em; }
  .wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h6 {
    color: #161d4c;
    font-weight: 500;
    margin-top: 3em;
    margin-bottom: .5em; }
    .wysiwyg h1 + *, .wysiwyg h2 + *, .wysiwyg h3 + *, .wysiwyg h4 + *, .wysiwyg h5 + *, .wysiwyg h6 + * {
      margin-top: 0em; }
  .wysiwyg strong, .wysiwyg b {
    color: #161d4c; }
  .wysiwyg ul {
    list-style: disc inside; }
    .wysiwyg ul li + li {
      margin-top: .5em; }
  .wysiwyg blockquote:not(.instagram-media) {
    font-family: inherit;
    color: #161d4c;
    font-size: 1.45em;
    line-height: 1.44em;
    position: relative; }
    @media (max-width: 960px) {
      .wysiwyg blockquote:not(.instagram-media) {
        padding-left: 3.9rem;
        padding-right: 3.9rem; } }
    .wysiwyg blockquote:not(.instagram-media):before, .wysiwyg blockquote:not(.instagram-media):after {
      position: absolute;
      color: #000;
      font-size: 6em;
      opacity: .05; }
      @media (max-width: 960px) {
        .wysiwyg blockquote:not(.instagram-media):before, .wysiwyg blockquote:not(.instagram-media):after {
          font-size: 9rem; } }
    .wysiwyg blockquote:not(.instagram-media):before {
      content: '“';
      top: .25em;
      right: calc(100% + .1em); }
      @media (max-width: 960px) {
        .wysiwyg blockquote:not(.instagram-media):before {
          left: 0;
          right: auto; } }
    .wysiwyg blockquote:not(.instagram-media):after {
      content: '“';
      bottom: .25em;
      left: calc(100% + 0em);
      transform: rotate(180deg); }
      @media (max-width: 960px) {
        .wysiwyg blockquote:not(.instagram-media):after {
          right: 0;
          left: auto; } }
  .wysiwyg img:not([style*="float"]) {
    width: calc(100% + 10rem) !important;
    margin: 0 -5rem;
    max-width: none;
    display: block;
    height: auto !important; }
  .wysiwyg img[style*="float:left"], .wysiwyg img[style*="float: left"] {
    width: auto;
    margin: 2rem 2rem 2rem 0; }
  .wysiwyg img[style*="float:right"], .wysiwyg img[style*="float: right"] {
    width: auto;
    margin: 2rem 0 2rem 2rem; }
  .wysiwyg > *:not(br) {
    margin: 2em 0 1em; }
    .wysiwyg > *:not(br):first-child {
      margin-top: 0; }
    .wysiwyg > *:not(br):last-child {
      margin-bottom: 0; }

.tabs-container .tabs-labels {
  position: relative;
  text-align: center; }
  .tabs-container .tabs-labels:before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.1rem;
    background-color: #e3e4e2;
    z-index: 1; }

.tabs-container .labels-innerwrap {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 960px) {
    .tabs-container .labels-innerwrap {
      display: block;
      white-space: nowrap;
      overflow: auto;
      overflow-x: scroll;
      overflow-y: hidden; } }

.tabs-container .labels-item {
  font-weight: 600;
  color: #8688a2;
  position: relative;
  font-size: 1.4rem;
  line-height: 1.8rem;
  padding: 1.8rem .6rem;
  height: 5.4rem;
  text-transform: uppercase;
  text-align: center;
  transition: color 200ms ease;
  position: relative;
  z-index: 2;
  margin: 0 2.4rem; }
  @media (max-width: 960px) {
    .tabs-container .labels-item {
      margin: 0 1rem; } }
  .tabs-container .labels-item:before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.2rem;
    background-color: #145aff;
    transform: scaleX(0);
    opacity: 0;
    transition: transform 600ms ease, opacity 600ms ease; }
  .tabs-container .labels-item.active {
    color: #145aff; }
    .tabs-container .labels-item.active:before {
      opacity: 1;
      transform: scaleX(1); }

.tabs-container .items-tab {
  opacity: 0;
  transition: opacity 350ms ease; }
  .tabs-container .items-tab.show {
    opacity: 1; }

.tabs-container .tabs-content {
  padding: 11rem 0 20rem; }

.table {
  border: none;
  border-collapse: collapse;
  font-size: 1.4rem;
  width: 100%; }
  .table td, .table th {
    font-weight: 500;
    border: none;
    padding: 1rem 2rem;
    line-height: 2rem;
    text-align: left; }
  .table th {
    width: 30rem; }
    @media (max-width: 960px) {
      .table th {
        width: 50%; } }
  .table tr:nth-child(odd) td, .table tr:nth-child(odd) th {
    background-color: rgba(247, 247, 250, 0.7); }
  .table tr:nth-child(even) td, .table tr:nth-child(even) th {
    background-color: rgba(234, 235, 243, 0.7); }

.messenger-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  padding: 1rem; }
  .messenger-container .messenger-message {
    font-size: 1.6rem;
    background-color: #ffcb00;
    color: #fff;
    border-radius: 0.6rem;
    padding: 1rem 1.8em;
    opacity: 0;
    transform: translate3d(0, -0.5rem, 0);
    transition: transform 400ms ease, opacity 400ms ease; }
    .messenger-container .messenger-message + .messenger-message {
      margin-top: 1rem; }
    .messenger-container .messenger-message.show {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
    .messenger-container .messenger-message.error {
      background-color: #ca320d; }
    .messenger-container .messenger-message.warning {
      background-color: #ffcb00; }
    .messenger-container .messenger-message.success {
      background-color: #16d2d3; }

.grid-container .grid-row {
  width: calc(100% + 2.2rem);
  margin: 0 -1.1rem;
  display: flex;
  flex-flow: row wrap; }

.grid-container .grid-col {
  width: 100%;
  padding: 1.1rem; }
  .grid-container .grid-col.x1 {
    width: 8.333333333333333%; }
  .grid-container .grid-col.x2 {
    width: 16.66666666666667%; }
  .grid-container .grid-col.x3 {
    width: 25%; }
  .grid-container .grid-col.x4 {
    width: 33.33333333333333%; }
  .grid-container .grid-col.x5 {
    width: 41.66666666666667%; }
  .grid-container .grid-col.x6 {
    width: 50%; }
  .grid-container .grid-col.x7 {
    width: 58.33333333333333%; }
  .grid-container .grid-col.x8 {
    width: 66.66666666666667%; }
  .grid-container .grid-col.x9 {
    width: 75%; }
  .grid-container .grid-col.x10 {
    width: 83.33333333333333%; }
  .grid-container .grid-col.x11 {
    width: 91.66666666666667%; }
  .grid-container .grid-col.x12 {
    width: 100%; }
  .grid-container .grid-col.sl-x1 {
    margin-left: 8.333333333333333%; }
  .grid-container .grid-col.sl-x2 {
    margin-left: 16.66666666666667%; }
  .grid-container .grid-col.sl-x3 {
    margin-left: 25%; }
  .grid-container .grid-col.sl-x4 {
    margin-left: 33.33333333333333%; }
  .grid-container .grid-col.sl-x5 {
    margin-left: 41.66666666666667%; }
  .grid-container .grid-col.sl-x6 {
    margin-left: 50%; }
  .grid-container .grid-col.sl-x7 {
    margin-left: 58.33333333333333%; }
  .grid-container .grid-col.sl-x8 {
    margin-left: 66.66666666666667%; }
  .grid-container .grid-col.sl-x9 {
    margin-left: 75%; }
  .grid-container .grid-col.sl-x10 {
    margin-left: 83.33333333333333%; }
  .grid-container .grid-col.sl-x11 {
    margin-left: 91.66666666666667%; }
  .grid-container .grid-col.sl-x12 {
    margin-left: 100%; }
  @media (max-width: 960px) {
    .grid-container .grid-col.m-x2 {
      width: 16.66666666666667%; }
    .grid-container .grid-col.m-x3 {
      width: 25%; }
    .grid-container .grid-col.m-x4 {
      width: 33.33333333333333%; }
    .grid-container .grid-col.m-x5 {
      width: 41.66666666666667%; }
    .grid-container .grid-col.m-x6 {
      width: 50%; }
    .grid-container .grid-col.m-x7 {
      width: 58.33333333333333%; }
    .grid-container .grid-col.m-x8 {
      width: 66.66666666666667%; }
    .grid-container .grid-col.m-x9 {
      width: 75%; }
    .grid-container .grid-col.m-x10 {
      width: 83.33333333333333%; }
    .grid-container .grid-col.m-x11 {
      width: 91.66666666666667%; }
    .grid-container .grid-col.m-x12 {
      width: 100%; }
    .grid-container .grid-col.m-sl-x1 {
      margin-left: 8.333333333333333%; }
    .grid-container .grid-col.m-sl-x2 {
      margin-left: 16.66666666666667%; }
    .grid-container .grid-col.m-sl-x3 {
      margin-left: 25%; }
    .grid-container .grid-col.m-sl-x4 {
      margin-left: 33.33333333333333%; }
    .grid-container .grid-col.m-sl-x5 {
      margin-left: 41.66666666666667%; }
    .grid-container .grid-col.m-sl-x6 {
      margin-left: 50%; }
    .grid-container .grid-col.m-sl-x7 {
      margin-left: 58.33333333333333%; }
    .grid-container .grid-col.m-sl-x8 {
      margin-left: 66.66666666666667%; }
    .grid-container .grid-col.m-sl-x9 {
      margin-left: 75%; }
    .grid-container .grid-col.m-sl-x10 {
      margin-left: 83.33333333333333%; }
    .grid-container .grid-col.m-sl-x11 {
      margin-left: 91.66666666666667%; }
    .grid-container .grid-col.m-sl-x12 {
      margin-left: 100%; } }

.fav-button {
  min-width: 2.2rem;
  height: 2rem;
  color: rgba(22, 29, 76, 0.3); }
  .fav-button.faved {
    color: #145aff; }
  .fav-button .fav-button-btn {
    min-height: 2rem;
    line-height: 2rem; }
  .fav-button .fav-button-icon {
    position: relative;
    width: 2.2rem;
    height: 2rem;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle; }
    .fav-button .fav-button-icon:disabled {
      cursor: inherit; }
    .fav-button .fav-button-icon i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
  .fav-button.disabled:not(.faved) {
    display: none; }

.consentbar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0rem .3rem 2.0rem 0rem rgba(0, 0, 0, 0.22);
  padding: 1.2rem 2rem;
  text-align: center; }
  @media (max-width: 960px) {
    .consentbar {
      padding: 2.8rem 2rem;
      display: block; } }
  .consentbar .consentbar-textwrap {
    margin-right: 3rem;
    font-size: 1.2rem;
    line-height: 1.4em; }
    .consentbar .consentbar-textwrap .link {
      color: inherit;
      text-decoration: underline; }
    @media (max-width: 960px) {
      .consentbar .consentbar-textwrap {
        margin: 0 0 1.2rem; } }

.scrollwrap-container {
  position: relative; }
  .scrollwrap-container .ScrollbarsCustom {
    max-height: 100%; }
  .scrollwrap-container .ScrollbarsCustom-Content {
    display: block !important; }
  .scrollwrap-container .ScrollbarsCustom-Wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 1rem;
    overflow: hidden; }
  .scrollwrap-container .ScrollbarsCustom-TrackY {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    width: .6rem;
    height: 100%;
    border-radius: .3rem;
    display: none; }
  .scrollwrap-container .ScrollbarsCustom-ThumbY {
    touch-action: none;
    cursor: pointer;
    border-radius: .3rem;
    background-color: rgba(134, 136, 162, 0.5);
    width: 100%;
    transform: translateY(80px); }

.youtube-wrapper {
  cursor: pointer;
  margin: auto;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  transition: 200ms;
  box-shadow: none; }
  .youtube-wrapper:hover {
    box-shadow: 0 1.8rem 3.6rem 0 rgba(22, 29, 76, 0.3);
    transition: 200ms; }
    .youtube-wrapper:hover .youtube-wrapper--playBtn {
      transition: 200ms; }
      .youtube-wrapper:hover .youtube-wrapper--playBtn:before {
        transition: 200ms;
        color: #145aff; }
  .youtube-wrapper--spinner, .youtube-wrapper--playBtn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white; }
  .youtube-wrapper--playBtn:before {
    transition: 200ms;
    font-size: 5.6em; }
  .youtube-wrapper--video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  /*.modal-loader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 40;
		background-color: $color-gray-light;
		opacity: 0;
		transition: opacity $transition-hover ease;
		border-radius: $radius-general;

		i {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: $color-gray;
			font-size: 3rem;
		}

		&.show {
			opacity: 1;
		}
	}*/ }
  .modal-container .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity 300ms ease 200ms;
    z-index: 1; }
  .modal-container .modal-outerwrap {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; }
  .modal-container .modal-content {
    position: relative;
    margin: 10vh auto 5rem;
    background-color: #fff;
    border-radius: 0.3rem;
    max-width: calc(100% - 10rem);
    width: 90rem;
    box-shadow: 0rem .3rem 2.0rem 0rem rgba(0, 0, 0, 0.22);
    opacity: 0;
    z-index: 2;
    transform: translateY(-1.5rem);
    transition: opacity 400ms ease, transform 300ms ease;
    -webkit-backface-visibility: hidden; }
    @media (max-width: 960px) {
      .modal-container .modal-content {
        margin: 5vh auto 5rem;
        max-width: calc(100% - 3rem);
        max-height: calc(95vh);
        overflow-y: scroll; } }
  .modal-container .modal-innercontent {
    padding: 2.6rem 2rem 2rem; }
  .modal-container .modal-closebtn {
    position: absolute;
    z-index: 50;
    top: 1.4rem;
    right: 1.4rem;
    color: #fff;
    background-color: #161d4c;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    font-size: .8rem;
    transition: color 200ms ease, background-color 200ms ease;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 960px) {
      .modal-container .modal-closebtn {
        font-size: 1.5rem; } }
    .modal-container .modal-closebtn:hover, .modal-container .modal-closebtn:active {
      color: #161d4c;
      background-color: #f7f7fa; }
  .modal-container.show .modal-overlay {
    opacity: 1;
    transition: opacity 300ms ease; }
  .modal-container.show .modal-content {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms ease 200ms, transform 300ms ease 200ms; }
  .modal-container.narrow .modal-content {
    width: 50rem; }

.modalHeadContent {
  position: relative;
  height: 60px; }
  .modalHeadContent img {
    position: absolute;
    top: 0;
    left: 0; }
  .modalHeadContent div {
    position: absolute;
    top: 13px;
    right: 0;
    font-size: 20px;
    font-weight: 600;
    font-style: normal; }
  .modalHeadContent:after {
    content: "";
    clear: both; }

.modal-innercontent p {
  font-size: 16px;
  color: #161d4c; }

.modal-innercontent .form-submitbtn.full-width {
  width: 100%; }

.modal-innercontent .info-credit-results {
  background-color: #f7f7fa;
  border-radius: 0.3rem;
  border: solid 1px #ebebf3;
  margin-top: 1.2rem;
  margin-bottom: 3.7rem; }
  .modal-innercontent .info-credit-results .tablePad {
    padding-right: 15px;
    padding-left: 15px;
    border-right: 1px solid #d5d5e3;
    border-left: 1px solid #d5d5e3; }
  .modal-innercontent .info-credit-results .tablePadLeft {
    padding-right: 15px;
    padding-left: 15px;
    border-left: 1px solid #d5d5e3; }
  .modal-innercontent .info-credit-results th {
    background-color: #ebebf3 !important; }
    .modal-innercontent .info-credit-results th:nth-child(1) {
      width: 16rem;
      padding-right: 0.5rem; }
    .modal-innercontent .info-credit-results th:nth-child(2) {
      width: 8rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .modal-innercontent .info-credit-results th:nth-child(3) {
      padding-right: 0.5rem;
      padding-left: 1rem; }
    .modal-innercontent .info-credit-results th:nth-child(4) {
      padding-right: 0.5rem;
      padding-left: 1rem; }
  .modal-innercontent .info-credit-results td:nth-child(1) {
    min-width: 10rem;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 0.5rem; }
    @media (max-width: 960px) {
      .modal-innercontent .info-credit-results td:nth-child(1) {
        min-width: 11rem; } }
  .modal-innercontent .info-credit-results td:nth-child(2) {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 10rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .modal-innercontent .info-credit-results td:nth-child(3) {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 0.5rem;
    padding-left: 1rem; }
  .modal-innercontent .info-credit-results td:nth-child(4) {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 0.5rem;
    padding-left: 1rem; }
  .modal-innercontent .info-credit-results td button {
    padding: 11px 11px; }
    @media (max-width: 960px) {
      .modal-innercontent .info-credit-results td button span.btn-content {
        font-size: 1.8rem; } }

.modal-innercontent.left-align {
  text-align: left; }

.form-field.small-font {
  font-size: 14px; }

.modal-container.modal-login .modal-closebtn {
  background-color: transparent;
  color: #8688a2; }

.modal-container.modal-login .modal-content {
  width: 52rem; }

.modal-container.modal-login .modal-innercontent {
  padding: 3.9rem 6rem 6rem; }
  @media (max-width: 960px) {
    .modal-container.modal-login .modal-innercontent {
      padding: 3rem 2rem; } }

.modal-container.modal-confirm .confirm-question {
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.6rem;
  padding: 0 2rem; }

.modal-container.modal-confirm .confirm-opts {
  margin-top: 3.6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.modal-container.modal-confirm .opts-opt {
  flex: 1; }
  .modal-container.modal-confirm .opts-opt + .opts-opt {
    margin-left: 2rem; }

.modal-container.modal-consent .modal-closebtn {
  background-color: #f7f7fa;
  color: #161d4c; }
  .modal-container.modal-consent .modal-closebtn:hover, .modal-container.modal-consent .modal-closebtn:active {
    color: #fff;
    background-color: #161d4c; }

.modal-container.modal-consent .consent-banner {
  width: 100%;
  height: 18rem; }

.modal-innercontent {
  padding-top: 1.6rem; }

.sharer-title {
  font-size: 1.6rem; }
  @media (max-width: 960px) {
    .sharer-title {
      font-size: 2rem; } }

.sharer-media {
  padding: 5.3rem 0 3rem;
  text-align: center; }
  @media (max-width: 960px) {
    .sharer-media {
      display: flex;
      justify-content: space-between;
      padding-top: 2.6rem; } }

.media-link {
  display: inline-block;
  position: relative;
  width: 6rem;
  height: 6rem;
  font-size: 2.8rem;
  border-radius: 50%;
  background: #161d4c;
  color: #fff;
  transition: background-color 200ms ease; }
  @media (max-width: 960px) {
    .media-link {
      width: 5rem;
      height: 5rem; } }
  .media-link i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0); }
  .media-link + .media-link {
    margin-left: 4.8rem; }
    @media (max-width: 960px) {
      .media-link + .media-link {
        margin-left: 0; } }
  .media-link.facebook {
    background-color: #3b5998; }
    .media-link.facebook:hover, .media-link.facebook:active {
      background-color: #2d4373; }
  .media-link.twitter {
    background-color: #55acee; }
    .media-link.twitter:hover, .media-link.twitter:active {
      background-color: #2795e9; }
  .media-link.whatsapp {
    background-color: #3ace01; }
    .media-link.whatsapp:hover, .media-link.whatsapp:active {
      background-color: #30aa01; }
  .media-link:hover, .media-link:active {
    background-color: #050611; }

.sharer-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.1rem solid #e3e4e2;
  padding: 1.9rem 0 0; }
  @media (max-width: 960px) {
    .sharer-info {
      display: block; } }

.info-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.8rem;
  flex: 1;
  display: block; }

.info-copy {
  border-color: #e3e4e2;
  width: 13.5rem;
  margin-left: 2rem; }
  @media (max-width: 960px) {
    .info-copy {
      width: 100%;
      margin-left: 0;
      margin-top: 2rem; } }
  .info-copy:hover, .info-copy:active {
    border-color: #145aff; }

.modal-container.modal-options .options-title {
  font-weight: 400;
  font-size: 3.6rem; }
  .modal-container.modal-options .options-title + .options-question {
    margin-top: 1.6rem; }

.modal-container.modal-options .options-question {
  font-size: 1.6rem; }

.modal-container.modal-options .options-opts {
  display: flex;
  justify-content: space-around;
  margin-top: 2.2rem; }

.modal-container.modal-options .opts-item {
  flex: 1; }
  .modal-container.modal-options .opts-item + .opts-item {
    margin-left: 2rem; }

.modal-container.modal-text .modal-innercontent {
  padding: 8rem 6rem; }

.modal-container.modal-text .text-title {
  font-weight: 400;
  font-size: 4rem;
  color: #145aff;
  margin-bottom: 3rem; }

.modal-container.modal-text .text-content {
  font-size: 1.6rem;
  line-height: 1.3em; }

@media (max-width: 960px) {
  .modal-container.modal-map .modal-innercontent {
    padding: 1.5rem; } }

@media (max-width: 960px) {
  .modal-container.modal-map .modal-closebtn {
    top: .4rem;
    right: .4rem; } }

.modal-container.modal-map .map-container {
  position: relative;
  z-index: 1;
  height: 40rem; }

.modal-container.modal-bid {
  text-align: center;
  font-size: 1.8rem; }
  .modal-container.modal-bid .modal-closebtn {
    background-color: transparent;
    color: #8688a2; }
  .modal-container.modal-bid .modal-content {
    width: 65rem; }
  .modal-container.modal-bid .modal-innercontent {
    padding: 8rem 10rem 10rem; }
    @media (max-width: 960px) {
      .modal-container.modal-bid .modal-innercontent {
        padding: 3rem 2rem; } }
  .modal-container.modal-bid .bid-title {
    font-weight: 700;
    margin-bottom: 4rem;
    font-size: 3.2rem;
    line-height: 1.2em; }
    .modal-container.modal-bid .bid-title strong {
      font-weight: inherit;
      color: #145aff; }
  .modal-container.modal-bid .form-message {
    margin-top: 2rem; }
  .modal-container.modal-bid .form-submitbtn {
    margin-top: 2rem; }
  .modal-container.modal-bid .bid-complete {
    font-size: 1.8rem;
    line-height: 1.2em; }
  .modal-container.modal-bid .complete-icon {
    font-size: 10rem;
    color: #16d2d3; }
  .modal-container.modal-bid .complete-description {
    margin-top: 2rem; }
    .modal-container.modal-bid .complete-description strong {
      font-weight: 600; }
  .modal-container.modal-bid .complete-controls {
    font-weight: 600;
    margin-top: 1.5rem; }
    .modal-container.modal-bid .complete-controls > * + * {
      margin-left: 2rem; }
  .modal-container.modal-bid .bid-login > * + * {
    margin-top: 2rem; }

.modal-container.modal-youtube .map-container {
  z-index: 1; }

.modal-container.modal-youtube .modal-content {
  width: 120rem; }

@media (max-width: 960px) {
  .modal-container.modal-youtube .modal-innercontent {
    padding: 1.5rem; } }

@media (max-width: 960px) {
  .modal-container.modal-youtube .modal-closebtn {
    top: .4rem;
    right: .4rem; } }

.modal-container.modal-youtube .youtube-wrap {
  width: 100%;
  position: relative;
  padding-bottom: 56%; }

.modal-container.modal-youtube .youtube-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.section.header {
  padding: 1.8rem 0 1.8rem;
  border-bottom: 0.1rem solid #f7f7fa;
  position: relative;
  z-index: 20; }
  @media (max-width: 960px) {
    .section.header {
      padding: 0;
      border-bottom: none;
      z-index: 4;
      position: fixed;
      width: 100%;
      background-color: white; }
      .section.header.menu-active {
        z-index: 1001; } }
  .section.header .header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .section.header .header-search {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0); }
  .section.header .header-logo {
    display: inline-block;
    height: 3.4rem; }
    .section.header .header-logo img {
      height: 100%; }
  @media (max-width: 960px) {
    .section.header .header-logo {
      height: 2.3rem; } }
  .section.header .header-nav {
    position: relative;
    line-height: 4rem;
    box-sizing: content-box;
    height: 4rem;
    color: #161d4c;
    font-size: 0;
    padding-right: 11.4rem; }
    @media (max-width: 960px) {
      .section.header .header-nav {
        height: auto;
        line-height: auto;
        padding-right: 0; } }
  .section.header .nav-link {
    font-weight: 600;
    font-size: 1.4rem;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    transition: color 200ms ease; }
    .section.header .nav-link.active, .section.header .nav-link:hover, .section.header .nav-link:active {
      color: #145aff; }
    .section.header .nav-link + .nav-link {
      margin-left: 1.7rem; }
  .section.header .nav-user {
    display: inline-block;
    margin-left: 9.3rem; }
  .section.header .user-item {
    position: relative;
    display: inline-block;
    border: 0.1rem solid #145aff;
    color: #145aff;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    vertical-align: middle;
    line-height: 4rem;
    font-size: 1.8rem;
    text-align: center; }
    .section.header .user-item + .user-item {
      margin-left: 2rem; }
    .section.header .user-item.avatar {
      border: none; }
    .section.header .user-item .item-image {
      width: 100%;
      height: 100%;
      border-radius: 50%; }
    .section.header .user-item .item-unread {
      position: absolute;
      right: -.2rem;
      bottom: -.2rem;
      background: #ff2757;
      color: #fff;
      font-size: 1rem;
      width: 1.8rem;
      height: 1.8rem;
      line-height: 1.6rem;
      border-radius: 50%;
      text-align: center; }
  .section.header .nav-menubtn {
    position: absolute;
    height: 7.6rem;
    width: 7.6rem;
    top: -1.7rem;
    right: -2.5rem;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-left: 0.1rem solid #f7f7fa;
    transition: border-color 200ms ease 400ms; }
    .section.header .nav-menubtn.open {
      border-left-color: transparent;
      transition: border-color 200ms ease; }
      .section.header .nav-menubtn.open .menubtn-inner span {
        background-color: #fff; }
        .section.header .nav-menubtn.open .menubtn-inner span:nth-of-type(1) {
          transform: translateY(1.7rem);
          opacity: 0; }
        .section.header .nav-menubtn.open .menubtn-inner span:nth-of-type(2) {
          transform: scaleX(1) rotate(-45deg); }
        .section.header .nav-menubtn.open .menubtn-inner span:nth-of-type(3) {
          transform: scaleX(1) rotate(45deg); }
        .section.header .nav-menubtn.open .menubtn-inner span:nth-of-type(4) {
          transform: translateY(-1.7rem) scaleX(0.8727);
          opacity: 0; }
    @media (max-width: 960px) {
      .section.header .nav-menubtn {
        position: relative;
        top: auto;
        right: auto;
        border-left: none;
        height: 5.0rem;
        width: 6.4rem;
        right: 0;
        top: 0;
        margin-right: -1.3rem; } }
  .section.header .menubtn-inner {
    width: 2.2rem;
    height: 1.4rem;
    position: relative; }
    .section.header .menubtn-inner span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: block;
      background-color: #161d4c;
      height: .2rem;
      border-radius: .1rem;
      transform-origin: 50% 50%;
      transition: background-color 200ms ease;
      transition: transform 200ms ease, opacity 200ms ease, background-color 200ms ease 200ms; }
      .section.header .menubtn-inner span:nth-of-type(2), .section.header .menubtn-inner span:nth-of-type(3) {
        top: .6rem;
        transform: scaleX(0.81818) translateX(8.1%); }
      .section.header .menubtn-inner span:nth-of-type(4) {
        top: 1.2rem;
        transform: scaleX(0.63636) translateX(25.3%); }
    @media (max-width: 960px) {
      .section.header .menubtn-inner {
        width: 2.4rem;
        height: 1.5rem; } }
  .section.header .nav-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    color: #fff; }
    .section.header .nav-menu.show .menu-overlay {
      opacity: 1;
      transition: opacity 300ms ease; }
    .section.header .nav-menu.show .menu-outerwrap {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: transform 300ms ease 150ms, opacity 300ms ease 150ms;
      -webkit-overflow-scrolling: touch; }
      .section.header .nav-menu.show .menu-outerwrap * {
        -webkit-overflow-scrolling: touch; }
    .section.header .nav-menu.show .content-innerwrap {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: transform 300ms ease 300ms, opacity 300ms ease 300ms; }
  .section.header .menu-outerwrap {
    background-color: #161d4c;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 58.4rem;
    opacity: 0;
    z-index: 2;
    transform: translate3d(100%, 0, 0);
    transition: transform 300ms ease, opacity 300ms ease 100ms; }
    @media (max-width: 960px) {
      .section.header .menu-outerwrap {
        width: 72.26%;
        /*left: 0;
						right: auto;
						transform: translate3d(-100%, 0, 0);*/ } }
  .section.header .menu-overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: opacity 300ms ease 300ms;
    background: rgba(0, 0, 0, 0.7); }
    @media (max-width: 960px) {
      .section.header .menu-overlay {
        background: rgba(22, 29, 76, 0.5); } }
  .section.header .menu-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-height: 100%; }
  .section.header .content-innerwrap {
    opacity: 0;
    font-size: 1.4rem;
    transform: translate3d(20%, 0, 0);
    transition: transform 300ms ease, opacity 300ms ease 100ms;
    padding: 10.1rem 8.1rem 7rem 6.3rem; }
    @media (max-width: 960px) {
      .section.header .content-innerwrap {
        transform: translate3d(-6%, 0, 0);
        padding: 2.1rem 3rem 3.2rem; } }
  .section.header .menu-header {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 960px) {
      .section.header .menu-header {
        justify-content: flex-start; } }
  .section.header .menu-logo {
    height: 2.2rem; }
  .section.header .menu-social {
    font-size: 0;
    letter-spacing: 0;
    line-height: 0; }
    .section.header .menu-social a {
      display: inline-block;
      width: 3rem; }
      .section.header .menu-social a img {
        width: 100%; }
      .section.header .menu-social a + a {
        margin-left: 1rem; }
      @media (max-width: 960px) {
        .section.header .menu-social a {
          width: 2.6rem; } }
  .section.header .menu-userbar {
    font-weight: 400;
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 3rem;
    text-decoration: underline;
    color: #16d2d3; }
  .section.header .userbar-link + .userbar-link {
    margin-left: 2.9rem; }
  .section.header .menu-items {
    font-weight: 200;
    font-size: 3.2rem;
    margin-top: 1.3rem; }
    @media (max-width: 960px) {
      .section.header .menu-items {
        font-size: 1.6rem;
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
        border-top: 0.1rem solid rgba(255, 255, 255, 0.1); } }
  .section.header .menu-item a {
    display: flex;
    justify-content: space-between;
    height: 8rem;
    align-items: center; }
    @media (max-width: 960px) {
      .section.header .menu-item a {
        height: 5.5rem; } }
  .section.header .menu-item .item-count {
    font-size: 1.6rem;
    opacity: .2; }
  .section.header .menu-item + .menu-item {
    border-top: 0.1rem solid rgba(255, 255, 255, 0.1); }
  .section.header .menu-sublinks {
    font-weight: 400;
    font-size: 1.4rem;
    text-transform: uppercase;
    margin-top: 4rem; }
    @media (max-width: 960px) {
      .section.header .menu-sublinks {
        margin-top: 2.7rem; } }
  .section.header .sublinks-item {
    display: inline-block;
    margin-right: 5rem;
    margin-bottom: 2rem; }
    @media (max-width: 960px) {
      .section.header .sublinks-item {
        font-size: 1.1rem;
        line-height: 1.3rem;
        display: block;
        margin-right: 0;
        margin-bottom: 0; }
        .section.header .sublinks-item + .sublinks-item {
          margin-top: 2.7rem; } }
  .section.header .menu-thumblinks {
    margin-top: 6rem;
    line-height: 0; }
    @media (max-width: 960px) {
      .section.header .menu-thumblinks {
        margin-top: 2.7rem;
        padding-top: 2rem;
        border-top: 0.1rem solid rgba(255, 255, 255, 0.1); } }
  .section.header .thumblinks-item {
    font-weight: 400;
    display: inline-block;
    width: 20rem; }
    .section.header .thumblinks-item.full {
      width: 100%; }
    @media (max-width: 960px) {
      .section.header .thumblinks-item {
        line-height: 1.8rem; } }
    .section.header .thumblinks-item:nth-child(2n+2) {
      margin-left: 2rem; }
      @media (max-width: 960px) {
        .section.header .thumblinks-item:nth-child(2n+2) {
          margin-left: 0;
          margin-top: 1.7rem; } }
  .section.header .thumblinks-image {
    margin-bottom: 1.2rem; }
    @media (max-width: 960px) {
      .section.header .thumblinks-image {
        margin-bottom: .8rem; } }
  .section.header .menu-applinks {
    margin-top: 8rem; }
    @media (max-width: 960px) {
      .section.header .menu-applinks {
        margin-top: 1.9rem;
        border-top: 0.1rem solid rgba(255, 255, 255, 0.1);
        padding-top: 3rem; } }
  .section.header .applinks-link {
    display: inline-block;
    width: calc(50% - 1rem); }
    @media (max-width: 960px) {
      .section.header .applinks-link {
        width: calc(50% - .5rem);
        padding-left: 0;
        padding-right: 0; }
        .section.header .applinks-link .btn-icon.pre {
          margin-right: .5rem; } }
    .section.header .applinks-link + .applinks-link {
      margin-left: 2rem; }
      @media (max-width: 960px) {
        .section.header .applinks-link + .applinks-link {
          margin-left: 1rem; } }

.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; }

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.nav-link-drop {
  background: white !important;
  border: none !important;
  color: black !important;
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  transition: color 200ms ease;
  color: black !important; }

.dropdown-item {
  background: white !important;
  border: none !important;
  color: black !important;
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  transition: color 200ms ease; }

.nav-link-drop-hamburger {
  position: absolute !important;
  top: -2rem !important;
  left: 0 !important;
  background: transparent !important;
  color: white !important;
  font-weight: 200 !important;
  font-size: 2rem !important; }

@media screen and (min-width: 1200px) {
  .nav-link-drop-hamburger {
    top: -8rem !important;
    font-size: 2.2rem !important;
    left: -1rem !important; } }

.section.footer {
  /*.list-group {
					@include media {
						width: calc(50% - 1.5rem);
					}
				}*/ }
  .section.footer .footer-jump-to {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #f7f7fa;
    color: #8688a2;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px; }
  .section.footer .footer-jump-to:hover {
    background-color: #555; }
  .section.footer .footer-bottom {
    background-color: #161d4c;
    color: #fff;
    padding: 5rem 0 5rem;
    font-size: 1.4rem; }
    @media (max-width: 960px) {
      .section.footer .footer-bottom {
        padding: 3rem 0 3.3rem; } }
  .section.footer .footer-wrap {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 960px) {
      .section.footer .footer-wrap {
        display: block;
        padding: 0 1.5rem; } }
  .section.footer .footer-logo {
    display: inline-block;
    width: 9.4rem; }
    @media (max-width: 960px) {
      .section.footer .footer-logo {
        width: 10.4rem; } }
    .section.footer .footer-logo img {
      width: 100%; }
  @media (max-width: 960px) {
    .section.footer .footer-col + .footer-col {
      margin-top: 2rem; } }
  .section.footer .footer-nav {
    width: 55.72%;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 960px) {
      .section.footer .footer-nav {
        width: auto;
        flex-flow: row wrap; } }
  .section.footer .nav-col {
    line-height: 2.43em; }
    @media (max-width: 960px) {
      .section.footer .nav-col {
        font-size: 1.4rem;
        line-height: 2.14em; } }
  .section.footer .nav-contact i {
    margin-right: .9rem; }
  @media (max-width: 960px) {
    .section.footer .nav-contact {
      width: 100%;
      margin-top: 6rem;
      display: static;
      justify-content: space-between;
      align-items: center; }
      .section.footer .nav-contact .contact-text {
        margin-bottom: 2rem; } }
  .section.footer .contact-social {
    margin-top: 1.8rem; }
    .section.footer .contact-social li {
      display: inline-block;
      width: 3rem;
      height: 3rem; }
      .section.footer .contact-social li + li {
        margin-left: 1rem; }
      .section.footer .contact-social li a, .section.footer .contact-social li img {
        width: 100%; }
    @media (max-width: 960px) {
      .section.footer .contact-social {
        margin-top: 0; } }
  @media (max-width: 960px) {
    .section.footer .footer-app {
      padding: 0 3rem;
      text-align: center; } }
  .section.footer .app-title {
    line-height: 3.4rem;
    text-transform: uppercase;
    margin-bottom: .3rem; }
    @media (max-width: 960px) {
      .section.footer .app-title {
        font-weight: 400;
        font-size: 1.4rem;
        margin-bottom: 1rem; } }
  .section.footer .app-link {
    width: calc(50% - .75rem); }
    .section.footer .app-link + .app-link {
      margin-left: .6rem; }
      @media (max-width: 960px) {
        .section.footer .app-link + .app-link {
          margin-left: 1.5rem; } }
  .section.footer .footer-subscription {
    margin-top: 1.2rem;
    position: relative;
    width: 30rem; }
    @media (max-width: 960px) {
      .section.footer .footer-subscription {
        width: auto;
        margin-top: 4rem;
        margin-bottom: 4.4rem; } }
  .section.footer .subscription-input > input {
    background: transparent;
    border: 0.1rem solid rgba(255, 255, 255, 0.2);
    color: #fff;
    border-radius: 0.3rem;
    display: block;
    font-size: 1.1rem;
    padding: 1.9rem 1.7rem;
    line-height: 1.3rem;
    width: 31rem; }
    @media (max-width: 960px) {
      .section.footer .subscription-input > input {
        padding: 1.3rem 1rem;
        line-height: 1.2rem;
        width: 31rem; } }
    .section.footer .subscription-input > input::placeholder {
      opacity: 1;
      color: #8a92a6; }
    .section.footer .subscription-input > input:-ms-input-placeholder {
      color: #8a92a6; }
    .section.footer .subscription-input > input::-ms-input-placeholder {
      color: #8a92a6; }
  .section.footer .subscription-submitwrap {
    position: absolute;
    min-width: 3rem;
    min-height: 3rem;
    right: 1rem;
    top: 2.6rem;
    transform: translate3d(0, -50%, 0); }
    @media (max-width: 960px) {
      .section.footer .subscription-submitwrap {
        right: 0.6rem;
        top: 2rem; } }
  .section.footer .submitwrap-submit {
    width: 3rem;
    height: 4rem;
    font-size: 1.1rem; }
  .section.footer .submitwrap-loader {
    animation: spin 3s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    font-size: 1.8rem; }
  .section.footer .submitwrap-complete {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 1.8rem;
    height: 1.8rem;
    font-size: 1.8rem; }
  .section.footer .footer-copyright {
    margin-top: 8rem;
    font-size: 1.2rem;
    color: #8a92a6; }
    @media (max-width: 960px) {
      .section.footer .footer-copyright {
        margin-top: 4.8rem;
        margin-bottom: 5.7rem; } }
  .section.footer .copyright-disclaimer {
    width: 50%;
    font-size: 1.2rem; }
    @media (max-width: 960px) {
      .section.footer .copyright-disclaimer {
        width: 100%; } }
  @media (max-width: 960px) {
    .section.footer .copyright-designby {
      margin-top: 2.6rem; } }
  .section.footer .copyright-designby a {
    text-decoration: underline; }
  .section.footer .footer-brands {
    background-color: #f7f7fa;
    color: #8688a2;
    padding: 6rem 0;
    font-size: 1.4rem; }
    @media (max-width: 960px) {
      .section.footer .footer-brands {
        padding: 2.7rem 1.5rem; } }
  .section.footer .brands-list {
    display: flex;
    justify-content: space-between; }
  .section.footer .brands-item {
    line-height: 2rem;
    padding: 1rem 0; }
    @media (max-width: 960px) {
      .section.footer .brands-item {
        font-size: 1.2rem; } }
  .section.footer .item-count {
    margin-left: .4rem; }
  .section.footer .payment-methods {
    width: 50%;
    text-align: right;
    margin-top: -30px; }
    @media (max-width: 960px) {
      .section.footer .payment-methods {
        width: 100%;
        text-align: left; } }
    .section.footer .payment-methods img {
      width: 70px;
      display: inline-block;
      margin-left: 25px; }
      @media (max-width: 960px) {
        .section.footer .payment-methods img {
          margin-left: 0;
          margin-right: 25px; } }

.section.listing-title {
  max-width: 160rem;
  margin: 0 auto;
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: space-between; }
  .section.listing-title h1 {
    padding: 2.1rem 2.5rem 2.1rem;
    font-weight: 300;
    font-size: 2.5rem; }
    @media (max-width: 960px) {
      .section.listing-title h1 {
        font-size: 2rem;
        padding: 2.1rem 2.5rem 2.1rem 1rem; } }
  @media (max-width: 960px) {
    .section.listing-title {
      z-index: 3; } }

.section.listing {
  min-height: 40rem;
  max-width: 160rem;
  margin: 0 auto;
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: space-between; }
  @media (max-width: 960px) {
    .section.listing {
      z-index: 3; } }
  .section.listing.has-filters .listing-content {
    width: calc(100% - 28rem);
    z-index: 4; }
    @media (max-width: 960px) {
      .section.listing.has-filters .listing-content {
        width: 100%; } }
  .section.listing.has-filters .listing-filters {
    width: 28rem;
    border-right: 0.1rem solid #f7f7fa;
    z-index: 5; }
    @media (max-width: 960px) {
      .section.listing.has-filters .listing-filters {
        width: 100%; } }
  .section.listing.has-filters-brand .listing-content {
    width: 100%;
    z-index: 4; }
    @media (max-width: 960px) {
      .section.listing.has-filters-brand .listing-content {
        width: 100%; } }
  .section.listing.has-filters-brand .listing-filters {
    width: 28rem;
    border-right: 0.1rem solid #f7f7fa;
    z-index: 5; }
    @media (max-width: 960px) {
      .section.listing.has-filters-brand .listing-filters {
        width: 100%; } }
  .section.listing.size-5 .results-item {
    width: 20%; }
    .section.listing.size-5 .results-item.x2 {
      width: 40%; }
    .section.listing.size-5 .results-item.x3 {
      width: 60%; }
    .section.listing.size-5 .results-item.x4 {
      width: 80%; }
    .section.listing.size-5 .results-item.x5 {
      width: 100%; }
    .section.listing.size-5 .results-item > * {
      flex: 1;
      width: 100%; }
    @media (max-width: 960px) {
      .section.listing.size-5 .results-item {
        width: 50%; }
        .section.listing.size-5 .results-item.banner {
          align-items: flex-start; }
          .section.listing.size-5 .results-item.banner.x2 {
            width: 100%; }
          .section.listing.size-5 .results-item.banner .banner-image {
            position: static; } }
  .section.listing.size-4 .results-item {
    width: 25%;
    min-height: 34rem;
    display: flex; }
    .section.listing.size-4 .results-item.x2 {
      width: 50%; }
    .section.listing.size-4 .results-item.x3 {
      width: 75%; }
    .section.listing.size-4 .results-item.x4 {
      width: 100%; }
    @media (max-width: 960px) {
      .section.listing.size-4 .results-item {
        width: 50%;
        min-height: auto; }
        .section.listing.size-4 .results-item.banner {
          align-items: flex-start; }
          .section.listing.size-4 .results-item.banner.x2 {
            width: 100%; }
          .section.listing.size-4 .results-item.banner .banner-image {
            position: static; } }
  .section.listing .listing-content {
    padding: 1rem 1.5rem;
    width: 100%; }
    @media (max-width: 960px) {
      .section.listing .listing-content {
        padding: 0; } }
  .section.listing .content-top {
    position: relative;
    z-index: 2;
    padding: 1rem 1.5rem .5rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media (max-width: 960px) {
      .section.listing .content-top {
        padding: 1rem 1.5rem 1rem;
        border-bottom: 0.1rem solid #eaebf3; } }
  .section.listing .top-filterstrigger {
    font-weight: 500;
    font-size: 1.4rem; }
    .section.listing .top-filterstrigger i {
      margin-right: 1.3rem;
      font-size: 1.5rem; }
  .section.listing .top-activefilters {
    margin-top: -.2rem; }
  .section.listing .activefilters-item {
    font-weight: 500;
    position: relative;
    font-size: 1.2rem;
    border: 0.1rem solid #f7f7fa;
    line-height: 1.5rem;
    border-radius: 1.5rem;
    display: inline-block;
    color: rgba(22, 29, 76, 0.5);
    padding: .4rem 3.2rem .4rem 1.6rem;
    margin: .3rem 1rem .3rem 0;
    transition: background-color 200ms ease, color 200ms ease; }
    .section.listing .activefilters-item:hover {
      background-color: rgba(247, 247, 250, 0.5);
      color: #161d4c; }
  .section.listing .item-title {
    font-weight: 700;
    display: block;
    font-size: 1rem; }
  .section.listing .item-value {
    display: block; }
  .section.listing .item-remove {
    position: absolute;
    right: .9rem;
    top: 50%;
    font-size: 1rem;
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, -50%, 0); }
  .section.listing .top-order {
    display: inline-block;
    width: 23rem;
    margin-left: auto; }
    @media (max-width: 960px) {
      .section.listing .top-order {
        width: calc(100% - 11rem); } }
    .section.listing .top-order .minoto-select .minoto-select__control {
      border: none;
      height: 3rem;
      min-height: auto;
      color: #145aff; }
    .section.listing .top-order .minoto-select .minoto-select__value-container {
      padding: 0 .9rem 0 0;
      height: 100%; }
    .section.listing .top-order .minoto-select .minoto-select__indicator {
      padding: 0;
      width: 1rem; }
      .section.listing .top-order .minoto-select .minoto-select__indicator svg path {
        fill: #145aff; }
      @media (max-width: 960px) {
        .section.listing .top-order .minoto-select .minoto-select__indicator {
          width: 1.5rem; }
          .section.listing .top-order .minoto-select .minoto-select__indicator svg path {
            fill: #161d4c; } }
    .section.listing .top-order .minoto-select .minoto-select__placeholder, .section.listing .top-order .minoto-select .minoto-select__single-value {
      right: 0;
      margin-right: .9rem;
      font-size: 1.2rem;
      text-transform: uppercase;
      color: #145aff;
      overflow: visible; }
      @media (max-width: 960px) {
        .section.listing .top-order .minoto-select .minoto-select__placeholder, .section.listing .top-order .minoto-select .minoto-select__single-value {
          font-weight: 500;
          font-size: 1.4rem;
          color: #161d4c; } }
    .section.listing .top-order .minoto-select .minoto-select__menu-list {
      border-top: none; }
    .section.listing .top-order .minoto-select .minoto-select__option {
      border-bottom: 0.1rem solid #eaebf3;
      padding: 1.8rem 2rem;
      color: #145aff;
      font-size: 1.4rem; }
      @media (max-width: 960px) {
        .section.listing .top-order .minoto-select .minoto-select__option {
          padding: 1.2rem 2rem;
          color: #161d4c; } }
  .section.listing .content-results {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0 1.5rem;
    position: relative;
    z-index: 1; }
    @media (max-width: 960px) {
      .section.listing .content-results {
        padding: .75rem .75rem 2rem; } }
  .section.listing .results-error {
    padding: 6rem;
    text-align: center; }
    .section.listing .results-error .error-message {
      display: inline-block;
      background-color: #f7f7fa;
      border: 0.1rem solid #eaebf3;
      padding: 4.5rem 1rem;
      font-size: 1.8rem;
      max-width: 100%;
      width: 58rem; }
  .section.listing .results-item {
    position: relative;
    padding: 1.5rem;
    display: flex; }
    @media (max-width: 960px) {
      .section.listing .results-item {
        padding: .75rem; } }
    .section.listing .results-item .contentbox {
      width: 100%;
      max-width: 100%; }
  .section.listing .banner {
    height: 380px;
    padding-top: 2%; }
    @media (max-width: 960px) {
      .section.listing .banner {
        padding-top: 40px; } }
  .section.listing .item-banner {
    position: relative;
    flex: 1;
    min-height: 32rem;
    width: 100%; }
    @media (max-width: 960px) {
      .section.listing .item-banner {
        flex: none;
        min-height: auto; } }
  .section.listing .banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%; }
  .section.listing .banner-instagram {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("/assets/images/Instagram.jpg");
    background-position: center center;
    background-size: cover; }
    .section.listing .banner-instagram .instagram-text {
      font-weight: 300;
      position: absolute;
      left: 2.5rem;
      top: 2.5rem;
      z-index: 2;
      color: #fff;
      line-height: 1.3em;
      font-size: 2.4rem; }
      @media (max-width: 960px) {
        .section.listing .banner-instagram .instagram-text {
          left: 2rem;
          top: 2rem;
          line-height: 1.2em;
          font-size: 1.7rem; } }
  .section.listing .banner-blog {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("/assets/images/Blog.jpg");
    background-position: center center;
    background-size: cover; }
    .section.listing .banner-blog .blog-text {
      font-weight: 300;
      position: absolute;
      left: 2.5rem;
      top: 2.5rem;
      z-index: 2;
      color: #fff;
      line-height: 1.3em;
      font-size: 2.4rem; }
      @media (max-width: 960px) {
        .section.listing .banner-blog .blog-text {
          left: 2rem;
          top: 2rem;
          line-height: 1.2em;
          font-size: 1.7rem; } }
  .section.listing .electric-img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-image: url("/assets/images/ElectricCar.jpg");
    background-position: center center;
    background-size: cover; }
    .section.listing .electric-img .electric-text {
      font-weight: 300;
      position: absolute;
      left: 2.5rem;
      top: 2.5rem;
      z-index: 2;
      color: #fff;
      line-height: 1.3em;
      font-size: 2.4rem; }
      @media (max-width: 960px) {
        .section.listing .electric-img .electric-text {
          left: 1.2rem;
          top: 2rem;
          line-height: 1.2em;
          font-size: 1.7rem; } }
  .section.listing .banner-youtube {
    width: 100%;
    background-color: #161d4c;
    color: #fff; }
    @media (max-width: 960px) {
      .section.listing .banner-youtube {
        padding: 1.2rem 2.5rem; } }
    .section.listing .banner-youtube .youtube-text {
      font-weight: 300;
      font-size: 1.6rem; }
      .section.listing .banner-youtube .youtube-text > * + * {
        margin-top: 1rem; }
    .section.listing .banner-youtube .text-heading {
      font-weight: 300;
      position: relative;
      padding-left: 1.4rem;
      font-size: 2.4rem;
      line-height: 1.3em; }
      @media (max-width: 960px) {
        .section.listing .banner-youtube .text-heading {
          font-size: 1.6rem; } }
      .section.listing .banner-youtube .text-heading strong {
        font-weight: 600; }
      .section.listing .banner-youtube .text-heading:before {
        position: absolute;
        left: 0;
        top: .6rem;
        bottom: .6rem;
        width: .5rem;
        background-color: #ff2757; }
    .section.listing .banner-youtube .text-subheading {
      color: rgba(255, 255, 255, 0.7); }
      @media (max-width: 960px) {
        .section.listing .banner-youtube .text-subheading {
          font-size: 1.2rem; } }
    .section.listing .banner-youtube .youtube-image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
      @media (max-width: 960px) {
        .section.listing .banner-youtube .youtube-image {
          margin-top: 1.3rem; } }
  .section.listing .banner-tag {
    width: 100%;
    padding: 2.5rem;
    background-color: #f7f7fa;
    color: #161D4CFF;
    border: 0.1rem solid #eaebf3; }
    .section.listing .banner-tag .tags-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem 2rem; }
      .section.listing .banner-tag .tags-wrapper .tag-wrapper {
        background-color: #f7f7fa;
        border: 0.1rem solid #a2a3a8;
        padding: .6rem .8rem;
        border-radius: 2rem; }
        @media (max-width: 960px) {
          .section.listing .banner-tag .tags-wrapper .tag-wrapper {
            font-size: 11px; } }
      @media (max-width: 960px) {
        .section.listing .banner-tag .tags-wrapper {
          gap: .8rem 1rem; } }
    @media (max-width: 960px) {
      .section.listing .banner-tag {
        padding: 1.2rem 1.2rem; } }
  .section.listing .banner-hr {
    background-color: #161d4c;
    color: #fff;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5rem; }
    @media (max-width: 960px) {
      .section.listing .banner-hr {
        padding: 1.2rem 2.5rem; } }
    .section.listing .banner-hr .hr-content {
      font-weight: 300;
      line-height: 1.3em;
      overflow: hidden;
      z-index: 0; }
      .section.listing .banner-hr .hr-content .inner-triangle {
        border-left: 25rem solid transparent;
        border-right: 29rem solid #092C7F;
        border-top: 18rem solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        bottom: 1.5rem;
        right: 1.5rem;
        z-index: 2; }
        @media (max-width: 960px) {
          .section.listing .banner-hr .hr-content .inner-triangle {
            display: none; } }
    .section.listing .banner-hr .content-title {
      font-size: 2.4rem;
      z-index: 555; }
      @media (max-width: 960px) {
        .section.listing .banner-hr .content-title {
          font-size: 1.5rem; } }
    .section.listing .banner-hr .content-description {
      font-size: 1.6rem;
      margin-top: 2rem;
      color: rgba(255, 255, 255, 0.7);
      z-index: 555; }
      @media (max-width: 960px) {
        .section.listing .banner-hr .content-description {
          font-size: 1.2rem; } }
      .section.listing .banner-hr .content-description strong {
        color: #fff; }
    .section.listing .banner-hr .content-cta {
      margin-top: 6rem;
      min-width: 16rem;
      max-width: 100%;
      z-index: 555; }
      @media (max-width: 960px) {
        .section.listing .banner-hr .content-cta {
          margin-top: 2rem;
          min-width: 10rem; } }
  .section.listing .banner-iframe {
    color: #fff;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; }
    @media (max-width: 960px) {
      .section.listing .banner-iframe {
        height: 20rem; } }
    .section.listing .banner-iframe iframe {
      width: 100% !important;
      height: 100% !important; }
  .section.listing .results-ad {
    text-align: center;
    display: block; }
  .section.listing .ad-link {
    display: inline-block;
    height: 9rem;
    flex: none;
    width: auto; }
    .section.listing .ad-link img {
      height: 100%;
      display: block; }
  .section.listing .content-infinitescroll {
    opacity: 0;
    text-align: center;
    transition: opacity 200ms ease; }
    .section.listing .content-infinitescroll.loading {
      opacity: 1; }
    .section.listing .content-infinitescroll span {
      display: inline-block;
      position: relative;
      height: 3rem;
      margin-top: -1.5rem; }
    .section.listing .content-infinitescroll i {
      animation: spin 3s infinite linear;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #145aff;
      font-size: 2rem; }

@media (max-width: 960px) {
  .section.listing-filters {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    padding: 0; }
    .section.listing-filters.active {
      display: block; }
      .section.listing-filters.active.show .filters-overlay {
        opacity: 1; }
      .section.listing-filters.active.show .filters-content {
        transform: translate3d(0, 0, 0);
        -webkit-overflow-scrolling: touch; }
        .section.listing-filters.active.show .filters-content * {
          -webkit-overflow-scrolling: touch; } }

.section.listing-filters.first-child .filters-innerwrap {
  padding-top: 0; }

.section.listing-filters.first-child .filters-header {
  border-top: none;
  padding-top: 0; }

@media (max-width: 960px) {
  .section.listing-filters .filters-content {
    position: relative;
    z-index: 2;
    background-color: #fff;
    width: 27rem;
    padding: 0 0 0;
    height: 100%;
    transform: translate3d(-100%, 0, 0);
    transition: transform 300ms ease; } }

.section.listing-filters .filters-overlay {
  display: block;
  border: none;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(22, 29, 76, 0.5);
  z-index: 1;
  transition: opacity 300ms ease;
  cursor: pointer; }

.section.listing-filters .filters-innerwrap {
  padding: 0 2.5rem 2.1rem;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto; }
  @media (max-width: 960px) {
    .section.listing-filters .filters-innerwrap {
      margin-top: 3.2em;
      padding-bottom: 15rem; } }

.section.listing-filters .filters-controls {
  background-color: #fff;
  padding: 2.1rem 2.5rem 4rem;
  /*position: fixed;
			bottom: 0;
			width: 28rem;
			box-shadow: $shadow-general;*/ }
  @media (max-width: 960px) {
    .section.listing-filters .filters-controls {
      position: absolute;
      bottom: 3em;
      left: 0;
      width: 100%;
      padding-bottom: 2.1rem; } }

.section.listing-filters .controls-filteronthego + .controls-btn {
  margin-top: 2rem; }

.section.listing-filters .filters-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.7rem 0 1.6rem;
  margin-bottom: 1.9rem;
  border-bottom: 0.1rem solid #eaebf3; }
  @media (max-width: 960px) {
    .section.listing-filters .filters-header {
      border-top: none; } }

.section.listing-filters .header-title {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem; }

.section.listing-filters .header-clear {
  font-weight: 600;
  font-size: 1.2rem;
  text-decoration: underline;
  color: rgba(22, 29, 76, 0.5); }

.section.listing-filters .filters-filter.expandable .filter-content {
  display: none;
  opacity: 0;
  transform: translate3d(0, -0.5rem, 0);
  transition: transform 200ms ease, opacity 200ms ease; }

.section.listing-filters .filters-filter.expandable.active .filter-content {
  display: block; }

.section.listing-filters .filters-filter.expandable.active.show .filter-title {
  border-bottom-color: transparent; }
  .section.listing-filters .filters-filter.expandable.active.show .filter-title:after {
    transform: translate3d(0, -50%, 0) rotate(180deg); }

.section.listing-filters .filters-filter.expandable.active.show .filter-content {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.section.listing-filters .filters-filter.type-list .filter-list {
  background-color: #f7f7fa; }
  .section.listing-filters .filters-filter.type-list .filter-list .checkwrap {
    padding-top: 0;
    padding-bottom: 0; }
    .section.listing-filters .filters-filter.type-list .filter-list .checkwrap input {
      top: 0; }
      .section.listing-filters .filters-filter.type-list .filter-list .checkwrap input + label {
        padding: .2rem 0 .2rem 2.1rem;
        display: block; }
        .section.listing-filters .filters-filter.type-list .filter-list .checkwrap input + label span {
          opacity: 1;
          top: 1.1rem;
          width: 1.4rem;
          height: 1.4rem; }

.section.listing-filters .filters-filter.type-list .filter-item {
  padding: 1rem 1rem; }
  .section.listing-filters .filters-filter.type-list .filter-item + .filter-item {
    border-top: 0.1rem solid #eaebf3; }

.section.listing-filters .filters-filter.type-list .item-text {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.section.listing-filters .filters-filter.type-list .title-count {
  color: #8688a2;
  font-size: 1.2rem;
  transition: color 200ms ease;
  margin-left: .3rem; }

.section.listing-filters .filters-filter.type-list .item-logo {
  height: 2rem; }

.section.listing-filters .filters-filter.type-list .checkwrap input:checked + label .title-count {
  color: #145aff; }

.section.listing-filters .filters-filter.type-tree .filter-list {
  background-color: #f7f7fa; }

.section.listing-filters .filters-filter.type-tree .filter-item.level-2 .item-wrap {
  padding-left: 2rem; }

.section.listing-filters .filters-filter.type-tree .filter-item.level-3 .item-wrap {
  padding-left: 3rem; }

.section.listing-filters .filters-filter.type-tree .filter-item.level-4 .item-wrap {
  padding-left: 4rem; }

.section.listing-filters .filters-filter.type-tree .item-wrap {
  padding: 0 0 0 1rem;
  min-height: 4.2rem;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  text-align: left;
  border-top: 0.1rem solid #e3e4e2;
  /* 	&.expandable {

							} */ }
  .section.listing-filters .filters-filter.type-tree .item-wrap .inputwrap {
    display: inline-block;
    max-width: calc(100% - 3rem); }
  .section.listing-filters .filters-filter.type-tree .item-wrap .checkwrap input {
    top: 0; }
    .section.listing-filters .filters-filter.type-tree .item-wrap .checkwrap input + label {
      padding: .2rem 0 .2rem 0.7rem;
      display: block; }
      .section.listing-filters .filters-filter.type-tree .item-wrap .checkwrap input + label span {
        opacity: 0;
        top: 1rem;
        width: 1.4rem;
        height: 1.4rem; }
  .section.listing-filters .filters-filter.type-tree .item-wrap .item-expand {
    flex: 1;
    margin-left: .5rem;
    height: 4.1rem;
    position: relative;
    z-index: 2; }

.section.listing-filters .filters-filter.type-tree .item-subgroup .item-submenu {
  display: none; }

.section.listing-filters .filters-filter.type-tree .item-subgroup > .item-wrap.expandable:after {
  position: absolute;
  font-size: .9rem;
  color: rgba(22, 29, 76, 0.3);
  right: .8rem;
  top: 50%;
  z-index: 1;
  transform: translate3d(0, -50%, 0); }

.section.listing-filters .filters-filter.type-tree .item-subgroup.expanded > .item-wrap:after {
  transform: translate3d(0, -50%, 0) rotate(90deg); }

.section.listing-filters .filters-filter.type-tree .item-subgroup.expanded > .item-submenu {
  display: block; }

.section.listing-filters .filters-filter.type-brands .filter-back {
  position: relative;
  background-color: #f7f7fa;
  /*padding: 0 1rem;
					min-height: 4.2rem;*/ }

.section.listing-filters .filters-filter.type-brands .back-icon {
  position: absolute;
  left: .7rem;
  top: 50%;
  font-size: .8em;
  color: #8688a2;
  transform: translate3d(0, -50%, 0); }

.section.listing-filters .filters-filter.type-brands .filter-list {
  background-color: #f7f7fa; }
  .section.listing-filters .filters-filter.type-brands .filter-list.showMore {
    overflow: hidden;
    position: relative; }

.section.listing-filters .filters-filter.type-brands .filter-item.level-2 .item-wrap {
  padding-left: 3rem; }

.section.listing-filters .filters-filter.type-brands .filter-item.level-3 .item-wrap {
  padding-left: 4rem; }

.section.listing-filters .filters-filter.type-brands .filter-item.level-4 .item-wrap {
  padding-left: 5rem; }

.section.listing-filters .filters-filter.type-brands .filter-item.level-5 .item-wrap {
  padding-left: 6rem; }

.section.listing-filters .filters-filter.type-brands .filter-item.level-6 .item-wrap {
  padding-left: 7rem; }

.section.listing-filters .filters-filter.type-brands .item-wrap {
  padding: 0 0 0 2rem;
  min-height: 4.2rem;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  text-align: left;
  border-top: 0.1rem solid #e3e4e2;
  /*.inputwrap {
								display: inline-block;
								max-width: calc(100% - 3rem);
							}*/ }
  .section.listing-filters .filters-filter.type-brands .item-wrap .showMoreBtn {
    cursor: pointer;
    color: #145aff; }
  .section.listing-filters .filters-filter.type-brands .item-wrap.active {
    font-weight: 600; }
  .section.listing-filters .filters-filter.type-brands .item-wrap .item-expand {
    flex: 1;
    margin-left: .5rem;
    height: 4.1rem;
    position: relative;
    z-index: 2; }
  .section.listing-filters .filters-filter.type-brands .item-wrap .item-count {
    font-size: .8em;
    margin-left: .5rem;
    color: #8688a2; }

.section.listing-filters .filters-filter.type-brands .item-subgroup .item-submenu {
  display: block; }

.section.listing-filters .filters-filter.type-icons .filter-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.section.listing-filters .filters-filter.type-icons .filter-item {
  width: 50%;
  position: relative;
  text-align: center;
  font-size: 1.1rem;
  padding-bottom: .8rem;
  color: #8688a2; }
  .section.listing-filters .filters-filter.type-icons .filter-item:nth-child(2n+2) {
    padding-left: 1.5rem; }
    .section.listing-filters .filters-filter.type-icons .filter-item:nth-child(2n+2):last-child {
      padding-bottom: 0; }
  .section.listing-filters .filters-filter.type-icons .filter-item:nth-child(2n+1) {
    border-right: 0.1rem solid #e3e4e2;
    padding-right: 1.5rem; }
    .section.listing-filters .filters-filter.type-icons .filter-item:nth-child(2n+1):last-child, .section.listing-filters .filters-filter.type-icons .filter-item:nth-child(2n+1):nth-last-child(2) {
      padding-bottom: 0; }
  .section.listing-filters .filters-filter.type-icons .filter-item input {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none; }
    .section.listing-filters .filters-filter.type-icons .filter-item input:checked + .item-content {
      color: #145aff; }
      .section.listing-filters .filters-filter.type-icons .filter-item input:checked + .item-content .content-icon * {
        fill: #145aff; }

.section.listing-filters .filters-filter.type-icons .item-content {
  display: block;
  border-bottom: 0.1rem solid #e3e4e2;
  padding-bottom: .8rem;
  cursor: pointer; }
  @media (min-width: 960px) {
    .section.listing-filters .filters-filter.type-icons .item-content:hover {
      color: #145aff; }
      .section.listing-filters .filters-filter.type-icons .item-content:hover .content-icon * {
        fill: #145aff; } }

.section.listing-filters .filters-filter.type-icons .content-icon {
  display: inline-block;
  height: 1.8rem;
  margin-bottom: .8rem; }
  .section.listing-filters .filters-filter.type-icons .content-icon * {
    fill: #8688a2; }
  .section.listing-filters .filters-filter.type-icons .content-icon svg {
    height: 100%;
    width: auto; }

.section.listing-filters .filters-filter.type-range .inputs-inputwrap {
  width: 7.8rem;
  font-size: 1.5rem;
  display: inline-block;
  position: relative; }
  @media (max-width: 960px) {
    .section.listing-filters .filters-filter.type-range .inputs-inputwrap {
      width: 10rem; } }
  .section.listing-filters .filters-filter.type-range .inputs-inputwrap + .inputs-inputwrap {
    margin-left: 2rem; }
    .section.listing-filters .filters-filter.type-range .inputs-inputwrap + .inputs-inputwrap:before {
      color: #8688a2;
      position: absolute;
      left: -1rem;
      top: 50%;
      content: '-';
      transform: translate3d(-50%, -50%, 0); }

.section.listing-filters .filters-filter.type-range .inputs-input {
  display: inline-block;
  border-radius: 0.3rem;
  border: 0.1rem solid #f7f7fa;
  width: 100%;
  line-height: 3.2rem;
  text-align: center; }

.section.listing-filters .filters-filter.type-range .inputs-submit {
  line-height: 3rem;
  padding: .2rem 1rem;
  margin-left: 1.2rem; }
  @media (max-width: 960px) {
    .section.listing-filters .filters-filter.type-range .inputs-submit {
      width: 100%;
      margin-left: 0;
      margin-top: 1rem;
      margin-bottom: 3rem; } }

.section.listing-filters .filters-filter.type-text {
  font-size: 1.5rem; }
  .section.listing-filters .filters-filter.type-text .filter-inputs.touched .inputs-input {
    border-color: #145aff; }
  .section.listing-filters .filters-filter.type-text .inputs-submit {
    position: absolute;
    right: 1.9rem;
    top: 50%;
    color: rgba(22, 29, 76, 0.3);
    font-size: 1.4rem;
    transform: translate3d(0, -50%, 0); }

.section.listing-filters .filter-title {
  position: relative;
  display: block;
  font-size: 1.4rem;
  line-height: 3rem;
  padding: 1rem 0;
  width: 100%;
  text-transform: uppercase;
  text-align: left;
  border-bottom: 0.1rem solid #eaebf3;
  transition: border-color 200ms ease; }
  .section.listing-filters .filter-title:after {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    font-size: .9rem;
    color: #161d4c;
    opacity: .3; }

.section.listing-filters .filter-content .filter-slidewrap {
  width: calc(100% + 1.2rem);
  margin-right: -1.2rem;
  padding-right: 1.2rem;
  min-height: 50px; }

@media (max-width: 960px) {
  .section .content-results .banner-hr {
    height: 100%; }
  .section .content-results .banner-youtube {
    height: 100%; } }

@media screen and (max-width: 960px) and (orientation: landscape) {
  .section .content-results .banner-instagram {
    height: 100%; }
    .section .content-results .banner-instagram .imagewrap-image {
      width: 100% !important;
      height: 100% !important; } }

.pagination-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem; }
  .pagination-wrapper .pagination-inner-wrapper {
    display: flex; }
    .pagination-wrapper .pagination-inner-wrapper button {
      color: black;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color .3s;
      border: 1px solid #ddd;
      margin: 0 4px; }
    .pagination-wrapper .pagination-inner-wrapper button.active {
      background-color: #145aff;
      color: white;
      border: 1px solid #145aff; }
    .pagination-wrapper .pagination-inner-wrapper .disabled {
      background: #ddd;
      pointer-events: none; }
    .pagination-wrapper .pagination-inner-wrapper .pagination {
      display: flex; }
      .pagination-wrapper .pagination-inner-wrapper .pagination button {
        color: black;
        padding: 8px 16px;
        text-decoration: none;
        transition: background-color .3s;
        border: 1px solid #ddd;
        margin: 0 4px; }
      .pagination-wrapper .pagination-inner-wrapper .pagination button.active {
        background-color: #145aff;
        color: white;
        border: 1px solid #145aff; }
      .pagination-wrapper .pagination-inner-wrapper .pagination span {
        padding: 8px 16px;
        text-align: end; }

.inputwrap.type-checkbox .checkwrap input + label:hover {
  color: #145aff;
  text-decoration: underline; }

.dealer-logo-wrapper {
  background-color: #f7f7fa;
  padding: 1rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  position: relative; }
  @media (max-width: 960px) {
    .dealer-logo-wrapper {
      height: 5rem; } }
  .dealer-logo-wrapper img {
    height: 100%; }

.section.subscriptionbar {
  background-color: #f7f7fa;
  text-align: center;
  padding: 4.2rem 0; }
  @media (max-width: 960px) {
    .section.subscriptionbar {
      padding: 4.2rem 2rem; } }
  .section.subscriptionbar .subscriptionbar-form {
    display: inline-block;
    text-align: left; }
    @media (max-width: 960px) {
      .section.subscriptionbar .subscriptionbar-form {
        text-align: center; } }
  .section.subscriptionbar .form-title {
    font-weight: 500;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle; }
    @media (max-width: 960px) {
      .section.subscriptionbar .form-title {
        display: block;
        margin-bottom: 2rem; } }
  .section.subscriptionbar .form-input {
    display: inline-block;
    vertical-align: middle;
    margin-left: 2.3rem;
    width: 24rem; }
    @media (max-width: 960px) {
      .section.subscriptionbar .form-input {
        width: calc(100% - 6rem);
        display: block;
        margin: 0 auto 2rem; } }
    .section.subscriptionbar .form-input.error input {
      border-bottom-color: #ca320d;
      color: #ca320d; }
      .section.subscriptionbar .form-input.error input::placeholder {
        opacity: 1;
        color: rgba(202, 50, 13, 0.5); }
      .section.subscriptionbar .form-input.error input:-ms-input-placeholder {
        color: rgba(202, 50, 13, 0.5); }
      .section.subscriptionbar .form-input.error input::-ms-input-placeholder {
        color: rgba(202, 50, 13, 0.5); }
    .section.subscriptionbar .form-input input {
      background-color: transparent;
      border-radius: 0;
      border: none;
      border-bottom: 0.1rem solid rgba(22, 29, 76, 0.5);
      font-size: 1.4rem;
      line-height: 2.2rem;
      padding: .7rem 0; }
    .section.subscriptionbar .form-input .input-error {
      display: none; }
    .section.subscriptionbar .form-input .input-label {
      background: transparent;
      padding: 0 0 .6rem;
      left: 0;
      color: #8688a2; }
  .section.subscriptionbar .form-submit {
    margin-left: 4rem;
    line-height: 1.8rem;
    width: 10rem; }
    @media (max-width: 960px) {
      .section.subscriptionbar .form-submit {
        margin-left: 0; } }

.section.loginform .loginform-title {
  font-weight: 400;
  line-height: 2.6rem;
  color: #145aff;
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: 3rem; }

.section.loginform .form-field + .form-field {
  margin-top: 1.7rem; }

.section.loginform .form-field.type-checkbox {
  color: #8688a2; }
  .section.loginform .form-field.type-checkbox .checkwrap {
    font-weight: 400; }

.section.loginform .form-field .field-link {
  font-weight: 600; }

.section.loginform .form-fakeerror {
  margin-bottom: 2rem; }

.section.loginform .loginform-nav {
  font-weight: 500;
  color: #8688a2;
  margin-top: .9rem;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 960px) {
    .section.loginform .loginform-nav {
      margin-top: 2.8rem; } }
  .section.loginform .loginform-nav.center {
    justify-content: center;
    margin-top: 3rem; }
    .section.loginform .loginform-nav.center .nav-btn {
      margin: 0 1rem; }
  .section.loginform .loginform-nav .nav-btn {
    display: inline-block;
    color: #145aff; }
    .section.loginform .loginform-nav .nav-btn + .nav-btn {
      margin-left: 1rem; }
    .section.loginform .loginform-nav .nav-btn i {
      margin-right: .4rem; }

.section.loginform .loginform-message {
  text-align: center;
  padding: 1.5rem 2rem;
  background-color: #f7f7fa;
  border-color: #eaebf3;
  border-radius: 0.3rem;
  margin-bottom: 2rem; }
  .section.loginform .loginform-message.success {
    background-color: #16d2d3;
    border-color: #11a4a5;
    color: #fff; }
  .section.loginform .loginform-message.error {
    background-color: #ca320d;
    border-color: #9a260a;
    color: #fff; }

.section.loginform .loginform-others {
  margin-top: 1.7rem; }

.section.loginform .others-seperator {
  font-weight: 400;
  color: #8688a2;
  text-align: center;
  margin-bottom: 2.2rem;
  position: relative;
  font-size: 1.4rem; }
  .section.loginform .others-seperator:before {
    content: ' ';
    overflow: hidden;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
    height: .1rem;
    background-color: #eaebf3; }
  .section.loginform .others-seperator span {
    display: inline-block;
    position: relative;
    z-index: 2;
    background-color: #fff;
    padding: 0 1.5rem; }

.section.loginform .others-opt + .others-opt {
  margin-top: 2rem; }

.section.loginform.type-self .loginform-title {
  font-weight: 600;
  font-size: 3.2rem;
  text-align: left; }
  @media (max-width: 960px) {
    .section.loginform.type-self .loginform-title {
      font-size: 1.8rem;
      margin-bottom: 2.2rem; } }

.page.notfound {
  text-align: center;
  padding: 6rem 0; }
  .page.notfound h1 {
    font-weight: 700;
    font-size: 11rem;
    margin-bottom: .2em; }

.section.home-intro {
  position: relative;
  z-index: 2;
  min-height: 40rem; }
  @media (max-width: 960px) {
    .section.home-intro {
      margin-top: -5rem;
      z-index: 1; } }
  .section.home-intro .intro-content {
    position: absolute;
    z-index: 2;
    top: 33%;
    left: 50%;
    text-align: center;
    transform: translate3d(-50%, -50%, 0); }
    @media (max-width: 960px) {
      .section.home-intro .intro-content {
        width: 100%;
        top: 35%; } }
  .section.home-intro .intro-title.forceWhite {
    color: white; }
    .section.home-intro .intro-title.forceWhite .colored {
      color: white; }
    .section.home-intro .intro-title.forceWhite .subtitle {
      color: white; }
  .section.home-intro .intro-title {
    font-weight: 400;
    line-height: 3rem;
    font-size: 4.2rem;
    opacity: .2; }
    @media (max-width: 960px) {
      .section.home-intro .intro-title {
        font-size: 2.5rem; } }
    .section.home-intro .intro-title .colored {
      color: #145aff; }
    .section.home-intro .intro-title .subtitle {
      font-weight: 500;
      display: block;
      font-size: 2.2rem;
      font-style: italic;
      line-height: 3rem;
      margin-top: 1.9rem; }
      @media (max-width: 960px) {
        .section.home-intro .intro-title .subtitle {
          font-size: 1.6rem;
          line-height: 1em;
          margin-top: .5rem; } }
  .section.home-intro .intro-bg {
    height: 49rem;
    background-color: #eaebf3; }
    .section.home-intro .intro-bg .imagewrap-image {
      background-position: left center; }
    .section.home-intro .intro-bg .loaderwrap-icon {
      top: auto;
      left: auto;
      right: 0;
      bottom: 0;
      transform: none;
      font-size: 21rem; }
    @media (max-width: 960px) {
      .section.home-intro .intro-bg {
        height: 46rem;
        max-height: 80vh; } }
  .section.home-intro .altin-orumcek {
    transform: scale(0.5);
    position: absolute;
    bottom: -2rem;
    right: -12rem; }
    @media (max-width: 960px) {
      .section.home-intro .altin-orumcek {
        display: none; } }
  .section.home-intro .intro-search {
    margin: 3.5rem auto 0; }
    @media (max-width: 960px) {
      .section.home-intro .intro-search {
        margin-top: 1rem;
        width: calc(100% - 9rem); } }

.section.home-listing {
  position: relative; }
  @media (max-width: 960px) {
    .section.home-listing {
      z-index: 3; } }

.section.dealers-listing {
  padding-bottom: 6rem; }
  .section.dealers-listing .listing-content.type-dealer {
    padding: 0; }
    @media (max-width: 960px) {
      .section.dealers-listing .listing-content.type-dealer {
        margin: 0 -1.5rem 2rem;
        width: calc(100% + 3rem); } }
    @media (min-width: 960px) {
      .section.dealers-listing .listing-content.type-dealer .content-top {
        display: none; } }
    .section.dealers-listing .listing-content.type-dealer .content-results {
      padding: 0; }
      @media (max-width: 960px) {
        .section.dealers-listing .listing-content.type-dealer .content-results {
          padding: .75rem; } }
    @media (min-width: 960px) {
      .section.dealers-listing .listing-content.type-dealer .results-item {
        padding: 0; } }
    .section.dealers-listing .listing-content.type-dealer .results-item .contentbox:before {
      display: none; }
    .section.dealers-listing .listing-content.type-dealer .results-item .contentbox:hover .contentbox-innerwrap {
      border-color: #f7f7fa; }
    @media (min-width: 960px) {
      .section.dealers-listing .listing-content.type-dealer .results-item .contentbox .contentbox-innerwrap {
        border-left: none;
        border-top: none; } }

.section.dealer-detail {
  display: flex;
  /*.info-search {
				position: relative;
				padding: 1.9rem 1.1rem 1.9rem 2.2rem;
			}

				.search-submit {
					width: 3.8rem;
					position: absolute;
					right: 1.9rem;
					top: 50%;
					font-size: 1.4rem;
					color: rgba($color-text, .3);
					transform: translate3d(0, -50%, 0);
				}*/
  /*.head-info {
						font-size: 1.4rem;
						color: $color-primary;
						text-transform: uppercase;
						text-decoration: underline;

						+ .head-info {
							margin-left: 2.8rem;
						}
					}*/
  /* .info-filters {
				margin-top: 4rem;
			} */ }
  @media (max-width: 960px) {
    .section.dealer-detail {
      display: block; } }
  .section.dealer-detail .detail-info {
    width: 28rem; }
    @media (max-width: 960px) {
      .section.dealer-detail .detail-info {
        width: auto; } }
  .section.dealer-detail .info-sum {
    position: relative;
    background-color: #f7f7fa;
    padding: 3rem 2rem 4rem;
    text-align: center;
    min-height: 37.2rem;
    /*.sum-address {
					font-size: 1.4rem;
					line-height: 2rem;
					margin-top: 2.2rem;
				}

					.address-showonmap {
						color: $color-primary;
						text-decoration: underline;
					}*/ }
    .section.dealer-detail .info-sum .sum-favbtn {
      position: absolute;
      top: 2rem;
      right: 2rem; }
    .section.dealer-detail .info-sum .sum-avatar {
      width: 12rem;
      height: 12rem;
      border-radius: 50%;
      overflow: hidden;
      display: block;
      margin: 0 auto 1.1rem; }
    .section.dealer-detail .info-sum .sum-title {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.2rem; }
    .section.dealer-detail .info-sum .title-badge {
      position: relative;
      width: 2.2rem;
      height: 2.2rem;
      font-size: 2.2rem;
      color: #145aff;
      display: inline-block;
      vertical-align: middle;
      margin-right: 1rem;
      margin-top: -.2em; }
    .section.dealer-detail .info-sum .badge-icon {
      color: #fff;
      font-size: 1.2rem;
      position: absolute;
      z-index: 2;
      top: 53%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
    .section.dealer-detail .info-sum .sum-workinghours {
      display: block;
      font-size: 1.2rem;
      line-height: 1.4rem;
      text-transform: uppercase;
      margin-top: .5rem;
      color: #ca320d; }
      .section.dealer-detail .info-sum .sum-workinghours span {
        margin: 0 1rem; }
      .section.dealer-detail .info-sum .sum-workinghours.open {
        color: #16d2d3; }
    .section.dealer-detail .info-sum .sum-controls {
      margin-top: 2.8rem;
      font-size: 0;
      letter-spacing: 0; }
      .section.dealer-detail .info-sum .sum-controls .btn .btn-icon {
        font-size: 1.3rem; }
        .section.dealer-detail .info-sum .sum-controls .btn .btn-icon.pre {
          margin-right: .5rem; }
    .section.dealer-detail .info-sum .sum-numbers {
      margin-top: 4.5rem;
      font-size: 1.4rem;
      text-transform: uppercase; }
    .section.dealer-detail .info-sum .numbers-elem + .numbers-elem {
      margin-left: 2.8rem; }
  .section.dealer-detail .info-branches {
    padding: 2.8rem 2.4rem 4rem 2.4rem; }
    @media (max-width: 960px) {
      .section.dealer-detail .info-branches {
        padding: 1.4rem 0; } }
  .section.dealer-detail .branches-head {
    padding: 0 0 1.1rem 0; }
  .section.dealer-detail .head-title {
    font-weight: 600;
    color: rgba(22, 29, 76, 0.5);
    font-size: 1.2rem;
    line-height: 1.4rem;
    display: block;
    text-transform: uppercase; }
  .section.dealer-detail .branches-list .list-branch {
    border-bottom: 0.1rem solid #eaebf3; }
    .section.dealer-detail .branches-list .list-branch.open .branch-details .collapse-innerwrap {
      background-color: #f7f7fa; }
  .section.dealer-detail .branches-list .branch-sum {
    position: relative;
    text-align: left;
    display: block;
    width: 100%;
    padding: 1.5rem 0 1.5rem; }
    .section.dealer-detail .branches-list .branch-sum:before {
      position: absolute;
      right: 1.6rem;
      color: #e3e4e2;
      font-size: .9rem;
      top: 2.3rem;
      line-height: 1.4rem; }
  .section.dealer-detail .branches-list .branch-title {
    font-weight: 600;
    color: rgba(22, 29, 76, 0.8);
    font-size: 1.6rem;
    line-height: 2rem; }
    .section.dealer-detail .branches-list .branch-title:before {
      width: .5rem;
      height: .5rem;
      border-radius: 50%;
      background-color: #16d2d3;
      display: inline-block;
      vertical-align: middle;
      margin-right: .6rem; }
  .section.dealer-detail .branches-list .title-listingcount {
    font-weight: 500;
    font-size: 1.2rem;
    color: #8688a2;
    margin-left: .2rem; }
  .section.dealer-detail .branches-list .branch-workinghours {
    font-weight: 500;
    display: block;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-transform: uppercase;
    color: #ca320d; }
    .section.dealer-detail .branches-list .branch-workinghours span {
      margin: 0 1rem; }
    .section.dealer-detail .branches-list .branch-workinghours.open {
      color: #16d2d3; }
  .section.dealer-detail .branches-list .branch-details .collapse-innerwrap {
    padding: 1rem 1.1rem 1.7rem 1.1rem;
    transition: background-color 200ms ease; }
  .section.dealer-detail .branches-list .details-showonmap {
    font-weight: 500;
    font-size: 1.4rem;
    display: inline-block;
    margin: 1.5rem 0 0;
    line-height: 2rem;
    color: #145aff;
    text-decoration: underline; }
  .section.dealer-detail .branches-list .details-controls {
    font-size: 0;
    letter-spacing: 0;
    margin-top: 1.7rem; }
    .section.dealer-detail .branches-list .details-controls .btn {
      font-size: 1.1rem; }
      .section.dealer-detail .branches-list .details-controls .btn:nth-child(2n+2) {
        margin-left: .7rem; }
      .section.dealer-detail .branches-list .details-controls .btn .btn-icon {
        font-size: 1.3rem; }
        .section.dealer-detail .branches-list .details-controls .btn .btn-icon.pre {
          margin-right: .5rem; }
  .section.dealer-detail .branches-extend {
    font-weight: 500;
    font-size: 1.4rem;
    color: #145aff;
    margin-top: 1.5rem; }
  .section.dealer-detail .detail-right {
    width: calc(100% - 28rem); }
    .section.dealer-detail .detail-right .breadcrumbs {
      padding: 1.8rem 0; }
    @media (max-width: 960px) {
      .section.dealer-detail .detail-right {
        width: auto; } }
  .section.dealer-detail .dealer-cover {
    height: 37.2rem;
    width: 100%;
    background-color: #eaebf3; }
    .section.dealer-detail .dealer-cover .loaderwrap-icon {
      top: auto;
      left: auto;
      right: -1rem;
      bottom: -1rem;
      font-size: 30rem;
      transform: none; }
  .section.dealer-detail .dealer-listing {
    border-left: 0.1rem solid #f7f7fa; }
    @media (max-width: 960px) {
      .section.dealer-detail .dealer-listing {
        border-left: none; } }

.popup-cover {
  max-width: 480px; }

.popup-image .modal-content {
  width: 480px; }

.section.branch-detail {
  display: flex; }
  @media (max-width: 960px) {
    .section.branch-detail {
      display: block; } }
  .section.branch-detail .detail-info {
    width: 28rem; }
    @media (max-width: 960px) {
      .section.branch-detail .detail-info {
        width: auto; } }
  .section.branch-detail .info-sum {
    background-color: #f7f7fa;
    padding: 2.8rem 2.3rem 1.5rem 2.6rem;
    margin-bottom: 4.9rem; }
    .section.branch-detail .info-sum .sum-title {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.2rem; }
    .section.branch-detail .info-sum .title-badge {
      position: relative;
      width: 2.2rem;
      height: 2.2rem;
      font-size: 2.2rem;
      color: #145aff;
      display: inline-block;
      vertical-align: middle;
      margin-right: 1rem;
      margin-top: -.2em; }
    .section.branch-detail .info-sum .badge-icon {
      color: #fff;
      font-size: 1.2rem;
      position: absolute;
      z-index: 2;
      top: 53%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
    .section.branch-detail .info-sum .sum-address {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-top: 2.5rem;
      max-width: 19rem; }
    .section.branch-detail .info-sum .address-showonmap {
      color: #145aff;
      text-decoration: underline; }
    .section.branch-detail .info-sum .sum-workinghours {
      display: block;
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-top: 1.4rem;
      color: #ca320d; }
      .section.branch-detail .info-sum .sum-workinghours span {
        margin: 0 1rem; }
      .section.branch-detail .info-sum .sum-workinghours.open {
        color: #16d2d3; }
    .section.branch-detail .info-sum .sum-controls {
      margin-top: 2rem;
      font-size: 0;
      letter-spacing: 0; }
      .section.branch-detail .info-sum .sum-controls .btn {
        width: calc(50% - .6rem);
        font-size: 1.1rem;
        padding: .9rem 0; }
        .section.branch-detail .info-sum .sum-controls .btn:nth-child(2n+2) {
          margin-left: 1.2rem; }
        .section.branch-detail .info-sum .sum-controls .btn .btn-icon {
          font-size: 1.3rem; }
          .section.branch-detail .info-sum .sum-controls .btn .btn-icon.pre {
            margin-right: .5rem; }
  .section.branch-detail .detail-right {
    width: calc(100% - 28rem); }
    .section.branch-detail .detail-right .breadcrumbs {
      padding: 1.8rem 0; }
    @media (max-width: 960px) {
      .section.branch-detail .detail-right {
        width: auto; } }
  .section.branch-detail .branch-cover {
    height: 37.2rem;
    width: 100%;
    background-color: #eaebf3; }
    .section.branch-detail .branch-cover .loaderwrap-icon {
      top: auto;
      left: auto;
      right: -1rem;
      bottom: -1rem;
      font-size: 30rem;
      transform: none; }
  .section.branch-detail .branch-listing {
    border-left: 0.1rem solid #f7f7fa; }
    @media (max-width: 960px) {
      .section.branch-detail .branch-listing {
        border-left: none; } }

.section.brands-listing {
  padding-bottom: 6rem; }
  .section.brands-listing .listing-content.type-brand {
    padding: 0;
    /*.content-top {
				@include mediaMin {	
					display: none;
				}
			}*/ }
    @media (max-width: 960px) {
      .section.brands-listing .listing-content.type-brand {
        margin: 0 -1.5rem 2rem;
        width: calc(100% + 3rem); } }

.section.brand-detail {
  display: flex; }
  @media (max-width: 960px) {
    .section.brand-detail {
      display: block; } }
  .section.brand-detail .detail-info {
    width: 28rem; }
    @media (max-width: 960px) {
      .section.brand-detail .detail-info {
        width: auto; } }
  .section.brand-detail .info-sum {
    position: relative;
    background-color: #f7f7fa;
    padding: 3rem 1rem 3rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-align: center;
    margin-bottom: 3rem; }
    .section.brand-detail .info-sum .sum-favbtn {
      position: absolute;
      top: 2rem;
      right: 2rem; }
    .section.brand-detail .info-sum .sum-logo {
      width: 12rem;
      height: 12rem;
      display: inline-block;
      background-color: #fff;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 1.1rem; }
    .section.brand-detail .info-sum .sum-title {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.2rem; }
    .section.brand-detail .info-sum .sum-numbers {
      text-decoration: underline;
      margin-top: 1.3rem; }
    .section.brand-detail .info-sum .numbers-item + .numbers-item {
      margin-left: 2.8rem; }
    .section.brand-detail .info-sum .sum-pricelist {
      margin-top: 1.7rem; }
  .section.brand-detail .info-search {
    position: relative;
    padding: 0 1.1rem 0 2rem;
    margin: 3rem 0 1.9rem; }
  .section.brand-detail .search-submit {
    width: 3.8rem;
    position: absolute;
    right: 1.9rem;
    top: 50%;
    font-size: 1.4rem;
    color: rgba(22, 29, 76, 0.3);
    transform: translate3d(0, -50%, 0); }
  .section.brand-detail .info-dealers {
    padding: 1.4rem 0 1.4rem 1rem; }
    @media (max-width: 960px) {
      .section.brand-detail .info-dealers {
        padding: 1.4rem 0; } }
  .section.brand-detail .dealers-title {
    font-weight: 600;
    font-size: 1.2rem;
    color: rgba(22, 29, 76, 0.5);
    text-transform: uppercase;
    line-height: 1.4rem;
    padding: .8rem 0 .8rem 2.4rem; }
  .section.brand-detail .dealers-list .list-branch {
    margin: 0 1rem;
    padding: 1rem 1.5rem;
    border-top: 0.1rem solid #e3e4e2; }
    .section.brand-detail .dealers-list .list-branch:last-child {
      border-bottom: 0.1rem solid #e3e4e2; }
    .section.brand-detail .dealers-list .list-branch.open {
      background-color: #f7f7fa;
      transition: background-color 200ms ease; }
  .section.brand-detail .dealers-list .branch-sum {
    position: relative;
    text-align: left;
    display: block;
    width: 100%;
    padding: 1.3rem 2rem 1.1rem 2.4rem; }
    .section.brand-detail .dealers-list .branch-sum:before {
      position: absolute;
      right: 1.6rem;
      color: #e3e4e2;
      font-size: .9rem;
      top: 2.3rem;
      line-height: 1.4rem; }
  .section.brand-detail .dealers-list .branch-title {
    color: rgba(22, 29, 76, 0.7);
    text-transform: uppercase;
    font-size: 1.6rem;
    line-height: 2rem; }
  .section.brand-detail .dealers-list .branch-workinghours {
    display: block;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-transform: uppercase;
    margin-top: .3rem;
    color: #ca320d; }
    .section.brand-detail .dealers-list .branch-workinghours span {
      margin: 0 1rem; }
    .section.brand-detail .dealers-list .branch-workinghours.open {
      color: #16d2d3; }
  .section.brand-detail .dealers-list .branch-details .collapse-innerwrap {
    padding: 1.2rem 1.3rem 2rem 2.4rem; }
  .section.brand-detail .dealers-list .details-showonmap {
    font-size: 1.4rem;
    display: inline-block;
    margin-bottom: 1.3rem;
    line-height: 2rem;
    color: #145aff;
    text-decoration: underline; }
  .section.brand-detail .dealers-list .details-controls {
    font-size: 0;
    letter-spacing: 0; }
    .section.brand-detail .dealers-list .details-controls .btn {
      width: calc(50% - .6rem);
      font-size: 1.1rem;
      padding: .9rem 0; }
      .section.brand-detail .dealers-list .details-controls .btn:nth-child(2n+2) {
        margin-left: 1.2rem; }
      .section.brand-detail .dealers-list .details-controls .btn .btn-icon {
        font-size: 1.3rem; }
        .section.brand-detail .dealers-list .details-controls .btn .btn-icon.pre {
          margin-right: .5rem; }
  .section.brand-detail .detail-right {
    width: calc(100% - 28rem); }
    .section.brand-detail .detail-right .breadcrumbs {
      padding: 1.8rem 0; }
    @media (max-width: 960px) {
      .section.brand-detail .detail-right {
        width: auto; } }
  .section.brand-detail .brand-cover {
    height: 37.2rem;
    width: 100%;
    background-color: #eaebf3; }
    .section.brand-detail .brand-cover .loaderwrap-icon {
      top: auto;
      left: auto;
      right: -1rem;
      bottom: -1rem;
      font-size: 30rem;
      transform: none; }
  .section.brand-detail .brand-listing {
    border-left: 0.1rem solid #f7f7fa; }
    @media (max-width: 960px) {
      .section.brand-detail .brand-listing {
        border-left: none; } }

.page.sitemap {
  padding: 5rem 0; }
  .page.sitemap h1 {
    display: block;
    margin: 0 0 5rem; }
  .page.sitemap section + section {
    margin-top: 5rem; }
  .page.sitemap section > * {
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 2rem; }
    .page.sitemap section > *.block {
      display: block; }
  .page.sitemap section .btn {
    min-width: 20rem; }
  .page.sitemap h2 {
    display: block;
    margin-bottom: 2rem; }
  .page.sitemap h3 {
    display: block;
    margin-top: 2rem;
    margin-bottom: 2rem; }

.page.detail .contentpage-form .inputwrap .input-error {
  overflow: unset; }

.page.detail.gallery-fullscreen .section.detail-content {
  z-index: 50; }
  .page.detail.gallery-fullscreen .section.detail-content .content-left {
    z-index: 50;
    width: 100vw; }
  .page.detail.gallery-fullscreen .section.detail-content .content-gallery {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 21; }
    @media (max-width: 960px) {
      .page.detail.gallery-fullscreen .section.detail-content .content-gallery {
        width: 100%;
        margin: 0; } }
    .page.detail.gallery-fullscreen .section.detail-content .content-gallery .gallery-mainslider {
      padding-bottom: 0;
      height: calc(100% - 15.3rem); }
      @media (max-width: 960px) {
        .page.detail.gallery-fullscreen .section.detail-content .content-gallery .gallery-mainslider {
          height: 100%; } }
      .page.detail.gallery-fullscreen .section.detail-content .content-gallery .gallery-mainslider .mainslider-slider {
        left: 0;
        right: 0; }
      .page.detail.gallery-fullscreen .section.detail-content .content-gallery .gallery-mainslider .imagewrap-image {
        background-size: contain; }
    .page.detail.gallery-fullscreen .section.detail-content .content-gallery .gallery-thumbs {
      height: 15.3rem; }

.page.detail .section.detail-top {
  padding: 1.8rem 0;
  color: #8688a2;
  border-bottom: 0.1rem solid #f7f7fa; }
  @media (max-width: 960px) {
    .page.detail .section.detail-top {
      background-color: #f7f7fa;
      padding: 1.1rem 1.5rem; } }
  .page.detail .section.detail-top .top-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .page.detail .section.detail-top .top-controls {
    font-size: 1.4rem; }
    .page.detail .section.detail-top .top-controls > * {
      vertical-align: middle; }
      .page.detail .section.detail-top .top-controls > * + * {
        display: inline-block;
        margin-left: 3.8rem; }
    .page.detail .section.detail-top .top-controls .controls-viewers {
      background-color: #16d2d3;
      color: #fff;
      border-radius: 0.3rem;
      font-size: 1.2rem;
      line-height: 1.4rem;
      padding: .4rem .8rem .5rem; }
    .page.detail .section.detail-top .top-controls .fav-button {
      height: 2.4em; }
      .page.detail .section.detail-top .top-controls .fav-button button {
        height: 100%; }
    .page.detail .section.detail-top .top-controls .share-button {
      height: 2.5em; }
    .page.detail .section.detail-top .top-controls .controls-btn, .page.detail .section.detail-top .top-controls .fav-button .fav-button-btn {
      text-transform: uppercase;
      font-size: 1.2rem;
      color: inherit;
      overflow: visible; }
      .page.detail .section.detail-top .top-controls .controls-btn.faved .fav-button-icon, .page.detail .section.detail-top .top-controls .fav-button .fav-button-btn.faved .fav-button-icon {
        color: #145aff; }
      .page.detail .section.detail-top .top-controls .controls-btn i, .page.detail .section.detail-top .top-controls .controls-btn .fav-button-icon, .page.detail .section.detail-top .top-controls .fav-button .fav-button-btn i, .page.detail .section.detail-top .top-controls .fav-button .fav-button-btn .fav-button-icon {
        font-size: 2.4rem;
        width: 2.4rem;
        height: 2.4rem;
        margin-right: .8rem;
        display: inline-block;
        vertical-align: middle;
        margin-top: -.16em;
        position: static;
        transform: none; }

.page.detail .section.detail-topinfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2rem;
  padding: 1.4rem 0; }
  @media (max-width: 960px) {
    .page.detail .section.detail-topinfo {
      padding: 0;
      line-height: 1.8rem; } }
  .page.detail .section.detail-topinfo .topinfo-highlights li {
    display: inline-block;
    display: inline-block;
    vertical-align: middle; }
    .page.detail .section.detail-topinfo .topinfo-highlights li > * {
      display: block; }
    .page.detail .section.detail-topinfo .topinfo-highlights li + li {
      margin-left: .7rem; }
  .page.detail .section.detail-topinfo .topinfo-highlights span {
    display: block;
    border: 0.1rem solid #eaebf3;
    font-size: 2rem;
    text-transform: uppercase;
    border-radius: 0.3rem;
    color: #8688a2;
    line-height: 2rem;
    padding: .2rem .6rem;
    background-color: #fff; }
    @media (max-width: 960px) {
      .page.detail .section.detail-topinfo .topinfo-highlights span {
        color: #161d4c; } }
  .page.detail .section.detail-topinfo .topinfo-highlights img {
    height: 1.8rem; }
  .page.detail .section.detail-topinfo .topinfo-id {
    text-transform: uppercase;
    color: #161d4c;
    font-size: 1.4rem; }
    @media (max-width: 960px) {
      .page.detail .section.detail-topinfo .topinfo-id {
        font-size: 1.2rem; } }
    .page.detail .section.detail-topinfo .topinfo-id strong {
      font-weight: 500;
      font-size: 1.2rem;
      color: #8688a2; }
  .page.detail .section.detail-topinfo .topinfo-controls {
    white-space: nowrap; }
    @media (max-width: 960px) {
      .page.detail .section.detail-topinfo .topinfo-controls .fav-button {
        height: 1.5em; }
        .page.detail .section.detail-topinfo .topinfo-controls .fav-button button {
          height: 100%; }
      .page.detail .section.detail-topinfo .topinfo-controls .share-button {
        height: 1.1em; }
      .page.detail .section.detail-topinfo .topinfo-controls button span {
        margin-left: .4em; } }
  .page.detail .section.detail-topinfo .controls-btn {
    display: inline-block;
    font-size: 2rem;
    vertical-align: middle;
    color: #8688a2; }
    .page.detail .section.detail-topinfo .controls-btn.faved {
      color: #145aff; }
    .page.detail .section.detail-topinfo .controls-btn + .controls-btn {
      margin-left: 1.3rem; }
    .page.detail .section.detail-topinfo .controls-btn .fav-button-icon {
      vertical-align: top; }

.page.detail .section.detail-content {
  position: relative;
  z-index: 2; }
  .page.detail .section.detail-content .content-wrap {
    display: flex;
    flex-flow: row wrap; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .content-wrap {
        display: block; } }
  .page.detail .section.detail-content .content-left {
    width: calc(100% - 46rem);
    position: relative;
    z-index: 1; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .content-left {
        width: auto; } }
  .page.detail .section.detail-content .content-right {
    width: 46rem;
    padding: 0 3rem;
    position: relative;
    z-index: 2; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .content-right {
        width: auto; } }
  .page.detail .section.detail-content .content-gallery {
    background-color: #fff; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .content-gallery {
        width: calc(100% + 3rem);
        margin: 0 -1.5rem; } }
  .page.detail .section.detail-content .gallery-close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10; }
  .page.detail .section.detail-content .gallery-mainslider {
    background: #f7f7fa;
    width: 100%;
    position: relative;
    padding-bottom: 52.6%; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .gallery-mainslider {
        padding-bottom: 75%; } }
    .page.detail .section.detail-content .gallery-mainslider .mainslider-nav {
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      width: 4.8rem;
      height: 4.8rem;
      line-height: 4.8rem;
      font-size: 3rem;
      z-index: 2; }
      .page.detail .section.detail-content .gallery-mainslider .mainslider-nav.prev {
        left: 0; }
        @media (max-width: 960px) {
          .page.detail .section.detail-content .gallery-mainslider .mainslider-nav.prev {
            padding-right: 2rem; } }
      .page.detail .section.detail-content .gallery-mainslider .mainslider-nav.next {
        right: 0; }
        @media (max-width: 960px) {
          .page.detail .section.detail-content .gallery-mainslider .mainslider-nav.next {
            padding-left: 2rem; } }
      @media (max-width: 960px) {
        .page.detail .section.detail-content .gallery-mainslider .mainslider-nav {
          color: #fff;
          height: 11rem;
          width: 6.8rem; } }
    .page.detail .section.detail-content .gallery-mainslider .mainslider-slider {
      position: absolute;
      top: 0;
      left: 10.5rem;
      right: 10.5rem;
      bottom: 0;
      z-index: 1; }
      @media (max-width: 960px) {
        .page.detail .section.detail-content .gallery-mainslider .mainslider-slider {
          left: 0;
          right: 0; } }
    .page.detail .section.detail-content .gallery-mainslider .slider-imagewrap {
      width: 100%;
      height: 100%; }
    .page.detail .section.detail-content .gallery-mainslider .imagewrap-image {
      position: relative;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 1;
      transition: opacity 200ms ease; }
      @media (max-width: 960px) {
        .page.detail .section.detail-content .gallery-mainslider .imagewrap-image {
          background-size: cover; } }
      .page.detail .section.detail-content .gallery-mainslider .imagewrap-image.swiper-lazy-loaded {
        opacity: 1; }
        .page.detail .section.detail-content .gallery-mainslider .imagewrap-image.swiper-lazy-loaded + .imagewrap-loader {
          opacity: 0; }
    .page.detail .section.detail-content .gallery-mainslider .imagewrap-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      overflow: hidden;
      transform: translate3d(-50%, -50%, 0);
      transition: opacity 200ms ease; }
      .page.detail .section.detail-content .gallery-mainslider .imagewrap-loader .imagewrap-image {
        background-size: cover; }
    .page.detail .section.detail-content .gallery-mainslider .mainslider-fullscreen {
      position: absolute;
      bottom: 1.8rem;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      z-index: 2;
      color: #161d4c;
      padding-top: .4rem;
      padding-bottom: .4rem;
      font-size: 1rem; }
      .page.detail .section.detail-content .gallery-mainslider .mainslider-fullscreen .btn-icon {
        font-size: 1.2rem; }
  .page.detail .section.detail-content .gallery-thumbs {
    position: relative;
    height: 11.4rem;
    padding: 1rem 1.9rem;
    background-color: #fff; }
    .page.detail .section.detail-content .gallery-thumbs:before, .page.detail .section.detail-content .gallery-thumbs:after {
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: #fff;
      width: 2.4rem;
      left: 0;
      z-index: 2; }
    .page.detail .section.detail-content .gallery-thumbs:after {
      left: auto;
      right: 0; }
  .page.detail .section.detail-content .thumbs-nav {
    position: absolute;
    top: 50%;
    color: #8688a2;
    transform: translate3d(0, -50%, 0);
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.4rem;
    font-size: 1.8rem;
    z-index: 3; }
    .page.detail .section.detail-content .thumbs-nav.prev {
      left: 0; }
    .page.detail .section.detail-content .thumbs-nav.next {
      right: 0; }
  .page.detail .section.detail-content .thumbs-carousel {
    height: 100%;
    width: 100%; }
    .page.detail .section.detail-content .thumbs-carousel .swiper-slide {
      padding: 0 .5rem; }
  .page.detail .section.detail-content .carousel-imagebtn {
    position: relative;
    display: block;
    width: 100%;
    height: 100%; }
    .page.detail .section.detail-content .carousel-imagebtn:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 0.1rem solid #145aff;
      border-bottom-width: .5rem;
      opacity: 0;
      transition: opacity 200ms ease; }
    .page.detail .section.detail-content .carousel-imagebtn.active:before {
      opacity: 1; }
  .page.detail .section.detail-content .carousel-image {
    width: 100%;
    height: 100%; }
  .page.detail .section.detail-content .content-details {
    margin-top: 3rem; }
  @media (max-width: 960px) {
    .page.detail .section.detail-content .details-tabs {
      margin: 0 -1.5rem;
      width: calc(100% + 3rem); } }
  .page.detail .section.detail-content .tabs-tab {
    padding: 5.6rem 5.6rem; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .tabs-tab {
        padding: 2.6rem 1.5rem; } }
  .page.detail .section.detail-content .tab-content {
    position: relative; }
    .page.detail .section.detail-content .tab-content.expandable {
      max-height: 39.6rem;
      overflow: hidden; }
      .page.detail .section.detail-content .tab-content.expandable:before {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4rem;
        pointer-events: none;
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); }
      .page.detail .section.detail-content .tab-content.expandable.expanded {
        max-height: none; }
        .page.detail .section.detail-content .tab-content.expandable.expanded:before {
          display: none; }
  .page.detail .section.detail-content .tab-textarea {
    background-color: #f7f7fa;
    padding: 3.6rem 3.2rem;
    font-size: 1.5rem;
    line-height: 1.47em; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .tab-textarea {
        font-size: 1.4rem;
        line-height: 1.57em;
        padding: 1.6rem 2rem 2rem; }
        .page.detail .section.detail-content .tab-textarea h1, .page.detail .section.detail-content .tab-textarea h2, .page.detail .section.detail-content .tab-textarea h3, .page.detail .section.detail-content .tab-textarea h4, .page.detail .section.detail-content .tab-textarea h5, .page.detail .section.detail-content .tab-textarea h6 {
          line-height: 1.38em; } }
  .page.detail .section.detail-content .tab-specslist {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .tab-specslist {
        display: block; } }
  .page.detail .section.detail-content .tab-equipmentlist table .icon-check-thin {
    color: #145aff; }
  .page.detail .section.detail-content .tab-equipmentlist table .group-title {
    background-color: white;
    padding-top: 35px; }
    .page.detail .section.detail-content .tab-equipmentlist table .group-title th {
      padding-top: 1em;
      padding-bottom: 1em;
      background-color: white; }
      .page.detail .section.detail-content .tab-equipmentlist table .group-title th:first-child {
        font-weight: bold; }
  .page.detail .section.detail-content .tab-equipmentlist table th:nth-child(n+2) {
    max-width: 4em;
    width: 6em;
    text-align: center; }
  .page.detail .section.detail-content .specslist-group {
    width: calc(50% - 3rem); }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .specslist-group {
        margin-top: 2em;
        width: auto; } }
    .page.detail .section.detail-content .specslist-group + .specslist-group + .specslist-group {
      margin-top: 3.6rem; }
      @media (max-width: 960px) {
        .page.detail .section.detail-content .specslist-group + .specslist-group + .specslist-group {
          margin-top: 2em; } }
  .page.detail .section.detail-content .equ .group-title {
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 2rem; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .equ .group-title {
        font-size: 1.5rem;
        margin-bottom: 1.1rem; } }
  .page.detail .section.detail-content .group-table {
    width: 100%; }
    .page.detail .section.detail-content .group-table td {
      font-weight: 700;
      color: #145aff; }
    .page.detail .section.detail-content .group-table th {
      padding-right: 1rem; }
    .page.detail .section.detail-content .group-table td {
      padding-left: 1rem; }
  .page.detail .section.detail-content .tab-gallery {
    width: 100%; }
    .page.detail .section.detail-content .tab-gallery .swiper-slide {
      width: auto; }
      .page.detail .section.detail-content .tab-gallery .swiper-slide + .swiper-slide {
        margin-left: 3.8rem; }
    .page.detail .section.detail-content .tab-gallery .swiper-wrapper {
      padding-bottom: 8.8rem; }
    .page.detail .section.detail-content .tab-gallery .swiper-scrollbar {
      left: 0;
      width: 100%;
      background-color: #f7f7fa; }
    .page.detail .section.detail-content .tab-gallery .swiper-scrollbar-drag {
      background-color: #145aff; }
  .page.detail .section.detail-content .gallery-item {
    display: inline-block;
    height: 34rem; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .gallery-item {
        height: auto; }
        .page.detail .section.detail-content .gallery-item + .gallery-item {
          margin-top: 1.5rem; } }
  .page.detail .section.detail-content .item-btn {
    height: 100%;
    background: #161d4c; }
    .page.detail .section.detail-content .item-btn:hover .item-image {
      opacity: .9; }
  .page.detail .section.detail-content .item-image {
    height: 100%;
    transition: opacity 200ms ease; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .item-image {
        height: auto; } }
  .page.detail .section.detail-content .tab-expand {
    margin-top: 5.6rem; }
  .page.detail .section.detail-content .tab-related {
    margin: -1.5rem -1.5rem;
    width: calc(100% + 3rem); }
  @media (max-width: 960px) {
    .page.detail .section.detail-content .detail-info {
      padding-top: 1rem; } }
  .page.detail .section.detail-content .info-title {
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 2.8rem; }
  .page.detail .section.detail-content .info-mainfeatures {
    padding: 1.4rem 0;
    color: #8688a2; }
    .page.detail .section.detail-content .info-mainfeatures li {
      display: inline-block;
      font-size: 1.4rem; }
      .page.detail .section.detail-content .info-mainfeatures li + li {
        margin-left: 1.8rem; }
    .page.detail .section.detail-content .info-mainfeatures i {
      font-size: 1.3rem;
      margin-right: .6rem; }
    .page.detail .section.detail-content .info-mainfeatures + .info-highlights {
      padding-top: 0; }
  .page.detail .section.detail-content .info-highlights {
    padding: 1.4rem 0; }
    .page.detail .section.detail-content .info-highlights li {
      display: inline-block;
      display: inline-block;
      vertical-align: middle; }
      .page.detail .section.detail-content .info-highlights li > * {
        display: block; }
      .page.detail .section.detail-content .info-highlights li + li {
        margin-left: .7rem; }
    .page.detail .section.detail-content .info-highlights span {
      display: block;
      border: 0.1rem solid #eaebf3;
      font-size: 1.2rem;
      text-transform: uppercase;
      border-radius: 0.3rem;
      color: #8688a2;
      line-height: 2rem;
      padding: 0 .5rem;
      background-color: #fff; }
      @media (max-width: 960px) {
        .page.detail .section.detail-content .info-highlights span {
          color: #161d4c; } }
    .page.detail .section.detail-content .info-highlights img {
      height: 1.8rem; }
  .page.detail .section.detail-content .info-ncap {
    font-weight: 600;
    position: relative;
    z-index: 4;
    border-top: 0.1rem solid #eaebf3;
    border-bottom: 0.1rem solid #eaebf3;
    font-size: 1.2rem;
    color: rgba(22, 29, 76, 0.7);
    padding: 1rem 0; }
    .page.detail .section.detail-content .info-ncap > * {
      display: inline-block;
      vertical-align: middle; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .info-ncap {
        padding: 1rem 1.5rem;
        width: calc(100% + 3rem);
        margin: 0 -1.5rem; } }
  .page.detail .section.detail-content .ncap-stars {
    color: #e3e4e2;
    font-size: 1.8rem;
    display: inline-block;
    margin-left: 1.2rem; }
    .page.detail .section.detail-content .ncap-stars i.active {
      color: #ffcb00; }
    .page.detail .section.detail-content .ncap-stars i + i {
      margin-left: .5rem; }
  .page.detail .section.detail-content .ncap-info {
    margin-left: .6rem; }
  .page.detail .section.detail-content .info-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0; }
  .page.detail .section.detail-content .price-current {
    font-weight: 600;
    font-size: 3.8rem;
    color: #145aff; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .price-current {
        font-size: 2.8rem;
        line-height: 3.4rem; } }
  .page.detail .section.detail-content .price-listing {
    font-size: 1.4rem; }
    .page.detail .section.detail-content .price-listing strong {
      font-weight: 500;
      color: #8688a2; }
    .page.detail .section.detail-content .price-listing.higher span {
      text-decoration: line-through; }
  .page.detail .section.detail-content .info-costs {
    position: relative;
    padding: 1.4rem 0;
    font-size: 1.4rem;
    line-height: 1.6rem;
    z-index: 3; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .info-costs {
        padding: 0; } }
    .page.detail .section.detail-content .info-costs .costs-sum {
      position: relative;
      background-color: #f7f7fa;
      display: block;
      width: 100%;
      border-radius: 0.3rem;
      padding: 1.2rem 1.5rem;
      text-align: left; }
      .page.detail .section.detail-content .info-costs .costs-sum:after {
        position: absolute;
        right: 1.5rem;
        opacity: .5;
        font-size: .7rem;
        color: #161d4c;
        top: 50%;
        transform: translate3d(0, -50%, 0); }
      .page.detail .section.detail-content .info-costs .costs-sum strong {
        color: #8688a2; }
    .page.detail .section.detail-content .info-costs .costs-wrap {
      margin-top: .2rem; }
    .page.detail .section.detail-content .info-costs .costs-list {
      border-radius: 0.3rem;
      background-color: #eaebf3; }
    .page.detail .section.detail-content .info-costs .list-cost {
      padding: 1.2rem 1.5rem;
      display: flex;
      justify-content: space-between; }
    .page.detail .section.detail-content .info-costs .cost-info {
      margin-left: 1rem; }
      .page.detail .section.detail-content .info-costs .cost-info > i {
        opacity: .5;
        font-size: 1.2rem; }
    .page.detail .section.detail-content .info-costs .cost-num {
      width: 7rem; }
  .page.detail .section.detail-content .info-controls {
    position: relative;
    z-index: 2; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .info-controls {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        padding: 1rem 2rem;
        z-index: 20;
        border-top: 0.1rem solid #eaebf3; } }
    .page.detail .section.detail-content .info-controls .controls-button {
      width: calc(50% - 1rem);
      position: relative;
      vertical-align: middle; }
      @media (max-width: 960px) {
        .page.detail .section.detail-content .info-controls .controls-button {
          width: calc(50% - 2.5rem);
          position: static;
          padding-left: 2rem;
          padding-right: 2rem; }
          .page.detail .section.detail-content .info-controls .controls-button .btn-info {
            position: relative;
            right: auto;
            margin-left: .6rem;
            z-index: 10;
            display: inline-block;
            vertical-align: bottom;
            margin-bottom: -.4rem; } }
      .page.detail .section.detail-content .info-controls .controls-button:first-child {
        z-index: 2; }
      .page.detail .section.detail-content .info-controls .controls-button:nth-child(2n+2) {
        margin-left: 2rem; }
      .page.detail .section.detail-content .info-controls .controls-button:disabled {
        opacity: 1; }
        .page.detail .section.detail-content .info-controls .controls-button:disabled.reservate {
          border-color: #f7f7fa;
          color: #8688a2; }
          .page.detail .section.detail-content .info-controls .controls-button:disabled.reservate:hover, .page.detail .section.detail-content .info-controls .controls-button:disabled.reservate:active {
            background-color: transparent; }
        .page.detail .section.detail-content .info-controls .controls-button:disabled.bid {
          background-color: rgba(255, 90, 96, 0.5);
          border: none; }
    .page.detail .section.detail-content .info-controls .controls-phone {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      z-index: 3;
      width: 7rem;
      height: 7rem;
      line-height: 6.6rem;
      font-size: 2.6rem;
      text-align: center;
      margin-left: -1rem;
      margin-right: -1rem;
      background-color: #145aff;
      color: #fff;
      border-radius: 50%;
      border: 0.4rem solid #fff;
      transition: background-color 40ms; }
      .page.detail .section.detail-content .info-controls .controls-phone:active {
        background-color: #0034ad; }
    .page.detail .section.detail-content .info-controls .controls-note {
      color: #145aff;
      font-size: 1.2rem; }
  .page.detail .section.detail-content .info-dealers {
    position: relative;
    padding: 1.4rem 0;
    font-size: 1.4rem;
    line-height: 1.6rem;
    z-index: 1; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .info-dealers {
        padding: 0; } }
    .page.detail .section.detail-content .info-dealers .dealers-sum {
      position: relative;
      background-color: #f7f7fa;
      display: block;
      width: 100%;
      border-radius: 0.3rem;
      padding: 1.2rem 1.5rem;
      text-align: left; }
      .page.detail .section.detail-content .info-dealers .dealers-sum:after {
        position: absolute;
        right: 1.5rem;
        opacity: .5;
        font-size: .7rem;
        color: #161d4c;
        top: 50%;
        transform: translate3d(0, -50%, 0); }
      .page.detail .section.detail-content .info-dealers .dealers-sum strong {
        color: #8688a2; }
    .page.detail .section.detail-content .info-dealers .dealers-wrap {
      margin-top: .2rem; }
    .page.detail .section.detail-content .info-dealers .dealers-list {
      border-radius: 0.3rem;
      background-color: #eaebf3; }
    .page.detail .section.detail-content .info-dealers .list-cost {
      padding: 1.2rem 1.5rem;
      display: flex;
      justify-content: space-between; }
    .page.detail .section.detail-content .info-dealers .cost-info {
      margin-left: 1rem; }
      .page.detail .section.detail-content .info-dealers .cost-info > i {
        opacity: .5;
        font-size: 1.2rem; }
    .page.detail .section.detail-content .info-dealers .cost-num {
      width: 7rem; }
  .page.detail .section.detail-content .info-dealer {
    background-color: #f7f7fa;
    border-radius: 0.3rem;
    margin-top: 3.7rem; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .info-dealer {
        margin-top: 1.5rem; } }
    .page.detail .section.detail-content .info-dealer .dealer-head {
      padding: 2.7rem 2.1rem 2.7rem 2.7rem;
      position: relative;
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: space-between; }
    .page.detail .section.detail-content .info-dealer .head-content {
      flex: 1; }
    .page.detail .section.detail-content .info-dealer .dealer-badge {
      position: absolute;
      top: 0.3rem;
      left: 0;
      width: 1.8rem;
      height: 1.8rem;
      font-size: 1.8rem;
      color: #145aff;
      display: inline-block; }
    .page.detail .section.detail-content .info-dealer .badge-icon {
      color: #fff;
      font-size: 0.9rem;
      position: absolute;
      z-index: 2;
      top: 53%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
    .page.detail .section.detail-content .info-dealer .dealer-image {
      width: 5rem;
      height: 5rem;
      margin-right: 2rem;
      border-radius: 50%;
      background-color: #fff; }
      .page.detail .section.detail-content .info-dealer .dealer-image .loaderwrap-icon {
        font-size: 5rem; }
    .page.detail .section.detail-content .info-dealer .dealer-title {
      font-weight: 600;
      font-size: 1.8rem;
      text-decoration: underline;
      text-transform: uppercase;
      display: block;
      padding-top: .2rem;
      position: relative;
      padding-left: 2.2rem; }
    .page.detail .section.detail-content .info-dealer .dealer-info {
      font-weight: 600;
      margin-top: 1rem;
      color: rgba(22, 29, 76, 0.5);
      text-transform: uppercase;
      font-size: 1.2rem; }
    .page.detail .section.detail-content .info-dealer .info-workinghours {
      margin-left: 1rem; }
      .page.detail .section.detail-content .info-dealer .info-workinghours span {
        margin: 0 1rem; }
      .page.detail .section.detail-content .info-dealer .info-workinghours.open {
        color: #16d2d3; }
    .page.detail .section.detail-content .info-dealer .dealer-rep {
      padding: 1.6rem 2rem 1.7rem;
      border-top: 0.1rem solid rgba(22, 29, 76, 0.2); }
    .page.detail .section.detail-content .info-dealer .rep-image {
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      height: 3.6rem;
      margin-right: 1rem; }
    .page.detail .section.detail-content .info-dealer .rep-title {
      font-weight: 500;
      display: inline-block;
      vertical-align: middle;
      font-size: 1.6rem; }
    .page.detail .section.detail-content .info-dealer .rep-role {
      text-transform: uppercase;
      color: rgba(22, 29, 76, 0.4);
      display: inline-block;
      vertical-align: middle;
      font-size: 1.2rem;
      margin-left: 1.5rem; }
    .page.detail .section.detail-content .info-dealer .dealer-controls {
      display: flex; }
      .page.detail .section.detail-content .info-dealer .dealer-controls > * {
        min-width: 50%;
        border-radius: 0; }
        .page.detail .section.detail-content .info-dealer .dealer-controls > *:nth-child(2n+1) {
          border-bottom-left-radius: 0.3rem;
          margin-right: .1rem; }
        .page.detail .section.detail-content .info-dealer .dealer-controls > *:nth-child(2n+2) {
          border-bottom-right-radius: 0.3rem; }
  .page.detail .section.detail-content .info-credit-calculation {
    background-color: #f7f7fa;
    border-radius: 0.3rem;
    border: solid 1px #ebebf3;
    padding: 17px 19px;
    margin-top: 3.7rem; }
    @media (max-width: 960px) {
      .page.detail .section.detail-content .info-credit-calculation {
        margin-top: 1.1rem; } }
    .page.detail .section.detail-content .info-credit-calculation h2 {
      font-size: 20px;
      color: #161d4c;
      font-weight: 600; }
    .page.detail .section.detail-content .info-credit-calculation p {
      margin-top: 8px;
      line-height: 1.43;
      color: #161d4c;
      font-weight: 400; }
    .page.detail .section.detail-content .info-credit-calculation .no-padding {
      padding-right: 0;
      padding-left: 0; }
      @media (max-width: 960px) {
        .page.detail .section.detail-content .info-credit-calculation .no-padding {
          padding-right: 1.1rem;
          padding-left: 1.1rem; } }
    .page.detail .section.detail-content .info-credit-calculation .credit-price.currency-after:after {
      content: 'TL';
      position: absolute;
      right: 2rem;
      top: 1.9rem;
      opacity: 0.6; }
    .page.detail .section.detail-content .info-credit-calculation .credit-price.month-after:after {
      content: 'AY';
      position: absolute;
      right: 2rem;
      top: 1.9rem;
      opacity: 0.6; }
    .page.detail .section.detail-content .info-credit-calculation .credit-price > input {
      padding: 1.5rem 1.3rem; }
  .page.detail .section.detail-content .info-credit-results {
    background-color: #f7f7fa;
    border-radius: 0.3rem;
    border: solid 1px #ebebf3;
    margin-top: 1.2rem;
    margin-bottom: 3.7rem; }
    .page.detail .section.detail-content .info-credit-results .tablePad {
      padding-right: 15px;
      padding-left: 15px;
      border-right: 1px solid #d5d5e3;
      border-left: 1px solid #d5d5e3; }
    .page.detail .section.detail-content .info-credit-results th {
      background-color: #ebebf3; }
      .page.detail .section.detail-content .info-credit-results th:nth-child(1) {
        width: 16rem;
        padding-right: 0.5rem; }
      .page.detail .section.detail-content .info-credit-results th:nth-child(2) {
        width: 8rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
      .page.detail .section.detail-content .info-credit-results th:nth-child(3) {
        padding-right: 0.5rem;
        padding-left: 1rem; }
      .page.detail .section.detail-content .info-credit-results th:nth-child(4) {
        width: 10rem;
        padding-right: 0.5rem;
        padding-left: 1rem; }
    .page.detail .section.detail-content .info-credit-results td:nth-child(1) {
      min-width: 10rem;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 0.5rem; }
      @media (max-width: 960px) {
        .page.detail .section.detail-content .info-credit-results td:nth-child(1) {
          min-width: 11rem; } }
    .page.detail .section.detail-content .info-credit-results td:nth-child(2) {
      padding-top: 20px;
      padding-bottom: 20px;
      width: 10rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .page.detail .section.detail-content .info-credit-results td:nth-child(3) {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 0.5rem;
      padding-left: 1rem;
      width: 121px; }
    .page.detail .section.detail-content .info-credit-results td:nth-child(4) {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 0.5rem;
      padding-left: 1rem; }
    .page.detail .section.detail-content .info-credit-results td button {
      padding: 8px 0px; }
      .page.detail .section.detail-content .info-credit-results td button span.btn-content {
        font-size: 1.8rem; }
      @media (max-width: 960px) {
        .page.detail .section.detail-content .info-credit-results td button span.btn-content {
          font-size: 1.8rem; } }

.page.detail .section.detail-related {
  border-top: 0.1rem solid #f7f7fa;
  padding: 5.4rem 0 10.1rem;
  margin-top: 3.3rem; }
  .page.detail .section.detail-related .related-innerwrap {
    width: calc(100% + 3rem);
    margin: 0 -1.5rem; }
  .page.detail .section.detail-related .section.listing .listing-content {
    padding: 0; }
  .page.detail .section.detail-related .section.listing .content-results {
    padding: 0; }
  .page.detail .section.detail-related .related-title {
    font-weight: 400;
    text-align: center;
    margin-bottom: 4.4rem;
    font-size: 2.2rem; }

.page.detail .section.detail-banners {
  padding: 7rem 0 10.2rem;
  text-align: center; }
  .page.detail .section.detail-banners .banners-item {
    height: 33rem;
    display: inline-block; }
    @media (max-width: 960px) {
      .page.detail .section.detail-banners .banners-item {
        height: auto; } }
    .page.detail .section.detail-banners .banners-item + .banners-item {
      margin-left: 4rem; }
      @media (max-width: 960px) {
        .page.detail .section.detail-banners .banners-item + .banners-item {
          margin: 1.5rem 0 0; } }
    .page.detail .section.detail-banners .banners-item img {
      height: 100%; }
      @media (max-width: 960px) {
        .page.detail .section.detail-banners .banners-item img {
          height: auto; } }

.page.detail .section.detail-blogposts {
  padding: 5.5rem 0 8.7rem;
  max-width: 160rem;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1; }
  .page.detail .section.detail-blogposts .blogposts-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0 -1.5rem;
    width: calc(100% + 3rem); }
  .page.detail .section.detail-blogposts .blogposts-item {
    width: 25%;
    padding: 1.5rem; }
    @media (max-width: 960px) {
      .page.detail .section.detail-blogposts .blogposts-item {
        width: 50%; } }

.section.listprices {
  padding: 0 0 8.8rem; }
  .section.listprices .listprices-head {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    margin-bottom: 2.7rem;
    align-items: flex-end; }
    @media (max-width: 960px) {
      .section.listprices .listprices-head {
        display: block;
        padding: 2rem 0 0; } }
  .section.listprices .head-title {
    font-weight: 300;
    font-size: 3.2rem;
    line-height: 1em; }
  .section.listprices .head-filters {
    margin-bottom: .2rem; }
    @media (max-width: 960px) {
      .section.listprices .head-filters {
        display: flex;
        justify-content: space-between;
        margin-top: 4rem; } }
  .section.listprices .filters-item {
    width: 17rem;
    display: inline-block; }
    @media (max-width: 960px) {
      .section.listprices .filters-item {
        width: calc(50% - 1rem); } }
    .section.listprices .filters-item + .filters-item {
      margin-left: 1.5rem; }
      @media (max-width: 960px) {
        .section.listprices .filters-item + .filters-item {
          margin-left: 0; } }
  @media (max-width: 960px) {
    .section.listprices .listprices-outerwrap {
      position: relative;
      width: calc(100% + 3rem);
      margin-left: -1.5rem;
      margin-right: -1.5rem; }
      .section.listprices .listprices-outerwrap:after, .section.listprices .listprices-outerwrap:before {
        content: ' ';
        overflow: hidden;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 1.5rem;
        z-index: 4;
        pointer-events: none;
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); }
      .section.listprices .listprices-outerwrap:before {
        transform: rotate(180deg);
        right: auto;
        left: 0; } }
  @media (max-width: 960px) {
    .section.listprices .listprices-wrap {
      overflow: scroll;
      overflow-x: scroll;
      overflow-y: hidden;
      box-sizing: content-box; } }
  @media (max-width: 960px) {
    .section.listprices .listprices-table {
      width: calc(100% - 1.5rem);
      margin-left: 1.5rem; } }
  .section.listprices .listprices-table td, .section.listprices .listprices-table th {
    text-align: center; }
  .section.listprices .listprices-table thead th {
    font-weight: 600;
    color: #eaebf3;
    background-color: #161d4c;
    text-transform: uppercase;
    font-size: 1.2rem;
    border-right: 0.1rem solid rgba(234, 235, 243, 0.7); }
    .section.listprices .listprices-table thead th:last-child {
      border-right: none; }
      @media (max-width: 960px) {
        .section.listprices .listprices-table thead th:last-child {
          border-right: 1.5rem solid #fff; } }
  .section.listprices .listprices-table tbody:before {
    height: 3rem; }
  .section.listprices .listprices-table tbody td {
    border-right: 0.1rem solid rgba(234, 235, 243, 0.7); }
    .section.listprices .listprices-table tbody td:last-child {
      border-right: none; }
      @media (max-width: 960px) {
        .section.listprices .listprices-table tbody td:last-child {
          border-right: 1.5rem solid #fff; } }
    .section.listprices .listprices-table tbody td.brand {
      background-color: transparent;
      padding: 0;
      position: relative;
      width: 11rem;
      min-height: 10rem; }
      .section.listprices .listprices-table tbody td.brand .brand-wrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-left: 0.1rem solid rgba(234, 235, 243, 0.7);
        border-top: 0.1rem solid rgba(234, 235, 243, 0.7);
        border-bottom: 0.1rem solid rgba(234, 235, 243, 0.7); }
      .section.listprices .listprices-table tbody td.brand .brand-logowrap {
        height: 100%;
        max-width: 6em;
        width: 100%;
        text-align: center;
        margin: auto;
        max-height: 5em; }
      .section.listprices .listprices-table tbody td.brand .brand-logo {
        width: 100%;
        height: 100%; }

.section.listprices-crumbs {
  margin-bottom: -.8rem; }
  @media (max-width: 960px) {
    .section.listprices-crumbs {
      display: none; } }

.section.about-head {
  background-color: #606b9c;
  color: #fff;
  text-align: center;
  padding: 9.6rem 0 16.8rem;
  font-size: 3.6rem;
  line-height: 4.3rem; }
  @media (max-width: 960px) {
    .section.about-head {
      padding: 2.6rem 0 7.5rem;
      font-size: 1.8rem;
      line-height: 2.5rem; } }
  .section.about-head .head-title {
    font-weight: 500;
    font-size: inherit;
    line-height: inherit;
    text-transform: lowercase; }
  .section.about-head .head-subtitle {
    font-weight: 300;
    font-size: inherit;
    line-height: inherit; }

.section.about-intro {
  margin-top: -11.6rem;
  text-align: center;
  padding: 0 1.5rem; }
  @media (max-width: 960px) {
    .section.about-intro {
      margin-top: -5rem; } }
  .section.about-intro .intro-image {
    width: 117rem;
    border-radius: 0.3rem;
    margin: 0 auto; }
  .section.about-intro .intro-text {
    width: 77rem;
    margin: 0 auto;
    padding: 8.7rem 0 12rem;
    font-size: 1.8rem;
    line-height: 1.555555em; }
    @media (max-width: 960px) {
      .section.about-intro .intro-text {
        width: calc(100% - 5.6rem);
        font-size: 1.4rem;
        padding: 2.2rem 0 2.6rem; } }

.section.about-counts {
  padding: 4rem 0;
  border-top: 0.1rem solid rgba(22, 29, 76, 0.1);
  border-bottom: 0.1rem solid rgba(22, 29, 76, 0.1); }
  @media (max-width: 960px) {
    .section.about-counts {
      padding: .5rem 1.5rem; } }
  .section.about-counts .counts-wrap {
    border-right-color: rgba(22, 29, 76, 0.1);
    display: flex; }
    @media (max-width: 960px) {
      .section.about-counts .counts-wrap {
        display: block; } }
  .section.about-counts .counts-item {
    flex: 1;
    text-align: center;
    padding: 8rem 0;
    position: relative; }
    @media (max-width: 960px) {
      .section.about-counts .counts-item {
        padding: 1.9rem 0; } }
    .section.about-counts .counts-item:nth-child(3n+2) {
      border-left: 0.1rem solid rgba(22, 29, 76, 0.1);
      border-right: 0.1rem solid rgba(22, 29, 76, 0.1); }
      @media (max-width: 960px) {
        .section.about-counts .counts-item:nth-child(3n+2) {
          border-left: none;
          border-right: none; } }
    @media (max-width: 960px) {
      .section.about-counts .counts-item + .counts-item:before {
        content: ' ';
        overflow: hidden;
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        width: 5rem;
        margin-left: -2.5rem;
        height: .1rem;
        background-color: rgba(22, 29, 76, 0.1); } }
  .section.about-counts .item-num {
    font-weight: 700;
    font-size: 4.2rem;
    display: block;
    color: #16d2d3;
    margin-bottom: 1.4rem; }
    @media (max-width: 960px) {
      .section.about-counts .item-num {
        font-size: 2.4rem; } }
  .section.about-counts .item-title {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #8688a2; }
    @media (max-width: 960px) {
      .section.about-counts .item-title {
        font-size: 1.4rem;
        line-height: 2rem; } }

.section.about-brands {
  padding: 13.8rem 0 14.4rem;
  color: #8688a2; }
  @media (max-width: 960px) {
    .section.about-brands {
      padding: 2.3rem 0 2.8rem; } }
  .section.about-brands .brands-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 960px) {
      .section.about-brands .brands-wrap {
        flex-flow: row wrap;
        justify-content: center;
        padding: 0 .5rem; } }
  .section.about-brands .brands-image {
    width: calc(50% - 1.5rem); }
    @media (max-width: 960px) {
      .section.about-brands .brands-image {
        width: 15.8rem; } }
  .section.about-brands .brands-content {
    font-weight: 200;
    width: calc(50% - 1.5rem);
    font-size: 1.8rem;
    line-height: 1.555555em; }
    @media (max-width: 960px) {
      .section.about-brands .brands-content {
        width: 100%; } }
  .section.about-brands .content-title {
    font-weight: inherit;
    font-size: 4.2rem;
    line-height: 5rem; }
    @media (max-width: 960px) {
      .section.about-brands .content-title {
        font-size: 2rem;
        line-height: 2.8rem; } }
  .section.about-brands .content-text {
    margin-top: 3rem;
    line-height: inherit; }
    @media (max-width: 960px) {
      .section.about-brands .content-text {
        font-weight: 200;
        font-size: 1.4rem;
        line-height: 1.57em; } }

.section.about-team {
  padding: 2.7rem 0 6.6rem;
  border-top: 0.1rem solid #f7f7fa; }
  @media (max-width: 960px) {
    .section.about-team {
      padding: 2.2rem 1.5rem 3rem; } }
  .section.about-team .team-title {
    font-weight: 200;
    color: #8688a2;
    text-align: center;
    font-size: 4.2rem;
    line-height: 5rem;
    margin-bottom: 6.1rem; }
    @media (max-width: 960px) {
      .section.about-team .team-title {
        font-size: 2rem;
        line-height: 2.8rem;
        margin-bottom: 1.9rem; } }
  .section.about-team .team-container {
    display: flex;
    justify-content: center; }
    @media (max-width: 960px) {
      .section.about-team .team-container {
        display: block;
        margin: 0 -.5rem;
        width: calc(100% + .5rem); }
        .section.about-team .team-container .swiper-slide {
          padding: 0 .5rem; } }
  .section.about-team .team-member {
    width: 23rem;
    margin: 0 4rem; }
    @media (max-width: 960px) {
      .section.about-team .team-member {
        width: 100%;
        margin: 0; } }
  .section.about-team .member-image {
    width: calc(100% + .2rem);
    padding-bottom: 100%;
    margin: -.1rem -.1rem 0;
    border-radius: 50%; }
  .section.about-team .member-content {
    padding: 2.1rem 2rem 2rem; }
  .section.about-team .member-position {
    font-weight: 200;
    font-size: 1.8rem;
    line-height: 3.2rem;
    color: #8688a2;
    display: block; }
    @media (max-width: 960px) {
      .section.about-team .member-position {
        font-size: 1.2rem;
        line-height: 2.2rem; } }
  .section.about-team .member-name {
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3.2rem; }
    @media (max-width: 960px) {
      .section.about-team .member-name {
        font-size: 1.4rem;
        line-height: 2.2rem; } }
  .section.about-team .member-contact {
    font-size: 0;
    margin-top: 2rem; }
    .section.about-team .member-contact > * {
      width: 4.2rem;
      display: inline-block; }
      @media (max-width: 960px) {
        .section.about-team .member-contact > * {
          width: 3.2rem; } }
      .section.about-team .member-contact > * + * {
        margin-left: 1.5rem; }

.section.account-nav {
  border-bottom: 0.1rem solid #f7f7fa;
  text-align: center;
  padding: 1.9rem 0;
  margin-bottom: 3.4rem; }
  @media (max-width: 960px) {
    .section.account-nav {
      border-bottom: none;
      text-align: left;
      overflow: auto;
      overflow-y: hidden;
      overflow-x: auto;
      padding-bottom: 0;
      margin-bottom: 1.6rem; } }
  @media (max-width: 960px) {
    .section.account-nav .nav-innerwrap {
      border-bottom: 0.3rem solid #f7f7fa;
      padding: 0 1.5rem;
      display: inline-block;
      white-space: nowrap; } }
  .section.account-nav .nav-item {
    font-weight: 500;
    position: relative;
    display: inline-block;
    font-size: 1.4rem;
    line-height: 2.4rem;
    padding-bottom: 1rem;
    transition: color 200ms ease;
    text-transform: uppercase; }
    @media (max-width: 960px) {
      .section.account-nav .nav-item {
        margin-bottom: -.3rem;
        padding-bottom: 2.1rem;
        line-height: 1.7rem;
        color: #8688a2; } }
    .section.account-nav .nav-item:before {
      position: absolute;
      height: .4rem;
      left: 0;
      bottom: 0;
      width: 100%;
      background: #145aff;
      transform: scaleX(0);
      opacity: 0;
      transition: opacity 200ms ease, transform 200ms ease; }
    .section.account-nav .nav-item + .nav-item {
      margin-left: 4.7rem; }
      @media (max-width: 960px) {
        .section.account-nav .nav-item + .nav-item {
          margin-left: 2.2rem; } }
    .section.account-nav .nav-item.active {
      color: #145aff; }
      .section.account-nav .nav-item.active:before {
        transform: scaleX(1);
        opacity: 1; }
    .section.account-nav .nav-item:hover {
      color: #145aff; }
      @media (min-width: 960px) {
        .section.account-nav .nav-item:hover:before {
          transform: scaleX(0.5);
          opacity: .9; } }

.section.account-wrap {
  padding: 0 0 3.4rem; }

.section.account-profile .profile-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  @media (max-width: 960px) {
    .section.account-profile .profile-wrap {
      display: block; } }

.section.account-profile .profile-sum {
  width: 27rem;
  text-align: center;
  border-radius: 0.6rem;
  border: 0.1rem solid #f7f7fa;
  padding: 2rem 1rem 0; }
  @media (max-width: 960px) {
    .section.account-profile .profile-sum {
      width: auto;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 1.4rem 1.5rem 1.3rem 1.4rem; } }

.section.account-profile .sum-image {
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  margin: 0 auto; }
  @media (max-width: 960px) {
    .section.account-profile .sum-image {
      width: 7.3rem;
      height: 7.3rem; } }

.section.account-profile .sum-content {
  margin-top: .9rem;
  padding-bottom: 2rem; }
  @media (max-width: 960px) {
    .section.account-profile .sum-content {
      flex: 1;
      margin-left: 3rem;
      margin-top: 0; } }

@media (max-width: 960px) {
  .section.account-profile .content-bio {
    display: flex;
    justify-content: space-between;
    align-items: center; } }

.section.account-profile .content-bio + .content-logout {
  margin-top: .8rem; }

.section.account-profile .bio-name {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.2rem; }

.section.account-profile .bio-location {
  font-size: 1.4rem;
  color: #8688a2;
  margin-top: .7rem;
  line-height: 1.8rem; }
  @media (max-width: 960px) {
    .section.account-profile .bio-location {
      margin-top: 0;
      color: inherit; } }
  .section.account-profile .bio-location i {
    margin-right: .2rem; }

.section.account-profile .content-completion {
  border-top: 0.1rem solid #f7f7fa;
  padding: 3rem 0 2rem;
  margin-top: 2.6rem; }
  @media (max-width: 960px) {
    .section.account-profile .content-completion {
      padding: 0;
      margin-top: 1.3rem;
      text-align: left; } }

.section.account-profile .completion-bar {
  height: 1rem;
  border-radius: .5rem;
  background-color: #f7f7fa;
  overflow: hidden; }

.section.account-profile .bar-progress {
  background-color: #145aff;
  width: 10%;
  height: 100%;
  transform-origin: 0 50%;
  border-radius: .5rem;
  transition: width 400ms ease; }

.section.account-profile .completion-status {
  font-weight: 400;
  margin-top: 2rem;
  font-size: 1.4rem;
  color: rgba(22, 29, 76, 0.6); }
  @media (max-width: 960px) {
    .section.account-profile .completion-status {
      margin-top: .9rem; } }
  .section.account-profile .completion-status span {
    color: rgba(20, 90, 255, 0.6); }

.section.account-profile .content-logout {
  font-weight: 400;
  color: #ca320d;
  text-decoration: underline; }

.section.account-profile .profile-form {
  width: calc(100% - 30rem); }
  @media (max-width: 960px) {
    .section.account-profile .profile-form {
      width: auto;
      margin-top: 2.1rem; } }

.section.account-profile .form-message {
  width: 44rem;
  text-align: center;
  padding: 1.5rem 2rem;
  background-color: #f7f7fa;
  border-color: #eaebf3;
  border-radius: 0.3rem;
  margin-bottom: 2rem; }
  @media (max-width: 960px) {
    .section.account-profile .form-message {
      width: auto; } }
  .section.account-profile .form-message.success {
    background-color: #16d2d3;
    border-color: #11a4a5;
    color: #fff; }
  .section.account-profile .form-message.error {
    background-color: #ca320d;
    border-color: #9a260a;
    color: #fff; }

.section.account-profile .form-section + .form-section {
  margin-top: 4rem; }

.section.account-profile .form-title {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3rem;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid #f7f7fa;
  margin-bottom: 2rem; }
  @media (max-width: 960px) {
    .section.account-profile .form-title {
      font-size: 2rem;
      line-height: 2.4rem;
      padding-bottom: 1.4rem;
      margin-bottom: 1.4rem; } }

.section.account-profile .form-group + .form-group {
  margin-top: 4rem; }

.section.account-profile .form-submit {
  margin-top: 3.4rem; }
  @media (max-width: 960px) {
    .section.account-profile .form-submit {
      width: 100%; } }

.section.account-profile .form-inputwrap {
  width: 44rem; }
  @media (max-width: 960px) {
    .section.account-profile .form-inputwrap {
      width: auto; } }
  .section.account-profile .form-inputwrap + .form-inputwrap {
    margin-top: 2.6rem; }
  .section.account-profile .form-inputwrap:nth-last-child(1) {
    z-index: 1; }
  .section.account-profile .form-inputwrap:nth-last-child(2) {
    z-index: 2; }
  .section.account-profile .form-inputwrap:nth-last-child(3) {
    z-index: 3; }
  .section.account-profile .form-inputwrap:nth-last-child(4) {
    z-index: 4; }
  .section.account-profile .form-inputwrap:nth-last-child(5) {
    z-index: 5; }
  .section.account-profile .form-inputwrap:nth-last-child(6) {
    z-index: 6; }
  .section.account-profile .form-inputwrap:nth-last-child(7) {
    z-index: 7; }
  .section.account-profile .form-inputwrap:nth-last-child(8) {
    z-index: 8; }
  .section.account-profile .form-inputwrap:nth-last-child(9) {
    z-index: 9; }
  .section.account-profile .form-inputwrap:nth-last-child(10) {
    z-index: 10; }

.section.account-profile .form-delete {
  font-weight: 400;
  margin-top: 6rem;
  color: #8688a2;
  font-size: 1.4rem;
  text-decoration: underline;
  line-height: 1.7rem; }

.section.account-notifications .notifications-group + .notifications-group {
  margin-top: 2rem; }

.section.account-notifications .group-title {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.8rem;
  padding-bottom: 1.6rem;
  border-bottom: 0.1rem solid #eaebf3; }

.section.account-notifications .items-notification {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.1rem;
  padding: 1.5rem 2rem;
  border-bottom: 0.1rem solid #eaebf3;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap; }
  @media (max-width: 960px) {
    .section.account-notifications .items-notification {
      align-items: flex-start; } }
  .section.account-notifications .items-notification.unread {
    background-color: rgba(247, 247, 250, 0.6); }

.section.account-notifications .notification-link {
  font-weight: 500;
  color: #145aff; }

.section.account-notifications .notification-image {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 0.1rem solid #f7f7fa;
  margin-right: 1.7rem; }

.section.account-notifications .notification-content {
  flex: 1; }

.section.account-notifications .notification-datetime {
  font-weight: 400;
  color: rgba(22, 29, 76, 0.5);
  margin-top: .1rem;
  font-size: 1.3rem; }

.section.account-favorites {
  margin-top: -3.4rem; }
  @media (max-width: 960px) {
    .section.account-favorites {
      margin-top: -1.6rem; } }
  .section.account-favorites .favorites-nav {
    text-align: center;
    background-color: #f7f7fa;
    line-height: 4.5rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center; }
    @media (max-width: 960px) {
      .section.account-favorites .favorites-nav {
        margin-bottom: 1.6rem;
        line-height: 3.8rem; } }
  .section.account-favorites .nav-link {
    text-transform: uppercase;
    font-size: 1.4rem;
    transition: color 200ms ease; }
    .section.account-favorites .nav-link.active, .section.account-favorites .nav-link:hover, .section.account-favorites .nav-link:active {
      color: #145aff; }
    .section.account-favorites .nav-link + .nav-link {
      margin-left: 3.7rem; }
  .section.account-favorites .favorites-error {
    padding: 3rem;
    text-align: center; }
    .section.account-favorites .favorites-error .error-message {
      display: inline-block;
      background-color: #f7f7fa;
      border: 0.1rem solid #eaebf3;
      padding: 4.5rem 1rem;
      font-size: 1.8rem;
      max-width: 100%;
      width: 58rem; }
  .section.account-favorites .favorites-list {
    width: calc(100% + 3rem);
    margin: 0 -1.5rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    @media (max-width: 960px) {
      .section.account-favorites .favorites-list {
        width: calc(100% + 1.5rem);
        margin: 0 -.75rem 0; } }
  .section.account-favorites .list-item {
    padding: 2rem 1.5rem 0;
    width: 25%; }
    @media (max-width: 960px) {
      .section.account-favorites .list-item {
        width: 50%;
        padding: 0 .75rem 1.5rem; } }

.section.account-messages .messages-error {
  padding: 3rem;
  text-align: center; }
  .section.account-messages .messages-error .error-message {
    display: inline-block;
    background-color: #f7f7fa;
    border: 0.1rem solid #eaebf3;
    padding: 4.5rem 1rem;
    font-size: 1.8rem;
    max-width: 100%;
    width: 58rem; }

.section.account-messages .list-item + .list-item {
  margin-top: 2.2rem; }

.section.account-messages .item-link {
  background-color: #f7f7fa;
  padding: 1rem 2rem;
  border-radius: 0.3rem;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(25, 42, 70, 0.1);
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  @media (max-width: 960px) {
    .section.account-messages .item-link {
      align-items: flex-start;
      padding: 1.6rem 1.4rem 1.6rem 1.9rem; } }

.section.account-messages .item-avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 50%; }

.section.account-messages .item-content {
  flex: 1;
  display: flex;
  align-items: center;
  flex-flow: row wrap; }
  @media (max-width: 960px) {
    .section.account-messages .item-content {
      display: block;
      margin-left: 2.3rem; } }

.section.account-messages .item-senderinfo {
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  position: relative;
  padding: 0 1rem;
  width: 11rem; }
  @media (max-width: 960px) {
    .section.account-messages .item-senderinfo {
      width: auto;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-flow: row wrap;
      padding: 0; } }
  @media (min-width: 960px) {
    .section.account-messages .item-senderinfo:after {
      content: ' ';
      overflow: hidden;
      display: block;
      position: absolute;
      right: 0;
      height: 2.2rem;
      width: .1rem;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      background-color: rgba(22, 29, 76, 0.6); } }

.section.account-messages .senderinfo-title {
  color: #145aff;
  margin-bottom: .2rem; }

.section.account-messages .senderinfo-datetime {
  color: #8688a2; }

.section.account-messages .senderinfo-subtitle {
  color: rgba(22, 29, 76, 0.6); }
  @media (max-width: 960px) {
    .section.account-messages .senderinfo-subtitle {
      width: 100%;
      margin-top: .5rem; } }

.section.account-messages .item-title {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-left: 2.1rem;
  flex-grow: 2; }
  @media (max-width: 960px) {
    .section.account-messages .item-title {
      margin-left: 0;
      margin-top: .5rem;
      display: block; } }

.section.account-messages .item-info {
  white-space: nowrap; }
  @media (max-width: 960px) {
    .section.account-messages .item-info {
      display: none; } }

.section.account-messages .info-field {
  font-weight: 400;
  color: rgba(22, 29, 76, 0.6);
  display: inline-block;
  font-size: 1.4rem;
  padding: 0 2rem;
  border-left: 0.1rem solid rgba(22, 29, 76, 0.6);
  line-height: 2rem;
  padding-bottom: .2rem; }
  .section.account-messages .info-field strong {
    font-weight: inherit;
    font-size: 1.2rem; }
  .section.account-messages .info-field:last-child {
    padding-right: 0; }
  .section.account-messages .info-field button {
    transition: color 200ms ease; }
    .section.account-messages .info-field button:hover, .section.account-messages .info-field button:active {
      color: #161d4c; }

.section.account-message-conversation .conversation-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2.6rem;
  line-height: 2.2rem; }
  @media (max-width: 960px) {
    .section.account-message-conversation .conversation-head {
      padding-bottom: 1.9rem; } }

.section.account-message-conversation .head-back {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  flex-flow: row wrap; }
  .section.account-message-conversation .head-back span {
    margin-left: .8rem;
    color: #8688a2;
    font-size: 1.5rem; }

.section.account-message-conversation .head-advertid {
  font-size: 1.5rem;
  color: #8688a2; }

.section.account-message-conversation .head-advertlink {
  font-weight: 400;
  font-size: 1.5rem;
  color: #145aff;
  text-decoration: underline; }

.section.account-message-conversation .conversation-dealer {
  background-color: #145aff;
  color: #fff;
  display: block;
  padding: 1rem;
  line-height: 5rem;
  margin-bottom: 4.2rem; }
  @media (max-width: 960px) {
    .section.account-message-conversation .conversation-dealer {
      margin-bottom: 2rem; } }

.section.account-message-conversation .dealer-avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 3rem; }
  @media (max-width: 960px) {
    .section.account-message-conversation .dealer-avatar {
      width: 3.5rem;
      height: 3.5rem;
      margin-right: .7rem; } }

.section.account-message-conversation .dealer-title {
  font-size: 1.6rem; }

.section.account-message-conversation .conversation-sender {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 5rem;
  color: #145aff;
  padding-left: 1rem;
  display: flex; }
  @media (max-width: 960px) {
    .section.account-message-conversation .conversation-sender {
      padding-left: 0; } }

.section.account-message-conversation .sender-avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.1rem; }

.section.account-message-conversation .sender-name {
  flex: 1; }

.section.account-message-conversation .sender-advertid {
  font-weight: 400;
  font-size: 1.4rem;
  color: rgba(22, 29, 76, 0.6); }

.section.account-message-conversation .conversation-content {
  padding-left: 6.1rem;
  padding-top: 2.1rem; }
  @media (max-width: 960px) {
    .section.account-message-conversation .conversation-content {
      padding-left: 0;
      padding-top: 1.6rem; } }

.section.account-message-conversation .conversation-title {
  font-weight: 600;
  font-size: 3.2rem;
  color: rgba(22, 29, 76, 0.8); }
  @media (max-width: 960px) {
    .section.account-message-conversation .conversation-title {
      font-size: 2rem; } }

.section.account-message-conversation .messages-message {
  padding: 3.2rem 0 2.9rem;
  display: flex;
  flex-flow: row wrap; }
  @media (max-width: 960px) {
    .section.account-message-conversation .messages-message {
      padding: 3.7rem 0 3.9rem; } }
  @media (max-width: 960px) {
    .section.account-message-conversation .messages-message.first {
      padding-top: 2.1rem; } }
  .section.account-message-conversation .messages-message + .messages-message {
    border-top: 0.1rem solid #eaebf3; }

.section.account-message-conversation .message-content {
  flex: 2; }

.section.account-message-conversation .message-text {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.56em; }
  @media (max-width: 960px) {
    .section.account-message-conversation .message-text {
      font-size: 1.4rem;
      line-height: 2em; } }

.section.account-message-conversation .message-datetime {
  font-weight: 400;
  color: #8688a2;
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-top: 2rem; }
  .section.account-message-conversation .message-datetime i {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.8rem;
    margin-top: -.3em;
    margin-right: .4rem; }

.section.account-message-conversation .message-profile {
  width: 4.4rem; }
  @media (max-width: 960px) {
    .section.account-message-conversation .message-profile {
      width: 5rem; } }

.section.account-message-conversation .profile-image {
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%; }
  @media (max-width: 960px) {
    .section.account-message-conversation .profile-image {
      width: 4rem;
      height: 4rem; } }

.section.account-message-conversation .conversation-composeform {
  position: relative;
  margin-top: 3rem; }
  @media (max-width: 960px) {
    .section.account-message-conversation .conversation-composeform {
      margin-top: .9rem; } }
  .section.account-message-conversation .conversation-composeform .inputwrap textarea {
    padding-left: 4.8rem;
    min-height: 4.6rem;
    height: 4.6rem;
    max-height: 15rem; }

.section.account-message-conversation .composeform-avatar {
  position: absolute;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  left: .9rem;
  top: 1.1rem;
  z-index: 3; }

.section.account-message-conversation .composeform-submit {
  position: absolute;
  right: .4rem;
  top: .4rem;
  width: 7.6rem; }
  @media (max-width: 960px) {
    .section.account-message-conversation .composeform-submit {
      position: relative;
      right: auto;
      top: auto;
      width: 100%;
      padding-top: 1.1rem;
      padding-bottom: 1.1rem;
      line-height: 2.1rem;
      margin-top: 2rem; } }

.section.account-reservations .list-reservation + .list-reservation {
  margin-top: 1.5rem; }

.section.account-reservations .list-reservation.expired .reservation-link {
  background-color: transparent; }

.section.account-reservations .reservation-link {
  border: 0.1rem solid #eaebf3;
  background-color: #f7f7fa;
  border-radius: 0.3rem;
  padding: 2.1rem 2.3rem 2.3rem;
  display: flex;
  align-items: center; }
  @media (max-width: 960px) {
    .section.account-reservations .reservation-link {
      padding: 1.6rem; } }

.section.account-reservations .reservation-image {
  width: 10rem;
  height: auto; }

.section.account-reservations .reservation-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-left: 3.6rem; }
  @media (max-width: 960px) {
    .section.account-reservations .reservation-content {
      display: block;
      margin-left: 1.8rem;
      overflow: hidden; } }

.section.account-reservations .content-title {
  font-weight: 400;
  display: block;
  font-size: 1.8rem;
  line-height: 2.2rem; }
  @media (max-width: 960px) {
    .section.account-reservations .content-title {
      font-size: 1.6rem;
      line-height: 1.9rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } }

.section.account-reservations .content-dealer {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-top: .5rem;
  color: rgba(22, 29, 76, 0.5); }
  @media (max-width: 960px) {
    .section.account-reservations .content-dealer {
      margin-top: .6rem; } }

.section.account-reservations .content-info {
  margin-top: .8rem;
  color: rgba(22, 29, 76, 0.6);
  line-height: 1.4rem; }
  .section.account-reservations .content-info div {
    display: inline-block; }
    .section.account-reservations .content-info div strong {
      font-weight: inherit;
      font-size: 1.2rem; }
    .section.account-reservations .content-info div + div {
      border-left: 0.1rem solid rgba(22, 29, 76, 0.6);
      margin-left: 2rem;
      padding-left: 2rem; }

.section.account-reservations .content-cancel {
  font-weight: 500;
  font-size: 1.4rem;
  color: #145aff;
  text-decoration: underline; }
  .section.account-reservations .content-cancel:disabled {
    color: rgba(22, 29, 76, 0.5);
    cursor: default; }

.section.account-login {
  border-top: 0.1rem solid #f7f7fa;
  padding: 6rem 0 6rem; }
  @media (max-width: 960px) {
    .section.account-login {
      padding: 2.3rem 0; } }
  .section.account-login .login-wrap {
    max-width: 50rem;
    margin: 0 auto; }

.section.reservation-layout {
  display: flex;
  justify-content: flex-end; }
  @media (max-width: 960px) {
    .section.reservation-layout {
      flex-flow: row wrap; } }
  .section.reservation-layout .layout-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 7rem;
    padding-top: 4.3rem;
    padding-bottom: 7rem; }
    @media (max-width: 960px) {
      .section.reservation-layout .layout-content {
        width: 100%;
        order: 2;
        display: block;
        padding: 2.5rem 1.5rem 1.8rem; } }
    .section.reservation-layout .layout-content .content-innerwrap {
      width: calc(86.5% - 7rem);
      max-width: 93.7rem; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-content .content-innerwrap {
          width: auto;
          max-width: none; } }
    .section.reservation-layout .layout-content .content-nav {
      margin-bottom: 6.6rem;
      display: flex;
      justify-content: flex-start; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-content .content-nav {
          margin-bottom: 2.8rem;
          justify-content: space-between; } }
    .section.reservation-layout .layout-content .nav-item {
      width: 19rem;
      height: 8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.1rem solid #8688a2;
      border-radius: 0.3rem;
      color: #8688a2; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-content .nav-item {
          width: 10.5rem;
          height: 6.5rem;
          border-color: #eaebf3; } }
      .section.reservation-layout .layout-content .nav-item + .nav-item {
        margin-left: 4rem; }
        @media (max-width: 960px) {
          .section.reservation-layout .layout-content .nav-item + .nav-item {
            margin-left: 0; } }
      .section.reservation-layout .layout-content .nav-item.active {
        border-color: #145aff;
        color: #145aff; }
        .section.reservation-layout .layout-content .nav-item.active.sum {
          color: #16d2d3;
          border-color: #16d2d3; }
      .section.reservation-layout .layout-content .nav-item.disabled {
        pointer-events: none; }
    .section.reservation-layout .layout-content .item-icon {
      font-size: 3.5rem;
      text-align: center; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-content .item-icon {
          font-size: 2.7rem; } }
    .section.reservation-layout .layout-content .item-text {
      font-weight: 300;
      width: 6.2rem;
      margin-left: 1.2rem;
      text-align: left;
      font-size: 1.6rem;
      line-height: 1.9rem; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-content .item-text {
          font-size: 1.4rem;
          line-height: 1.6rem;
          margin-left: .7rem;
          width: auto; } }
      .section.reservation-layout .layout-content .item-text strong {
        font-weight: 600;
        display: block; }
  .section.reservation-layout .layout-sidebar {
    width: 28.125%;
    background-color: rgba(247, 247, 250, 0.8); }
    @media (max-width: 960px) {
      .section.reservation-layout .layout-sidebar {
        width: 100%;
        order: 1; } }
    .section.reservation-layout .layout-sidebar .sidebar-content {
      max-width: 35rem;
      width: 77.777%;
      padding: 5rem 0 5rem 5rem; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-sidebar .sidebar-content {
          width: auto;
          max-width: none;
          padding: 1.5rem 1.5rem; } }
    .section.reservation-layout .layout-sidebar .sidebar-advertinfo {
      margin-bottom: 3.1rem; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-sidebar .sidebar-advertinfo {
          margin-bottom: 0;
          display: flex;
          align-items: center; } }
    .section.reservation-layout .layout-sidebar .advertinfo-imagewrap {
      position: relative; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-sidebar .advertinfo-imagewrap {
          width: 13.8rem; } }
    .section.reservation-layout .layout-sidebar .imagewrap-price {
      font-weight: 600;
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: #145aff;
      color: #fff;
      line-height: 3rem;
      padding: 0 1.6rem;
      font-size: 1.5rem; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-sidebar .imagewrap-price {
          font-size: 1.3rem;
          line-height: 2.4rem;
          padding: 0 .7rem; } }
    @media (max-width: 960px) {
      .section.reservation-layout .layout-sidebar .advertinfo-content {
        flex: 1;
        padding-left: 1.5rem; } }
    .section.reservation-layout .layout-sidebar .content-id {
      font-weight: 500;
      font-size: 1.4rem;
      text-transform: uppercase;
      color: #8688a2;
      margin-top: 1.8rem;
      line-height: 1.7rem; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-sidebar .content-id {
          font-size: 1.1rem;
          margin-top: 0; } }
      .section.reservation-layout .layout-sidebar .content-id strong {
        font-size: 1.2rem;
        font-weight: inherit; }
        @media (max-width: 960px) {
          .section.reservation-layout .layout-sidebar .content-id strong {
            font-size: inherit; } }
    .section.reservation-layout .layout-sidebar .content-title {
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 1.33em;
      margin-top: .8rem;
      padding-bottom: 1.3rem;
      border-bottom: 0.1rem solid #eaebf3; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-sidebar .content-title {
          font-size: 1.4rem;
          line-height: 1.6rem;
          border-bottom: none;
          margin-top: .5rem;
          padding-bottom: 0; } }
    .section.reservation-layout .layout-sidebar .content-info {
      font-weight: 500;
      color: #8688a2;
      line-height: 2.4rem;
      padding: .9rem 0;
      border-bottom: 0.1rem solid #eaebf3; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-sidebar .content-info {
          line-height: 2.4rem;
          font-size: 1.2rem;
          padding: .2rem 0; } }
      .section.reservation-layout .layout-sidebar .content-info strong {
        font-weight: 400;
        font-size: 1.4rem; }
        @media (max-width: 960px) {
          .section.reservation-layout .layout-sidebar .content-info strong {
            font-size: inherit; } }
      .section.reservation-layout .layout-sidebar .content-info span {
        color: #161d4c; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-sidebar .content-info:last-child {
          padding-bottom: 0;
          border-bottom: none; } }
      .section.reservation-layout .layout-sidebar .content-info.price span {
        font-weight: 600;
        color: #145aff;
        font-size: 1.8rem; }
        @media (max-width: 960px) {
          .section.reservation-layout .layout-sidebar .content-info.price span {
            font-size: 1.6rem; } }
    .section.reservation-layout .layout-sidebar .sidebar-agreement .agreement-link {
      color: #145aff;
      text-decoration: underline; }
    .section.reservation-layout .layout-sidebar .sidebar-agreement .checkwrap {
      margin-bottom: 3.3rem; }
      @media (max-width: 960px) {
        .section.reservation-layout .layout-sidebar .sidebar-agreement .checkwrap {
          margin-bottom: 2rem; } }
      .section.reservation-layout .layout-sidebar .sidebar-agreement .checkwrap input:checked + label {
        color: inherit; }
      .section.reservation-layout .layout-sidebar .sidebar-agreement .checkwrap input + label {
        font-weight: 400;
        white-space: nowrap;
        overflow: visible;
        color: #8688a2; }
    @media (max-width: 960px) {
      .section.reservation-layout .layout-sidebar .sidebar-controls {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 1.5rem;
        z-index: 5;
        background-color: #fff;
        box-shadow: 0rem .3rem 2.0rem 0rem rgba(0, 0, 0, 0.22); } }

.section.reservation-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  .section.reservation-info .info-faq {
    margin-right: 12rem;
    flex: 1;
    padding-top: 1.4rem; }
    @media (max-width: 960px) {
      .section.reservation-info .info-faq {
        margin: 0;
        padding-top: 0; } }
  .section.reservation-info .faq-item {
    font-weight: 400;
    font-size: 1.4rem;
    color: fade(#161d4c, 0.7);
    line-height: 2.2rem; }
    @media (max-width: 960px) {
      .section.reservation-info .faq-item {
        font-size: 1.3rem;
        line-height: 2.2rem; } }
    .section.reservation-info .faq-item + .faq-item {
      margin-top: 5.4rem; }
      @media (max-width: 960px) {
        .section.reservation-info .faq-item + .faq-item {
          margin-top: 2.3rem; } }
  .section.reservation-info .item-title {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.9rem;
    margin-bottom: 2.3rem;
    color: #161d4c; }
    @media (max-width: 960px) {
      .section.reservation-info .item-title {
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: .6rem; } }
  .section.reservation-info .info-logo {
    width: 36.3rem; }

.section.reservation-payment .payment-iyzico {
  display: flex;
  align-items: flex-start;
  margin-bottom: 4.7rem; }
  @media (max-width: 960px) {
    .section.reservation-payment .payment-iyzico {
      margin-bottom: 3.2rem; } }

.section.reservation-payment .iyzico-icon {
  color: #16d2d3;
  font-size: 5rem;
  margin-right: 1.8rem; }
  @media (max-width: 960px) {
    .section.reservation-payment .iyzico-icon {
      font-size: 4.1rem;
      margin-right: 1.3rem; } }

.section.reservation-payment .iyzico-title {
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: .6rem;
  display: block; }
  @media (max-width: 960px) {
    .section.reservation-payment .iyzico-title {
      font-size: 1.6rem;
      margin-top: -.5rem; } }

.section.reservation-payment .iyzico-subtitle {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #8688a2; }
  @media (max-width: 960px) {
    .section.reservation-payment .iyzico-subtitle {
      font-size: 1.4rem;
      line-height: 1.7rem; } }

.section.reservation-payment .payment-cardinfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.section.reservation-payment .cardinfo-form {
  width: 40rem;
  padding-top: .9rem;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap; }
  @media (max-width: 960px) {
    .section.reservation-payment .cardinfo-form {
      padding-top: 0; } }

.section.reservation-payment .form-field {
  margin-bottom: .9rem;
  width: 100%;
  z-index: 1; }
  @media (max-width: 960px) {
    .section.reservation-payment .form-field {
      margin-bottom: 1.5rem; } }
  .section.reservation-payment .form-field.half {
    width: calc(50% - .5rem); }
  .section.reservation-payment .form-field.has-info {
    z-index: 2; }
  .section.reservation-payment .form-field.cvc {
    z-index: 3; }
  .section.reservation-payment .form-field.iyzico {
    margin-top: 2.9rem; }
    @media (max-width: 960px) {
      .section.reservation-payment .form-field.iyzico {
        margin-top: 0; } }
    .section.reservation-payment .form-field.iyzico .checkwrap {
      border: 0.1rem solid #eaebf3;
      border-radius: 0.3rem;
      background-color: #f7f7fa;
      padding: 1.5rem 1.4rem 1.5rem 1.4rem; }
      .section.reservation-payment .form-field.iyzico .checkwrap input + label > span {
        top: 47%; }
  .section.reservation-payment .form-field.billing {
    margin-top: 2rem; }
    @media (max-width: 960px) {
      .section.reservation-payment .form-field.billing {
        margin-top: 0; } }
  .section.reservation-payment .form-field .input-info .popinfo-content {
    width: 33rem; }
  .section.reservation-payment .form-field .input-info .iyzico-info {
    display: flex;
    flex-flow: row wrap;
    align-items: center; }
    .section.reservation-payment .form-field .input-info .iyzico-info .info-image {
      width: 3.8rem;
      margin-right: 2.2rem;
      margin-left: .7rem; }
    .section.reservation-payment .form-field .input-info .iyzico-info span {
      flex: 1; }

.section.reservation-payment .form-billing {
  font-weight: 400;
  border: 0.1rem solid #eaebf3;
  border-radius: 0.3rem;
  transition: border-color 200ms ease; }
  .section.reservation-payment .form-billing.error {
    border-color: #ca320d; }

.section.reservation-payment .billing-btn {
  line-height: 4.8rem;
  padding: 0 1.3rem;
  position: relative;
  width: 100%;
  display: block;
  text-align: left; }
  .section.reservation-payment .billing-btn:after {
    position: absolute;
    top: 50%;
    right: 2.1rem;
    transform: translate3d(0, -50%, 0);
    color: #8688a2;
    font-size: .8rem; }

.section.reservation-payment .btn-selected {
  font-size: .9em;
  margin-left: .5em;
  color: #8688a2; }
  .section.reservation-payment .btn-selected:before {
    content: '('; }
  .section.reservation-payment .btn-selected:after {
    content: ')'; }

.section.reservation-payment .billing-content {
  border-top: 0.1rem solid #eaebf3;
  min-height: 10rem; }

.section.reservation-payment .content-error {
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.6rem;
  margin-top: 0.5rem;
  color: #ca320d;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: .9rem; }

.section.reservation-payment .list-address {
  padding: 0 1.4rem;
  border-bottom: 0.1rem solid #eaebf3; }
  .section.reservation-payment .list-address .checkwrap {
    padding: 2.8rem 0 2.8rem 0; }
    .section.reservation-payment .list-address .checkwrap input + label {
      display: block; }
      .section.reservation-payment .list-address .checkwrap input + label > span {
        top: 1rem; }

.section.reservation-payment .address-content {
  display: block; }

.section.reservation-payment .address-title {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem; }
  .section.reservation-payment .address-title span {
    font-weight: 400;
    font-size: 1.1rem;
    color: #8688a2;
    margin-left: 1rem; }

.section.reservation-payment .address-info {
  font-weight: 400;
  margin-top: .5rem;
  color: #8688a2;
  font-size: 1.3rem;
  line-height: 1.8rem; }

.section.reservation-payment .addresses-controls {
  padding: 2.9rem 1.4rem 2.1rem; }

.section.reservation-payment .controls-new {
  font-weight: 500;
  margin-bottom: 3.2rem;
  text-decoration: underline; }

.section.reservation-payment .content-newaddress {
  padding: .8rem 1.4rem 2.1rem; }

.section.reservation-payment .newaddress-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }

.section.reservation-payment .newaddress-field {
  width: calc(50% - .5rem);
  margin-bottom: 1rem;
  z-index: 1; }
  .section.reservation-payment .newaddress-field.city {
    z-index: 3; }
  .section.reservation-payment .newaddress-field.district {
    z-index: 2; }
  @media (max-width: 960px) {
    .section.reservation-payment .newaddress-field {
      width: 100%;
      margin-bottom: 1.5rem; } }
  .section.reservation-payment .newaddress-field.full {
    width: 100%; }

.section.reservation-payment .newaddress-type {
  margin: 1.8rem 0 2.6rem; }

.section.reservation-payment .type-opt {
  display: inline-block; }
  .section.reservation-payment .type-opt + .type-opt {
    margin-left: 3.1rem; }

.section.reservation-payment .newaddress-submit {
  margin-top: 1rem; }

.section.reservation-payment .cardinfo-preview {
  position: relative;
  width: 48rem;
  pointer-events: none; }

.section.reservation-payment .preview-cardinfo {
  font-weight: 500;
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.4rem;
  z-index: 2; }
  .section.reservation-payment .preview-cardinfo.name {
    bottom: 28%;
    left: 19%;
    max-width: 10rem; }
  .section.reservation-payment .preview-cardinfo.number {
    bottom: 43%;
    left: 19%;
    font-size: 1.6rem; }
  .section.reservation-payment .preview-cardinfo.expiry {
    bottom: 28%;
    left: 43.6%; }
  .section.reservation-payment .preview-cardinfo.cvc {
    bottom: 28%;
    left: 85.5%;
    width: 9.3%;
    text-align: center; }
  .section.reservation-payment .preview-cardinfo.type {
    width: 10.3%;
    bottom: 18%;
    right: 24%; }

.section.reservation-sum {
  text-align: center;
  padding-right: 20%;
  padding-bottom: 6rem; }
  @media (max-width: 960px) {
    .section.reservation-sum {
      padding-right: 0;
      padding-bottom: 0;
      padding-top: 1rem; } }
  .section.reservation-sum .sum-check {
    background-color: #16d2d3;
    color: #fff;
    width: 8.5rem;
    height: 8.5rem;
    padding-top: .8rem;
    line-height: 7.5rem;
    border-radius: 50%;
    font-size: 5rem;
    display: inline-block;
    text-align: center;
    margin-bottom: 4.9rem; }
    @media (max-width: 960px) {
      .section.reservation-sum .sum-check {
        width: 6.5rem;
        height: 6.5rem;
        line-height: 5.5rem;
        font-size: 3.4rem;
        padding-top: .7rem;
        margin-bottom: 2.7rem; } }
  .section.reservation-sum .sum-title {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 1.2rem; }
    @media (max-width: 960px) {
      .section.reservation-sum .sum-title {
        font-size: 1.6rem;
        margin-bottom: 2.1rem; } }
  .section.reservation-sum .sum-text {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.43em;
    color: #8688a2; }
  .section.reservation-sum .sum-ref {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-top: 5.1rem; }
    @media (max-width: 960px) {
      .section.reservation-sum .sum-ref {
        margin-top: 4.5rem;
        font-size: 1.6rem; } }
  .section.reservation-sum .sum-links {
    border-top: 0.1rem solid #eaebf3;
    margin-top: 5.9rem;
    padding-top: 2.8rem;
    text-transform: uppercase;
    color: #8688a2;
    font-size: 1.4rem; }
    @media (max-width: 960px) {
      .section.reservation-sum .sum-links {
        margin-top: 3.1rem;
        padding: 3.1rem 0; } }
  .section.reservation-sum .links-item + .links-item {
    margin-left: 3.8rem; }

.section.contentpage {
  position: relative;
  min-height: 23rem;
  padding: 8.3rem 0 7rem; }
  @media (max-width: 960px) {
    .section.contentpage {
      padding: 5.5rem 0 7rem; } }
  .section.contentpage:before {
    content: ' ';
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #145aff;
    height: 23rem;
    z-index: 1; }
    @media (max-width: 960px) {
      .section.contentpage:before {
        height: 7rem; } }
  .section.contentpage .contentpage-wrap {
    position: relative;
    z-index: 2; }
  .section.contentpage .contentpage-content {
    background: #fff;
    padding: 6.5rem 8.5rem;
    box-shadow: 0 0.1rem 0.2rem 0 rgba(144, 156, 177, 0.2); }
    @media (max-width: 960px) {
      .section.contentpage .contentpage-content {
        box-shadow: none;
        padding: 2.2rem 1.5rem; } }
  .section.contentpage .content-title {
    font-weight: 300;
    font-size: 3.2rem;
    line-height: 1em;
    text-align: center;
    margin-bottom: 5.1rem; }
    @media (max-width: 960px) {
      .section.contentpage .content-title {
        font-size: 2.2rem;
        line-height: 1.41em;
        margin-bottom: 1.3rem; } }
  .section.contentpage .content-subtitle {
    font-weight: 300;
    font-size: 2rem;
    line-height: 1.2em;
    text-align: center;
    color: #8688a2;
    margin-bottom: 5rem; }

.section.contentpage-faq .faq-item + .faq-item {
  margin-top: .9rem; }

.section.contentpage-textarea {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.94em;
  color: rgba(22, 29, 76, 0.7); }
  @media (max-width: 960px) {
    .section.contentpage-textarea {
      font-size: 1.4rem; } }
  .section.contentpage-textarea ol {
    margin-left: 5rem;
    padding-left: 1.5rem; }
    .section.contentpage-textarea ol li {
      margin-bottom: 3rem; }
  .section.contentpage-textarea .text-center {
    text-align: center; }
  .section.contentpage-textarea .indent {
    text-indent: 5rem; }
  .section.contentpage-textarea .indent.noMargin {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }

.section.contentpage-form {
  max-width: 62rem;
  margin: 0 auto;
  /*.form-row {
			width: calc(100% + 2.2rem);
			margin: 0 -1.1rem;
			display: flex;
			flex-flow: row wrap;
		}

		.form-col {
			width: 50%;
			padding: 1.1rem;

			&.x2 {
				width: 100%;
			}
		}*/ }
  .section.contentpage-form .form-opts {
    padding-top: 0; }
    .section.contentpage-form .form-opts .opts-inner {
      margin-left: -1rem;
      width: calc(100% + 1rem); }
    .section.contentpage-form .form-opts .opts-item {
      display: inline-block;
      border: 0.1rem solid #eaebf3;
      border-radius: 1.4rem;
      line-height: 2.8rem;
      padding: 0 1.6rem;
      font-size: 1.2rem;
      margin-left: 1rem;
      margin-bottom: 1rem;
      color: rgba(22, 29, 76, 0.5); }
      .section.contentpage-form .form-opts .opts-item button {
        font-size: .8rem;
        margin-left: 1rem;
        transition: color 200ms ease; }
        .section.contentpage-form .form-opts .opts-item button:hover {
          color: #161d4c; }
  .section.contentpage-form .form-agreement .checkwrap input + label {
    color: #8688a2;
    font-weight: 400; }
  .section.contentpage-form .form-submitbtn {
    max-width: 37rem;
    margin: 0 auto; }

.section.contact-map {
  background-color: #f7f7fa;
  height: 52rem;
  max-height: 70vh; }
  @media (max-width: 960px) {
    .section.contact-map {
      height: 25rem; } }
  .section.contact-map .map-container {
    height: 100%; }

.section.contact-info {
  padding: 8.1rem 0 9rem;
  /* .info-section {
			
		} */ }
  @media (max-width: 960px) {
    .section.contact-info {
      padding: 3rem 0 5rem; } }
  .section.contact-info .info-wrap {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 960px) {
      .section.contact-info .info-wrap {
        display: block; } }
  .section.contact-info .info-details {
    width: 62.1rem;
    border-bottom: 0.1rem solid #eaebf3; }
    @media (max-width: 960px) {
      .section.contact-info .info-details {
        width: calc(100% + 3rem);
        margin: 0 -1.5rem; } }
  .section.contact-info .details-item {
    font-weight: 400;
    display: flex;
    flex-flow: row wrap;
    line-height: 3rem;
    font-size: 1.8rem;
    padding: .7rem 0; }
    .section.contact-info .details-item + .details-item {
      border-top: 0.1rem solid #eaebf3; }
    @media (max-width: 960px) {
      .section.contact-info .details-item {
        font-weight: 500;
        border-top: 0.1rem solid #eaebf3;
        padding: 1.5rem 1.5rem;
        font-size: 1.4rem;
        line-height: 1.8rem; } }
  .section.contact-info .item-heading {
    color: #8688a2;
    font-weight: inherit;
    width: 19rem; }
    @media (max-width: 960px) {
      .section.contact-info .item-heading {
        width: 14.5rem; } }
  .section.contact-info .item-content {
    flex: 1; }
  .section.contact-info .info-form {
    width: 64.8rem; }
    @media (max-width: 960px) {
      .section.contact-info .info-form {
        width: auto;
        margin-top: 1.3rem; } }
  .section.contact-info .form-typeselection {
    font-size: 0;
    letter-spacing: 0;
    padding-bottom: 1rem; }
    @media (max-width: 960px) {
      .section.contact-info .form-typeselection {
        padding-bottom: .6rem; } }
  .section.contact-info .typeselection-opt {
    display: inline-block;
    position: relative; }
    .section.contact-info .typeselection-opt + .typeselection-opt {
      margin-left: 4.8rem; }
      @media (max-width: 960px) {
        .section.contact-info .typeselection-opt + .typeselection-opt {
          margin-left: 2.8rem; } }
    .section.contact-info .typeselection-opt input {
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      opacity: 0; }
      .section.contact-info .typeselection-opt input:checked + label {
        color: #145aff; }
        .section.contact-info .typeselection-opt input:checked + label:after {
          background-color: #145aff;
          transform: scaleX(1); }
    .section.contact-info .typeselection-opt label {
      font-weight: 600;
      position: relative;
      font-size: 1.4rem;
      line-height: 1.7rem;
      padding-bottom: 1.1rem;
      display: block;
      text-transform: uppercase;
      cursor: pointer;
      transition: color 200ms ease; }
      @media (max-width: 960px) {
        .section.contact-info .typeselection-opt label {
          padding-bottom: .3rem; } }
      .section.contact-info .typeselection-opt label:after {
        content: ' ';
        overflow: hidden;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: .3rem;
        background-color: #161d4c;
        transform: scaleX(0);
        transition: background-color 200ms ease, transform 200ms ease; }
        @media (max-width: 960px) {
          .section.contact-info .typeselection-opt label:after {
            height: .1rem; } }
  .section.contact-info .form-contentwrap {
    background-color: #f7f7fa;
    padding: .6rem .4rem 1.6rem;
    display: flex;
    flex-flow: row wrap; }
    @media (max-width: 960px) {
      .section.contact-info .form-contentwrap {
        border-radius: 0.3rem;
        padding: .5rem .5rem 1rem; } }
  .section.contact-info .form-section {
    padding: 1.2rem;
    width: 50%; }
    .section.contact-info .form-section.x2 {
      width: 100%; }
    @media (max-width: 960px) {
      .section.contact-info .form-section {
        padding: .5rem; } }
  .section.contact-info .form-controls {
    width: 100%;
    text-align: center; }
    @media (max-width: 960px) {
      .section.contact-info .form-controls {
        margin-top: .5rem; } }
    .section.contact-info .form-controls > * {
      width: 15.5rem; }

.section.blog-head {
  background-color: #145aff;
  height: 21.5rem; }
  @media (max-width: 960px) {
    .section.blog-head {
      height: 7rem; } }
  .section.blog-head .head-wrap {
    padding: 5.9rem 0 0;
    position: relative; }
    @media (max-width: 960px) {
      .section.blog-head .head-wrap {
        padding: 0; } }
  .section.blog-head .head-title {
    font-weight: 300;
    color: #fff;
    font-size: 3.2rem;
    line-height: 3.8rem;
    position: relative;
    z-index: 2; }
    @media (max-width: 960px) {
      .section.blog-head .head-title {
        font-size: 1.2rem;
        padding-left: 3.5rem;
        line-height: 2.8rem; } }
    .section.blog-head .head-title strong {
      font-weight: 700; }
  .section.blog-head .head-bg {
    position: absolute;
    top: 0;
    height: 21.5rem;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 1; }
    @media (max-width: 960px) {
      .section.blog-head .head-bg {
        height: 7rem; } }
  .section.blog-head .head-controls {
    position: relative;
    z-index: 1;
    margin-top: 6.9rem;
    background: #fff;
    padding: 2.7rem 3.4rem;
    box-shadow: 0rem .3rem 2.0rem 0rem rgba(0, 0, 0, 0.22);
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 960px) {
      .section.blog-head .head-controls {
        margin-top: 2.7rem;
        display: block;
        padding: 0;
        box-shadow: none; } }
  .section.blog-head .controls-nav {
    font-weight: 600;
    font-size: 1.6rem;
    text-transform: uppercase;
    display: flex; }
    @media (max-width: 960px) {
      .section.blog-head .controls-nav {
        font-size: 1rem;
        display: block;
        text-align: center;
        box-shadow: 0rem .1rem 1.0rem 0rem rgba(0, 0, 0, 0.02); ;
        padding: .5rem; } }
  @media (max-width: 960px) {
    .section.blog-head .nav-item {
      padding: 0 1.5rem;
      line-height: 2.5rem;
      display: inline-block; } }
  @media (min-width: 960px) {
    .section.blog-head .nav-item + .nav-item {
      padding-left: 2rem;
      margin-left: 2rem;
      border-left: 0.2rem solid rgba(22, 29, 76, 0.25); } }
  .section.blog-head .controls-search {
    width: 25rem;
    position: relative; }
    @media (max-width: 960px) {
      .section.blog-head .controls-search {
        width: 100%;
        margin-top: 1.5rem; } }
    @media (max-width: 960px) {
      .section.blog-head .controls-search .inputwrap input[type="text"] {
        padding: .8rem .9rem;
        line-height: 1.7rem; } }
  .section.blog-head .search-submit {
    position: absolute;
    right: 1.3rem;
    font-size: 1.4rem;
    color: rgba(22, 29, 76, 0.3);
    z-index: 2;
    top: 50%;
    transform: translate3d(0, -50%, 0); }

.section.blog-results {
  margin-top: 9.5rem; }
  @media (max-width: 960px) {
    .section.blog-results {
      margin-top: 10rem;
      padding-bottom: 2.25rem; } }
  .section.blog-results .results-error {
    color: #ca320d;
    text-align: center; }
  .section.blog-results .results-list {
    margin: 0 -1.5rem;
    display: flex;
    flex-flow: row wrap; }
    @media (max-width: 960px) {
      .section.blog-results .results-list {
        margin: .25rem -.75rem 0; } }
  .section.blog-results .results-item {
    padding: 1.5rem;
    width: 33.333333%; }
    @media (max-width: 960px) {
      .section.blog-results .results-item {
        padding: .75rem;
        width: 50%; } }

.section.blog-detail {
  padding-bottom: 10rem; }
  @media (max-width: 960px) {
    .section.blog-detail iframe {
      max-width: 100%; } }
  .section.blog-detail .detail-wrap {
    display: flex;
    flex-flow: row wrap;
    padding-right: 20rem;
    align-items: flex-start; }
    @media (max-width: 960px) {
      .section.blog-detail .detail-wrap {
        padding-right: 0; } }
  .section.blog-detail .detail-meta {
    width: 100%;
    text-align: right;
    font-size: 1.2rem;
    margin-bottom: 2.8rem; }
    @media (max-width: 960px) {
      .section.blog-detail .detail-meta {
        padding-top: .8rem;
        margin-bottom: 1.4rem;
        float: right; } }
  .section.blog-detail .detail-content {
    width: calc(100% - 20rem); }
    @media (max-width: 960px) {
      .section.blog-detail .detail-content {
        width: 100%; } }
  .section.blog-detail .content-text {
    font-size: 2.2rem;
    line-height: 1.86em; }
    @media (max-width: 960px) {
      .section.blog-detail .content-text {
        font-size: 1.4rem;
        line-height: 2rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    .section.blog-detail .content-text h2, .section.blog-detail .content-text h3, .section.blog-detail .content-text h4, .section.blog-detail .content-text h5, .section.blog-detail .content-text h6 {
      font-weight: 600;
      font-size: 1em; }
  .section.blog-detail .text-title {
    font-weight: 500;
    max-width: 57rem;
    font-size: 4.2rem;
    line-height: 1.24em;
    color: #145aff; }
    @media (max-width: 960px) {
      .section.blog-detail .text-title {
        font-size: 2.2rem;
        line-height: 2.4rem;
        margin-bottom: 1.8rem; } }
  .section.blog-detail .content-gallery {
    background-color: #f7f7fa;
    width: calc(100% + 10rem);
    margin: 4rem -5rem 0;
    position: relative;
    padding-bottom: 70%; }
    @media (max-width: 960px) {
      .section.blog-detail .content-gallery {
        padding-bottom: 75%;
        width: calc(100% + 3rem);
        margin-left: -1.5rem;
        margin-right: -1.5rem; } }
    .section.blog-detail .content-gallery .gallery-nav {
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      width: 4.8rem;
      height: 4.8rem;
      line-height: 4.8rem;
      font-size: 3rem;
      z-index: 2; }
      .section.blog-detail .content-gallery .gallery-nav.prev {
        left: 0; }
      .section.blog-detail .content-gallery .gallery-nav.next {
        right: 0; }
      @media (max-width: 960px) {
        .section.blog-detail .content-gallery .gallery-nav {
          color: #fff; } }
    .section.blog-detail .content-gallery .gallery-slider {
      position: absolute;
      top: 0;
      left: 10.5rem;
      right: 10.5rem;
      bottom: 0;
      z-index: 1; }
      @media (max-width: 960px) {
        .section.blog-detail .content-gallery .gallery-slider {
          left: 0;
          right: 0; } }
    .section.blog-detail .content-gallery .slider-imagewrap {
      width: 100%;
      height: 100%; }
    .section.blog-detail .content-gallery .imagewrap-image {
      position: relative;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 1;
      transition: opacity 200ms ease; }
      @media (max-width: 960px) {
        .section.blog-detail .content-gallery .imagewrap-image {
          background-size: cover; } }
      .section.blog-detail .content-gallery .imagewrap-image.swiper-lazy-loaded {
        opacity: 1; }
        .section.blog-detail .content-gallery .imagewrap-image.swiper-lazy-loaded + .imagewrap-loader {
          opacity: 0; }
    .section.blog-detail .content-gallery .imagewrap-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15rem;
      height: 15rem;
      border-radius: 50%;
      overflow: hidden;
      transform: translate3d(-50%, -50%, 0);
      transition: opacity 200ms ease; }
      .section.blog-detail .content-gallery .imagewrap-loader .imagewrap-image {
        background-size: cover; }
  .section.blog-detail .detail-sharer {
    width: 20rem;
    padding-right: 15.8rem; }
  .section.blog-detail .sharer-title {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 1.6rem;
    display: block; }
  .section.blog-detail .opts-item {
    width: 100%; }
    .section.blog-detail .opts-item + .opts-item {
      margin-top: 2rem; }
    .section.blog-detail .opts-item img {
      width: 100%; }
  .section.blog-detail .sharer-link.icon {
    width: 4.2rem;
    height: 4.2rem;
    display: block;
    color: #fff;
    background-color: #161d4c;
    border-radius: 50%;
    line-height: 4.2rem;
    text-align: center;
    font-size: 2.2rem; }
  .section.blog-detail .detail-relevant {
    margin-top: 10.5rem; }
    @media (max-width: 960px) {
      .section.blog-detail .detail-relevant {
        margin-top: 2rem; } }
  .section.blog-detail .relevant-title {
    font-weight: 500;
    color: #8688a2;
    display: block;
    text-align: center;
    font-size: 2.2rem;
    padding-bottom: 1.3rem;
    margin: 0 auto;
    border-bottom: 0.1rem solid #f7f7fa; }
    @media (max-width: 960px) {
      .section.blog-detail .relevant-title {
        font-size: 1.4rem;
        padding-bottom: .6rem;
        line-height: 2.8rem;
        width: calc(100% - 3rem); } }
  .section.blog-detail .relevant-items {
    margin: 1.25rem -.75rem 0;
    display: flex;
    flex-flow: row wrap; }
  .section.blog-detail .relevant-item {
    width: 20%;
    padding: 1.5rem; }
    @media (max-width: 960px) {
      .section.blog-detail .relevant-item {
        width: 50%;
        padding: .75rem; } }

.section.comparison {
  padding-bottom: 6rem; }
  .section.comparison .comparison-title {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.8rem;
    margin-top: 2.4rem;
    margin-bottom: -5.2rem; }
  .section.comparison .comparison-tablewrap {
    padding-top: 5.2rem; }
  .section.comparison .comparison-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0; }
    .section.comparison .comparison-table tr:nth-child(odd) .table-datafield {
      background-color: rgba(247, 247, 250, 0.7); }
    .section.comparison .comparison-table tr:nth-child(even) .table-datafield {
      background-color: rgba(234, 235, 243, 0.7); }
    .section.comparison .comparison-table td, .section.comparison .comparison-table th {
      padding: 1rem 2rem;
      line-height: 2rem;
      font-size: 1.4rem;
      border: 0.1rem solid #eaebf3; }
    .section.comparison .comparison-table th {
      font-weight: 500;
      text-align: left;
      color: #8688a2;
      width: 25%; }
      .section.comparison .comparison-table th.empty {
        border: none; }
    .section.comparison .comparison-table td {
      width: 25%; }
      .section.comparison .comparison-table td.picker {
        background: none;
        padding: 3.2rem 2.3rem; }
  .section.comparison .table-carpicker .carpicker-imagewrap {
    position: relative;
    padding-bottom: 75%; }
  .section.comparison .table-carpicker .carpicker-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .section.comparison .table-carpicker .carpicker-selector {
    margin-top: 1rem; }
  .section.comparison .table-datafield {
    border-top: none;
    border-bottom: none; }
    .section.comparison .table-datafield.unavailable {
      text-align: center;
      color: #e3e4e2; }

.otv-calculator .info-paragraph {
  color: #8688a2;
  padding-top: 1.2em; }

.otv-calculator .minoto-select__menu {
  z-index: 5; }

.otv-calculator .row {
  display: flex;
  flex-wrap: wrap; }

.otv-calculator .col {
  width: 50%; }
  @media (max-width: 960px) {
    .otv-calculator .col {
      width: 100%; } }

.otv-calculator .inputwrap {
  margin-top: 1.75em; }

.otv-calculator .calculator-results {
  padding-top: 1.5em;
  padding-left: 2.8em;
  position: relative; }
  @media (max-width: 960px) {
    .otv-calculator .calculator-results {
      padding: 0;
      margin-top: 1.2em; } }
  .otv-calculator .calculator-results .row {
    padding: 1rem 2rem; }
    .otv-calculator .calculator-results .row .col:nth-child(even) {
      color: #145aff; }
      @media (max-width: 960px) {
        .otv-calculator .calculator-results .row .col:nth-child(even) {
          margin-top: 1em; } }
    .otv-calculator .calculator-results .row:nth-child(odd) {
      background-color: rgba(247, 247, 250, 0.7); }
    .otv-calculator .calculator-results .row:nth-child(even) {
      background-color: rgba(234, 235, 243, 0.7); }
    .otv-calculator .calculator-results .row:last-child {
      font-weight: bold; }
  .otv-calculator .calculator-results--message {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #145aff; }
    @media (max-width: 960px) {
      .otv-calculator .calculator-results--message {
        padding-top: .6em;
        padding-bottom: .6em;
        position: relative;
        text-align: left; } }

.otv-calculator .contents {
  margin-top: 3em; }
  .otv-calculator .contents .col {
    width: 100%; }
  .otv-calculator .contents h2 {
    font-size: 1.6em;
    font-weight: 300;
    margin-top: 1.3em; }
    @media (max-width: 960px) {
      .otv-calculator .contents h2:not(:first-child) {
        margin-top: 1.3em; } }
  .otv-calculator .contents p {
    line-height: 1.4em;
    margin-top: 1.3em;
    font-weight: 400;
    color: #6e6f86;
    font-size: 1.8rem; }

.minoto-ui .ad-compare .comparison-tablewrap {
  overflow: auto; }

.minoto-ui .ad-compare th {
  min-width: 22em;
  max-width: 15px !important; }

.minoto-ui .ad-compare .info-dealer .head-content a {
  height: 3em;
  display: inline-block; }

.minoto-ui .ad-compare .ad-compare-table {
  margin-top: 2.6em; }
  .minoto-ui .ad-compare .ad-compare-table .listprices-table th, .minoto-ui .ad-compare .ad-compare-table .listprices-table td {
    border: none; }
  .minoto-ui .ad-compare .ad-compare-table .table-carpicker {
    display: block; }
  .minoto-ui .ad-compare .ad-compare-table .bg-gray {
    background-color: #f7f7fa; }
  .minoto-ui .ad-compare .ad-compare-table__header__labelArea {
    display: block; }
    .minoto-ui .ad-compare .ad-compare-table__header__labelArea .topinfo-highlights li {
      display: inline-block;
      display: inline-block;
      vertical-align: middle; }
      .minoto-ui .ad-compare .ad-compare-table__header__labelArea .topinfo-highlights li > * {
        display: block; }
      .minoto-ui .ad-compare .ad-compare-table__header__labelArea .topinfo-highlights li + li {
        margin-left: .7rem; }
    .minoto-ui .ad-compare .ad-compare-table__header__labelArea .topinfo-highlights span {
      display: block;
      border: 0.1rem solid #eaebf3;
      font-size: 1.2rem;
      text-transform: uppercase;
      border-radius: 0.3rem;
      color: #8688a2;
      line-height: 2rem;
      padding: 0 .5rem;
      background-color: #fff; }
      @media (max-width: 960px) {
        .minoto-ui .ad-compare .ad-compare-table__header__labelArea .topinfo-highlights span {
          color: #161d4c; } }
    .minoto-ui .ad-compare .ad-compare-table__header__labelArea .topinfo-highlights img {
      height: 1.8rem; }
    .minoto-ui .ad-compare .ad-compare-table__header__labelArea button {
      color: #145aff; }
    .minoto-ui .ad-compare .ad-compare-table__header__labelArea div {
      display: inline-block; }
      .minoto-ui .ad-compare .ad-compare-table__header__labelArea div:nth-child(2) {
        float: right; }
  .minoto-ui .ad-compare .ad-compare-table__header__titleArea {
    margin-top: 1em;
    margin-bottom: 2.1em; }
    .minoto-ui .ad-compare .ad-compare-table__header__titleArea div {
      display: block;
      text-align: right; }
    .minoto-ui .ad-compare .ad-compare-table__header__titleArea h1 {
      line-height: 1.2em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 16em; }
    .minoto-ui .ad-compare .ad-compare-table__header__titleArea a {
      margin-top: .4em;
      display: block;
      color: #161d4c;
      opacity: 0.5; }
    .minoto-ui .ad-compare .ad-compare-table__header__titleArea-title {
      opacity: 1 !important; }
    .minoto-ui .ad-compare .ad-compare-table__header__titleArea h4 {
      display: inline-block; }
      .minoto-ui .ad-compare .ad-compare-table__header__titleArea h4:first-child {
        color: #161d4c;
        opacity: 0.5; }
  .minoto-ui .ad-compare .ad-compare-table__header__priceArea {
    margin-top: 2.4em; }
    .minoto-ui .ad-compare .ad-compare-table__header__priceArea__btnContainer {
      text-align: justify;
      margin-top: 2.1em; }
      .minoto-ui .ad-compare .ad-compare-table__header__priceArea__btnContainer button {
        width: calc(50% - .7em); }
        .minoto-ui .ad-compare .ad-compare-table__header__priceArea__btnContainer button:last-child {
          float: right; }
    .minoto-ui .ad-compare .ad-compare-table__header__priceArea .pricetag {
      font-weight: 600;
      font-size: 2.8rem;
      color: #145aff; }
    .minoto-ui .ad-compare .ad-compare-table__header__priceArea .price-current {
      display: block;
      text-align: center; }
      .minoto-ui .ad-compare .ad-compare-table__header__priceArea .price-current h2 {
        color: #145aff; }
  .minoto-ui .ad-compare .ad-compare-table-techSpec span {
    display: block;
    font-weight: 500;
    text-align: left;
    color: #8688a2;
    margin-bottom: .5em; }
  @media (max-width: 960px) {
    .minoto-ui .ad-compare .ad-compare-table__creditCalculator td {
      padding: 0;
      text-align: center; } }

.minoto-ui .credit-calculator {
  background-color: #f7f7fa;
  border-radius: 0.3rem;
  border: solid 1px #ebebf3;
  padding: 17px 19px;
  margin-top: 3.7rem;
  max-width: 100%; }
  @media (max-width: 960px) {
    .minoto-ui .credit-calculator {
      margin-top: 1.1rem; } }
  .minoto-ui .credit-calculator h2 {
    font-size: 20px;
    color: #161d4c;
    font-weight: 600; }
  .minoto-ui .credit-calculator p {
    margin-top: 8px;
    line-height: 1.43;
    color: #161d4c;
    font-weight: 400; }
  .minoto-ui .credit-calculator .no-padding {
    padding-right: 0;
    padding-left: 0; }
    @media (max-width: 960px) {
      .minoto-ui .credit-calculator .no-padding {
        padding-right: 1.1rem;
        padding-left: 1.1rem; } }
  .minoto-ui .credit-calculator .credit-price.currency-after:after {
    content: 'TL';
    position: absolute;
    right: 2rem;
    top: 1.9rem;
    opacity: 0.6; }
  .minoto-ui .credit-calculator .credit-price.month-after:after {
    content: 'AY';
    position: absolute;
    right: 2rem;
    top: 1.9rem;
    opacity: 0.6; }
  .minoto-ui .credit-calculator .credit-price > input {
    padding: 1.5rem 1.3rem; }
  .minoto-ui .credit-calculator .credit-results {
    background-color: #f7f7fa;
    border-radius: 0.3rem;
    border: solid 1px #ebebf3;
    margin-top: 1.2rem;
    margin-bottom: 3.7rem; }
    .minoto-ui .credit-calculator .credit-results .tablePad {
      padding-right: 15px;
      padding-left: 15px;
      border-right: 1px solid #d5d5e3;
      border-left: 1px solid #d5d5e3; }
    .minoto-ui .credit-calculator .credit-results th {
      min-width: 5.9em;
      background-color: #ebebf3; }
      .minoto-ui .credit-calculator .credit-results th:nth-child(1) {
        width: 16rem;
        padding-right: 0.5rem; }
      .minoto-ui .credit-calculator .credit-results th:nth-child(2) {
        width: 8rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
      .minoto-ui .credit-calculator .credit-results th:nth-child(3) {
        padding-right: 0.5rem;
        padding-left: 1rem; }
      .minoto-ui .credit-calculator .credit-results th:nth-child(4) {
        width: 10rem;
        padding-right: 0.5rem;
        padding-left: 1rem; }
    .minoto-ui .credit-calculator .credit-results td {
      font-size: .9em; }
      .minoto-ui .credit-calculator .credit-results td:nth-child(1) {
        min-width: 10rem;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 0.5rem; }
        @media (max-width: 960px) {
          .minoto-ui .credit-calculator .credit-results td:nth-child(1) {
            min-width: 11rem; } }
      .minoto-ui .credit-calculator .credit-results td:nth-child(2) {
        padding-top: 20px;
        padding-bottom: 20px;
        width: 10rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
      .minoto-ui .credit-calculator .credit-results td:nth-child(3) {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 0.5rem;
        padding-left: 1rem;
        width: 121px; }
      .minoto-ui .credit-calculator .credit-results td:nth-child(4) {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 0.5rem;
        padding-left: 1rem; }
      .minoto-ui .credit-calculator .credit-results td button {
        padding: 8px 0px; }
        .minoto-ui .credit-calculator .credit-results td button span.btn-content {
          font-size: 1.8rem; }
        @media (max-width: 960px) {
          .minoto-ui .credit-calculator .credit-results td button span.btn-content {
            font-size: 1.8rem; } }

.minoto-ui .info-dealer {
  background-color: #f7f7fa;
  border-radius: 0.3rem; }
  @media (max-width: 960px) {
    .minoto-ui .info-dealer {
      margin-top: 1.5rem; } }
  .minoto-ui .info-dealer .dealer-head {
    padding: 2.7rem 2.1rem 2.7rem 2.7rem;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between; }
  .minoto-ui .info-dealer .head-content {
    flex: 1; }
  .minoto-ui .info-dealer .dealer-badge {
    position: absolute;
    top: 0.3rem;
    left: 0;
    width: 1.8rem;
    height: 1.8rem;
    font-size: 1.8rem;
    color: #145aff;
    display: inline-block; }
  .minoto-ui .info-dealer .badge-icon {
    color: #fff;
    font-size: 0.9rem;
    position: absolute;
    z-index: 2;
    top: 53%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0); }
  .minoto-ui .info-dealer .dealer-image {
    width: 5rem;
    height: 5rem;
    margin-right: 2rem;
    border-radius: 50%;
    background-color: #fff; }
    .minoto-ui .info-dealer .dealer-image .loaderwrap-icon {
      font-size: 5rem; }
  .minoto-ui .info-dealer .dealer-title {
    font-weight: 600;
    font-size: 1.8rem;
    text-decoration: underline;
    text-transform: uppercase;
    display: block;
    padding-top: .2rem;
    position: relative;
    padding-left: 2.2rem; }
  .minoto-ui .info-dealer .dealer-info {
    font-weight: 600;
    margin-top: 1rem;
    color: rgba(22, 29, 76, 0.5);
    text-transform: uppercase;
    font-size: 1.2rem; }
  .minoto-ui .info-dealer .info-workinghours {
    margin-left: 1rem; }
    .minoto-ui .info-dealer .info-workinghours span {
      margin: 0 1rem; }
    .minoto-ui .info-dealer .info-workinghours.open {
      color: #16d2d3; }
  .minoto-ui .info-dealer .dealer-rep {
    padding: 1.6rem 2rem 1.7rem;
    border-top: 0.1rem solid rgba(22, 29, 76, 0.2); }
  .minoto-ui .info-dealer .rep-image {
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    height: 3.6rem;
    margin-right: 1rem; }
  .minoto-ui .info-dealer .rep-title {
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    font-size: 1.6rem; }
  .minoto-ui .info-dealer .rep-role {
    text-transform: uppercase;
    color: rgba(22, 29, 76, 0.4);
    display: inline-block;
    vertical-align: middle;
    font-size: 1.2rem;
    margin-left: 1.5rem; }
  .minoto-ui .info-dealer .dealer-controls {
    display: flex; }
    .minoto-ui .info-dealer .dealer-controls > * {
      min-width: 50%;
      border-radius: 0; }
      .minoto-ui .info-dealer .dealer-controls > *:nth-child(2n+1) {
        border-bottom-left-radius: 0.3rem;
        margin-right: .1rem; }
      .minoto-ui .info-dealer .dealer-controls > *:nth-child(2n+2) {
        border-bottom-right-radius: 0.3rem; }

.minoto-ui .compare-modal--message {
  color: #145aff;
  margin: 2rem 0;
  display: block;
  background-color: #f7f7fa;
  padding: 1rem;
  font-size: 2rem; }

.minoto-ui .compare-modal .compare-cards {
  display: flex;
  flex-wrap: nowrap; }
  @media (max-width: 960px) {
    .minoto-ui .compare-modal .compare-cards {
      width: max-content; } }
  .minoto-ui .compare-modal .compare-cards button {
    display: block; }
  .minoto-ui .compare-modal .compare-cards__item {
    position: relative;
    margin: 3vh .6em 3rem;
    background-color: #fff;
    border-radius: 0.3rem;
    width: 20rem;
    box-shadow: 0rem .3rem 2.0rem 0rem rgba(0, 0, 0, 0.22);
    z-index: 2;
    transform: translateY(-1.5rem);
    transition: opacity 400ms ease, transform 300ms ease;
    -webkit-backface-visibility: hidden;
    padding: 1rem; }
    .minoto-ui .compare-modal .compare-cards__item h4 {
      margin-top: .8em;
      min-height: 4.4rem; }
    .minoto-ui .compare-modal .compare-cards__item img {
      background-color: #e3e4e2;
      width: 100%; }
    .minoto-ui .compare-modal .compare-cards__item .pricetag {
      margin-top: 1rem;
      float: right; }
      .minoto-ui .compare-modal .compare-cards__item .pricetag span {
        color: #145aff; }
    .minoto-ui .compare-modal .compare-cards__item button {
      width: 100%;
      margin-top: 4rem;
      height: 3rem;
      padding: 0;
      /* margin: 0; */
      display: block; }
      @media (max-width: 960px) {
        .minoto-ui .compare-modal .compare-cards__item button {
          margin-top: 0;
          width: 2.4rem;
          height: 2.4rem; } }
    .minoto-ui .compare-modal .compare-cards__item .btn__cancel-circle {
      position: absolute;
      top: .25em;
      right: .25em;
      width: 1.3em;
      height: 1.3em; }
      .minoto-ui .compare-modal .compare-cards__item .btn__cancel-circle i {
        font-size: .7em; }

.minoto-ui .compare-modal .modal-footer {
  text-align: right; }

.minoto-ui .compare-modal .scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto; }
  @media (max-width: 960px) {
    .minoto-ui .compare-modal .scroll-container {
      overflow: unset; } }

.minoto-ui .compare-modal .container::-webkit-scrollbar {
  display: none; }

@media (max-width: 960px) {
  .minoto-ui .modal-compare .modal-content {
    border-radius: 0;
    position: absolute;
    bottom: 0;
    margin: 0;
    width: 100%;
    max-width: 100%; }
  .minoto-ui .modal-compare .compare-modal--message {
    color: #161d4c;
    opacity: .5;
    background-color: transparent;
    font-size: 16px;
    padding: 0; } }
  @media (max-width: 960px) and (max-width: 960px) {
    .minoto-ui .modal-compare .compare-modal--message {
      margin-top: .5em; } }

@media (max-width: 960px) {
  .minoto-ui .modal-compare .compare-modal .compare-cards {
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%; }
    .minoto-ui .modal-compare .compare-modal .compare-cards__item {
      border: 1px solid #f7f7fa;
      min-width: 45%;
      width: 47%;
      margin: 1em 0 0;
      padding: 0;
      color: #161d4c;
      box-shadow: none; }
      .minoto-ui .modal-compare .compare-modal .compare-cards__item h4 {
        font-size: 14px;
        margin-top: 0;
        padding: .6em .9em 0 .9em; }
      .minoto-ui .modal-compare .compare-modal .compare-cards__item .pricetag {
        font-size: 18px;
        font-weight: 600;
        float: left;
        padding-left: .8em;
        padding-bottom: .6em;
        margin-top: 0; }
  .minoto-ui .modal-compare .compare-modal .modal-footer button {
    width: 100%;
    font-size: 18px; } }

.minoto-ui .label {
  display: inline-block;
  border: 0.1rem solid #eaebf3;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-repeat: initial;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-radius: 0.3rem;
  color: #8688a2;
  line-height: 2rem;
  padding: 0 .5rem;
  background-color: #fff; }

.minoto-ui .btn {
  font-weight: 600;
  position: relative;
  border-radius: 0.3rem;
  display: inline-block;
  background-color: #145aff;
  color: #fff;
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 1.3rem 1rem;
  text-align: center;
  border: 0.1rem solid #145aff;
  transition: background-color 200ms ease, color 200ms ease, border-color 200ms ease; }
  @media (min-width: 960px) {
    .minoto-ui .btn:hover, .minoto-ui .btn:active {
      background-color: #0043e0;
      border-color: #0043e0; } }
  @media (max-width: 960px) {
    .minoto-ui .btn {
      transition: background-color 40ms ease, color 40ms ease, border-color 40ms ease; } }
  .minoto-ui .btn:active {
    background-color: #0034ad;
    border-color: #0034ad; }
  .minoto-ui .btn.hollow {
    background-color: transparent;
    color: #145aff; }
    @media (min-width: 960px) {
      .minoto-ui .btn.hollow:hover, .minoto-ui .btn.hollow:active {
        background-color: #0043e0;
        color: #fff; } }
    .minoto-ui .btn.hollow:active {
      background-color: #0034ad;
      color: #fff; }
  .minoto-ui .btn.white {
    background-color: #fff;
    color: #145aff;
    border-color: #fff; }
    @media (min-width: 960px) {
      .minoto-ui .btn.white:hover, .minoto-ui .btn.white:active {
        background-color: #e6e6e6;
        border-color: #e6e6e6; } }
    .minoto-ui .btn.white:active {
      background-color: #cccccc;
      border-color: #cccccc; }
    .minoto-ui .btn.white.hollow {
      background-color: transparent;
      color: #fff; }
      @media (min-width: 960px) {
        .minoto-ui .btn.white.hollow:hover, .minoto-ui .btn.white.hollow:active {
          background-color: #e6e6e6;
          color: #145aff; } }
      .minoto-ui .btn.white.hollow:active {
        background-color: #cccccc;
        color: #145aff; }
  .minoto-ui .btn.text {
    background-color: #161d4c;
    color: #fff;
    border-color: #161d4c; }
    @media (min-width: 960px) {
      .minoto-ui .btn.text:hover, .minoto-ui .btn.text:active {
        background-color: #0b0e24;
        border-color: #0b0e24; } }
    .minoto-ui .btn.text:active {
      background-color: black;
      border-color: black; }
    .minoto-ui .btn.text.hollow {
      background-color: transparent;
      color: #161d4c; }
      @media (min-width: 960px) {
        .minoto-ui .btn.text.hollow:hover, .minoto-ui .btn.text.hollow:active {
          background-color: #0b0e24;
          color: #fff; } }
      .minoto-ui .btn.text.hollow:active {
        background-color: black;
        color: #fff; }
  .minoto-ui .btn.dark {
    background-color: #e3e4e2;
    color: #161d4c;
    border-color: #e3e4e2; }
    @media (min-width: 960px) {
      .minoto-ui .btn.dark:hover, .minoto-ui .btn.dark:active {
        background-color: #cacbc8;
        border-color: #cacbc8; } }
    .minoto-ui .btn.dark:active {
      background-color: #b0b3ad;
      border-color: #b0b3ad; }
    .minoto-ui .btn.dark.hollow {
      background-color: transparent;
      color: #161d4c; }
      @media (min-width: 960px) {
        .minoto-ui .btn.dark.hollow:hover, .minoto-ui .btn.dark.hollow:active {
          background-color: #e3e4e2;
          border-color: #e3e4e2; } }
      .minoto-ui .btn.dark.hollow:active {
        background-color: #b0b3ad;
        border-color: #b0b3ad; }
  .minoto-ui .btn.facebook {
    background-color: #3b5998;
    border-color: #3b5998; }
    @media (min-width: 960px) {
      .minoto-ui .btn.facebook:hover, .minoto-ui .btn.facebook:active {
        background-color: #2d4373;
        border-color: #2d4373; } }
    .minoto-ui .btn.facebook:active {
      background-color: #1e2e4f;
      border-color: #1e2e4f; }
    .minoto-ui .btn.facebook.hollow {
      background-color: transparent;
      color: #3b5998; }
      @media (min-width: 960px) {
        .minoto-ui .btn.facebook.hollow:hover, .minoto-ui .btn.facebook.hollow:active {
          background-color: #2d4373;
          color: #fff; } }
      .minoto-ui .btn.facebook.hollow:active {
        background-color: #1e2e4f;
        color: #fff; }
  .minoto-ui .btn.google {
    background-color: #d44638;
    border-color: #d44638; }
    @media (min-width: 960px) {
      .minoto-ui .btn.google:hover, .minoto-ui .btn.google:active {
        background-color: #b23327;
        border-color: #b23327; } }
    .minoto-ui .btn.google:active {
      background-color: #89271d;
      border-color: #89271d; }
    .minoto-ui .btn.google.hollow {
      background-color: transparent;
      color: #d44638; }
      @media (min-width: 960px) {
        .minoto-ui .btn.google.hollow:hover, .minoto-ui .btn.google.hollow:active {
          background-color: #b23327;
          color: #fff; } }
      .minoto-ui .btn.google.hollow:active {
        background-color: #89271d;
        color: #fff; }
  .minoto-ui .btn.block {
    width: 100%;
    display: block; }
  .minoto-ui .btn.uppercase {
    text-transform: uppercase; }
  .minoto-ui .btn.big {
    font-size: 1.4rem; }
  .minoto-ui .btn.wide {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .minoto-ui .btn.low {
    padding-top: .8rem;
    padding-bottom: .8rem; }
  .minoto-ui .btn.small {
    padding-top: .4rem;
    padding-bottom: .4rem;
    font-size: 1.2rem; }
  .minoto-ui .btn.light {
    font-weight: 400; }
  .minoto-ui .btn.small-icon i {
    font-size: .9rem; }
  .minoto-ui .btn.has-info {
    padding-right: 2.6rem;
    padding-left: 2.6rem; }
  .minoto-ui .btn:disabled {
    opacity: 0.5; }
  .minoto-ui .btn.status-loading:disabled {
    opacity: 1; }
  .minoto-ui .btn.show-status .btn-content {
    transform: scale(0.5);
    opacity: 0;
    transition: opacity 200ms ease, transform 200ms ease; }
  .minoto-ui .btn.show-status .btn-statuswrap {
    opacity: .8;
    transform: translate3d(-50%, -50%, 0) scale(1);
    transition: opacity 200ms ease 180ms, transform 200ms ease 180ms; }
  .minoto-ui .btn .btn-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.8rem;
    margin: -.3em 0 -.1em; }
    .minoto-ui .btn .btn-icon.pre {
      margin-right: 1rem; }
    .minoto-ui .btn .btn-icon.post {
      margin-left: 1rem; }
  .minoto-ui .btn .btn-content {
    display: block;
    width: 100%;
    transition: opacity 200ms ease 180ms, transform 200ms ease 180ms; }
  .minoto-ui .btn .btn-statuswrap {
    opacity: 0;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(0.5);
    transition: opacity 200ms ease, transform 200ms ease; }
  .minoto-ui .btn .btn-loader {
    animation: spin 3s infinite linear;
    font-size: 2.4rem;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    top: 50%;
    left: 50%; }
  .minoto-ui .btn .btn-status {
    font-size: 2rem; }
  .minoto-ui .btn .btn-info {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    font-size: 1.4rem;
    height: 1.4rem;
    line-height: 1.4rem;
    opacity: .5;
    transition: opacity 200ms ease; }
    .minoto-ui .btn .btn-info:hover {
      opacity: 1; }
  .minoto-ui .btn__cancel-circle {
    position: absolute;
    z-index: 50;
    top: 1.4rem;
    right: 1.4rem;
    color: #fff;
    background-color: #161d4c;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    font-size: .8rem;
    transition: color 200ms ease, background-color 200ms ease;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 960px) {
      .minoto-ui .btn__cancel-circle {
        font-size: 1.5rem; } }
    .minoto-ui .btn__cancel-circle:hover, .minoto-ui .btn__cancel-circle:active {
      color: #161d4c;
      background-color: #f7f7fa; }

.minoto-ui .ncap-stars {
  color: #e3e4e2;
  font-size: 1.8rem;
  display: inline-block; }
  .minoto-ui .ncap-stars i.active {
    color: #ffcb00; }
  .minoto-ui .ncap-stars i + i {
    margin-left: .5rem; }

.minoto-ui .ncap-info {
  margin-left: .6rem; }

.minoto-ui .compare-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }
  .minoto-ui .compare-table tr:nth-child(odd) .table-datafield {
    background-color: rgba(247, 247, 250, 0.7); }
  .minoto-ui .compare-table tr:nth-child(even) .table-datafield {
    background-color: rgba(234, 235, 243, 0.7); }
  .minoto-ui .compare-table td, .minoto-ui .compare-table th {
    padding: 1rem 2rem;
    line-height: 2rem;
    font-size: 1.4rem;
    border: 0.1rem solid #eaebf3; }
  .minoto-ui .compare-table th {
    font-weight: 500;
    text-align: left;
    color: #8688a2;
    width: 25%; }
    .minoto-ui .compare-table th.empty {
      border: none; }
  .minoto-ui .compare-table td {
    width: 25%; }
    .minoto-ui .compare-table td.picker {
      background: none;
      padding: 3.2rem 2.3rem; }

/* Style The Dropdown Button */
.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer; }

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block; }

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #111111;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1; }

/* Links inside the dropdown */
.dropdown-content a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1; }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block; }

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41; }
