.section {
	&.subscriptionbar {
		background-color: $color-gray-light;
		text-align: center;
		padding: 4.2rem 0;

		@include media {
			padding: 4.2rem 2rem;
		}

		.subscriptionbar-form {
			display: inline-block;
			text-align: left;

			@include media {
				text-align: center;
			}
		}

			.form-title {
				@include font-medium;
				font-size: 2.2rem;
				display: inline-block;
				vertical-align: middle;

				@include media {
					display: block;
					margin-bottom: 2rem;
				}
			}

			.form-input {
				display: inline-block;
				vertical-align: middle;
				margin-left: 2.3rem;
				width: 24rem;

				@include media {
					width: calc(100% - 6rem);
					display: block;
					margin: 0 auto 2rem;
				}

				&.error {
					input {
						border-bottom-color: $color-error;
						color: $color-error;
						@include placeholder() {
							color: rgba($color-error, .5);
						}
					}
				}

				input {
					background-color: transparent;
					border-radius: 0;
					border: none;
					border-bottom: .1rem solid rgba($color-text, .5);
					font-size: 1.4rem;
					line-height: 2.2rem;
					padding: .7rem 0;
				}

				.input-error {
					display: none;
				}

				.input-label {
					background: transparent;
					padding: 0 0 .6rem;
					left: 0;
					color: $color-text-light;
				}
			}

			.form-submit {
				margin-left: 4rem;
				line-height: 1.8rem;
				width: 10rem;

				@include media {
					margin-left: 0;
				}
			}
	}
}