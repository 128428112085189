.table {
	border: none;
	border-collapse: collapse;
	font-size: 1.4rem;
	width: 100%;

	td, th {
		@include font-medium;
		border: none;
		padding: 1rem 2rem;
		line-height: 2rem;
		text-align: left;
	}
	th{
		width: 30rem;
		@include media{
			width: 50%;
		}
	}

	tr{
		&:nth-child(odd){
			td, th {
				background-color: rgba($color-gray-light, .7);
			}
		}
		&:nth-child(even){
			td, th {
				background-color: rgba($color-ice, .7);
			}
		}
	}
}
