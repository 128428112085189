.loader-container {
	position: relative;
	min-height: 30rem;
	z-index: 1;

	> .loader {
		position: absolute;
	}
}

.loader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba($color-white, .9);
	z-index: 999;
	opacity: 0;
	transition: opacity 400ms ease;

	&.inner {
		position: absolute;
	}

	&.strict {
		background-color: $color-white;
	}

	&.oversize {
		.loader-spinnerwrap {
			height: 50rem;
		}
	}

	.loader-spinnerwrap {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	i {
		@include spin;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $color-primary;
		font-size: 3rem;
	}

	/*&.in-page{
		z-index: 9;
		background-color: $color-ice;

		i{
			display: none;
			color: $color-gray;
		}
	}*/

	&.show {
		opacity: 1;
	}
}