@include font-face('Barlow', '../fonts/subset-Barlow-ExtraLight', $weight-extralight);
@include font-face('Barlow', '../fonts/subset-Barlow-Light', $weight-light);
@include font-face('Barlow', '../fonts/subset-Barlow-Regular', $weight-regular);
@include font-face('Barlow', '../fonts/subset-Barlow-Medium', $weight-medium);
@include font-face('Barlow', '../fonts/subset-Barlow-MediumItalic', $weight-medium, italic);
@include font-face('Barlow', '../fonts/subset-Barlow-SemiBold', $weight-semibold);
@include font-face('Barlow', '../fonts/subset-Barlow-Bold', $weight-bold);

@mixin font-main{
	font-family: 'Barlow', Arial, Helvetica, sans-serif;
}

@mixin font-extralight{ font-weight: $weight-extralight; }
@mixin font-light{ font-weight: $weight-light; }
@mixin font-regular{ font-weight: $weight-regular; }
@mixin font-medium{ font-weight: $weight-medium; }
@mixin font-semibold{ font-weight: $weight-semibold; }
@mixin font-bold{ font-weight: $weight-bold; }

html{
	position: relative;
	font-size: 10px;
	-ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
	//background-color: $color-black;
}

body{
	@include font-main;
	@include font-medium;
	font-size: 1.4rem;
	opacity: 1;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	//background-color: $color-white;


}

/*.site-content{
	&.loading{
		&:before{
			@extend %pseudo;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 999;
			background-color: $color-white;
		}
	}
}*/

@include media(1600) {
	html{
		//font-size: .625vw;
		@include calcFontSize(1600);
	}
}

@include media() {
	html{
		font-size: 10px;
	}

	body{
		font-size: 1.4rem;
	}
}

@include media(750) {
	html{
		@include calcFontSize(375);
		//@include calcFontSize(750);
	}
}

@include mediaLandscape(750, 750) {
	html{
		@include calcFontSize(375, true);
	}
}

.router-wrap {
	min-height: calc(100vh - 7rem);

	@include media {
		min-height: calc(100vh - 5rem);
		padding-top: 4em;
	}
}

.text-minoto{
	color: $color-blue;
}

.seoElement{
	opacity: 0;
	width: 0;
	height:0;
}

.cursor-pointer{
	cursor: pointer !important;
}
.table tr:nth-child(even) td, .table tr:nth-child(even) th {
	background-color: transparent!important;
}
