.section {
	&.blog-head {
		background-color: $color-primary;
		height: 21.5rem;

		@include media {
			height: 7rem;
		}

		.head-wrap {
			padding: 5.9rem 0 0;
			position: relative;

			@include media {
				padding: 0;
			}
		}

		.head-title {
			@include font-light;
			color: $color-text-negative;
			font-size: 3.2rem;
			line-height: 3.8rem;
			position: relative;
			z-index: 2;

			@include media {
				font-size: 1.2rem;
				padding-left: 3.5rem;
				line-height: 2.8rem;
			}

			strong {
				@include font-bold;
			}
		}

		.head-bg {
			position: absolute;
			top: 0;
			height: 21.5rem;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			z-index: 1;

			@include media {
				height: 7rem;
			}
		}

		.head-controls {
			position: relative;
			z-index: 1;
			margin-top: 6.9rem;
			background: $color-white;
			padding: 2.7rem 3.4rem;
			box-shadow: $shadow-general;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include media {
				margin-top: 2.7rem;
				display: block;
				padding: 0;
				box-shadow: none;
			}
		}

			.controls-nav {
				@include font-semibold;
				font-size: 1.6rem;
				text-transform: uppercase;
				display: flex;

				@include media {
					font-size: 1rem;
					display: block;
					text-align: center;
					box-shadow: $shadow-shallow;
					padding: .5rem;
				}
			}

				.nav-item {

					@include media {
						padding: 0 1.5rem;
						line-height: 2.5rem;
						display: inline-block;
					}

					+ .nav-item {
						@include mediaMin {
							padding-left: 2rem;
							margin-left: 2rem;
							border-left: .2rem solid rgba($color-text, .25);
						}
					}
				}

			.controls-search {
				width: 25rem;
				position: relative;

				@include media {
					width: 100%;
					margin-top: 1.5rem;
				}

				.inputwrap {
					input[type="text"]{
						@include media {
							padding: .8rem .9rem;
							line-height: 1.7rem;
						}
					}
				}
			}

				.search-submit {
					position: absolute;
					right:  1.3rem;
					font-size: 1.4rem;
					color: rgba($color-text, .3);
					z-index: 2;
					top: 50%;
					transform: translate3d(0, -50%, 0);
				}
	}

	&.blog-results {
		margin-top: 9.5rem;

		@include media {
			margin-top: 10rem;
			padding-bottom: 2.25rem;
		}

		.results-error {
			color: $color-error;
			text-align: center;
		}

		.results-list {
			margin: 0 -1.5rem;
			display: flex;
			flex-flow: row wrap;

			@include media {
				margin: .25rem -.75rem 0;
			}
		}

			.results-item {
				padding: 1.5rem;

				width: 33.333333%;

				@include media {
					padding: .75rem;
					width: 50%;
				}
			}
	}

	&.blog-detail {
		iframe{
			@include media{
				max-width: 100%;
			}
		}
		padding-bottom: 10rem;
		.detail-wrap {
			display: flex;
			flex-flow: row wrap;
			padding-right: 20rem;
			align-items: flex-start;

			@include media {
				padding-right: 0;
				//padding: 0 1.5rem;
			}
		}

		.detail-meta {
			width: 100%;
			text-align: right;
			font-size: 1.2rem;
			margin-bottom: 2.8rem;

			@include media {
				padding-top: .8rem;
				margin-bottom: 1.4rem;
				float: right;
			}
		}

		.detail-content {
			width: calc(100% - 20rem);

			@include media {
				width: 100%;
			}
		}


			.content-text {
				font-size: 2.2rem;
				line-height: 1.86em;

				@include media {
					font-size: 1.4rem;
					line-height: 2rem;
					padding-left: 1.5rem;
					padding-right: 1.5rem;
				}

				h2, h3, h4, h5, h6 {
					@include font-semibold;
					font-size: 1em;
				}
			}

				.text-title {
					@include font-medium;
					max-width: 57rem;
					font-size: 4.2rem;
					line-height: 1.24em;
					color: $color-primary;

					@include media {
						font-size: 2.2rem;
						line-height: 2.4rem;
						margin-bottom: 1.8rem;
					}
				}

			.content-gallery {
				background-color: $color-gray-light;
				width: calc(100% + 10rem);
				margin: 4rem -5rem 0;
				position: relative;
				padding-bottom: 70%;

				@include media {
					padding-bottom: 75%;
					width: calc(100% + 3rem);
					margin-left: -1.5rem;
					margin-right: -1.5rem;
				}

				.gallery-nav {
					position: absolute;
					top: 50%;
					transform: translate3d(0, -50%, 0);
					width: 4.8rem;
					height: 4.8rem;
					line-height: 4.8rem;
					font-size: 3rem;
					z-index: 2;

					&.prev {
						left: 0;
					}

					&.next {
						right: 0;
					}

					@include media {
						color: $color-text-negative;
					}
				}

				.gallery-slider {
					position: absolute;
					top: 0;
					left: 10.5rem;
					right: 10.5rem;
					bottom: 0;
					z-index: 1;

					@include media {
						left: 0;
						right: 0;
					}
				}

					.slider-imagewrap {
						width: 100%;
						height: 100%;
					}

						.imagewrap-image {
							position: relative;
							width: 100%;
							height: 100%;
							background-size: contain;
							background-repeat: no-repeat;
							background-position: center;
							opacity: 1;
							transition: opacity $transition-hover ease;

							@include media {
								background-size: cover;
							}


							&.swiper-lazy-loaded {
								opacity: 1;
								+ .imagewrap-loader {
									opacity: 0;
								}
							}
						}

						.imagewrap-loader {
							position: absolute;
							top: 50%;
							left: 50%;
							width: 15rem;
							height: 15rem;
							border-radius: 50%;
							overflow: hidden;
							transform: translate3d(-50%, -50%, 0);
							transition: opacity $transition-hover ease;

							.imagewrap-image {
								background-size: cover;
							}
						}
			}

		.detail-sharer {
			width: 20rem;
			padding-right: 15.8rem;
		}

			.sharer-title {
				@include font-semibold;
				text-transform: uppercase;
				font-size: 1rem;
				margin-bottom: 1.6rem;
				display: block;
			}



				.opts-item {
					width: 100%;

					+ .opts-item {
						margin-top: 2rem;
					}

					img {
						width: 100%;
					}
				}

			.sharer-link {
				&.icon {
					width: 4.2rem;
					height: 4.2rem;
					display: block;
					color: $color-text-negative;
					background-color: $color-text;
					border-radius: 50%;
					line-height: 4.2rem;
					text-align: center;
					font-size: 2.2rem;
				}
			}

		.detail-relevant {
			margin-top: 10.5rem;

			@include media {
				margin-top: 2rem;
			}
		}

			.relevant-title {
				@include font-medium;
				color: $color-text-light;
				display: block;
				text-align: center;
				font-size: 2.2rem;
				padding-bottom: 1.3rem;
				margin: 0 auto;
				border-bottom: .1rem solid $color-gray-light;

				@include media {
					font-size: 1.4rem;
					padding-bottom: .6rem;
					line-height: 2.8rem;
					width: calc(100% - 3rem);
				}
			}

			.relevant-items {
				margin: 1.25rem -.75rem 0;
				display: flex;
				flex-flow: row wrap;
			}

			.relevant-item {
				width: 20%;
				padding: 1.5rem;

				@include media {
					width: 50%;
					padding: .75rem;
				}
			}
	}
}
