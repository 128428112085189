.section {
	&.brand-detail {
		display: flex;

		@include media {
			display: block;
		}

		.detail-info {
			width: 28rem;

			@include media {
				width: auto;
			}
		}

			.info-sum {
				position: relative;
				background-color: $color-gray-light;
				padding: 3rem 1rem 3rem;
				font-size: 1.4rem;
				line-height: 1.7rem;
				text-align: center;
				margin-bottom: 3rem;

				.sum-favbtn {
					position: absolute;
					top: 2rem;
					right: 2rem;
				}

				.sum-logo {
					width: 12rem;
					height: 12rem;
					display: inline-block;
					background-color: $color-white;
					border-radius: 50%;
					overflow: hidden;
					margin-bottom: 1.1rem;
				}

				.sum-title {
					@include font-semibold;
					font-size: 1.8rem;
					line-height: 2.2rem;
				}

				.sum-numbers {
					text-decoration: underline;
					margin-top: 1.3rem;
					//padding-bottom: 1.4rem;
					//border-bottom: .1rem solid $color-ice;
				}

					.numbers-item + .numbers-item {
						margin-left: 2.8rem;
					}

				.sum-pricelist {
					margin-top: 1.7rem;
				}
			}

			.info-search {
				position: relative;
				padding: 0 1.1rem 0 2rem;
				margin: 3rem 0 1.9rem;
			}

				.search-submit {
					width: 3.8rem;
					position: absolute;
					right: 1.9rem;
					top: 50%;
					font-size: 1.4rem;
					color: rgba($color-text, .3);
					transform: translate3d(0, -50%, 0);
				}

			.info-dealers {
				padding: 1.4rem 0 1.4rem 1rem;

				@include media {
					padding: 1.4rem 0;
				}
			}

				.dealers-title {
					@include font-semibold;
					font-size: 1.2rem;
					color: rgba($color-text, .5);
					text-transform: uppercase;
					line-height: 1.4rem;
					padding: .8rem 0 .8rem 2.4rem;
				}

				.dealers-list {

					.list-branch {
						margin:0 1rem;
						padding:1rem 1.5rem;
						border-top: .1rem solid $color-gray;

						&:last-child {
							border-bottom: .1rem solid $color-gray;
						}

						&.open {
							background-color: $color-gray-light;
							transition: background-color $transition-hover ease;
						}
					}

						.branch-sum {
							position: relative;
							text-align: left;
							display: block;
							width: 100%;
							padding: 1.3rem 2rem 1.1rem 2.4rem;

							&:before {
								@include icon('angle-down');
								position: absolute;
								right: 1.6rem;
								color: $color-gray;
								font-size: .9rem;
								top: 2.3rem;
								line-height: 1.4rem;
							}
						}

						.branch-title {
							color: rgba($color-text, .7);
							text-transform: uppercase;
							font-size: 1.6rem;
							line-height: 2rem;
						}

						.branch-workinghours {
							display: block;
							font-size: 1.2rem;
							line-height: 1.4rem;
							text-transform: uppercase;
							margin-top: .3rem;
							color: $color-error;

							span {
								margin: 0 1rem;
							}

							&.open {
								color: $color-success;
							}
						}

						.branch-details .collapse-innerwrap {
							padding: 1.2rem 1.3rem 2rem 2.4rem;
						}

							.details-showonmap {
								font-size: 1.4rem;
								display: inline-block;
								margin-bottom: 1.3rem;
								line-height: 2rem;
								color: $color-primary;
								text-decoration: underline;
							}

							.details-controls {
								font-size: 0;
								letter-spacing: 0;

								.btn {
									width: calc(50% - .6rem);
									font-size: 1.1rem;
									padding: .9rem 0;

									&:nth-child(2n+2){
										margin-left: 1.2rem;
									}

									.btn-icon {
										font-size: 1.3rem;

										&.pre {
											margin-right: .5rem;
										}
									}
								}
							}
				}

		.detail-right {
			width: calc(100% - 28rem);
			.breadcrumbs{
				padding: 1.8rem 0;
			}
			@include media {
				width: auto;
			}
		}

		.brand-cover {
			height: 37.2rem;
			width: 100%;
			background-color: $color-ice;

			.loaderwrap-icon{
				top: auto;
				left: auto;
				right: -1rem;
				bottom: -1rem;
				font-size: 30rem;
				transform: none;
			}
		}

		.brand-listing {
			border-left: .1rem solid $color-gray-light;

			@include media {
				border-left: none;
			}
		}
	}
}
