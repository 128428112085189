.section {
	&.listing-title{
		max-width: $wrapper-regular;
		margin: 0 auto;
		display: flex;
		position: relative;
		z-index: 1;
		justify-content: space-between;
		h1{
			padding: 2.1rem 2.5rem 2.1rem;
			@include font-light;
			font-size: 2.5rem;
			@include media {
				font-size: 2rem;
				padding: 2.1rem 2.5rem 2.1rem 1rem;
			}
		}
		@include media {
			z-index: 3;
		}
	}
	&.listing {
		min-height: 40rem;
		max-width: $wrapper-regular;
		margin: 0 auto;
		display: flex;
		position: relative;
		z-index: 1;
		justify-content: space-between;

		@include media {
			z-index: 3;
		}

		&.has-filters {
			.listing-content {
				width: calc(100% - 28rem);
				z-index: 4;
				@include media {
					width: 100%;
				}
			}

			.listing-filters {
				width: 28rem;
				border-right: .1rem solid $color-gray-light;
				z-index: 5;
				@include media {
					width: 100%;
				}
			}
		}


		&.has-filters-brand {
			.listing-content {
				width: 100%;
				z-index: 4;
				@include media {
					width: 100%;
				}
			}

			.listing-filters {
				width: 28rem;
				border-right: .1rem solid $color-gray-light;
				z-index: 5;
				@include media {
					width: 100%;
				}
			}
		}


		&.size-5 {
			.results-item{
				width: 20%;

				&.x2 {
					width: 40%;
				}

				&.x3 {
					width: 60%;
				}

				&.x4 {
					width: 80%;
				}

				&.x5 {
					width: 100%;
				}

				> * {
					flex: 1;
					width: 100%;
				}

				@include media {
					width: 50%;

					&.banner {

						align-items: flex-start;

						&.x2 {
							width: 100%;
						}

						.banner-image {
							position: static;
						}
					}
				}
			}
		}

		&.size-4 {
			.results-item {
				width: 25%;
				min-height: 34rem;
				display: flex;
				//flex-direction: column;

				&.x2 {
					width: 50%;
				}

				&.x3 {
					width: 75%;
				}

				&.x4 {
					width: 100%;
				}

				@include media {
					width: 50%;
					min-height: auto;

					&.banner {

						align-items: flex-start;

						&.x2 {
							width: 100%;
						}

						.banner-image {
							position: static;
						}
					}
				}
			}
		}

		.listing-content {
			padding: 1rem 1.5rem;
			width: 100%;

			@include media {
				padding: 0;
			}
		}

			.content-top {
				position: relative;
				z-index: 2;
				padding: 1rem 1.5rem .5rem;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;

				@include media {
					padding: 1rem 1.5rem 1rem;
					border-bottom: .1rem solid $color-ice;
				}
			}

				.top-filterstrigger {
					@include font-medium;
					font-size: 1.4rem;

					i {
						margin-right: 1.3rem;
						font-size: 1.5rem;
					}
				}

				.top-activefilters {
					margin-top: -.2rem;
				}

					.activefilters-item {
						@include font-medium;
						position: relative;
						font-size: 1.2rem;
						border: .1rem solid $color-gray-light;
						line-height: 1.5rem;
						//height: 3rem;
						border-radius: 1.5rem;
						display: inline-block;
						color: rgba($color-text, .5);
						padding: .4rem 3.2rem .4rem 1.6rem;
						margin: .3rem 1rem .3rem 0;
						//margin-right: 1rem;
						transition: background-color $transition-hover ease, color $transition-hover ease;

						&:hover {
							background-color: rgba($color-gray-light, .5);
							color: $color-text;
						}
					}

						.item-title {
							@include font-bold;
							display: block;
							font-size: 1rem;
						}

						.item-value {
							display: block;
						}

						.item-remove {
							position: absolute;
							right: .9rem;
							top: 50%;
							font-size: 1rem;
							display: inline-block;
							vertical-align: middle;
							transform: translate3d(0, -50%, 0);
						}

				.top-order {
					display: inline-block;
					width: 23rem;
					margin-left: auto;

					@include media {
						width: calc(100% - 11rem);
					}

					.minoto-select{
						.minoto-select__control {
							border: none;
							height: 3rem;
							min-height: auto;
							color: $color-primary;
						}

						.minoto-select__value-container{
						 	padding: 0 .9rem 0 0;
						 	height: 100%;
						}

						.minoto-select__indicator {
							padding: 0;
							width: 1rem;
							svg path {
								fill: $color-primary;
							}

							@include media {
								width: 1.5rem;

								svg path {
									fill: $color-text;
								}
							}
						}

						.minoto-select__placeholder, .minoto-select__single-value {
							right: 0;
							margin-right: .9rem;
							font-size: 1.2rem;
							text-transform: uppercase;
							color: $color-primary;
							overflow: visible;

							@include media {
								@include font-medium;
								font-size: 1.4rem;
								color: $color-text;
							}
						}

						.minoto-select__menu-list{
							border-top: none;
						}

						.minoto-select__option{
							border-bottom: .1rem solid $color-ice;
							padding: 1.8rem 2rem;
							color: $color-primary;
							font-size: 1.4rem;

							@include media {
								padding: 1.2rem 2rem;
								color: $color-text;
							}
						}
					}
				}

			.content-results {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				padding: 0 0 1.5rem;
				position: relative;
				z-index: 1;

				@include media {
					padding: .75rem .75rem 2rem;
				}
			}

				.results-error {
					padding: 6rem;
					text-align: center;

					.error-message {
						display: inline-block;
						background-color: $color-gray-light;
						border: .1rem solid $color-ice;
						padding: 4.5rem 1rem;
						font-size: 1.8rem;
						max-width: 100%;
						width: 58rem;
					}
				}

				.results-item {
					position: relative;
					padding: 1.5rem;
					display: flex;
					//flex-direction: column;

					@include media {
						padding: .75rem;
					}

					.contentbox {
						width: 100%;
						max-width: 100%;
					}

				}

				.banner{
					height:380px;
					padding-top:2%;
					@include media{
						padding-top:40px;
					}
				}

					.item-banner {
						position: relative;
						flex: 1;
						min-height: 32rem;
						width: 100%;

						@include media {
							flex: none;
							min-height: auto;
						}
					}

						.banner-image {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background-size:100% 100%;

						}

						.banner-instagram {
							position: relative;
							display: block;
							width: 100%;
							height:100%;
							background-image:url("/assets/images/Instagram.jpg");
							background-position:center center;
							background-size: cover;

							.instagram-text {
								@include font-light;
								position: absolute;
								left: 2.5rem;
								top: 2.5rem;
								z-index: 2;
								color: $color-white;
								line-height: 1.3em;
								font-size: 2.4rem;
								@include media {
									left: 2rem;
									top: 2rem;
									line-height: 1.2em;
									font-size: 1.7rem;
								}
							}
						}

						.banner-blog {
							position: relative;
							display: block;
							width: 100%;
							height:100%;
							background-image:url("/assets/images/Blog.jpg");
							background-position:center center;
							background-size: cover;

							.blog-text {
								@include font-light;
								position: absolute;
								left: 2.5rem;
								top: 2.5rem;
								z-index: 2;
								color: $color-white;
								line-height: 1.3em;
								font-size: 2.4rem;
								@include media {
									left: 2rem;
									top: 2rem;
									line-height: 1.2em;
									font-size: 1.7rem;
								}
							}

						}

						.electric-img{
							position: relative;
							display: block;
							width:100%;
							height:100%;
							cursor:pointer;
							background-image:url("/assets/images/ElectricCar.jpg");
							background-position:center center;
							background-size: cover;

							.electric-text {
								@include font-light;
								position: absolute;
								left: 2.5rem;
								top: 2.5rem;
								z-index: 2;
								color: $color-white;
								line-height: 1.3em;
								font-size: 2.4rem;
								@include media {
									left: 1.2rem;
									top: 2rem;
									line-height: 1.2em;
									font-size: 1.7rem;
								}
							}
						}

						.banner-youtube {
							width: 100%;
							background-color: $color-text;
							color: $color-white;

							@include media{
								padding:1.2rem 2.5rem;
							}

							.youtube-text {
								@include font-light;
								font-size: 1.6rem;

								> * + * {
									margin-top: 1rem;
								}
							}

								.text-heading {
									@include font-light;
									position: relative;
									padding-left: 1.4rem;
									font-size: 2.4rem;
									line-height: 1.3em;

									@include media{
										font-size:1.6rem;
									}

									strong {
										@include font-semibold;
									}

									&:before {
										@extend %pseudo;
										position: absolute;
										left: 0;
										top: .6rem;
										bottom: .6rem;
										width: .5rem;
										background-color: $color-red;
									}
								}

								.text-subheading {
									color: rgba($color-white, .7);
									@include media{
										font-size:1.2rem;
									}
								}

							.youtube-image {
								display: block;
								width: 100%;
								height:100%;
								object-fit:cover;
								@include media{
									margin-top: 1.3rem;
								}
							}
						}

						.banner-tag {
							width: 100%;
							padding: 2.5rem;
							background-color: #f7f7fa;
							color: #161D4CFF;
							border:0.1rem solid #eaebf3;

							.tags-wrapper{
								display:flex;
								flex-wrap:wrap;
								gap:1.6rem 2rem;
								.tag-wrapper{
									background-color: #f7f7fa;
									border:0.1rem solid #a2a3a8;
									padding:.6rem .8rem;
									border-radius:2rem;
									@include media{
										font-size:11px;
									}
								}
								@include media{
									gap:.8rem 1rem;
								}
							}

							@include media{
								padding:1.2rem 1.2rem;
							}
						}

						.banner-hr {
							background-color: $color-text;
							color: $color-white;
							text-align: center;
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 2.5rem;

							@include media{
								padding:1.2rem 2.5rem;
							}

							.hr-content {
								@include font-light;
								line-height: 1.3em;
								overflow: hidden;
								z-index: 0;

								.inner-triangle{
									border-left: 25rem solid transparent;
									border-right: 29rem solid #092C7F;
									border-top: 18rem solid transparent;
									height: 0;
									width: 0;
									position: absolute;
									bottom: 1.5rem;
									right: 1.5rem;
									z-index: 2;
									@include media {
										display: none;
									}
								}

							}

							.content-title {
								font-size: 2.4rem;
								z-index: 555;
								@include media{
									font-size:1.5rem;
								}
							}

							.content-description {
								font-size: 1.6rem;
								margin-top: 2rem;
								color: rgba($color-white, .7);
								@include media{
									font-size:1.2rem;
								}
								strong {
									color: $color-white;
								}
								z-index: 555;
							}

							.content-cta {
								margin-top: 6rem;
								min-width: 16rem;
								max-width: 100%;
								z-index: 555;
								@include media {
									margin-top:2rem;
									min-width: 10rem;
								}
							}
						}

						.banner-iframe {
							color: $color-white;
							text-align: center;
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 0;
							@include media {
								height: 20rem;
							}

							iframe{
								width: 100% !important;
								height: 100% !important;
							}
						}

					.results-ad {
						text-align: center;
						display: block;
					}

					.ad-link {
						display: inline-block;
						height: 9rem;
						flex: none;
						width: auto;

						img {
							height: 100%;
							display: block;
						}
					}

			.content-infinitescroll {
				opacity: 0;
				text-align: center;
				transition: opacity $transition-hover ease;

				&.loading {
					opacity: 1;
				}

				span {
					display: inline-block;
					position: relative;
					height: 3rem;
					margin-top: -1.5rem;

				}

				i {
					@include spin;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: $color-primary;
					font-size: 2rem;
				}
			}
	}

	&.listing-filters {
		//width: 28rem;
		//padding: 2.1rem 2.5rem;

		@include media {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			z-index: 1000;
			width: 100%;
			padding: 0;

			&.active {
				display: block;

				&.show {
					.filters-overlay {
						opacity: 1;
					}

					.filters-content {
						transform: translate3d(0, 0, 0);
						-webkit-overflow-scrolling: touch;
						*{
							-webkit-overflow-scrolling: touch;
						}
					}
				}
			}
		}

		&.first-child {
			.filters-innerwrap {
				//padding-top: 2.1rem;
				padding-top: 0;
			}

			.filters-header {
				border-top: none;
				padding-top: 0;
			}
		}

		.filters-content {
			@include media {
				position: relative;
				z-index: 2;
				background-color: $color-white;
				width: 27rem;
				padding: 0 0 0;
				height: 100%;
				transform: translate3d(-100%, 0, 0);
				transition: transform $transition-anim-fast ease;
			}
		}

		.filters-overlay {
			display: block;
			border: none;
			position: absolute;
			opacity: 0;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color-text, .5);
			z-index: 1;
			transition: opacity $transition-anim-fast ease;
			cursor: pointer;
		}

		.filters-innerwrap {
			padding: 0 2.5rem 2.1rem;
			height: 100%;
			overflow: auto;
			overflow-x: hidden;
			overflow-y: auto;

			@include media {
				margin-top: 3.2em;
				padding-bottom: 15rem;
			}
		}

		.filters-controls {
			background-color: $color-white;
			padding: 2.1rem 2.5rem 4rem;

			/*position: fixed;
			bottom: 0;
			width: 28rem;
			box-shadow: $shadow-general;*/

			@include media {
				position: absolute;
				bottom: 3em;
				left: 0;
				width: 100%;
				padding-bottom: 2.1rem;
			}
		}

			.controls-filteronthego {
				+ .controls-btn {
					margin-top: 2rem;
				}
			}

		.filters-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1.7rem 0 1.6rem;
			margin-bottom: 1.9rem;
			//border-top: .1rem solid $color-ice;
			border-bottom: .1rem solid $color-ice;

			@include media {
				border-top: none;
			}
		}

			.header-title {
				@include font-regular;
				font-size: 1.6rem;
				line-height: 1.9rem;
			}

			.header-clear {
				@include font-semibold;
				font-size: 1.2rem;
				text-decoration: underline;
				color: rgba($color-text, .5);
			}

		.filters-filter {
			&.expandable {
				.filter-content {
					display: none;
					opacity: 0;
					transform: translate3d(0, -.5rem, 0);
					transition: transform $transition-hover ease, opacity $transition-hover ease;
				}

				&.active {
					.filter-content {
						display: block;
					}

					&.show {
						.filter-title {
							border-bottom-color: transparent;
							&:after {
								transform: translate3d(0, -50%, 0) rotate(180deg);
							}
						}
						.filter-content {
							opacity: 1;
							transform: translate3d(0, 0, 0);
						}
					}
				}
			}

			&.type-list {
				.filter-list {
					background-color: $color-gray-light;

					.checkwrap {
						padding-top: 0;
						padding-bottom: 0;

						input{
							top: 0;

							+ label {
								padding: .2rem 0 .2rem 2.1rem;
								display: block;
								span {
									opacity: 1;
									top: 1.1rem;
									width: 1.4rem;
									height: 1.4rem;
								}
							}
						}
					}

				}


					.filter-item {
						padding: 1rem 1rem;

						+ .filter-item {
							border-top: .1rem solid $color-ice;
						}
					}

						.item-text {
							display: flex;
							justify-content: space-between;
							align-items: center;
						}



						.title-count {
							color: $color-text-light;
							font-size: 1.2rem;
							transition: color $transition-hover ease;
							margin-left: .3rem;
						}

						.item-logo {
							height: 2rem;
						}

					.checkwrap input:checked + label .title-count {
						color: $color-primary;
					}
			}

			&.type-tree {
				.filter-list {
					background-color: $color-gray-light;
				}

					.filter-item {
						&.level-2 {
							.item-wrap {
								padding-left: 2rem;
							}
						}

						&.level-3 {
							.item-wrap {
								padding-left: 3rem;
							}
						}

						&.level-4 {
							.item-wrap {
								padding-left: 4rem;
							}
						}
					}

						.item-wrap {
							padding: 0 0 0 1rem;
							min-height: 4.2rem;
							position: relative;
							display: flex;
							flex-flow: row wrap;
							align-items: center;
							width: 100%;
							text-align: left;
							border-top: .1rem solid $color-gray;

						/* 	&.expandable {

							} */

							.inputwrap {
								display: inline-block;
								max-width: calc(100% - 3rem);
							}

							.checkwrap {
								input{
									top: 0;

									+ label {
										padding: .2rem 0 .2rem 0.7rem;
										display: block;
										span {
											opacity: 0;
											top: 1rem;
											width: 1.4rem;
											height: 1.4rem;
										}
									}
								}
							}

							.item-expand {
								flex: 1;
								margin-left: .5rem;
								height: 4.1rem;
								position: relative;
								z-index: 2;
							}
						}

						.item-subgroup {
							.item-submenu {
								display: none;
							}

							> .item-wrap.expandable {
								&:after {
									@include icon('angle-right');
									position: absolute;
									font-size: .9rem;
									color: rgba($color-text, .3);
									right: .8rem;
									top: 50%;
									z-index: 1;
									transform: translate3d(0, -50%, 0);
								}
							}

							&.expanded {
								> .item-wrap {
									&:after {
										transform: translate3d(0, -50%, 0) rotate(90deg);
									}
								}
								> .item-submenu {
									display: block;
								}
							}
						}
			}

			&.type-brands {
				.filter-back {
					position: relative;
					background-color: $color-gray-light;
					/*padding: 0 1rem;
					min-height: 4.2rem;*/
				}

					.back-icon {
						position: absolute;
						left: .7rem;
						top: 50%;
						font-size: .8em;
						color: $color-text-light;
						transform: translate3d(0, -50%, 0);
					}

				.filter-list {
					background-color: $color-gray-light;
					&.showMore{
						overflow: hidden;
						position: relative;
					}
				}

					.filter-item {
						&.level-2 {
							.item-wrap {
								padding-left: 3rem;
							}
						}

						&.level-3 {
							.item-wrap {
								padding-left: 4rem;
							}
						}

						&.level-4 {
							.item-wrap {
								padding-left: 5rem;
							}
						}

						&.level-5 {
							.item-wrap {
								padding-left: 6rem;
							}
						}

						&.level-6 {
							.item-wrap {
								padding-left: 7rem;
							}
						}
					}

						.item-wrap {
							padding: 0 0 0 2rem;
							min-height: 4.2rem;
							position: relative;
							display: flex;
							flex-flow: row wrap;
							align-items: center;
							width: 100%;
							text-align: left;
							border-top: .1rem solid $color-gray;
							.showMoreBtn{
								cursor: pointer;
								color: $color-primary;
							}

							&.active {
								@include font-semibold;
							}

							/*.inputwrap {
								display: inline-block;
								max-width: calc(100% - 3rem);
							}*/

							.item-expand {
								flex: 1;
								margin-left: .5rem;
								height: 4.1rem;
								position: relative;
								z-index: 2;
							}

							.item-count {
								font-size: .8em;
								margin-left: .5rem;
								color: $color-text-light;
							}
						}

						.item-subgroup {
							.item-submenu {
								display: block;
							}
						}
			}

			&.type-icons {
				.filter-list {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
				}

				.filter-item {
					width: 50%;
					position: relative;
					text-align: center;
					font-size: 1.1rem;
					padding-bottom: .8rem;
					color: $color-text-light;

					&:nth-child(2n+2){
						padding-left: 1.5rem;

						&:last-child {
							padding-bottom: 0;
						}
					}

					&:nth-child(2n+1){
						border-right: .1rem solid $color-gray;
						padding-right: 1.5rem;

						&:last-child, &:nth-last-child(2) {
							padding-bottom: 0;
						}
					}



					input {
						position: absolute;
						left: 0;
						right: 0;
						width: 100%;
						height: 100%;
						opacity: 0;
						pointer-events: none;

						&:checked {
							+ .item-content{
								color: $color-primary;
								.content-icon {
									* {
										fill: $color-primary;
									}
								}
							}
						}
					}
				}

					.item-content {
						display: block;
						border-bottom: .1rem solid $color-gray;
						padding-bottom: .8rem;
						cursor: pointer;
						//transition: color $transition-hover ease;

						&:hover {
							@include mediaMin {
								color: $color-primary;
								.content-icon * {
									fill: $color-primary;
								}
							}

						}
					}

						.content-icon {
							display: inline-block;
							height: 1.8rem;
							margin-bottom: .8rem;

							* {
								fill: $color-text-light;
							}

							svg {
								height: 100%;
								width: auto;
							}
						}
			}

			&.type-range {
					.inputs-inputwrap {
						width: 7.8rem;
						font-size: 1.5rem;
						display: inline-block;
						position: relative;
						@include media{
							width: 10rem;
						}
						+ .inputs-inputwrap {
							margin-left: 2rem;

							&:before {
								color: $color-text-light;
								position: absolute;
								left: -1rem;
								top: 50%;
								content: '-';
								transform: translate3d(-50%, -50%, 0);
							}
						}
					}

					.inputs-input {
						display: inline-block;
						border-radius: $radius-general;
						border: .1rem solid $color-gray-light;
						width: 100%;
						line-height: 3.2rem;
						text-align: center;
					}

					.inputs-submit {
						@include media{
							width: 100%;
							margin-left: 0;
							margin-top: 1rem;
							margin-bottom: 3rem;
						}
						line-height: 3rem;
						padding: .2rem 1rem;
						margin-left: 1.2rem;
					}
			}

			&.type-text {
				font-size: 1.5rem;

				.filter-inputs {
					&.touched {
						.inputs-input {
							border-color: $color-primary;
						}
					}
				}

					.inputs-submit {
						position: absolute;
						right: 1.9rem;
						top: 50%;
						color: rgba($color-text, .3);
						font-size: 1.4rem;
						transform: translate3d(0, -50%, 0);
					}
			}
		}

			.filter-title {
				position: relative;
				display: block;
				font-size: 1.4rem;
				line-height: 3rem;
				padding: 1rem 0;
				width: 100%;
				text-transform: uppercase;
				text-align: left;
				border-bottom: .1rem solid $color-ice;
				transition: border-color $transition-hover ease;

				&:after {
					@extend %pseudo;
					@include icon('angle-down');
					position: absolute;
					right: 1rem;
					top: 50%;
					transform: translate3d(0, -50%, 0);
					font-size: .9rem;
					color: $color-text;
					opacity: .3;
				}
			}

			.filter-content{
				.filter-slidewrap {
					width: calc(100% + 1.2rem);
					margin-right: -1.2rem;
					padding-right: 1.2rem;
					//max-height: 34rem;
					min-height: 50px;
				}

			}


	}
	.content-results{
		@include media{
			.banner{
				&-hr{
					height: 100%;
				}
				&-youtube{
					height: 100%;
				}

				&-instagram{
					@media screen and (orientation:landscape) {
						height: 100%;
						.imagewrap-image{
							width: 100% !important;
							height: 100% !important;
						}
					}
				}
			}
		}
	}
}

.pagination-wrapper{
	display:flex;
	justify-content: center;
	padding-bottom:2rem;
	.pagination-inner-wrapper{
		display:flex;
		button {
			color: black;
			padding: 8px 16px;
			text-decoration: none;
			transition: background-color .3s;
			border: 1px solid #ddd;
			margin: 0 4px;
		}
		button.active {
			background-color: #145aff;
			color: white;
			border: 1px solid #145aff;
		}
		.disabled{
			background: #ddd;
			pointer-events: none;
		}
		.pagination {
			display:flex;
			button {
				color: black;
				padding: 8px 16px;
				text-decoration: none;
				transition: background-color .3s;
				border: 1px solid #ddd;
				margin: 0 4px;
			}
			button.active {
				background-color: #145aff;
				color: white;
				border: 1px solid #145aff;
			}
			span{
				padding: 8px 16px;
				text-align:end;
			}
		}
	}


}

.inputwrap.type-checkbox .checkwrap input + label:hover{
	color: $color-blue;
	text-decoration: underline;
}

.dealer-logo-wrapper{
	background-color:#f7f7fa;
	padding:1rem;
	height:6rem;
	display:flex;
	justify-content: center;
	position:relative;

	@include media{
		height:5rem;
	}

	img{
		height:100%;
	}
}

