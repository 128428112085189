.credit-calculator {
  background-color: $color-gray-light;
  border-radius: $radius-general;
  border: solid 1px #ebebf3;
  padding: 17px 19px;
  margin-top: 3.7rem;
  max-width: 100%;
  @include media {
    margin-top: 1.1rem;
  }


  h2 {
    font-size: 20px;
    color: #161d4c;
    font-weight: 600;
  }

  p {
    margin-top: 8px;
    line-height: 1.43;
    color: #161d4c;
    font-weight: 400;
  }

  .no-padding {
    padding-right: 0;
    padding-left: 0;
    @include media {
      padding-right: 1.1rem;
      padding-left: 1.1rem;
    }
  }

  .credit-price.currency-after {
    &:after {
      content: 'TL';
      position: absolute;
      right: 2rem;
      top: 1.9rem;
      opacity: 0.6;
    }
  }

  .credit-price.month-after {
    &:after {
      content: 'AY';
      position: absolute;
      right: 2rem;
      top: 1.9rem;
      opacity: 0.6;
    }
  }

  .credit-price > input {
    padding: 1.5rem 1.3rem;
  }

  .credit-results{
    background-color: $color-gray-light;
    border-radius: $radius-general;
    border: solid 1px #ebebf3;
    margin-top: 1.2rem;
    margin-bottom: 3.7rem;
    .tablePad{
      padding-right: 15px;
      padding-left: 15px;
      border-right: 1px solid #d5d5e3;
      border-left: 1px solid #d5d5e3;
    }
    th{
      min-width: 5.9em;
      background-color: #ebebf3;
      &:nth-child(1){
        width: 16rem;
        padding-right: 0.5rem;
      }
      &:nth-child(2){
        width: 8rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
      &:nth-child(3){
        padding-right: 0.5rem;
        padding-left: 1rem;
      }
      &:nth-child(4){
        width: 10rem;
        padding-right: 0.5rem;
        padding-left: 1rem;
      }
    }
    td{
      font-size: .9em;
      &:nth-child(1){
        min-width: 10rem;
        @include media{
          min-width: 11rem;
        }
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 0.5rem;
      }
      &:nth-child(2){
        padding-top: 20px;
        padding-bottom: 20px;
        width: 10rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
      &:nth-child(3){
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 0.5rem;
        padding-left: 1rem;
        width: 121px;
      }
      &:nth-child(4){
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 0.5rem;
        padding-left: 1rem;
      }

      button{
        padding: 8px 0px;
        span.btn-content{
          font-size: 1.8rem;
        }
        @include media{
          span.btn-content{
            font-size: 1.8rem;
          }
        }
      }
    }

  }

}
