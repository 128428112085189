.contentbox {
	position: relative;
	display: flex;
	flex: 1;

	&:hover {
		&:before{
			opacity: 1;
		}

		.contentbox-innerwrap {
			border-color: $color-primary;
		}
	}

	&:before {
		@extend %pseudo;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		box-shadow: 0 1.8rem 3.6rem 0 rgba(22, 29, 76, 0.3);
		opacity: 0;
		transition: opacity $transition-hover ease;
	}

	&.inactive {
		.contentbox-title {
			opacity: .5;
		}

		.info-price {
			color: $color-text;
			opacity: .5;
		}

		.contentbox-image {
			opacity: .6;
  			filter: grayscale(100%);
		}
	}

	.contentbox-innerwrap {
		width: 100%;
		position: relative;
		display: block;
		z-index: 2;
		background: $color-white;
		border: .1rem solid $color-ice;
		transition: border-color $transition-hover ease;
	}

	.contentbox-content {
		padding: 1.8rem 1rem 1.4rem;
		flex: 1;

		@include media {
			padding: .5rem 1rem .9rem;
		}
	}

		.contentbox-imagewrap {
			position: relative;
			width: 100%;
			//padding-bottom: 75.333333333%;
			padding-bottom: 75%;
		}

			.contentbox-image {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			.contentbox-badge {
				@include font-semibold;
				position: absolute;
				z-index: 3;
				left: 0;
				top: 1rem;
				background-color: $color-primary;
				color: $color-text-negative;
				line-height: 3rem;
				padding: 0 1.1rem;
				text-transform: uppercase;
				border-top-right-radius: $radius-general;
				border-bottom-right-radius: $radius-general;

				&.error {
					background-color: $color-red-light;
				}

				@include media {
					font-size: 1.4rem;
					line-height: 2.6rem;
				}
			}

			.contentbox-favicon {
				position: absolute;
				z-index: 2;
				color: $color-primary;
				font-size: 2rem;
				top: 1.5rem;
				right: 1.5rem;
				text-shadow: rgb(255, 255, 255) .1rem 0rem 0rem, rgb(255, 255, 255) .0540302rem .0841471rem 0rem, rgb(255, 255, 255) -.0416147rem .0909297rem 0rem, rgb(255, 255, 255) -.0989992rem .014112rem 0rem, rgb(255, 255, 255) -.0653644rem -.0756802rem 0rem, rgb(255, 255, 255) .0283662rem -.0958924rem 0rem, rgb(255, 255, 255) .096017rem -.0279415rem 0rem;
			}

		.contentbox-pretitle {
			font-size: 1.2rem;
			margin-bottom: 1.6rem;
			color:black!important;
		}

		.contentbox-title {
			@include font-semibold;
			font-size: 1.4rem;
			display: block;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			color:black!important;
			@include media {
				white-space: normal;
				line-height: 1.9rem;
				height: 3.8rem;
			}
		}

		.contentbox-info {
			//display: flex;
			margin-top: .8rem;

			@include media {
				display: block;
			}
		}

			.info-subtitle {
				@include font-semibold;
				font-size: 1.2rem;
				opacity: .5;
				padding-top: .3rem;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				color:black!important;

				@include media {
					font-size: 1.1rem;
					line-height: 1.3rem;
				}
			}

			.info-price {
				@include font-semibold;
				display: inline-block;
				font-size: 1.8rem;
				color: $color-primary;
				text-align: right;
				white-space: nowrap;
				margin-top: 1rem;
				flex-grow: 2;

				@include media {
					margin-top: .8rem;
					display: block;
					text-align: left;
				}
			}

		.contentbox-additions {
			margin-top: 1.3rem;
			min-height: 2rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include media {
				display: block;
				margin-top: 1rem;
			}
		}

			.additions-labels {
				span {
					display: inline-block;
					font-size: 1.2rem;
					line-height: 1.6rem;
					color: $color-text-light;
					border: .1rem solid $color-gray-light;
					margin-right: .6rem;
					border-radius: $radius-general;
					padding: 0 .6rem .2rem;
					text-transform: uppercase;
				}
			}

			.additions-bottomnote {
				font-size: 1.2rem;
				color: $color-text;

				&.success {
					color: $color-success;
				}

				@include media {
					display: none;
				}
			}

	&.type-plain {
		text-align: center;

		.contentbox-imagewrap {
			padding-bottom: 50%;

			@include media {
				margin-top: .6rem;
				padding-bottom: 65%;
				margin-bottom: .6rem;
			}

			.contentbox-image {
				top: 50%;
				left: 50%;
				width: 50%;
				height: 50%;
				transform: translate3d(-50%, -50%, 0);

				@include media {
					width: 75%;
					height: 80%;
				}
			}
		}

		.contentbox-content {
			padding: 0 2.2rem 1.5rem;

			@include media {
				padding: 0 0 1rem;
			}
		}

		.contentbox-subtitle {
			@include font-semibold;
			font-size: 1.2rem;
			color: rgba($color-text, .5);
			margin-top: .8rem;
		}

		.contentbox-additions {
			border-top: .1rem solid $color-gray-light;
			padding: 1.5rem 0 0;
			display: block;

			@include media {
				margin-top: .5rem;
			}
		}

			.additions-title {
				color: $color-primary;
				font-size: 1.3rem;

				+ .additions-labels {
					margin-top: .4rem;
				}
			}

			.additions-labels {
				span {
					font-size: 1.3rem;
					border: none;
					padding: 0;
					margin-right: 0;
					color: $color-text;
					text-transform: none;

					/* + span {
						&:before{
							content: ', ';
						}
					} */
				}
			}
	}

	&.type-blogpost {
		.contentbox-title {
			@include font-regular;
			font-size: 2.4rem;
			line-height: 3.2rem;
			white-space: normal;
			height: 6.4rem;

			@include media {
				font-size: 1.4rem;
				line-height: 1.5rem;
				height: 3.2rem;
				&.wrap{
					&:after{
						content: '...';
						text-align: right;
						position: absolute;
						right: 1rem;
						bottom: 1rem;
						width: 3rem;
						background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 99%);
						background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(70%,rgba(255,255,255,1)), color-stop(99%,rgba(255,255,255,1)));
						background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,1) 99%);
						background: -o-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,1) 99%);
						background: -ms-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,1) 99%);
						background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 58%,rgba(255,255,255,1) 99%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#fff',GradientType=1 );

					}
				}

			}

		}

		.contentbox-pretitle {
			margin-bottom: .6rem;
		}

		.contentbox-content {
			@include media {
				padding-top: .7rem;
			}
		}
	}
}

.store-logo{
	position:absolute;
	bottom:15px;
	right:15px;
}
