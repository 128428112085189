.modal-container.modal-options{

	.options-title {
		@include font-regular;
		font-size: 3.6rem;

		+ .options-question {
			margin-top: 1.6rem;
		}
	}

	.options-question {
		font-size: 1.6rem;
	}
	
	.options-opts {
		display: flex;
		justify-content: space-around;
		margin-top: 2.2rem;
	}

		.opts-item {
			flex: 1;

			+ .opts-item {
				margin-left: 2rem;
			}
		}
}