.section{
	&.reservation-layout {
		display: flex;
		justify-content: flex-end;

		@include media {
			flex-flow: row wrap;
		}

		.layout-content {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			padding-right: 7rem;
			padding-top: 4.3rem;
			padding-bottom: 7rem;

			@include media {
				width: 100%;
				order: 2;
				display: block;
				padding: 2.5rem 1.5rem 1.8rem;
			}

			.content-innerwrap {
				width: calc(86.5% - 7rem);
				max-width: 93.7rem;

				@include media {
					width: auto;
					max-width: none;
				}
			}

			.content-nav {
				margin-bottom: 6.6rem;
				display: flex;
				justify-content: flex-start;

				@include media {
					margin-bottom: 2.8rem;
					justify-content: space-between;
				}
			}

				.nav-item {
					width: 19rem;
					height: 8rem;
					display: flex;
					justify-content: center;
					align-items: center;
					border: .1rem solid $color-text-light;
					border-radius: $radius-general;
					color: $color-text-light;

					@include media {
						width: 10.5rem;
						height: 6.5rem;
						border-color: $color-ice;
					}

					+ .nav-item {
						margin-left: 4rem;

						@include media {
							margin-left: 0;
						}
					}

					&.active {
						border-color: $color-primary;
						color: $color-primary;

						&.sum {
							color: $color-success;
							border-color: $color-success;
						}
					}

					&.disabled {
						pointer-events: none;
					}
				}

					.item-icon {
						font-size: 3.5rem;
						text-align: center;

						@include media {
							font-size: 2.7rem;
						}
					}

					.item-text {
						@include font-light;
						width: 6.2rem;
						margin-left: 1.2rem;
						text-align: left;
						font-size: 1.6rem;
						line-height: 1.9rem;

						@include media {
							font-size: 1.4rem;
							line-height: 1.6rem;
							margin-left: .7rem;
							width: auto;
						}

						strong {
							@include font-semibold;
							display: block;
						}
					}
		}


		.layout-sidebar {
			width: 28.125%;
			background-color: rgba($color-gray-light, .8);

			@include media {
				width: 100%;
				order: 1;
			}
			
			.sidebar-content{
				max-width: 35rem;
				width: 77.777%;
				padding: 5rem 0 5rem 5rem;

				@include media {
					width: auto;
					max-width: none;
					padding: 1.5rem 1.5rem;
				}
			}

			.sidebar-advertinfo{
				margin-bottom: 3.1rem;

				@include media {
					margin-bottom: 0;
					display: flex;
					align-items: center;
				}
			}

				.advertinfo-imagewrap {
					position: relative;

					@include media {
						width: 13.8rem;
					}
				}

					.imagewrap-price {
						@include font-semibold;
						position: absolute;
						right: 0;
						bottom: 0;
						background-color: $color-primary;
						color: $color-white;
						line-height: 3rem;
						padding: 0 1.6rem;
						font-size: 1.5rem;

						@include media {
							font-size: 1.3rem;
							line-height: 2.4rem;
							padding: 0 .7rem;
						}
					}

				.advertinfo-content {
					@include media {
						flex: 1;
						padding-left: 1.5rem;
					}
				}

					.content-id {
						@include font-medium;
						font-size: 1.4rem;
						text-transform: uppercase;
						color: $color-text-light;
						margin-top: 1.8rem;
						line-height: 1.7rem;

						@include media {
							font-size: 1.1rem;
							margin-top: 0;
						}

						strong {
							font-size: 1.2rem;
							font-weight: inherit;

							@include media {
								font-size: inherit;
							}
						}
					}

					.content-title {
						@include font-medium;
						font-size: 1.8rem;
						line-height: 1.33em;
						margin-top: .8rem;
						padding-bottom: 1.3rem;
						border-bottom: .1rem solid $color-ice;

						@include media {
							font-size: 1.4rem;
							line-height: 1.6rem;
							border-bottom: none;
							margin-top: .5rem;
							padding-bottom: 0;
						}
					}

					.content-info {
						@include font-medium;
						color: $color-text-light;
						line-height: 2.4rem;
						padding: .9rem 0;
						border-bottom: .1rem solid $color-ice;

						@include media {
							line-height: 2.4rem;
							font-size: 1.2rem;
							padding: .2rem 0;
						}

						strong {
							@include font-regular;
							font-size: 1.4rem;

							@include media {
								font-size: inherit;
							}
						}

						span {
							color: $color-text;
						}

						&:last-child {
							@include media {
								padding-bottom: 0;
								border-bottom: none;
							}
						}

						&.price {
							span {
								@include font-semibold;
								color: $color-primary;
								font-size: 1.8rem;

								@include media {
									font-size: 1.6rem;
								}
							}
						}
					}

			.sidebar-agreement {
				.agreement-link {
					color: $color-primary;
					text-decoration: underline;
				}
				.checkwrap{
					margin-bottom: 3.3rem;

					@include media {
						margin-bottom: 2rem;
					}

					input{
						&:checked{
							+ label{
								color: inherit;
							}
						}

						+ label {
							@include font-regular;
							white-space: nowrap;
							overflow: visible;
							color: $color-text-light;
						}
					}
				}
			}

			.sidebar-controls {
				@include media {
					position: fixed;
					left: 0;
					right: 0;
					bottom: 0;
					padding: 1.5rem;
					z-index: 5;
					background-color: $color-white;
					box-shadow: $shadow-general;
				}
			}
		}
	}

	&.reservation-info {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.info-faq {
			margin-right: 12rem;
			flex: 1;
			padding-top: 1.4rem;

			@include media {
				margin: 0;
				padding-top: 0;
			}
		}

			.faq-item {
				@include font-regular;
				font-size: 1.4rem;
				color: fade($color-text, .7);
				line-height: 2.2rem;

				@include media {
					font-size: 1.3rem;
					line-height: 2.2rem;
				}

				+ .faq-item {
					margin-top: 5.4rem;

					@include media {
						margin-top: 2.3rem;
					}
				}
			}

				.item-title {
					@include font-medium;
					font-size: 2.4rem;
					line-height: 2.9rem;
					margin-bottom: 2.3rem;
					color: $color-text;

					@include media {
						font-size: 1.8rem;
						line-height: 2.2rem;
						margin-bottom: .6rem;
					}
				}

		.info-logo {
			width: 36.3rem;
		}	
	}

	&.reservation-payment {
		.payment-iyzico {
			display: flex;
			align-items: flex-start;
			margin-bottom: 4.7rem;

			@include media {
				margin-bottom: 3.2rem;
			}
		}

			.iyzico-icon {
				color: $color-success;
				font-size: 5rem;
				margin-right: 1.8rem;

				@include media {
					font-size: 4.1rem;
					margin-right: 1.3rem;
				}
			}

			.iyzico-title {
				font-size: 2rem;
				line-height: 2.4rem;
				margin-bottom: .6rem;
				display: block;

				@include media {
					font-size: 1.6rem;
					margin-top: -.5rem;
				}
			}

			.iyzico-subtitle {
				@include font-regular;
				font-size: 1.5rem;
				line-height: 1.8rem;
				color: $color-text-light;

				@include media {
					font-size: 1.4rem;
					line-height: 1.7rem;
				}
			}

		.payment-cardinfo {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

			.cardinfo-form {
				width: 40rem;
				padding-top: .9rem;
				display: flex;
				justify-content: space-between;
				flex-flow: row wrap;

				@include media {
					padding-top: 0;
				}
			}

				.form-field {
					margin-bottom: .9rem;
					width: 100%;
					z-index: 1;

					@include media {
						margin-bottom: 1.5rem;
					}

					&.half{
						width: calc(50% - .5rem);
					}

					&.has-info {
						z-index: 2;
					}

					&.cvc {
						z-index: 3;
					}

					&.iyzico {
						margin-top: 2.9rem;

						@include media {
							margin-top: 0;
						}

						.checkwrap {
							border: .1rem solid $color-ice;
							border-radius: $radius-general;
							background-color: $color-gray-light;
							padding: 1.5rem 1.4rem 1.5rem 1.4rem;

							input + label > span{
								top: 47%;
							}
						}
					}

					&.billing {
						margin-top: 2rem;

						@include media {
							margin-top: 0;
						}
					}

					.input-info{
						.popinfo-content {
							width: 33rem;
						}

						.iyzico-info {
							display: flex;
							flex-flow: row wrap;
							align-items: center;

							.info-image {
								width: 3.8rem;
								margin-right: 2.2rem;
								margin-left: .7rem;
							}

							span {
								flex: 1;
							}
						}
					}

				}

				.form-billing {
					@include font-regular;
					border: .1rem solid $color-ice;
					border-radius: $radius-general;
					transition: border-color $transition-hover ease;

					&.error {
						border-color: $color-error;
					}

				}

					.billing-btn {
						line-height: 4.8rem;
						padding: 0 1.3rem;
						position: relative;
						width: 100%;
						display: block;
						text-align: left;

						&:after {
							@include icon('caret-down');
							position: absolute;
							top: 50%;
							right: 2.1rem;
							transform: translate3d(0, -50%, 0);
							color: $color-text-light;
							font-size: .8rem;
						}
					}

						.btn-selected {
							font-size: .9em;
							margin-left: .5em;
							color: $color-text-light;
							&:before {
								content: '(';
							}
							&:after {
								content: ')';
							}
						}

					.billing-content {
						border-top: .1rem solid $color-ice;
						min-height: 10rem;
					}

						.content-error {
							@include font-regular;
							font-size: 1.1rem;
							line-height: 1.6rem;
							margin-top: 0.5rem;
							color: $color-error;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							padding-bottom: .9rem;
						}


								.list-address {
									padding: 0 1.4rem;
									border-bottom: .1rem solid $color-ice;
									.checkwrap {
										padding: 2.8rem 0 2.8rem 0;

										input{
											+ label{
												display: block;

												> span {
													top: 1rem;
												}
											}
										}
									}

								}
									.address-content {
										display: block;
									}

									.address-title {
										@include font-medium;
										font-size: 1.4rem;
										line-height: 1.7rem;

										span {
											@include font-regular;
											font-size: 1.1rem;
											color: $color-text-light;
											margin-left: 1rem;
										}
									}

									.address-info {
										@include font-regular;
										margin-top: .5rem;
										color: $color-text-light;
										font-size: 1.3rem;
										line-height: 1.8rem;
									}

						.addresses-controls {
							padding: 2.9rem 1.4rem 2.1rem;
						}

							.controls-new {
								@include font-medium;
								margin-bottom: 3.2rem;
								text-decoration: underline;
							}

						.content-newaddress {
							padding: .8rem 1.4rem 2.1rem;
						}

							.newaddress-form {
								display: flex;
								flex-flow: row wrap;
								justify-content: space-between;
							}

							.newaddress-field {
								width: calc(50% - .5rem);
								margin-bottom: 1rem;
								z-index: 1;

								&.city {
									z-index: 3;
								}

								&.district {
									z-index: 2;
								}

								@include media {
									width: 100%;
									margin-bottom: 1.5rem;
								}

								&.full {
									width: 100%;
								}
							}

							.newaddress-type {
								margin: 1.8rem 0 2.6rem
							}

								.type-opt {
									display: inline-block;

									+ .type-opt {
										margin-left: 3.1rem;
									}
								}

							.newaddress-submit {
								margin-top: 1rem;
							}

			.cardinfo-preview {
				position: relative;
				width: 48rem;
				pointer-events: none;
			}

				.preview-cardinfo {
					@include font-medium;
					position: absolute;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 1.4rem;
					z-index: 2;

					&.name {
						bottom: 28%;
						left: 19%;
						max-width: 10rem;
					}

					&.number {
						bottom: 43%;
						left: 19%;
						font-size: 1.6rem;
					}

					&.expiry {
						bottom: 28%;
						left: 43.6%;
					}

					&.cvc {
						bottom: 28%;
						left: 85.5%;
						width: 9.3%;
						text-align: center;
					}

					&.type {
						width: 10.3%;
						bottom: 18%;
						right: 24%;
					}
				}
	}

	&.reservation-sum {
		text-align: center;
		padding-right: 20%;
		padding-bottom: 6rem;

		@include media {
			padding-right: 0;
			padding-bottom: 0;
			padding-top: 1rem;
		}

		.sum-check {
			background-color: $color-success;
			color: $color-white;
			width: 8.5rem;
			height: 8.5rem;
			padding-top: .8rem;
			line-height: 7.5rem;
			border-radius: 50%;
			font-size: 5rem;
			display: inline-block;
			text-align: center;
			margin-bottom: 4.9rem;

			@include media {
				width: 6.5rem;
				height: 6.5rem;
				line-height: 5.5rem;
				font-size: 3.4rem;
				padding-top: .7rem;
				margin-bottom: 2.7rem;
			}
		}

		.sum-title {
			@include font-medium;
			font-size: 1.8rem;
			line-height: 2.2rem;
			margin-bottom: 1.2rem;

			@include media {
				font-size: 1.6rem;
				margin-bottom: 2.1rem;
			}
		}

		.sum-text{
			@include font-regular;
			font-size: 1.4rem;
			line-height: 1.43em;
			color: $color-text-light;
		}

		.sum-ref {
			font-size: 1.8rem;
			line-height: 2.2rem;
			margin-top: 5.1rem;

			@include media {
				margin-top: 4.5rem;
				font-size: 1.6rem;
			}
		}

		.sum-links {
			border-top: .1rem solid $color-ice;
			margin-top: 5.9rem;
			padding-top: 2.8rem;
			text-transform: uppercase;
			color: $color-text-light;
			font-size: 1.4rem;

			@include media {
				margin-top: 3.1rem;
				padding: 3.1rem 0;
			}
		}

			.links-item {
				+ .links-item {
					margin-left: 3.8rem;
				}
			}
	}
}

