.section {
	&.account-nav {
		border-bottom: .1rem solid $color-gray-light;
		text-align: center;
		padding: 1.9rem 0;
		margin-bottom: 3.4rem;

		@include media {
			border-bottom: none;
			text-align: left;
			overflow: auto;
			overflow-y: hidden;
			overflow-x: auto;
			padding-bottom: 0;
			margin-bottom: 1.6rem;
		}

		.nav-innerwrap {
			@include media {
				border-bottom: .3rem solid $color-gray-light;
				padding: 0 1.5rem;
				display: inline-block;
				white-space: nowrap;
			}
		}

		.nav-item {
			@include font-medium;
			position: relative;
			display: inline-block;
			font-size: 1.4rem;
			line-height: 2.4rem;
			padding-bottom: 1rem;
			transition:  color $transition-hover ease;
			text-transform: uppercase;

			@include media {
				margin-bottom: -.3rem;
				padding-bottom: 2.1rem;
				line-height: 1.7rem;
				color: $color-text-light;
			}

			&:before {
				@extend %pseudo;
				position: absolute;
				height: .4rem;
				left: 0;
				bottom: 0;
				width: 100%;
				background: $color-primary;
				transform: scaleX(0);
				opacity: 0;
				transition: opacity $transition-hover ease, transform $transition-hover ease;
			}

			+ .nav-item {
				margin-left: 4.7rem;

				@include media {
					margin-left: 2.2rem;
				}
			}

			&.active {
				color: $color-primary;
				&:before {
					transform: scaleX(1);
					opacity: 1;
				}
			}

			&:hover {
				color: $color-primary;

				@include mediaMin {
					&:before {
						transform: scaleX(.5);
						opacity: .9;
					}
				}
			}
		}
	}

	&.account-wrap {
		padding: 0 0 3.4rem;
	}

	&.account-profile {

		.profile-wrap {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			@include media {
				display: block;
			}
		}

		.profile-sum {
			width: 27rem;
			text-align: center;
			border-radius: $radius-big;
			border: .1rem solid $color-gray-light;
			padding: 2rem 1rem 0;

			@include media {
				width: auto;
				display: flex;
				flex-flow: row wrap;
				align-items: center;
				padding: 1.4rem 1.5rem 1.3rem 1.4rem;
			}
		}

			.sum-image {
				width: 12rem;
				height: 12rem;
				border-radius: 50%;
				margin: 0 auto;

				@include media{
					width: 7.3rem;
					height: 7.3rem;
				}
			}

			.sum-content {
				margin-top: .9rem;
				padding-bottom: 2rem;

				@include media {
					flex: 1;
					margin-left: 3rem;
					margin-top: 0;
				}
			}

				.content-bio {
					@include media {
						display: flex;
						justify-content: space-between;
						align-items: center;
					}

					+ .content-logout {
						margin-top: .8rem;
					}
				}

					.bio-name {
						@include font-medium;
						font-size: 1.8rem;
						line-height: 2.2rem;
					}

					.bio-location {
						font-size: 1.4rem;
						color: $color-text-light;
						margin-top: .7rem;
						line-height: 1.8rem;

						@include media {
							margin-top: 0;
							color: inherit;
						}

						i {
							margin-right: .2rem;
						}
					}

				.content-completion {
					border-top: .1rem solid $color-gray-light;
					padding: 3rem 0 2rem;
					margin-top: 2.6rem;

					@include media {
						padding: 0;
						margin-top: 1.3rem;
						text-align: left;
					}
				}

					.completion-bar {
						height: 1rem;
						border-radius: .5rem;
						background-color: $color-gray-light;
						overflow: hidden;
					}

						.bar-progress {
							background-color: $color-primary;
							width: 10%;
							height: 100%;
							transform-origin: 0 50%;
							border-radius: .5rem;
							transition: width $transition-anim ease;
						}

					.completion-status {
						@include font-regular;
						margin-top: 2rem;
						font-size: 1.4rem;
						color: rgba($color-text, .6);

						@include media {
							margin-top: .9rem;
						}

						span {
							color: rgba($color-primary, .6);
						}
					}

				.content-logout {
					@include font-regular;
					color: $color-error;
					text-decoration: underline;
				}

		.profile-form {
			width: calc(100% - 30rem);

			@include media {
				width: auto;
				margin-top: 2.1rem;
			}
		}

			.form-message {
				width: 44rem;
				text-align: center;
				padding: 1.5rem 2rem;
				background-color: $color-gray-light;
				border-color: $color-ice;
				border-radius: $radius-general;
				margin-bottom: 2rem;

				@include media {
					width: auto;
				}

				&.success {
					background-color: $color-success;
					border-color: darken($color-success, 10%);
					color: $color-white;
				}

				&.error {
					background-color: $color-error;
					border-color: darken($color-error, 10%);
					color: $color-white;
				}
			}

			.form-section {
				+ .form-section {
					margin-top: 4rem;
				}
			}

			.form-title {
				@include font-regular;
				font-size: 2.4rem;
				line-height: 3rem;
				padding-bottom: 2rem;
				border-bottom: .1rem solid $color-gray-light;
				margin-bottom: 2rem;

				@include media {
					font-size: 2rem;
					line-height: 2.4rem;
					padding-bottom: 1.4rem;
					margin-bottom: 1.4rem;
				}
			}

			.form-group {
				+ .form-group {
					margin-top: 4rem;
				}
			}

			.form-submit {
				margin-top: 3.4rem;

				@include media {
					width: 100%;
				}
			}

			.form-inputwrap {
				width: 44rem;

				@include media {
					width: auto;
				}

				+ .form-inputwrap {
					margin-top: 2.6rem;
				}

				@for $i from 1 through 10 {
					&:nth-last-child(#{$i}) {
						z-index: $i;
					}
				}
			}

		.form-delete {
			@include font-regular;
			margin-top: 6rem;
			color: $color-text-light;
			font-size: 1.4rem;
			text-decoration: underline;
			line-height: 1.7rem;
		}
	}

	&.account-notifications {
		.notifications-group {
			+ .notifications-group {
				margin-top: 2rem;
			}
		}

			.group-title {
				@include font-medium;
				font-size: 2.4rem;
				line-height: 2.8rem;
				padding-bottom: 1.6rem;
				border-bottom: .1rem solid $color-ice;
			}



				.items-notification {
					@include font-regular;
					font-size: 1.5rem;
					line-height: 2.1rem;
					padding: 1.5rem 2rem;
					border-bottom: .1rem solid $color-ice;
					display: flex;
					align-items: center;
					flex-direction: row;
					flex-wrap: wrap;

					@include media {
						align-items: flex-start;
					}

					&.unread {
						background-color: rgba($color-gray-light, .6);
					}
				}

					.notification-link {
						@include font-medium;
						color: $color-primary;
					}

					.notification-image {
						width: 5rem;
						height: 5rem;
						border-radius: 50%;
						border: .1rem solid $color-gray-light;
						margin-right: 1.7rem;
					}

					.notification-content {
						flex: 1;
					}

					.notification-datetime {
						@include font-regular;
						color: rgba($color-text, .5);
						margin-top: .1rem;
						font-size: 1.3rem;
					}
	}

	&.account-favorites {
		margin-top: -3.4rem;

		@include media {
			margin-top: -1.6rem;
		}

		.favorites-nav {
			text-align: center;
			background-color: $color-gray-light;
			line-height: 4.5rem;
			margin-bottom: 3rem;
			display: flex;
justify-content: center;
			@include media {
				margin-bottom: 1.6rem;
				line-height: 3.8rem;
			}
		}

			.nav-link {
				text-transform: uppercase;
				font-size: 1.4rem;
				transition: color $transition-hover ease;

				&.active, &:hover, &:active {
					color: $color-primary;
				}

				+ .nav-link {
					margin-left: 3.7rem;
				}
			}

		.favorites-error {
			padding: 3rem;
			text-align: center;

			.error-message {
				display: inline-block;
				background-color: $color-gray-light;
				border: .1rem solid $color-ice;
				padding: 4.5rem 1rem;
				font-size: 1.8rem;
				max-width: 100%;
				width: 58rem;
			}
		}

		.favorites-list {
			width: calc(100% + 3rem);
			margin: 0 -1.5rem 0;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			@include media {
				width: calc(100% + 1.5rem);
				margin: 0 -.75rem 0;
			}
		}

			.list-item {
				padding: 2rem 1.5rem 0;
				width: 25%;

				@include media {
					width: 50%;
					padding: 0 .75rem 1.5rem;
				}
			}
	}

	&.account-messages {
		.messages-error {
			padding: 3rem;
			text-align: center;

			.error-message {
				display: inline-block;
				background-color: $color-gray-light;
				border: .1rem solid $color-ice;
				padding: 4.5rem 1rem;
				font-size: 1.8rem;
				max-width: 100%;
				width: 58rem;
			}
		}


			.list-item {
				+ .list-item {
					margin-top: 2.2rem;
				}
			}

				.item-link {
					background-color: $color-gray-light;
					padding: 1rem 2rem;
					border-radius: $radius-general;
					box-shadow: 0 .2rem .4rem 0 rgba(25, 42, 70, 0.1);
					display: flex;
					flex-flow: row wrap;
					align-items: center;

					@include media {
						align-items: flex-start;
						padding: 1.6rem 1.4rem 1.6rem 1.9rem;
					}
				}

				.item-avatar {
					width: 5rem;
					height: 5rem;
					border-radius: 50%;
				}

				.item-content {
					flex: 1;
					display: flex;
					align-items: center;
					flex-flow: row wrap;

					@include media {
						display: block;
						margin-left: 2.3rem;
					}
				}

				.item-senderinfo {
					text-align: center;
					font-size: 1.4rem;
					line-height: 1.8rem;
					position: relative;
					padding: 0 1rem;
					width: 11rem;

					@include media {
						width: auto;
						text-align: left;
						display: flex;
						justify-content: space-between;
						align-items: flex-end;
						flex-flow: row wrap;
						padding: 0;
					}

					@include mediaMin {
						&:after {
							@include pseudo;
							position: absolute;
							right: 0;
							height: 2.2rem;
							width: .1rem;
							top: 50%;
							transform: translate3d(0, -50%, 0);
							background-color: rgba($color-text, .6);
						}
					}
				}

					.senderinfo-title {
						color: $color-primary;
						margin-bottom: .2rem;
					}

					.senderinfo-datetime {
						color: $color-text-light;
					}

					.senderinfo-subtitle {
						color: rgba($color-text, .6);

						@include media {
							width: 100%;
							margin-top: .5rem;
						}
					}

				.item-title {
					@include font-regular;
					font-size: 1.6rem;
					line-height: 1.9rem;
					margin-left: 2.1rem;
					flex-grow: 2;

					@include media {
						margin-left: 0;
						margin-top: .5rem;
						display: block;
					}
				}

				.item-info {
					white-space: nowrap;

					@include media {
						display: none;
					}
				}

					.info-field {
						@include font-regular;
						color: rgba($color-text, .6);
						display: inline-block;
						font-size: 1.4rem;
						padding: 0 2rem;
						border-left: .1rem solid rgba($color-text, .6);
						line-height: 2rem;
						padding-bottom: .2rem;

						strong {
							font-weight: inherit;
							font-size: 1.2rem;
						}

						&:last-child {
							padding-right: 0;
						}

						button {
							transition: color $transition-hover ease;

							&:hover, &:active {
								color: $color-text;
							}
						}
					}
	}

	&.account-message-conversation {
		.conversation-head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 2.6rem;
			line-height: 2.2rem;

			@include media {
				padding-bottom: 1.9rem;
			}
		}

			.head-back {
				font-size: 1.8rem;
				display: flex;
				align-items: center;
				flex-flow: row wrap;

				span {
					margin-left: .8rem;
					color: $color-text-light;
					font-size: 1.5rem;
				}
			}

			.head-advertid {
				font-size: 1.5rem;
				color: $color-text-light;
			}

			.head-advertlink {
				@include font-regular;
				font-size: 1.5rem;
				color: $color-primary;
				text-decoration: underline;
			}

		.conversation-dealer {
			background-color: $color-primary;
			color: $color-white;
			display: block;
			padding: 1rem;
			line-height: 5rem;
			margin-bottom: 4.2rem;

			@include media {
				margin-bottom: 2rem;
			}
		}

			.dealer-avatar {
				width: 5rem;
				height: 5rem;
				border-radius: 50%;
				display: inline-block;
				vertical-align: middle;
				margin-right: 3rem;

				@include media {
					width: 3.5rem;
					height: 3.5rem;
					margin-right: .7rem;
				}
			}

			.dealer-title {
				font-size: 1.6rem;
			}

		.conversation-sender {
			@include font-semibold;
			font-size: 1.6rem;
			line-height: 5rem;
			color: $color-primary;
			padding-left: 1rem;
			display: flex;

			@include media {
				padding-left: 0;
			}
		}

			.sender-avatar {
				width: 5rem;
				height: 5rem;
				border-radius: 50%;
				display: inline-block;
				vertical-align: middle;
				margin-right: 1.1rem;
			}

			.sender-name {
				flex: 1;
			}

			.sender-advertid {
				@include font-regular;
				font-size: 1.4rem;
				color: rgba($color-text, .6);
			}

		.conversation-content {
			padding-left: 6.1rem;
			padding-top: 2.1rem;

			@include media {
				padding-left: 0;
				padding-top: 1.6rem;
			}
		}

		.conversation-title {
			@include font-semibold;
			font-size: 3.2rem;
			color: rgba($color-text, .8);

			@include media {
				font-size: 2rem;
			}
		}



			.messages-message {
				padding: 3.2rem 0 2.9rem;
				display: flex;
				flex-flow: row wrap;

				@include media {
					padding: 3.7rem 0 3.9rem;
				}

				&.first {
					@include media {
						padding-top: 2.1rem;
					}
				}

				+ .messages-message {
					border-top: .1rem solid $color-ice;
				}
			}

				.message-content {
					flex: 2;
				}

				.message-text {
					@include font-regular;
					font-size: 1.8rem;
					line-height: 1.56em;

					@include media {
						font-size: 1.4rem;
						line-height: 2em;
					}
				}

				.message-datetime {
					@include font-regular;
					color: $color-text-light;
					font-size: 1.5rem;
					line-height: 1.8rem;
					margin-top: 2rem;

					i {
						display: inline-block;
						vertical-align: middle;
						font-size: 1.8rem;
						margin-top: -.3em;
						margin-right: .4rem;
					}
				}

				.message-profile {
					width: 4.4rem;

					@include media {
						width: 5rem;
					}
				}

					.profile-image {
						width: 2.6rem;
						height: 2.6rem;
						border-radius: 50%;

						 @include media {
						 	width: 4rem;
						 	height: 4rem;
						 }
					}

		.conversation-composeform {
			position: relative;
			margin-top: 3rem;

			@include media {
				margin-top: .9rem;
			}

			.inputwrap textarea {
				padding-left: 4.8rem;
				min-height: 4.6rem;
				height: 4.6rem;
				max-height: 15rem;
			}
		}

			.composeform-avatar {
				position: absolute;
				width: 2.6rem;
				height: 2.6rem;
				border-radius: 50%;
				left: .9rem;
				top: 1.1rem;
				z-index: 3;
			}

			.composeform-submit {
				position: absolute;
				right: .4rem;
				top: .4rem;
				width: 7.6rem;

				@include media {
					position: relative;
					right: auto;
					top: auto;
					width: 100%;
					padding-top: 1.1rem;
					padding-bottom: 1.1rem;
					line-height: 2.1rem;
					margin-top: 2rem;
				}
			}
	}

	&.account-reservations {


			.list-reservation {
				+ .list-reservation { margin-top: 1.5rem; }

				&.expired {
					.reservation-link {
						background-color: transparent;
					}
				}
			}

				.reservation-link {
					border: .1rem solid $color-ice;
					background-color: $color-gray-light;
					border-radius: $radius-general;
					padding: 2.1rem 2.3rem 2.3rem;
					display: flex;
					align-items: center;

					@include media {
						padding: 1.6rem;
					}
				}

				.reservation-image {
					width: 10rem;
					height: auto;
				}

				.reservation-content {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex: 1;
					margin-left: 3.6rem;

					@include media {
						display: block;
						margin-left: 1.8rem;
						overflow: hidden;
					}
				}

					.content-title {
						@include font-regular;
						display: block;
						font-size: 1.8rem;
						line-height: 2.2rem;

						@include media {
							font-size: 1.6rem;
							line-height: 1.9rem;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}

					.content-dealer {
						@include font-semibold;
						font-size: 1.2rem;
						line-height: 1.4rem;
						margin-top: .5rem;
						color: rgba($color-text, .5);

						@include media {
							margin-top: .6rem;
						}
					}

					.content-info {
						margin-top: .8rem;
						color: rgba($color-text, .6);
						line-height: 1.4rem;

						div {
							display: inline-block;

							strong {
								font-weight: inherit;
								font-size: 1.2rem;
							}

							+ div {
								border-left: .1rem solid rgba($color-text, .6);
								margin-left: 2rem;
								padding-left: 2rem;
							}
						}
					}

					.content-cancel {
						@include font-medium;
						font-size: 1.4rem;
						color: $color-primary;
						text-decoration: underline;

						&:disabled {
							color: rgba($color-text, .5);
							cursor: default;
						}
					}
	}

	&.account-login {
		border-top: .1rem solid $color-gray-light;
		padding: 6rem 0 6rem;

		@include media {
			padding: 2.3rem 0;
		}

		.login-wrap {
			max-width: 50rem;
			margin: 0 auto;
		}
	}
}
