.section{
	&.dealers-listing {
		padding-bottom: 6rem;

		.listing-content.type-dealer {
			padding: 0;

			@include media {
				margin: 0 -1.5rem 2rem;
				width: calc(100% + 3rem);
			}

			.content-top {
				@include mediaMin {
					display: none;
				}
			}

			.content-results {
				padding: 0;

				@include media {
					padding: .75rem;
				}
			}

				.results-item{
					@include mediaMin {	
						padding: 0;
					}

					.contentbox{
						&:before{ display: none; }

						&:hover {
							.contentbox-innerwrap{
								border-color: $color-gray-light;
							}
						}

						.contentbox-innerwrap {
							@include mediaMin {	
								border-left: none;
								border-top: none;
							}
						}
					}
				}
		}

	}
}