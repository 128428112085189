.page {
	&.notfound {
		text-align: center;
		padding: 6rem 0;

		h1 {
			@include font-bold;
			font-size: 11rem;
			margin-bottom: .2em;
		}
	}
}