.section {
	&.listprices {
		padding: 0 0 8.8rem;

		.listprices-head {
			display: flex;
			justify-content: space-between;
			position: relative;
			z-index: 2;
			margin-bottom: 2.7rem;
			align-items: flex-end;

			@include media {
				display: block;
				padding: 2rem 0 0;
			}
		}

			.head-title {
				@include font-light;
				font-size: 3.2rem;
				line-height: 1em;
			}

			.head-filters {
				margin-bottom: .2rem;

				@include media {
					display: flex;
					justify-content: space-between;
					margin-top: 4rem;
				}
			}

				.filters-item {
					width: 17rem;
					display: inline-block;

					@include media {
						width: calc(50% - 1rem);
					}

					+ .filters-item {
						margin-left: 1.5rem;

						@include media {
							margin-left: 0;
						}
					}
				}

		.listprices-outerwrap {
			@include media {
				position: relative;
				width: calc(100% + 3rem);
				margin-left: -1.5rem;
				margin-right: -1.5rem;

				&:after, &:before{
					@include pseudo;
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					width: 1.5rem;
					z-index: 4;
					pointer-events: none;
					background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
					background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
					background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
				}

				&:before {
					transform: rotate(180deg);
					right: auto;
					left: 0;
				}
			}
		}

		.listprices-wrap {
			@include media {
				overflow: scroll;
				overflow-x: scroll;
				overflow-y: hidden;
				box-sizing: content-box;
			}
		}

		.listprices-table {

			@include media {
				width: calc(100% - 1.5rem);
				margin-left: 1.5rem;
			}

			td, th {
				text-align: center;
			}

			thead {
				th {
					@include font-semibold;
					color: $color-ice;
					background-color: $color-text;
					text-transform: uppercase;
					font-size: 1.2rem;
					border-right: .1rem solid rgba($color-ice, .7);

					&:last-child {
						border-right: none;

						@include media {
							border-right: 1.5rem solid $color-white;
						}
					}
				}
			}

			tbody {

				&:before {
					@extend %pseudo;
					height: 3rem;
				}


				td {
					border-right: .1rem solid rgba($color-ice, .7);
					&:last-child {
						border-right: none;

						@include media {
							border-right: 1.5rem solid $color-white;
						}
					}

					&.brand {
						background-color: transparent;
						padding: 0;
						position: relative;
						width: 11rem;
						min-height: 10rem;

						.brand-wrap {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							border-left: .1rem solid rgba($color-ice, .7);
							border-top: .1rem solid rgba($color-ice, .7);
							border-bottom: .1rem solid rgba($color-ice, .7);
						}

						.brand-logowrap {
							height: 100%;
							max-width: 6em;
							width: 100%;
							text-align: center;
							margin: auto;
							max-height: 5em;
						}

						.brand-logo {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}

	&.listprices-crumbs {
		margin-bottom: -.8rem;

		@include media {
			display: none;
		}
	}
}
