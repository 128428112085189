.section{
	&.contentpage {
		position: relative;
		min-height: 23rem;
		padding: 8.3rem 0 7rem;

		@include media {
			padding: 5.5rem 0 7rem;
		}

		&:before {
			@include pseudo;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			background-color: $color-primary;
			height: 23rem;
			z-index: 1;

			@include media {
				height: 7rem;
			}
		}

		.contentpage-wrap {
			position: relative;
			z-index: 2;
		}

		.contentpage-content {
			background: $color-white;
			padding: 6.5rem 8.5rem;
			box-shadow: 0 .1rem .2rem 0 rgba(144, 156, 177, 0.2);

			@include media {
				box-shadow: none;
				padding: 2.2rem 1.5rem;
			}
		}

			.content-title {
				@include font-light;
				font-size: 3.2rem;
				line-height: 1em;
				text-align: center;
				margin-bottom: 5.1rem;

				@include media {
					font-size: 2.2rem;
					line-height: 1.41em;
					margin-bottom: 1.3rem;
				}
			}

			.content-subtitle {
				@include font-light;
				font-size: 2rem;
				line-height: 1.2em;
				text-align: center;
				color: $color-text-light;
				margin-bottom: 5rem;
			}
	}

	&.contentpage-faq {
		.faq-item{
			+ .faq-item {
				margin-top: .9rem;
			}
		}
	}

	&.contentpage-textarea {
		@include font-regular;
		font-size: 1.6rem;
		line-height: 1.94em;
		color: rgba($color-text, .7);

		@include media {
			font-size: 1.4rem;
		}
		ol{
			margin-left: 5rem;
			padding-left: 1.5rem;
			li{
				margin-bottom: 3rem;
			}
		}
		.text-center{
			text-align: center;
		}
		.indent{
			text-indent: 5rem;
		}
		.indent.noMargin{
			margin-top: 0 !important;
			margin-bottom: 0 !important;
		}
	}

	&.contentpage-form {
		max-width: 62rem;
		margin: 0 auto;

		/*.form-row {
			width: calc(100% + 2.2rem);
			margin: 0 -1.1rem;
			display: flex;
			flex-flow: row wrap;
		}

		.form-col {
			width: 50%;
			padding: 1.1rem;

			&.x2 {
				width: 100%;
			}
		}*/

		.form-opts {
			padding-top: 0;

			.opts-inner {
				margin-left: -1rem;
				width: calc(100% + 1rem);
			}

			.opts-item {
				display: inline-block;
				border: .1rem solid $color-ice;
				border-radius: 1.4rem;
				line-height: 2.8rem;
				padding: 0 1.6rem;
				font-size: 1.2rem;
				margin-left: 1rem;
				margin-bottom: 1rem;
				color: rgba($color-text, .5);

				button {
					font-size: .8rem;
					margin-left: 1rem;
					transition: color $transition-hover ease;

					&:hover{
						color: $color-text;
					}
				}
			}
		}

		.form-agreement {
			.checkwrap {
				input + label{
					color: $color-text-light;
					@include font-regular;
				}
			}
		}

		.form-submitbtn {
			max-width: 37rem;
			margin: 0 auto;
		}
	}
}