.info-dealer {
  background-color: $color-gray-light;
  border-radius: $radius-general;


  @include media {
    margin-top: 1.5rem;
  }

  .dealer-head{
    padding: 2.7rem 2.1rem 2.7rem 2.7rem;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .head-content {
    flex: 1;
  }

  .dealer-badge {
    position: absolute;
    top: 0.3rem;
    left: 0;
    width: 1.8rem;
    height: 1.8rem;
    font-size: 1.8rem;
    color: $color-primary;
    display: inline-block;
  }

  .badge-icon {
    color: $color-white;
    font-size: 0.9rem;
    position: absolute;
    z-index: 2;
    top: 53%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .dealer-image {
    width: 5rem;
    height: 5rem;
    margin-right: 2rem;
    border-radius: 50%;
    background-color: $color-white;

    .loaderwrap-icon {
      font-size: 5rem;
    }
  }

  .dealer-title {
    @include font-semibold;
    font-size: 1.8rem;
    text-decoration: underline;
    text-transform: uppercase;
    display: block;
    padding-top: .2rem;
    position: relative;
    padding-left: 2.2rem;
  }

  .dealer-info {
    @include font-semibold;
    margin-top: 1rem;
    color: rgba($color-text, .5);
    text-transform: uppercase;
    font-size: 1.2rem;
  }

  .info-workinghours {
    margin-left: 1rem;

    span {
      margin: 0 1rem;
    }

    &.open {
      color: $color-success;
    }
  }

  .dealer-rep {
    padding: 1.6rem 2rem 1.7rem;
    border-top: .1rem solid rgba($color-text, .2);
  }

  .rep-image {
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    height: 3.6rem;
    margin-right: 1rem;
  }

  .rep-title {
    @include font-medium;
    display: inline-block;
    vertical-align: middle;
    font-size: 1.6rem;
  }

  .rep-role {
    text-transform: uppercase;
    color: rgba($color-text, .4);
    display: inline-block;
    vertical-align: middle;
    font-size: 1.2rem;
    margin-left: 1.5rem;
  }

  .dealer-controls {
    display: flex;

    > * {
      min-width: 50%;
      border-radius: 0;

      &:nth-child(2n+1){
        border-bottom-left-radius: $radius-general;
        margin-right: .1rem;
      }

      &:nth-child(2n+2){
        border-bottom-right-radius: $radius-general;
      }
    }
  }
}
