.section {
	&.contact-map {
		background-color: $color-gray-light;
		height: 52rem;
		max-height: 70vh;

		@include media {
			height: 25rem;
		}

		.map-container {
			height: 100%;
		}
	}

	&.contact-info {
		padding: 8.1rem 0 9rem;

		@include media {
			padding: 3rem 0 5rem;
		}

		.info-wrap {
			display: flex;
			justify-content: space-between;

			@include media {
				display: block;
			}
		}

		/* .info-section {
			
		} */

		.info-details {
			width: 62.1rem;
			border-bottom: .1rem solid $color-ice;

			@include media {
				width: calc(100% + 3rem);
				margin: 0 -1.5rem;
			}
		}

			.details-item {
				@include font-regular;
				display: flex;
				flex-flow: row wrap;
				line-height: 3rem;
				font-size: 1.8rem;
				padding: .7rem 0;
				+ .details-item {
					border-top: .1rem solid $color-ice;
				}

				@include media {
					@include font-medium;
					border-top: .1rem solid $color-ice;
					padding: 1.5rem 1.5rem;
					font-size: 1.4rem;
					line-height: 1.8rem;
				}
			}

				.item-heading {
					color: $color-text-light;
					font-weight: inherit;
					width: 19rem;

					@include media {
						width: 14.5rem;
					}
				}

				.item-content {
					flex: 1;
				}

		.info-form {
			width: 64.8rem;

			@include media {
				width: auto;
				margin-top: 1.3rem;
			}
		}

			.form-typeselection {
				font-size: 0;
				letter-spacing: 0;
				padding-bottom: 1rem;

				@include media {
					padding-bottom: .6rem;
				}
			}

				.typeselection-opt {
					display: inline-block;
					position: relative;

					+ .typeselection-opt {
						margin-left: 4.8rem;

						@include media {
							margin-left: 2.8rem;
						}
					}

					input {
						position: absolute;
						pointer-events: none;
						top: 0;
						left: 0;
						opacity: 0;

						&:checked {
							+ label {
								color: $color-primary;

								&:after {
									background-color: $color-primary;
									transform: scaleX(1);
								}
							}
						}
					}

					label {
						@include font-semibold;
						position: relative;
						font-size: 1.4rem;
						line-height: 1.7rem;
						padding-bottom: 1.1rem;
						display: block;
						text-transform: uppercase;
						cursor: pointer;
						transition: color $transition-hover ease;

						@include media {
							padding-bottom: .3rem;
						}

						&:after {
							@include pseudo;
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: .3rem;
							background-color: $color-text;
							transform: scaleX(0);
							transition: background-color $transition-hover ease, transform $transition-hover ease;

							@include media {
								height: .1rem;
							}
						}
					}
				}

			.form-contentwrap {
				background-color: $color-gray-light;
				padding: .6rem .4rem 1.6rem;
				display: flex;
				flex-flow: row wrap;

				@include media {
					border-radius: $radius-general;
					padding: .5rem .5rem 1rem;
				}
			}

			.form-section {
				padding: 1.2rem;
				width: 50%;

				&.x2 {
					width: 100%;
				}

				@include media {
					padding: .5rem;
				}
			}

			.form-controls {
				width: 100%;
				text-align: center;

				@include media {
					margin-top: .5rem;
				}

				> * {
					width: 15.5rem;
				}
			}
	}
}