.section {
	&.about-head {
		background-color: #606b9c;
		color: $color-white;
		text-align: center;
		padding: 9.6rem 0 16.8rem;
		font-size: 3.6rem;
		line-height: 4.3rem;

		@include media {
			padding: 2.6rem 0 7.5rem;
			font-size: 1.8rem;
			line-height: 2.5rem;
		}

		.head-title {
			@include font-medium;
			font-size: inherit;
			line-height: inherit;
			text-transform: lowercase;
		}

		.head-subtitle {
			@include font-light;
			font-size: inherit;
			line-height: inherit;
		}

	}

	&.about-intro {
		margin-top: -11.6rem;
		text-align: center;
		padding: 0 1.5rem;

		@include media {
			margin-top: -5rem;
		}

		.intro-image {
			width: 117rem;
			border-radius: $radius-general;
			margin: 0 auto;
		}

		.intro-text {
			width: 77rem;
			margin: 0 auto;
			padding: 8.7rem 0 12rem;
			font-size: 1.8rem;
			line-height: 1.555555em;

			@include media {
				width: calc(100% - 5.6rem);
				font-size: 1.4rem;
				padding: 2.2rem 0 2.6rem;
			}
		}
	}

	&.about-counts {
		padding: 4rem 0;
		border-top: .1rem solid rgba($color-text, .1);
		border-bottom: .1rem solid rgba($color-text, .1);

		@include media {
			padding: .5rem 1.5rem;
		}

		.counts-wrap {
			border-right-color: rgba($color-text, .1);
			display: flex;

			@include media {
				display: block;
			}
		}

		.counts-item {
			flex: 1;
			text-align: center;
			padding: 8rem 0;
			position: relative;

			@include media {
				padding: 1.9rem 0;
			}

			&:nth-child(3n+2){
				border-left: .1rem solid rgba($color-text, .1);
				border-right: .1rem solid rgba($color-text, .1);

				@include media {
					border-left: none;
					border-right: none;
				}
			}

			+ .counts-item {
				&:before {
					@include media {
						@include pseudo;
						position: absolute;
						top: 0;
						left: 50%;
						width: 5rem;
						margin-left: -2.5rem;
						height: .1rem;
						background-color: rgba($color-text, .1);
					}
				}
			}
		}

			.item-num {
				@include font-bold;
				font-size: 4.2rem;
				display: block;
				color: $color-success;
				margin-bottom: 1.4rem;

				@include media {
					font-size: 2.4rem;
				}
			}

			.item-title {
				@include font-regular;
				font-size: 1.6rem;
				line-height: 1.9rem;
				color: $color-text-light;

				@include media {
					font-size: 1.4rem;
					line-height: 2rem;
				}
			}
	}

	&.about-brands {
		padding: 13.8rem 0 14.4rem;
		color: $color-text-light;

		@include media {
			padding: 2.3rem 0 2.8rem;
		}

		.brands-wrap {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include media {
				flex-flow: row wrap;
				justify-content: center;
				padding: 0 .5rem;
			}
		}

		.brands-image {
			width: calc(50% - 1.5rem);

			@include media {
				width: 15.8rem;
			}
		}

		.brands-content {
			@include font-extralight;
			width: calc(50% - 1.5rem);
			font-size: 1.8rem;
			line-height: 1.555555em;

			@include media {
				width: 100%;
			}
		}

			.content-title {
				font-weight: inherit;
				font-size: 4.2rem;
				line-height: 5rem;

				@include media {
					font-size: 2rem;
					line-height: 2.8rem;
				}
			}

			.content-text {
				margin-top: 3rem;
				line-height: inherit;
				@include media {
					@include font-extralight;
					font-size: 1.4rem;
					line-height: 1.57em;
				}
			}
	}

	&.about-team {
		padding: 2.7rem 0 6.6rem;
		border-top: .1rem solid $color-gray-light;

		@include media {
			padding: 2.2rem 1.5rem 3rem;
		}

		.team-title {
			@include font-extralight;
			color: $color-text-light;
			text-align: center;
			font-size: 4.2rem;
			line-height: 5rem;
			margin-bottom: 6.1rem;

			@include media {
				font-size: 2rem;
				line-height: 2.8rem;
				margin-bottom: 1.9rem;
			}
		}

		.team-container {
			display: flex;
			//justify-content: space-between;
			justify-content: center;

			@include media {
				display: block;
				margin: 0 -.5rem;
				width: calc(100% + .5rem);

				.swiper-slide {
					padding: 0 .5rem;
				}
			}
		}

		.team-member {
			width: 23rem;
			//border: .1rem solid $color-ice;
			//border-radius: $radius-big;
			margin: 0 4rem;

			@include media {
				width: 100%;
				margin: 0;
			}
		}

			.member-image {
				width: calc(100% + .2rem);
				padding-bottom: 100%;
				margin: -.1rem -.1rem 0;
				//border-radius: $radius-big;
				border-radius: 50%;
			}

			.member-content {
				padding: 2.1rem 2rem 2rem;
			}

			.member-position {
				@include font-extralight;
				font-size: 1.8rem;
				line-height: 3.2rem;
				color: $color-text-light;
				display: block;

				@include media {
					font-size: 1.2rem;
					line-height: 2.2rem;
				}
			}

			.member-name {
				@include font-regular;
				font-size: 2.2rem;
				line-height: 3.2rem;

				@include media {
					font-size: 1.4rem;
					line-height: 2.2rem;
				}
			}

			.member-contact {
				font-size: 0;
				margin-top: 2rem;

				> * {
					width: 4.2rem;
					display: inline-block;

					@include media {
						width: 3.2rem;
					}

					+ * {
						margin-left: 1.5rem;
					}
				}
			}
	}
}