.searchbar {
	position: relative;
	width: 56rem;

	&.show {
		.searchbar-input {
			border-color: $color-primary;
		}

		.searchbar-results {
			opacity: 1;
			height: auto;
			transform: translate3d(0, 0rem, 0);
		}
	}

	&.fullscreen {
		display: none;
		position: fixed;
		z-index: 30;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity $transition-hover ease;
		background-color: $color-white;

		&.active {
			display: block;

			&.show {
				opacity: 1;
			}
		}

		.searchbar-icon {
			position: absolute;
			left: 2rem;
			font-size: 1.9rem;
			top: 50%;
			transform: translate3d(0, -50%, 0);
		}

		.searchbar-input {
			border-radius: 0;
			border: none;
			border-bottom: .1rem solid $color-ice;
			font-size: 1.4rem;
			color: $color-primary;
			line-height: 4rem;
			padding: 1rem 5.4rem .9rem 5.8rem;
		}

		.searchbar-results {
			top: 6rem;
			height: calc(100% - 6rem);
			overflow: scroll;
			overflow-x: hidden;
			overflow-y: scroll;
		}



				.group-wrap {
					max-height: none;
				}

				.group-item {
					a{
						padding: .9rem 2rem .9rem 5.8rem;
						height: auto;
						line-height: 2rem;
					}
				}

					.item-image {
						width: 3rem;
						height: 3rem;
						margin-right: .9rem;
					}
	}

	.searchbar-form {
		position: relative;
	}

	.searchbar-input {
		width: 100%;
		display: block;
		line-height: 4.4rem;
		padding: 0 9rem 0 2rem;
		border-radius: $radius-general;
		background-color: $color-white;
		border: .1rem solid $color-gray;
		transition: border-color $transition-hover ease;
		font-size: 1.5rem;

		@include media {
			line-height: 3.5rem;
			padding: .4rem 8.7rem .5rem 1.4rem;
		}
	}

	.searchbar-loader {
		@include spin;
		position: absolute;
		top: 2.3rem;
		right: 8.5rem;
		color: $color-gray;
	}

	.searchbar-submit {
		position: absolute;
		top: .5rem;
		right: .5rem;
		padding: .7rem 1.4rem;
		text-transform: uppercase;
		color:white !important;
		i {
			font-size: 1.4rem;
			margin-right: 1.1rem;
		}
		&:hover{
			color:white!important;
		}
		a{
			color:white !important;
		}
	}

	.searchbar-close {
		position: absolute;
		font-size: 1.4rem;
		right: 0;
		height: 6rem;
		line-height: 6rem;
		width: 5.4rem;
		top: 50%;
		transform: translate3d(0, -50%, 0);
	}

	.searchbar-results {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		background: $color-white;
		border-radius: $radius-general;
		font-size: 1.4rem;
		box-shadow: 0 1.2rem 1.8rem 0 rgba(29, 42, 68, 0.13);
		overflow: hidden;
		opacity: 0;
		height: 0;
		transform: translate3d(0, -.3rem, 0);
		transition: opacity $transition-hover ease, transform $transition-hover ease;
	}

		.results-group {
			color: $color-primary;
		}

			.group-title {
				display: block;
				background-color: $color-gray;
				color: $color-text;
				font-size: 1.1rem;
				text-transform: uppercase;
				padding: .8rem 2rem .8rem;
				line-height: 1.4rem;
			}

			.group-wrap {
				max-height: 20rem;
			}

			.group-item {

				&.focused {
					a {
						background-color: $color-gray-light;
					}
				}

				a{
					display: flex;
					align-items: center;
					line-height: 1.7rem;
					padding: 0 2rem .2rem;
					height: 5.2rem;
					transition: background-color $transition-hover ease;

					&:hover, &:active {
						background-color: $color-gray-light;
					}
				}

				+ .group-item {
					border-top: .1rem solid $color-gray-light;
				}
			}

				.item-image {
					width: 3.7rem !important;
					height: 3.7rem !important;
					border-radius: 50%;
					border-right-color: .1rem solid $color-gray-light;
					margin-right: 1.8rem;
				}

			.group-cta {
				border-top: .1rem solid $color-gray-light;
				color: $color-text;
				padding: .6rem 1.1rem .4rem;
			}

				.cta-link {
					@include font-semibold;
					display: block;
					text-align: center;
					text-transform: uppercase;
					border: .1rem solid $color-gray;
					font-size: 1.3rem;
					line-height: 3.8rem;
					transition: background-color $transition-hover ease;

					&:hover, &:active {
						background-color: $color-gray-light;
					}

					i {
						font-size: 1rem;
						display: inline-block;
						vertical-align: middle;
						margin: -.3rem 0 0 1rem;
					}
				}
}
