.section{
	&.home-intro {
		position: relative;
		z-index: 2;
		min-height: 40rem;

		@include media {
			margin-top: -5rem;
			z-index: 1;
		}

		.intro-content {
			position: absolute;
			z-index: 2;
			top: 33%;
			left: 50%;
			text-align: center;
			transform: translate3d(-50%, -50%, 0);

			@include media {
				width: 100%;
				top: 35%;
			}
		}

		.intro-title.forceWhite{
			color: white;
			.colored{
				color: white;
			}
			.subtitle{
				color: white;
			}
		}

		.intro-title {
			@include font-regular;
			line-height: 3rem;
			font-size: 4.2rem;
			opacity: .2;

			@include media {
				font-size: 2.5rem;
			}

			.colored {
				color: $color-primary;
			}
		

			.subtitle {
				@include font-medium;
				display: block;
				font-size: 2.2rem;
				font-style: italic;
				line-height: 3rem;
				margin-top: 1.9rem;

				@include media {
					font-size: 1.6rem;
					line-height: 1em;
					margin-top: .5rem;
				}
			}

		
		}

		.intro-bg {
			height: 49rem;
			background-color: $color-ice;
			.imagewrap-image{
				background-position: left center;
			}
			.loaderwrap-icon{
				top: auto;
				left: auto;
				right: 0;
				bottom: 0;
				transform: none;
				font-size: 21rem;
			}

			@include media {
				height: 46rem;
				max-height: 80vh;
			}
		}

		.altin-orumcek{
			transform:scale(.5);
			position:absolute;
			bottom:-2rem;
			right:-12rem;
			@include media {
				display:none;
			}
		}

		.intro-search {
			margin: 3.5rem auto 0;

			@include media {
				margin-top:1rem;
				width: calc(100% - 9rem);
			}
		}
	}

	&.home-listing {
		position: relative;

		@include media {
			z-index: 3;
		}
	}
}