.site-content {
	max-width: 192rem;
	margin: 0 auto;
}

.wrapper {
	//width: $wrapper-regular;
	max-width: calc(100% - 5rem);
	margin: 0 auto;

	&.medium {
		width: $wrapper-medium;
	}

	&.narrow {
		width: $wrapper-narrow;
		//width: $wrapper-narrow;
	}

	&.narrower {
		width: $wrapper-narrower;
		//width: $wrapper-narrow;
	}
}

.link {
	color: $color-primary;
	text-decoration: underline;
}

@include media() {
	.wrapper {
		max-width: calc(100% - 3rem);
	}
}