.modal-container.modal-login{

	.modal-closebtn {
		background-color: transparent;
		color: $color-text-light;
	}

	.modal-content {
		width: 52rem;
	}

	.modal-innercontent {
		padding: 3.9rem 6rem 6rem;

		@include media {
			padding: 3rem 2rem;
		}
	}
}