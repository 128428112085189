.section{
	&.header {
		padding: 1.8rem 0 1.8rem;
		border-bottom: .1rem solid $color-gray-light;
		position: relative;
		z-index: 20;

		@include media() {
			padding: 0;
			border-bottom: none;
			z-index: 4;
			position: fixed;
			width: 100%;
			background-color: white;

			&.menu-active {
				z-index: 1001;
			}
		}

		.header-wrap{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.header-search {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
		}

		.header-logo {
			display: inline-block;
			height: 3.4rem;

			img {
				height: 100%;
			}
		}

		@include media() {
			.header-logo {
				height: 2.3rem;
			}
		}

		.header-nav {
			position: relative;
			line-height: 4rem;
			box-sizing: content-box;
			height: 4rem;
			color: $color-text;
			font-size: 0;
			padding-right: 11.4rem;

			@include media {
				height: auto;
				line-height: auto;
				padding-right: 0;
			}
		}

			.nav-link {
				@include font-semibold;
				font-size: 1.4rem;
				display: inline-block;
				vertical-align: middle;
				text-transform: uppercase;
				transition: color $transition-hover ease;

				&.active, &:hover, &:active {
					color: $color-primary;
				}

				+ .nav-link {
					margin-left: 1.7rem;
				}
			}

			.nav-user {
				display: inline-block;
				margin-left: 9.3rem;
			}

				.user-item {
					position: relative;
					display: inline-block;
					border: .1rem solid $color-primary;
					color: $color-primary;
					width: 4rem;
					height: 4rem;
					border-radius: 50%;
					vertical-align: middle;
					line-height: 4rem;
					font-size: 1.8rem;
					text-align: center;

					+ .user-item {
						margin-left: 2rem;
					}

					&.avatar {
						border: none;
					}

					.item-image {
						width: 100%;
						height: 100%;
						border-radius: 50%;
					}

					.item-unread {
						position: absolute;
						right: -.2rem;
						bottom: -.2rem;
						background: $color-red;
						color: $color-white;
						font-size: 1rem;
						width: 1.8rem;
						height: 1.8rem;
						line-height: 1.6rem;
						border-radius: 50%;
						text-align: center;
					}
				}


			.nav-menubtn {
				position: absolute;
				height: 7.6rem;
				width: 7.6rem;
				top: -1.7rem;
				right: -2.5rem;
				z-index: 6;
				display: flex;
				align-items: center;
				justify-content: space-around;
				border-left: .1rem solid $color-gray-light;
				transition: border-color $transition-hover ease $transition-anim;

				&.open {
					border-left-color: transparent;
					transition: border-color $transition-hover ease;
					.menubtn-inner span {
						background-color: $color-white;
						//transform-origin: 50% 50%;

						&:nth-of-type(1) {
							transform: translateY(1.7rem);
							opacity: 0;
						}

						&:nth-of-type(2) {
							transform: scaleX(1) rotate(-45deg);
						}

						&:nth-of-type(3) {
							transform: scaleX(1) rotate(45deg);
						}

						&:nth-of-type(4) {
							transform: translateY(-1.7rem) scaleX(0.8727);
							opacity: 0;
						}
					}
				}

				@include media {
					position: relative;
					top: auto;
					right: auto;
					border-left: none;
					height: 5.0rem;
					width: 6.4rem;
					right: 0;
					top: 0;
					margin-right: -1.3rem;
				}
			}


				.menubtn-inner {
					width: 2.2rem;
					height: 1.4rem;
					position: relative;

					span {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						display: block;
						background-color: $color-text;
						height: .2rem;
						border-radius: .1rem;
						transform-origin: 50% 50%;
						transition: background-color $transition-hover ease;
						transition: transform $transition-hover ease, opacity $transition-hover ease, background-color $transition-hover ease $transition-hover;

						&:nth-of-type(2), &:nth-of-type(3){
							top: .6rem;
							transform: scaleX(0.818181) translateX(8.1%);
						}

						&:nth-of-type(4){
							top: 1.2rem;
							transform: scaleX(0.636363) translateX(25.3%)
						}
					}

					@include media {
						width: 2.4rem;
						height: 1.5rem;
					}
				}

			.nav-menu {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 5;
				color: $color-text-negative;

				&.show {
					.menu-overlay {
						opacity: 1;
						transition: opacity $transition-anim-fast ease;
					}

					.menu-outerwrap {
						opacity: 1;
						transform: translate3d(0, 0, 0);
						transition: transform $transition-anim-fast ease ($transition-anim-fast/2), opacity $transition-anim-fast ease ($transition-anim-fast/2);
						-webkit-overflow-scrolling: touch;
						*{
							-webkit-overflow-scrolling: touch;
						}
					}

						.content-innerwrap {
							opacity: 1;
							transform: translate3d(0, 0, 0);
							transition: transform $transition-anim-fast ease ($transition-anim-fast), opacity $transition-anim-fast ease ($transition-anim-fast);
						}
				}
			}

				.menu-outerwrap {
					background-color: $color-text;
					color: $color-text-negative;
					position: absolute;
					top: 0;
					right: 0;
					height: 100%;
					width: 58.4rem;
					opacity: 0;
					z-index: 2;
					transform: translate3d(100%, 0, 0);
					transition: transform $transition-anim-fast ease, opacity $transition-anim-fast ease 100ms;

					@include media {
						width: 72.26%;
						/*left: 0;
						right: auto;
						transform: translate3d(-100%, 0, 0);*/
					}
				}

				.menu-overlay {
					position: absolute;
					top: 0;
					right: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					opacity: 0;
					transition: opacity $transition-anim-fast ease $transition-anim-fast;
					background: rgba($color-black, .7);

					@include media {
						background: rgba($color-text, .5);
					}
				}

				.menu-content {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					height: 100%;
					max-height: 100%;
				}

					.content-innerwrap {
						opacity: 0;
						font-size: 1.4rem;
						transform: translate3d(20%, 0, 0);
						transition: transform $transition-anim-fast ease, opacity $transition-anim-fast ease 100ms;
						padding: 10.1rem 8.1rem 7rem 6.3rem;

						@include media {
							transform: translate3d(-6%, 0, 0);
							padding: 2.1rem 3rem 3.2rem;
						}
					}

				.menu-header {
					display: flex;
					justify-content: space-between;

					@include media {
						justify-content: flex-start;
					}
				}

					.menu-logo {
						height: 2.2rem;
					}

					.menu-social {
						font-size: 0;
						letter-spacing: 0;
						line-height: 0;
						a {
							display: inline-block;
							width: 3rem;
							img {
								width: 100%;
							}

							+ a {
								margin-left: 1rem;
							}

							@include media {
								width: 2.6rem;
							}
						}
					}

				.menu-userbar {
					@include font-regular;
					margin-top: 2rem;
					font-size: 1.6rem;
					line-height: 3rem;
					text-decoration: underline;
					color: $color-turquoise;
				}

					.userbar-link {
						+ .userbar-link {
							margin-left: 2.9rem;
						}
					}

				.menu-items {
					@include font-extralight;
					font-size: 3.2rem;
					margin-top: 1.3rem;

					@include media {
						font-size: 1.6rem;
						border-bottom: .1rem solid rgba($color-text-negative, .1);
						border-top: .1rem solid rgba($color-text-negative, .1);
					}
				}

					.menu-item {
						a {
							display: flex;
							justify-content: space-between;
							height: 8rem;
							align-items: center;

							@include media {
								height: 5.5rem;
							}
						}

						.item-count {
							font-size: 1.6rem;
							opacity: .2;
						}

						+ .menu-item {
							border-top: .1rem solid rgba($color-text-negative, .1);
						}
					}

				.menu-sublinks {
					@include font-regular;
					font-size: 1.4rem;
					text-transform: uppercase;
					margin-top: 4rem;

					@include media {
						margin-top: 2.7rem;
					}
				}

					.sublinks-item {
						display: inline-block;
						margin-right: 5rem;
						margin-bottom: 2rem;

						@include media {
							font-size: 1.1rem;
							line-height: 1.3rem;
							display: block;
							margin-right: 0;
							margin-bottom: 0;

							+ .sublinks-item {
								margin-top: 2.7rem;
							}
						}
					}

				.menu-thumblinks {
					margin-top: 6rem;
					line-height: 0;

					@include media {
						margin-top: 2.7rem;
						padding-top: 2rem;
						border-top: .1rem solid rgba($color-text-negative, .1);
					}
				}

					.thumblinks-item {
						@include font-regular;
						display: inline-block;
						width: 20rem;

						&.full {
							width: 100%;
						}

						@include media {
							line-height: 1.8rem;
						}

						&:nth-child(2n+2){
							margin-left: 2rem;

							@include media {
								margin-left: 0;
								margin-top: 1.7rem;
							}
						}
					}

					.thumblinks-image {
						margin-bottom: 1.2rem;

						@include media {
							margin-bottom: .8rem;
						}
					}

				.menu-applinks {
					margin-top: 8rem;

					@include media {
						margin-top: 1.9rem;
						border-top: .1rem solid rgba($color-text-negative, .1);
						padding-top: 3rem;
					}
				}

					.applinks-link{
						display: inline-block;
						width: calc(50% - 1rem);

						@include media {
							width: calc(50% - .5rem);
							padding-left: 0;
							padding-right: 0;

							.btn-icon.pre{
								margin-right: .5rem;
							}
						}

						+ .applinks-link {
							margin-left: 2rem;

							@include media {
								margin-left: 1rem;
							}
						}
					}
	}
}

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}
.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.nav-link-drop{
	background: white!important;
	border:none!important;
	color:black!important;
	font-weight: 600!important;
	font-size: 1.4rem!important;
	display: inline-block;
	vertical-align: middle;
	text-transform: uppercase;
	transition: color 200ms ease;
	color:black!important;


}
.dropdown-item{
	background: white!important;
	border:none!important;
	color:black!important;
	font-weight: 600!important;
	font-size: 1.4rem!important;
	display: inline-block;
	vertical-align: middle;
	text-transform: uppercase;
	transition: color 200ms ease;
}
.nav-link-drop-hamburger{
	position: absolute!important;
	top:-2rem!important;
	left: 0!important;

	background: transparent!important;
	color:white!important;
	font-weight: 200!important;
	font-size: 2rem!important;

}
@media screen and (min-width: 1200px) {
	.nav-link-drop-hamburger{
		top:-8rem!important;
		font-size: 2.2rem !important;
		left: -1rem!important;
	}
}
