.section {
	&.dealer-detail {
		display: flex;

		@include media {
			display: block;
		}

		.detail-info {
			width: 28rem;

			@include media {
				width: auto;
			}
		}

			.info-sum {
				position: relative;
				background-color: $color-gray-light;
				padding: 3rem 2rem 4rem;
				text-align: center;
				min-height: 37.2rem;

				.sum-favbtn {
					position: absolute;
					top: 2rem;
					right: 2rem;
				}

				.sum-avatar {
					width: 12rem;
					height: 12rem;
					border-radius: 50%;
					overflow: hidden;
					display: block;
					margin: 0 auto 1.1rem;
				}

				.sum-title {
					@include font-semibold;
					font-size: 1.8rem;
					line-height: 2.2rem;
				}

					.title-badge {
						position: relative;
						width: 2.2rem;
						height: 2.2rem;
						font-size: 2.2rem;
						color: $color-primary;
						display: inline-block;
						vertical-align: middle;
						margin-right: 1rem;
						margin-top: -.2em;
					}

						.badge-icon {
							color: $color-white;
							font-size: 1.2rem;
							position: absolute;
							z-index: 2;
							top: 53%;
							left: 50%;
							transform: translate3d(-50%, -50%, 0);
						}

				/*.sum-address {
					font-size: 1.4rem;
					line-height: 2rem;
					margin-top: 2.2rem;
				}

					.address-showonmap {
						color: $color-primary;
						text-decoration: underline;
					}*/

				.sum-workinghours {
					display: block;
					font-size: 1.2rem;
					line-height: 1.4rem;
					text-transform: uppercase;
					margin-top: .5rem;
					color: $color-error;

					span {
						margin: 0 1rem;
					}

					&.open {
						color: $color-success;
					}
				}

				.sum-controls {
					margin-top: 2.8rem;
					font-size: 0;
					letter-spacing: 0;

					.btn {
						//width: calc(50% - .6rem);
						//font-size: 1.1rem;
						//padding: .9rem 0;

						//&:nth-child(2n+2){
						//	margin-left: 1.2rem;
						//}

						.btn-icon {
							font-size: 1.3rem;

							&.pre {
								margin-right: .5rem;
							}
						}
					}
				}

				.sum-numbers {
					margin-top: 4.5rem;
					//color: $color-primary;
					font-size: 1.4rem;
					//text-decoration: underline;
					text-transform: uppercase;
				}

					.numbers-elem {
						+ .numbers-elem {
							margin-left: 2.8rem;
						}
					}
			}

			/*.info-search {
				position: relative;
				padding: 1.9rem 1.1rem 1.9rem 2.2rem;
			}

				.search-submit {
					width: 3.8rem;
					position: absolute;
					right: 1.9rem;
					top: 50%;
					font-size: 1.4rem;
					color: rgba($color-text, .3);
					transform: translate3d(0, -50%, 0);
				}*/

			.info-branches {
				padding: 2.8rem 2.4rem 4rem 2.4rem;

				@include media {
					padding: 1.4rem 0;
				}
			}

				.branches-head {
					padding: 0 0 1.1rem 0;
				}

					/*.head-info {
						font-size: 1.4rem;
						color: $color-primary;
						text-transform: uppercase;
						text-decoration: underline;

						+ .head-info {
							margin-left: 2.8rem;
						}
					}*/

					.head-title {
						@include font-semibold;
						color: rgba($color-text, .5);
						font-size: 1.2rem;
						line-height: 1.4rem;
						display: block;
						text-transform: uppercase;
					}

				.branches-list {

					.list-branch {
						//border-top: .1rem solid $color-ice;
						border-bottom: .1rem solid $color-ice;


						&.open {

							.branch-details .collapse-innerwrap{
								background-color: $color-gray-light;
							}
						}
					}

						.branch-sum {
							position: relative;
							text-align: left;
							display: block;
							width: 100%;
							padding: 1.5rem 0 1.5rem;

							&:before {
								@include icon('angle-down');
								position: absolute;
								right: 1.6rem;
								color: $color-gray;
								font-size: .9rem;
								top: 2.3rem;
								line-height: 1.4rem;
							}
						}

						.branch-title {
							@include font-semibold;
							color: rgba($color-text, .8);
							font-size: 1.6rem;
							line-height: 2rem;

							&:before {
								@extend %pseudo;
								width: .5rem;
								height: .5rem;
								border-radius: 50%;
								background-color: $color-success;
								display: inline-block;
								vertical-align: middle;
								margin-right: .6rem;
							}
						}

							.title-listingcount {
								@include font-medium;
								font-size: 1.2rem;
								color: $color-text-light;
								margin-left: .2rem;
							}

						.branch-workinghours {
							@include font-medium;
							display: block;
							font-size: 1.2rem;
							line-height: 1.4rem;
							text-transform: uppercase;
							//margin-top: .3rem;
							color: $color-error;

							span {
								margin: 0 1rem;
							}

							&.open {
								color: $color-success;
							}
						}

						.branch-details {
							.collapse-innerwrap {
								padding: 1rem 1.1rem 1.7rem 1.1rem;
								transition: background-color $transition-hover ease;
							}
						}

							.details-showonmap {
								@include font-medium;
								font-size: 1.4rem;
								display: inline-block;
								margin: 1.5rem 0 0;
								line-height: 2rem;
								color: $color-primary;
								text-decoration: underline;
							}

							.details-controls {
								font-size: 0;
								letter-spacing: 0;
								margin-top: 1.7rem;

								.btn {
									//width: calc(50% - .6rem);
									font-size: 1.1rem;
									//padding: .9rem 0;

									&:nth-child(2n+2){
										margin-left: .7rem;
									}

									.btn-icon {
										font-size: 1.3rem;

										&.pre {
											margin-right: .5rem;
										}
									}
								}
							}
				}

				.branches-extend {
					@include font-medium;
					font-size: 1.4rem;
					color: $color-primary;
					margin-top: 1.5rem;
				}

			/* .info-filters {
				margin-top: 4rem;
			} */

		.detail-right {
			width: calc(100% - 28rem);
			.breadcrumbs{
				padding: 1.8rem 0;
			}
			@include media {
				width: auto;
			}
		}

		.dealer-cover {
			height: 37.2rem;
			width: 100%;
			background-color: $color-ice;

			.loaderwrap-icon{
				top: auto;
				left: auto;
				right: -1rem;
				bottom: -1rem;
				font-size: 30rem;
				transform: none;
			}
		}



		.dealer-listing {
			border-left: .1rem solid $color-gray-light;

			@include media {
				border-left: none;
			}
		}
	}
}
.popup-cover {
	max-width: 480px;
}
.popup-image {
	.modal-content {
		width: 480px;
	}
}
